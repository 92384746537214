import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Container, Row} from "react-bootstrap";
import '../category/Category.scss';
import {useTranslation} from "react-i18next";

const CategoryEso = () => {
    //MARK -const

    const {t} = useTranslation();
    const [categories, setCategories] = useState([]);
    const [cat1, setCat1] = useState([]);
    const [cat2, setCat2] = useState([]);
    const [cat3, setCat3] = useState([]);
    const [cat4, setCat4] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [selected, setSelected] = useState({
        cat1name: '',
        cat2name: '',
        cat3name: '',
        cat4name: '',
        cat1code: '',
        cat2code: '',
        cat3code: '',
        cat4code: '',
        code: ''
        
    });

    //MARK -effect
   useEffect(() => {
        if(!loaded){
            axios.get('api/categoryEso?langId=2').then(res => {
                setCategories(res.data.items);
                setCat1(res.data.items.filter(f => f.cat1 > 0 && f.cat2 === 0 && f.cat3 ===0 && f.cat4 === 0));
                console.log(res.data);
                setLoaded(true);

            });
        }
        
    },[loaded] );
    

   
    
    //MARK -actions
    
    const handleCatClickAction = (event) => {
        let level = event.target.getAttribute('data-level');
        let code = event.target.getAttribute('data-code');
        let name = event.target.getAttribute('data-name');
        let cat1 = parseInt(event.target.getAttribute('data-cat1'));
        let cat2 = parseInt(event.target.getAttribute('data-cat2'));
        let cat3 = parseInt(event.target.getAttribute('data-cat3'));
        let cat4 = parseInt(event.target.getAttribute('data-cat4'));
        let arr = [];

        switch(level) {
            case '1':
                
                
                arr = categories.filter(f => f.cat1 === cat1 && f.cat2 > 0 && f.cat3 === 0 && f.cat4 === 0  );
                setCat2(arr);
                setSelected(prevState => ({...prevState, cat1name: name, cat1code: cat1, cat2name: '', cat2code: '', cat3name: '', cat3code: '', cat4name: '', cat4code: '', code: code}))
                setCat3([]);
                setCat4([]);
                break;
                
            case '2':
                arr = categories.filter(f => f.cat1 === cat1 && f.cat2 === cat2 && f.cat3 > 0 && f.cat4 === 0  );
                setCat3(arr);
                setSelected(prevState => ({...prevState, cat2name: name, cat2code: cat2, cat3name: '', cat3code: '', cat4name: '', cat4code: '', code: code}))
                setCat4([]);
                break;

            case '3':
                arr = categories.filter(f => f.cat1 === cat1 && f.cat2 === cat2 && f.cat3 === cat3 && f.cat4 > 0  );
                setCat4(arr);
                setSelected(prevState => ({...prevState, cat3name: name, cat3code: cat3, cat4name: '', cat4code: '', code: code}))
                break;

            case '4':
                setSelected(prevState => ({...prevState, cat4name: name, cat4code: cat4, code: code}))
                break;
            
            default:
            // code block
        }
    }
    
    //MARK - componenst

    const CatBox = (props) => {
        return (

            <div className="containerItem">
                <h2>{t('menu.category')} {props.level}</h2>

                <div className="cat">
                    {props.data.length > 0 && props.data.map((x, k) => {
                        return (
                            <div className={ x.code === props.selectedCode ? "selectedCategory item" : "item" } key={k} data-index={k} data-level={props.level}  data-cat1={x.cat1} data-cat2={x.cat2} data-cat3={x.cat3} data-cat4={x.cat4} data-code={x.code} data-name={x.name} onClick={(event) =>{handleCatClickAction(event)}}>
                                {x.name}
                            </div>
                        )
                    })

                    }
                </div>
            </div>

        );
    }


    

    return (
        <Container fluid={true} className="categories">
            <Row className="p-1">
                <h1>{t('menu.categoryEso')}</h1>
            </Row>
            <Row className="p-3">
                <span className={"catStringEso"}>{selected.code}<span className={"delimeter"}>|</span>{selected.cat1name}<span className={"delimeter"}>|</span>{selected.cat2name}<span className={"delimeter"}>|</span>{selected.cat3name}<span className={"delimeter"}>|</span>{selected.cat4name}</span>
            </Row>
            <Row className="containerCat p-3">
                <CatBox level={1} data={cat1} selectedCode={selected.cat1code.toString() + '000000'} />
                <CatBox level={2} data={cat2} selectedCode={selected.cat1code.toString() + selected.cat2code.toString() + '0000'} />
                <CatBox level={3} data={cat3} selectedCode={selected.cat1code.toString() + selected.cat2code.toString() + selected.cat3code.toString() + '00'} />
                <CatBox level={4} data={cat4} selectedCode={selected.cat1code.toString() + selected.cat2code.toString() + selected.cat3code.toString() + selected.cat4code.toString()} />
            </Row>
        </Container>
    );
}

export default CategoryEso;