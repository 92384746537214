import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import axios from "axios";
import Modal from "../shared/modal/Modal";
import './Category.scss';
import AddCategory from "./AddCategory";
import Langs from "../shared/langs/Langs";
import {faWindowClose} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {Button, Container, Row} from "react-bootstrap";
import {useUserContext} from "../../contexts/UserContext";
import {IsReadOnly} from "../../helpers/RoleProvider";

const Category = () => {


    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [modalNewOpen, setModalNewOpen] = useState(false);

    const {i18n, t} = useTranslation();
    const user = useUserContext();
    const [loaded, setLoaded] = useState(false);
    const [langAbbrv, setLangAbbrv] = useState(i18n.language);
    const [langs, setLangs] = useState([]);

    const [editCatId, setEditCatId] = useState(0);

    const [categories, setCategories] = useState([]);

    const [search1, setSearch1] = useState('');
    const [searchCat1, setSearchCat1] = useState([]);

    const [search2, setSearch2] = useState('');
    const [searchCat2, setSearchCat2] = useState([]);

    const [search3, setSearch3] = useState('');
    const [searchCat3, setSearchCat3] = useState([]);

    const [search4, setSearch4] = useState('');
    const [searchCat4, setSearchCat4] = useState([]);

    const [search5, setSearch5] = useState('');
    const [searchCat5, setSearchCat5] = useState([]);

    const [catString, setCatString] = useState({
        '1': '',
        '2': '',
        '3': '',
        '4': '',
        '5': ''
    });

    const [cat1, setCat1] = useState(0);
    const [cat2, setCat2] = useState(0);
    const [cat3, setCat3] = useState(0);
    const [cat4, setCat4] = useState(0);
    const [cat5, setCat5] = useState(0);
    const [forModal, setForModal] = useState({
        catid: 0,
        level: 0,
        cat1id: 0,
        cat2id: 0,
        cat3id: 0,
        cat4id: 0,
        cat5id: 0,
    });


    useEffect(() => {

        const getCategory = () => {
            axios.get('/category/' + langAbbrv + '/get').then(res => {
                setCategories(res.data);
                setLoaded(true);
            });
        }

        const getLangs = () => {
            axios.get('/lang').then(res => {
                setLangs(res.data);
            });

        }

        if (!loaded) {
            getLangs();
            getCategory();
        }
    }, [loaded, langAbbrv]);

    const vymazatVyber = () => {
        setCat1(0);
        setCat2(0);
        setCat3(0);
        setCat4(0);
        setCat5(0);
        setCatString({
            '1': '',
            '2': '',
            '3': '',
            '4': '',
            '5': ''
        });
    }

    const setLang = (lang) => {
        setLangAbbrv(lang);
        setLoaded(false);
    }


    const search = (e) => {
        let text = e.target.value.toLowerCase();
        let level = e.target.getAttribute("level");
        let searchData = '';
        switch (level) {
            case '1':
                setSearch1(text);
                searchData = categories.filter(x => x.nazevLang.toLowerCase().indexOf(text) > -1);
                setSearchCat1(searchData);
                break;
            case '2':
                setSearch2(text);
                searchData = categories.filter(x => x.nazevLang.toLowerCase().indexOf(text) > -1);
                setSearchCat2(searchData);
                break;
            case '3':
                setSearch3(text);
                searchData = categories.filter(x => x.nazevLang.toLowerCase().indexOf(text) > -1);
                setSearchCat3(searchData);
                break;
            case '4':
                setSearch4(text);
                searchData = categories.filter(x => x.nazevLang.toLowerCase().indexOf(text) > -1);
                setSearchCat4(searchData);
                break;
            case '5':
                setSearch5(text);
                searchData = categories.filter(x => x.nazevLang.toLowerCase().indexOf(text) > -1);
                setSearchCat5(searchData);
                break;
            default:
                setSearch1(text);
                searchData = categories.filter(x => x.nazevLang.toLowerCase().indexOf(text) > -1);
                setSearchCat1(searchData);
                break;
        }


    }


    const categorySelectHandler = (e) => {
        let level = e.target.getAttribute("level");
        let name = e.target.getAttribute("name");
        let catId = e.target.id;
        setEditCatId(catId);
        setCat1(0);
        setCat2(0);
        setCat3(0);
        setCat4(0);
        setCat5(0);
        switch (level) {
            case '1':
                setCat1(e.target.getAttribute("cat1"));
                setCatString(prevState => ({
                    ...prevState,
                    '1': name,
                    '2': '',
                    '3': '',
                    '4': '',
                    '5': ''
                }))
                break;

            case '2':
                setCat1(e.target.getAttribute("cat1"));
                setCat2(e.target.getAttribute("cat2"));
                setCatString(prevState => ({
                    ...prevState,
                    '2': name,
                    '3': '',
                    '4': '',
                    '5': ''
                }))
                break;

            case '3':
                setCat1(e.target.getAttribute("cat1"));
                setCat2(e.target.getAttribute("cat2"));
                setCat3(e.target.getAttribute("cat3"));
                setCatString(prevState => ({
                    ...prevState,
                    '3': name,
                    '4': '',
                    '5': ''
                }))
                break;
            case '4':
                setCat1(e.target.getAttribute("cat1"));
                setCat2(e.target.getAttribute("cat2"));
                setCat3(e.target.getAttribute("cat3"));
                setCat4(e.target.getAttribute("cat4"));
                setCatString(prevState => ({
                    ...prevState,
                    '4': name,
                    '5': ''
                }))
                break;
            case '5':
                setCat1(e.target.getAttribute("cat1"));
                setCat2(e.target.getAttribute("cat2"));
                setCat3(e.target.getAttribute("cat3"));
                setCat4(e.target.getAttribute("cat4"));
                setCat5(e.target.getAttribute("cat5"));
                setCatString(prevState => ({
                    ...prevState,
                    '5': name
                }))
                break;
            default:
                setCat1(e.target.getAttribute("cat1"));
                setCatString(prevState => ({
                    ...prevState,
                    '1': name,
                    '2': '',
                    '3': '',
                    '4': '',
                    '5': ''
                }))
                break;

        }
    }

    const openModalAddCat = (level) => {

        switch (level) {
            case  1:
                break;
            case 2:
                if (cat1 === 0) {
                    alert('Vyberte nadřazené kategorie');
                    return false;
                }
                break;
            case 3:
                if (cat2 === 0) {
                    alert('Vyberte nadřazené kategorie');
                    return false;
                }
                break;
            case 4:
                if (cat3 === 0) {
                    alert('Vyberte nadřazené kategorie');
                    return false;
                }
                break;
            case 5:
                if (cat4 === 0) {
                    alert('Vyberte nadřazené kategorie');
                    return false;
                }
                break;
            default:
                return false;

        }
        let data = {
            level: level,
            cat1id: cat1,
            cat2id: cat2,
            cat3id: cat3,
            cat4id: cat4,
            cat5id: cat5,
            catid: 0
        }

        setForModal(data);
        setModalNewOpen(true);
    };

    const openModalEditCat = (level) => {
        switch (level) {
            case  1:
                if (cat1 === 0) {
                    alert('Vyberte kategorii 1');
                    return false;
                }
                setEditCatId(cat1);
                break;
            case 2:
                if (cat2 === 0) {
                    alert('Vyberte kategorii 2');
                    return false;
                }
                setEditCatId(cat2);
                break;
            case 3:
                if (cat3 === 0) {
                    alert('Vyberte kategorii 3');
                    return false;
                }
                setEditCatId(cat3);
                break;
            case 4:
                if (cat4 === 0) {
                    alert('Vyberte kategorii 4');
                    return false;
                }
                setEditCatId(cat4);
                break;
            case 5:
                if (cat5 === 0) {
                    alert('Vyberte kategorii 5');
                    return false;
                }
                setEditCatId(cat5);
                break;
            default:
                alert('Došlo k chybě výběru správné kategorie.');
                return false;

        }

        let data = {
            level: level,
            catid: editCatId,
            cat1id: cat1,
            cat2id: cat2,
            cat3id: cat3,
            cat4id: cat4,
            cat5id: cat5
        }
        setForModal(data);

        setModalEditOpen(true);
    };


    return (


        <Container fluid={true} className="categories">
            <div className="catString">
                <span title="Vymazat aktuální výběr" onClick={vymazatVyber} className="icon"><FontAwesomeIcon
                    icon={faWindowClose}/></span>
                <h2>Vybraný strom kategorií:</h2>
                {catString['1'] > '' ? <div className="catStringItem"><span className="num">1</span><span
                    className="value">{catString['1']}</span></div> : null}
                {catString['2'] > '' ? <div className="catStringItem"><span className="num">2</span><span
                    className="value">{catString['2']}</span></div> : null}
                {catString['3'] > '' ? <div className="catStringItem"><span className="num">3</span><span
                    className="value">{catString['3']}</span></div> : null}
                {catString['4'] > '' ? <div className="catStringItem"><span className="num">4</span><span
                    className="value">{catString['4']}</span></div> : null}
                {catString['5'] > '' ? <div className="catStringItem"><span className="num">5</span><span
                    className="value">{catString['5']}</span></div> : null}
            </div>
            <Row className={"p-3"}>
                <Langs langs={langs} setLang={setLang} actualLang={langAbbrv}/>
            </Row>


            <Row className="containerCat p-3">
                <div className="containerItem">
                    <h2>Kategorie 1</h2>
                    <input className={"form-control search"} type="search" level="1" placeholder="Search.."
                           onChange={search}/>
                    <div className="cat cat1">

                        {categories && categories.map((x, k) => {
                                return (x.cat2 === 0) && ((search1 === '' && searchCat1.length === 0) || searchCat1.find(z => z.id === x.id) != null ?
                                    <div key={k} data-index={k} id={x.id} level="1" cat1={x.cat1} name={x.nazevLang}
                                         className={parseInt(cat1) === parseInt(x.cat1) ? 'selectedCategory item' : 'item'}
                                         onClick={categorySelectHandler}>{x.nazevLang}</div> : null)

                            }
                        )}
                    </div>
                    <div className="ctrl">
                        {!IsReadOnly(user) ?
                            <React.Fragment>
                                <Button variant={"primary"} className="item" onClick={() => openModalAddCat(1)}>
                                    <FontAwesomeIcon icon={faPlus} className="i"/>
                                    {t("category.addCat")} 1
                                < /Button>
                                <Button variant={"secondary"} className="item"
                                        onClick={() => openModalEditCat(1)}>
                                    <FontAwesomeIcon icon={faPencilAlt} className="i"/>
                                    {t("category.editCat")}
                                </Button>
                            </React.Fragment> : null}
                    </div>
                </div>
                <div className="containerItem">
                    <h2>Kategorie 2</h2>
                    <input className={"form-control search"} type="search" placeholder="Search.." level="2"
                           onChange={search}/>
                    <div className="cat cat2">

                        {cat1 === 0 ? <span className="note">Vyberte nějakou Kategorii 1</span> : null}

                        {categories && categories.map((x, k) => {
                                return (parseInt(x.cat1) === parseInt(cat1) && parseInt(x.cat2) > 0 && parseInt(x.cat3) === 0) && ((search2 === '' && searchCat2.length === 0) || searchCat2.find(z => parseInt(z.id) === parseInt(x.id)) != null ?
                                    <div key={k} data-index={k} id={x.id} level="2" cat1={x.cat1} cat2={x.cat2}
                                         name={x.nazevLang}
                                         className={parseInt(cat2) === parseInt(x.cat2) ? 'selectedCategory item' : 'item'}
                                         onClick={categorySelectHandler}>{x.nazevLang}</div> : null)
                            }
                        )}


                    </div>
                    <div className="ctrl">
                        {!IsReadOnly(user) ?
                            <React.Fragment>
                                <Button variant={"primary"} className="item" onClick={() => openModalAddCat(2)}>
                                    <FontAwesomeIcon icon={faPlus} className="i"/>
                                    {t("category.addCat")} 2
                                </Button>
                                <Button variant={"secondary"} className="item"
                                        onClick={() => openModalEditCat(2)}><FontAwesomeIcon icon={faPencilAlt}
                                                                                             className="i"/>
                                    {t("category.editCat")}
                                </Button>
                            </React.Fragment> : null}
                    </div>
                </div>
                <div className="containerItem">
                    <h2>Kategorie 3</h2>
                    <input className={"form-control search"} type="search" placeholder="Search.." level="3"
                           onChange={search}/>
                    <div className="cat cat3">

                        {cat2 === 0 ? <span className="note">Vyberte nějakou Kategorii 2</span> : null}

                        {categories && categories.map((x, k) => {
                                return (parseInt(x.cat1) === parseInt(cat1) && parseInt(x.cat2) === parseInt(cat2) && x.cat3 > 0 && x.cat4 === 0) && ((search3 === '' && searchCat3.length === 0) || searchCat3.find(z => z.id === x.id) != null ?
                                    <div key={k} data-index={k} id={x.id} level="3" cat1={x.cat1} cat2={x.cat2}
                                         cat3={x.cat3} name={x.nazevLang}
                                         className={parseInt(cat3) === parseInt(x.cat3) ? 'selectedCategory item' : 'item'}
                                         onClick={categorySelectHandler}>{x.nazevLang}</div> : null)
                            }
                        )}


                    </div>
                    <div className="ctrl">
                        {!IsReadOnly(user) ?
                            <React.Fragment>
                                <Button variant={"primary"} className="item" onClick={() => openModalAddCat(3)}>
                                    <FontAwesomeIcon icon={faPlus} className="i"/>
                                    {t("category.addCat")} 3
                                </Button>
                                <Button variant={"secondary"} className="item"
                                        onClick={() => openModalEditCat(3)}><FontAwesomeIcon icon={faPencilAlt}
                                                                                             className="i"/>
                                    {t("category.editCat")}
                                </Button>
                            </React.Fragment> : null}
                    </div>
                </div>
                <div className="containerItem">
                    <h2>Kategorie 4</h2>
                    <input className={"form-control search"} type="search" placeholder="Search.." level="4"
                           onChange={search}/>
                    <div className="cat cat4">

                        {cat3 === 0 ? <span className="note">Vyberte nějakou Kategorii 3</span> : null}

                        {categories && categories.map((x, k) => {
                                return (parseInt(x.cat1) === parseInt(cat1) && parseInt(x.cat2) === parseInt(cat2) && parseInt(x.cat3) === parseInt(cat3) && x.cat4 > 0 && x.cat5 === 0) && ((search4 === '' && searchCat4.length === 0) || searchCat4.find(z => z.id === x.id) != null ?
                                    <div key={k} data-index={k} id={x.id} level="4" cat1={x.cat1} cat2={x.cat2}
                                         cat3={x.cat3} cat4={x.cat4} name={x.nazevLang}
                                         className={parseInt(cat4) === parseInt(x.cat4) ? 'selectedCategory item' : 'item'}
                                         onClick={categorySelectHandler}>{x.nazevLang}</div> : null)
                            }
                        )}


                    </div>
                    <div className="ctrl">
                        {!IsReadOnly(user) ?
                            <React.Fragment>
                                <Button variant={"primary"} className="item" onClick={() => openModalAddCat(4)}>
                                    <FontAwesomeIcon icon={faPlus} className="i"/>
                                    {t("category.addCat")} 4
                                </Button>
                                <Button variant={"secondary"} className="item"
                                        onClick={() => openModalEditCat(4)}><FontAwesomeIcon icon={faPencilAlt}
                                                                                             className="i"/>
                                    {t("category.editCat")}
                                </Button>
                            </React.Fragment> : null}
                    </div>
                </div>
                <div className="containerItem">
                    <h2>Kategorie 5</h2>
                    <input className={"form-control search"} type="search" placeholder="Search.." level="5"
                           onChange={search}/>
                    <div className="cat cat5">

                        {cat4 === 0 ? <span className="note">Vyberte nějakou Kategorii 4</span> : null}

                        {categories && categories.map((x, k) => {
                                return (parseInt(x.cat1) === parseInt(cat1) && parseInt(x.cat2) === parseInt(cat2) && parseInt(x.cat3) === parseInt(cat3) && parseInt(x.cat4) === parseInt(cat4) && x.cat5 > 0) && ((search5 === '' && searchCat5.length === 0) || searchCat5.find(z => z.id === x.id) != null ?
                                    <div key={k} data-index={k} id={x.id} level="5" cat1={x.cat1} cat2={x.cat2}
                                         cat3={x.cat3} cat4={x.cat4} cat5={x.cat5} name={x.nazevLang}
                                         className={parseInt(cat5) === parseInt(x.cat5) ? 'selectedCategory item' : 'item'}
                                         onClick={categorySelectHandler}>{x.nazevLang}</div> : null)
                            }
                        )}


                    </div>
                    <div className="ctrl">
                        {!IsReadOnly(user) ?
                            <React.Fragment>
                                <Button variant={"primary"} className="item" onClick={() => openModalAddCat(5)}>
                                    <FontAwesomeIcon icon={faPlus} className="i"/>
                                    {t("category.addCat")} 5
                                </Button>
                                <Button variant={"secondary"} className="item"
                                        onClick={() => openModalEditCat(5)}>
                                    <FontAwesomeIcon icon={faPencilAlt} className="i"/>
                                    {t("category.editCat")}
                                </Button>
                            </React.Fragment> : null}
                    </div>
                </div>
            </Row>


            <Modal isOpen={modalNewOpen} onClose={() => {
                setModalNewOpen(false)
            }}>
                <AddCategory langs={langs} forModal={forModal} edit={false} setEditcatId={setEditCatId}
                             catString={catString} saveModal={() => {
                    setModalNewOpen(false);
                    setLoaded(false)
                }}/>
            </Modal>

            <Modal isOpen={modalEditOpen} onClose={() => {
                setModalEditOpen(false)
            }}>
                <AddCategory langs={langs} forModal={forModal} edit={true} setEditcatId={setEditCatId}
                             catString={catString} saveModal={() => {
                    setModalEditOpen(false);
                    setLoaded(false)
                }}/>
            </Modal>

        </Container>
    );

}

export default Category;