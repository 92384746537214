import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBoxes,
    faBoxOpen,
    faBuilding,
    faPaperPlane, faSave, faShoppingBasket, faTrash,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import "./forecastBasket.scss";
import {Alert, Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import NumberFormat from "react-number-format";
import {ADD, useAlertContext} from "../../../contexts/AlertContext";
import axios from "axios";
import Form from 'react-bootstrap/Form';
import DataTable from "react-data-table-component";
import Badge from "react-bootstrap/Badge";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import {useTranslation} from "react-i18next";
import Modal from "../../shared/modal/Modal";
import {ForecastStocksCont} from "./ForecastStocksCont";
import LabelInfo from "../../LabelInfo";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import Select from "react-select";
import theme2Styles from "../../../config/dataTable/theme2Styles";


export const ForecastBasket = (props) => {

    const noNote = "";
    const {t, i18n} = useTranslation();

    const handleRowClicked = (row) => setSelectedRow(row.cisZbozi);
    

    const basket = props.basket;
    
    const [objem, setObjem] = useState(0);
    const [cena, setcena] = useState(0);
    const {alertDispatch} = useAlertContext();
    const [supPoznamka, setSupPoznamka] = useState(noNote);
    const [isTest, setIsTest] = useState(false);
    const [esoResponse, setEsoResponse] = useState(null);
    const [isOpen, setOpen] = useState(false);

    const [selectedRows, setSelectedRows] = React.useState([]);
    const [toggleCleared, setToggleCleared] = React.useState(false);

    const handleRowSelected = React.useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

    const [selectedRow, setSelectedRow] = useState(null);


    const filter = props.filter;


    const [idzbozi, setIdzbozi] = React.useState(0);


    const openDetailModal = (idzbozi) => {
        setOpen(true);
        setIdzbozi(idzbozi);

    }
  

   /* useEffect(() => {
        let fobjem = 0;
        let fcena = 0;
        for (let i = 0; i < basket.length; i++) {
            fobjem += (parseFloat(basket[i]['mnObjem']) * parseInt(basket[i]['amountInBasket']));
            fcena += (parseFloat(basket[i]['amountInBasket']) * parseFloat(basket[i]['hdCena']));
        }

        setObjem(fobjem);
        setcena(fcena);

    }, [basket]);*/
    
    const deleteAll = (where) => {
        let data = {
            supplierCode : filter.supplier,
            supplyPlanner : filter.supplyPlanner === 'All' ? '' : filter.supplyPlanner
        }
        axios
            .post('api/reports/forecast/deleteAllBasketItems', data).then(res => {

            if (res.data.state === 0) {

                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {state: 'success', message: 'Položky byly vymazány'}
                    }
                });
                //refresh basket and reset filter
                if(where === 'supplier')
                    onSupplierChange('', 'All', 0)
                if(where === 'supplyPlanner')
                    onSupplyPChange('All')
                
            } else {

              

                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {
                            state: 'warning',
                            message: 'Došlo k chybě.'
                        }
                    }
                });
            }

        })
            .catch(err => {
                console.log(err);
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {
                            state: 'warning',
                            message: 'Došlo k chybě.'
                        }
                    }
                });

            })
        
    }
    
    const deleteSelectedRows = () => {
       
        if(selectedRows.length === 0){
            alertDispatch({
                type: ADD,
                payload: {
                    content: {state: 'warning', message: 'Nejsou vybrány žádné položky.'}
                }
            });
            return false;
        }
        let formData = new FormData();
        for (let i = 0; i < selectedRows.length; i++) {
            console.log(selectedRows[i]);
            formData.append("data", selectedRows[i]['cisZbozi']);
        }
        
      
        axios.post('api/reports/forecast/deleteBasketItems', formData).then(res => {

            if (res.data.state === 0) {

                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {state: 'success', message: 'Položky byly vymazány'}
                    }
                });

                props.refreshBasket();
              

            } else {
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {
                            state: 'warning',
                            message: 'Došlo k chybě.'
                        }
                    }
                });
            }
        });
    };

 

    const sendToEsoHandler = () => {
        let esoString = {};
        let codesArr = [];
        //{"vlTyp":1008,"params":[{"name":"VLNEANOTEST","value":1},{"name":"HDOK","value":[{"IDSUBJEKT":9932,"KOD_UZIV":"pinm","SDOK":[{"CIS_ZBOZI":"1320100210","MNPOCET":1700.00000},{"CIS_ZBOZI":"1324919722","MNPOCET":5600.00000}]}]}]}
        esoString.vlTyp = 1008;
        esoString.params = [];
        esoString.params.push({name: "VLNEANOTEST", value: isTest ? 1 : 0});
        esoString.params.push({name: "HDOK", value: []});
        esoString.params[1].value.push({
            "IDSUBJEKT": filter.supplierId,
            "KOD_UZIV": filter.supplyPlanner === "All" ? "" : filter.supplyPlanner,
            "SDOK": []
        });

        //data.supplierCode = props.filter.supplier;
        //data.supplierName = props.filter.supplierName;
        //data.supplierPlanner = props.filter.supplyP === "All" ? null : props.filter.supplyP;
        if (basket.length > 0) {
            for (let i = 0; i < basket.length; i++) {
                esoString.params[1].value[0].SDOK.push({
                    "CIS_ZBOZI": basket[i].cisZbozi,
                    "MNPOCET": basket[i].amountInBasket
                });
                codesArr.push(basket[i].cisZbozi);
            }
        } else {
            alertDispatch({
                type: ADD,
                payload: {
                    content: {
                        state: 'warning',
                        message: 'Položky jsou prázdné.'
                    }
                }
            });

            return false;

        }

        esoString = JSON.stringify(esoString);
        let data = new FormData();
        data.append("esoString", esoString);
        for (let i = 0; i < codesArr.length; i++) {
            data.append("codes", codesArr[i]);
        }
        

        axios
            .post('api/reports/forecast/sendBasketToEso', data, {'Content-Type': 'multipart/form-data'}).then(res => {

            let resData = res.data;
            resData.response = JSON.parse(res.data.rawResponse);
           
            if (res.data.state === 0) {
                
                    alertDispatch({
                        type: ADD,
                        payload: {
                            content: {state: 'success', message: 'Objednávka byla úspěšně založena v ESO9.'}
                        }
                    });
                    props.resetFilter();
                    props.refreshBasket();
                

                setEsoResponse(resData);
            } else {

                resData.error = JSON.parse(resData.response.error);
                setEsoResponse(resData);
                
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {
                            state: 'warning',
                            message: 'Došlo k chybě.'
                        }
                    }
                });
            }

        })
            .catch(err => {
                console.log(err);
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {
                            state: 'warning',
                            message: 'Došlo k chybě.'
                        }
                    }
                });

            })

    }

    const onSupplierChange = (type, code, id) => {

        let filterNew = {...filter};
        filterNew['supplier'] = code;
        filterNew['supplierId'] = id;
        let supplier = [];
        if(code === "All"){
            filterNew.supplierName = "All";
        } else {
            supplier = props.suppliers.filter(f => f.kodSubjektu === code);
            if(supplier.length > 0) {
                filterNew.supplierName = supplier[0].subjNazev;
            }
        }

        props.filterHandler(filterNew);


        setEsoResponse(null);

        if (id === 0) {
            setSupPoznamka(noNote);
            return;
        }

        let data = new FormData();
        let bodyText = {"vlTyp": 1006, "params": [{"name": "IDSUBJEKT", "value": id}]};
        console.log(JSON.stringify(bodyText));

        data.append("bodyText", JSON.stringify(bodyText));
        //data.append("bodyText", JSON.stringify({"vlTyp":1006, "params":[{"name":"IDSUBJEKT","value":"16320"}]}));

        axios
            .post('api/reports/stocks/eso', data, {'Content-Type': 'multipart/form-data'})
            .then((res) => {


                if (res.data.state === 0) {

                    let response = JSON.parse(res.data.rawResponse);
                    if (response.vlResult === 1) {
                        setSupPoznamka(response.data[0].SUBJPOZNMEMO);
                    } else {
                        setSupPoznamka(noNote);
                    }
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const onSupplyPChange = (code) => {

        let filterNew = {...filter};
        filterNew['supplyPlanner'] = code;

        props.filterHandler(filterNew);

       
    }

    const renderName = (props) => {

        return (
            <span>
                <span className="type">
                        {props.vlNeAnoVypadek === 1 ? <Badge variant="danger mr-1">K</Badge> : null}
                    {props.vlNeAnoVypadek === 0 && props.vlNeAnoVypadekBud === 1 ? <Badge variant="warning mr-1">V</Badge> : null}
                    {props.vlNeAnoVypadekBud === 0 && props.vlNeAnoVypadek === 0 ?
                        <Badge variant="success mr-1">Ok</Badge> : null}
                    </span>
                
                <span>
                    {props.nazevZbozi}
                </span>
            </span>
        )

    }

    const deleteItem = (code) => {
        let data = new FormData();
        //let codeArr = [];
        //codeArr.push(code);
        data.append("data", code);
        axios.post("api/reports/forecast/deleteBasketItems", data, {'Content-Type': 'multipart/form-data'}).then((res) => {
            if (res.data.state === 0) {
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {state: 'success', message: 'Ok'}
                    }
                });
                props.refreshBasket();
            } else {
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {
                            state: 'warning',
                            message: 'Chyba'
                        }
                    }
                });
                console.log(res.data);
            }

        })
            .catch(err => {
                console.log(err);
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {
                            state: 'warning',
                            message: 'Chyba'
                        }
                    }
                });

            });
    }

    const returnLastComm = (props) => {
        if(props.comments.length === 0){
            return(
                <div>
                    Položka nemá žádný komentář.
                </div>
            )
        }
        return(
            <div>
                Poslední komentář:
                <br></br>
                {props.comments[0].comment}
            </div>
        )

    }


    const renderActionButtons = (props) => {
        return (
            <span>
                <span>
                     <OverlayTrigger
                         placement='top'
                         overlay={
                             <Tooltip id={`tooltip-top`}>
                                 Detail
                             </Tooltip>
                         }
                     >
                        <Badge style={{cursor: 'pointer'}} variant="primary mr-1 p-2" onClick={() => openDetailModal(props.idZbozi)}>
                            <FontAwesomeIcon icon={faInfoCircle}/>
                        </Badge>
                    </OverlayTrigger>
                   
                </span>
                
                <span>
                     <OverlayTrigger
                         placement='right'
                         overlay={
                             <Tooltip id={`tooltip-right`}>
                                 {returnLastComm(props)}
                             </Tooltip>
                         }
                     >
                        
                      
                        <Badge style={{cursor: 'pointer'}}  variant="info mr-1 p-2">
                            {props.comments.length}
                        </Badge>
                    </OverlayTrigger>
                </span>
              
                
            </span>
        )

    }

    const renderDelButton = (props) => {
        return (
            <span>
                <span>
                     <OverlayTrigger
                         placement='top'
                         overlay={
                             <Tooltip id={`tooltip-top`}>
                                 Smazat
                             </Tooltip>
                         }
                     >
                        <Badge style={{cursor: 'pointer'}} variant="danger mr-1 p-2" onClick={() => deleteItem(props.cisZbozi)}>
                             <FontAwesomeIcon  icon={faTrash}   />
                        </Badge>
                    </OverlayTrigger>
                   
                </span>
            </span>
        )

    }

   
    
    const RenderAmount = (props) => {

        const [amount, setAmount] = useState(props.amountInBasket);
        const [error, setError] = useState(false);
        const [isChanged, setChanged] = useState(false);
        const objem = parseFloat(props.mnObjem);
        const karton = parseFloat(props.mnPocetVKartonu);
        const moq = parseInt(props.moq);
        const cena = parseFloat(props.hdCena);
        const kodMenyCheck = props.kodMeny ? true : false;
        //const [zasoba, setZasoba] = useState(((props.mnFyzickyStavObj + props.amountInBasket - props.mnBlokovanoCel) / props.mnProdejMesic ).toFixed(2));
        const [zasoba, setZasoba] = useState((( (props.mnFyzickyStavObj - props.mnMinZasoba - props.mnBlokovanoLt) / props.mnProdej90Deleno3) ).toFixed(2));

        const upsertBasket = () => {
            if (isNaN(amount) ){
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {
                            state: 'warning',
                            message: 'Množství MOQ musí být číslo.'
                        }
                    }
                });
                return false;

            }
            let data = {
                referenceCode: props.cisZbozi,
                amountInBasket: amount
            }
            axios
                .post('api/reports/forecast/upsertBasketItem', data)
                .then((res) => {
                    if (res.data.state === 0) {
                        alertDispatch({
                            type: ADD,
                            payload: {
                                content: {state: 'success', message: 'Ok'}
                            }
                        });
                        setChanged(false);
                        props.refreshBasket();
                    } else {
                        alertDispatch({
                            type: ADD,
                            payload: {
                                content: {
                                    state: 'warning',
                                    message: 'Chyba'
                                }
                            }
                        });
                        console.log(res.data);
                    }

                })
                .catch(err => {
                    console.log(err);
                    alertDispatch({
                        type: ADD,
                        payload: {
                            content: {
                                state: 'warning',
                                message: 'Chyba'
                            }
                        }
                    });

                });
        }

        const checkAmount = (val) => {
            let res = false;
            if((val % karton) !== 0 || (val < moq)){
                res =  true;
            }
            return res;
        }

        /*useEffect(() => {
            setAmount(props.amountInBasket);

        }, [])*/


        useEffect(() => {
            setError(checkAmount(amount));

        }, [amount])
        const handleAmountChange = (val) => {
          /*  if(isNaN(val) || val < 1){
                setAmount(amount);
                setError(true);
                return false;
            }*/
            
            console.log(val);


            setAmount((val));
            setZasoba(((props.mnFyzickyStavObj + val - props.mnBlokovanoCel) / props.mnProdejMesic ).toFixed(2))
            if(!isChanged){
                setChanged(true);
            }


        }
      
      
        return(
            <span style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <span style={{display: 'flex', flexDirection: 'column',  width: 90}}>
                    <span>
                        <Form.Control
                            aria-label="Default"
                            aria-describedby="inputGroup-sizing-default"
                            type="text"
                            value= {amount}
                            className="number"
                            style={{fontSize: 12, padding: 5, height: 25, width: 80,  marginRight: 3}}
                            onChange={(e) => handleAmountChange(parseInt(e.target.value))}
                        />
                    </span>
                    <span style={{display: 'flex', alignItems: 'center', marginTop: 5}}>
                          <div className="save">
                              <Button disabled={!isChanged} variant="success" size="sm" onClick={() => upsertBasket()} ><FontAwesomeIcon icon={faSave} /></Button>
                          </div>
                        <div className="error" style={{marginLeft: 5}}>
                            {error ? 
                                
                                    <OverlayTrigger
                                        placement='top'
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>
                                                Množství je chybně zadané, neodpovídá celému počtu kartonů a nebo je menší než MOQ
                                            </Tooltip>
                                        }
                                    >
                                    
                                        <FontAwesomeIcon  icon={faExclamationCircle} style={{color: 'red'}}  />
                                   
                                </OverlayTrigger>
                            
                                
                                : null}
                            {
                                props.upozorneniNob > "" ?
                                    <OverlayTrigger
                                        placement='top'
                                        overlay={
                                            <Tooltip id={`tooltip-top2`}>
                                                {props.upozorneniNob}
                                            </Tooltip>
                                        }
                                    >

                                        <FontAwesomeIcon  icon={faExclamationTriangle} style={{color: 'red', marginLeft: 3}}  />

                                    </OverlayTrigger>
                                    : null
                            }
                        </div> 
                    </span>
                   
                    
                   
                </span>
                <span style={{display: 'flex', flexDirection: 'column'}}>
                    <span>
                        <span>Kartonů:</span>
                        <span style={{marginLeft: 2}}>{(amount / karton).toFixed(2)}</span>
                    </span>
                    <span>
                        <span>Objem:</span>
                        <span style={{marginLeft: 2}}>{(amount * objem).toFixed(2)}</span>
                    </span>
                    <span>
                        <span>Cena:</span>
                        <span style={{marginLeft: 2}}>{(amount * cena).toFixed(2)}</span>
                        <span style={{marginLeft: 1}}>
                        { kodMenyCheck ?  props.kodMeny : <span style={{textAlign: "center", display:"flex", justifyContent:"center"}}> <LabelInfo label={"!"} iconText="Na položce chybí kód měny, položka tedy nemá přiřazený ceník."/></span>}
                        </span>
                    </span>
                    <span>
                       
                        <span>Zásoba (m):</span>
                        {zasoba > 5 ? <span style={{marginLeft: 2, color: 'red'}}  >{zasoba}</span> : <span style={{marginLeft: 2}}  >{zasoba}</span>}
                        
                    </span>
                </span>
            </span>
        )
    }

    const columns = ([
        {
            name: '#',
            cell: (row, index) => index + 1,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '70px',
            center: true
        },
        {
            name: '',
            cell: (row) => renderActionButtons(row),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '60px',
            center: true
        },

        {
            name: 'Ref',
            selector: 'cisZbozi',
            sortable: false,
            width: '100px',
            center: true
        },
        {
            name: 'Název',
            cell: (row) => renderName(row),
            sortable: false,
            minWidth: '190px'

        },
        {
            name: <div><div>Bal /</div><div>MJ</div></div>,
            cell: (row) => ( <div><div>{row.text1}</div><div>{row.kodMj}</div></div>),
            selector: 'text1',
            sortable: false,
            width: '120px'
        },
        
        {
            name: 'Prio.',
            selector: 'jkpov',
            sortable: false,
            width: '50px',
            center: true
        },
       
        {
            name: <div><div>Lead</div><div>time</div></div>,
            cell: (row) => ( <div><div>{row.dobaDodani + 'd'}</div><div>{row.tydnyDodani + 't'}</div></div>  ),
            sortable: false,
            width: '50px',
            center: true
        },
        {
            name: 'Cena',
            cell: (row) => row.hdCena.toFixed(2) + ' ' + row.kodMeny ,
            sortable: false,
            width: '110px',
            right: true
        },
        {
            name: <b>MOQ</b>,
            cell: (row) =>  <b>{row.moq}</b> ,
            sortable: false,
            width: '60px',
            right: true
        },
        {
            name: 'Karton',
            selector: 'mnPocetVKartonu',
            sortable: false,
            width: '60px',
            right: true
        },
        {
            name: 'Objem',
            cell: (row) => row.mnObjem.toFixed(2) ,
            sortable: false,
            width: '60px',
            right: true
        },
        {
          name: 'Doporučeno',
          selector: 'mnNavrhNobDoklad',
          sortable: false,
          width: '90px',
            right: true  
        },
        {
            name: <div><div>Množství - <span> <OverlayTrigger
                placement='top'
                overlay={
                    <Tooltip id={`tooltip-top`}>
                        ( (Stock qty w.o. – safety stock – block do LT) / (sales 90/3) )
                    </Tooltip>
                }
            >
                                    
                                        <FontAwesomeIcon  icon={faExclamationCircle} style={{color: '#bebeff', marginRight: 5}}  />
                                   
                                </OverlayTrigger></span>
                <span>Zásoba (m):</span></div></div>,
          cell: (row, index, column, id) =>   <RenderAmount {...row} index={index} refreshBasket={() => props.refreshBasket()} />,
            sortable: false,
            width: '260px'  
        },
        {
            name: '',
            cell: (row) => renderDelButton(row),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '30px',
            center: true
        },
       
        {
            name: 'Stock QTY',
            selector: 'mnFyzickyStav',
            sortable: false,
            width: '80px',
            right: true
        },
        {
            name: <b>Stock QTY w. o.</b>,
            cell: (row) => <b>{row.mnFyzickyStavObj}</b>,
            sortable: false,
            width: '110px',
            right: true
        },
        {
            name: <span> 
                    <OverlayTrigger placement='top'
                        overlay={<Tooltip id={`tooltip-top`}>Stock qty w.o. – blok do LT</Tooltip>}
                    >
                        <FontAwesomeIcon  icon={faExclamationCircle} style={{color: '#bebeff', marginRight: 5}}  />
                    </OverlayTrigger>
                    Výpočet
                </span>,
            selector: 'mnFyzickyStavObj',
            cell: (row) => ( row.mnFyzickyStavObj - row.mnBlokovanoLt ),
            sortable: false,
            width: '110px',
            right: true
        },
        {
            name: 'Safety stock',
            selector: 'mnMinZasoba',
            sortable: false,
            width: '90px',
            right: true
        },
        {
            name:  <div><div>Blok. celk./</div> <div><b>Blok. do LT</b></div></div>,
            cell: (row) => ( <div><div>{row.mnBlokovanoCel}</div> <div><b>{row.mnBlokovanoLt}</b></div></div>  ),
            sortable: false,
            width: '100px',
            right: true
        },
        {
            name: 'Objednáno',
            selector: 'mnObjednanoCel',
            sortable: false,
            width: '100px',
            right: true
        },
        {
            name: 'Sales 90/3',
            selector: 'mnProdej90Deleno3',
            sortable: false,
            width: '100px',
            right: true
        },
        {
            name: 'Sales 30',
            selector: 'mnProdejMesic',
            sortable: false,
            width: '100px',
            right: true
        },
        {
            name: 'Sales yr -1',
            selector: 'mnProdejRokuMinus1',
            sortable: false,
            width: '100px',
            right: true
        },
        {
            name: 'Sales yr -2',
            selector: 'mnProdejRokuMinus2',
            sortable: false,
            width: '100px',
            right: true
        },


        



    ]);

    const [pagination] = useState({
        rowsPerPageText: t('global.itemsOnPage') + ':',
        rangeSeparatorText: t('global.from'),
        noRowsPerPage: false
    });

    const conditionalRowStyles = [
        {
            when: (row) => row.cisZbozi === selectedRow,
            style: {
                backgroundColor: "#a2e8a0", // Change this to the desired color
            },
        },
    ];


    return (
        <React.Fragment>
            
        <div className="ForecastBasket">
            <div>
                <h1>
                    <FontAwesomeIcon className={"i"} icon={faShoppingBasket}/>
                    Košík
                </h1>
            </div>
            
            
            <div className="mt-3 pl-2">
                <h4 className="text-primary"><FontAwesomeIcon icon={faBuilding} className="i text-primary"/>Suppliers:
                </h4>
            </div>
           {/* <div className="supplyP d-flex flex-wrap">

                {props.suppliers.length > 0 ?

                    props.suppliers.map((x, i) => {
                        let bg = filter.supplier === x.kodSubjektu ? "bg-primary text-white" : "bg-light";

                        return (
                            <div className={"border rounded p-1 m-1 d-flex justify-content-center " + bg}
                                 style={{width: 320, cursor: "pointer"}} key={i}
                                 onClick={() => onSupplierChange("supplier", x.kodSubjektu, x.idSubjekt)}>
                                {x.subjNazev}
                            </div>
                            
                        )
                    })
                    : null}


            </div>*/}
            <div className="supplyP d-flex flex-wrap">
                <div style={{width: 520}} className={"p-2"}>
                    {props.filter ?
                    <div className="flex-row align-items-center d-flex" >
                        
                        <div style={{minWidth: 480}} className="mr-2">
                           

                            <Select
                                
                                value={{subjNazev: props.filter.supplierName, kodSubjektu: props.filter.supplier}}
                                options={props.suppliers}
                                getOptionLabel={(option)=>option.subjNazev + '- [' + option.kodSubjektu + ']'}
                                getOptionValue ={(option)=>option.kodSubjektu}
                                onChange={(e) => onSupplierChange("supplier", e.kodSubjektu, e.idSubjekt)}
                                
                            />


                        </div>
                        <Button variant="outline-primary"  onClick={() => onSupplierChange("supplier", "All", 0)}   >All</Button>
                    </div>
                        : null}
                </div>
            </div>
            <div className="mt-2">
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Poznámka u dodavatele</Form.Label>
                    <Form.Control as="textarea" rows={4} value={supPoznamka}/>
                </Form.Group>
            </div>
            <div className="pl-2">
                <h4 className="text-primary"><FontAwesomeIcon icon={faUser} className="i text-primary"/> Supply
                    planners:</h4>
            </div>
            <div className="supplyP d-flex flex-wrap">

                {props.supplyPlanners.length > 0 ?

                    props.supplyPlanners.map((x, i) => {
                        let bg = filter.supplyPlanner === x.kodUzivPlanoNemedAdd ? "bg-primary" : "bg-light";

                        return (
                            <div className={"border rounded p-1 m-1 d-flex justify-content-center " + bg}
                                 style={{width: 70, cursor: "pointer"}} key={i}
                                 onClick={() => onSupplyPChange( x.kodUzivPlanoNemedAdd)}>
                                {x.kodUzivPlanoNemedAdd}
                            </div>
                        )
                    })
                    : null}


            </div>
            <div className="pl-2" style={{marginTop: 50}}>
                <h4 className="text-primary"><FontAwesomeIcon icon={faBoxes} className="i text-primary"/>Položky:</h4>
            </div>
            <div className="mt-3 mb-2 pl-2">
                <Button variant="danger" onClick={() => deleteAll('supplyPlanner')} disabled={filter.supplyPlanner === 'All'} size="sm" className="mr-2"><FontAwesomeIcon icon={faTrash} className="i"/>Vymazat položky supply plannera</Button>
                <Button variant="danger" onClick={() => deleteAll('supplier')} disabled={filter.supplierName === 'All'} size="sm" className="mr-2" ><FontAwesomeIcon icon={faTrash} className="i"/>Vymazat položky dodavatele</Button>
                <Button variant="danger" onClick={() => deleteSelectedRows()} disabled={selectedRows.length === 0} size="sm" ><FontAwesomeIcon icon={faTrash} className="i"/>Vymazat vybrané položky z tabulky</Button>
                
            </div>


            {/*{basket.length > 0 ?
                <div className="basketCont">
                    <div className="w-100 rounded p-2 border d-flex m-1 align-content-center item text-primary header">
                        <div className="del"></div>
                        <div className="ref">REF</div>
                        <div className="name">NÁZEV</div>
                        <div className="moq">MOQ</div>
                        <div className="kartonmj">KARTON</div>
                        <div className="amount">MNOŽSTVÍ</div>
                        <div className="mj">MJ</div>
                        <div className="objem">OBJEM</div>
                        <div className="karton">KARTONŮ</div>
                        <div className="cena">CENA</div>
                        <div className="cenaC">CELKEM</div>
                        <div className="mena">MĚNA</div>
                        <div className="save"></div>
                        <div className="error"></div>

                    </div>
                    {basket.map((x, i) => {
                        return (
                            <ForecastBasketItem key={x.cisZbozi} refreshBasket={() => props.refreshBasket()} x={x} i={i}/>

                        )
                    })}
                </div>

                :
                <div>
                    <h5>Košík je prázdný</h5>
                </div>
            }*/}

            <div>
                
                <DataTable

                    data={basket}
                    columns={columns}
                    paginationComponentOptions={pagination}
                    pagination
                    paginationPerPage={100}
                    paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                    theme="theme1"
                    customStyles={theme2Styles}
                    responsive
                    dense={true}
                    noHeader={true}
                    fixedHeader={true}
                    fixedHeaderScrollHeight="400px"
                    striped={true}
                    selectableRows 
                    onSelectedRowsChange={handleRowSelected} 
                    clearSelectedRows={toggleCleared}
                    conditionalRowStyles={conditionalRowStyles}
                    onRowClicked={handleRowClicked}

                />
            </div>

            <div className="mt-4 pl-1">
                <h4 className="text-primary"><FontAwesomeIcon icon={faBoxOpen} className="i text-primary"/>Přehled:</h4>
            </div>
            <div className="basketPrehled pl-1">
                <div className="item">
                    <div className="key">Počet položek:</div>
                    <div className="value">{props.summary.pocetPolozek}</div>
                </div>
                <div className="item">
                    <div className="key">Celkový objem:</div>
                    <div className="value"><NumberFormat value={props.summary.celkovyObjem.toFixed(2)} displayType={'text'}
                                                         thousandSeparator={" "}/></div>
                </div>
                <div className="item">
                    <div className="key">Celková cena:</div>
                    <div className="value"><NumberFormat value={props.summary.celkovaCena.toFixed(2)} displayType={'text'}
                                                         thousandSeparator={" "}/></div>
                </div>
            </div>
            <div className="border-top mt-4">

            </div>
            <div className="mt-4">

                {props.filter.supplierName === "All" ?
                    <Alert variant="danger">
                        Pro odeslání do ESO9 vyberte dodavatele
                    </Alert>


                    :
                    <div>
                        <div className="mb-4">
                            <Form.Check
                                checked={isTest}
                                onChange={() => setIsTest(!isTest)}
                                type="switch"
                                label="Odeslat jako testovací objednávku"
                                id="disabled-custom-switch"
                            />
                        </div>
                        <Button variant="primary" onClick={sendToEsoHandler}><FontAwesomeIcon icon={faPaperPlane}
                                                                                              className="i"/>Odeslat do
                            ESO9</Button>
                    </div>


                }
            </div>
            <div className="mt-3">
                {esoResponse && esoResponse.state === 0 ?

                    <Alert variant="success">
                        <div>
                            <div>Objednávka byla úspešně založena </div>
                            <div>ISHDOK: {esoResponse.response.data[0].IDHDOK}</div>
                            <div>CIS_DOK: {esoResponse.response.data[0].CIS_DOK}</div>
                            <div>Zobrazení v eso <a style={{display: "inline"}} target="_blank" href={esoResponse.response.data[0].URL} >ZDE</a> </div>
                        </div>
                    </Alert>
                    
                    : null }

                { esoResponse && esoResponse.state !== 0 ?

                    <Alert variant="warning">
                        <div>
                            <div>Kod chyby: {esoResponse.error[0].errorNumber}</div>
                            <div>Text chyby: {esoResponse.error[0].errorMessage}</div>
                            <div>Text chyby iBatist: {esoResponse.iBatistMessage}</div>
                        </div>
                    </Alert>
                    
                    : null}
                

               
            </div>

        </div>
            <Modal isOpen={isOpen} onClose={() => {setOpen(false)}}>
                <ForecastStocksCont idzbozi={idzbozi} addToBasket={(d) => props.addToBasket(d)} />
            </Modal>
        </React.Fragment>
    )

}