import React, {useState, useEffect} from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import 'react-overlay-loader/styles.css';
import Modal from "../shared/modal/Modal";
import ErpAdd from "./ErpAdd";
import ErpEdit from "./ErpEdit";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {faEdit} from "@fortawesome/free-regular-svg-icons";

const columns = (clickHandler => [
    {
        name: 'ID',
        selector: 'id',
        sortable: true,
    },
    {
        name: 'Name',
        selector: 'name',
        sortable: true,
    },
    {
        cell: (row) => <button className={"btn-edit"} onClick={clickHandler} id={row.id}><FontAwesomeIcon icon={faEdit}/> Upravit</button>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
    },

]);

const Erp = () => {

    const modalRef = React.useRef();
    const [data, setData] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [actionNew, setActionNew] = useState(false);
    const [selectedId, setSelectedId] = useState(0);

    useEffect(() => {

        const load = () => {
            axios.get('/erp/all').then(res => {
                setData(res.data);
            }).catch(err => {
                console.log(err);
            })
        }

        if (!loaded) {
            load();
            setLoaded(true);
        }

    }, [loaded]);

    const onFinished = () => {
        modalRef.current.closeModal();
        setLoaded(false);
    };


    const handleButtonClick = (state) => {
        setSelectedId(state.target.id);
        setActionNew(false);
        modalRef.current.openModal();
    };

    const handleChange = state => {
        this.setState({selectedRows: state.selectedRows});
    };

    const [pagination] = useState({
        rowsPerPageText: 'Záznamů na stránku:',
        rangeSeparatorText: 'z',
        noRowsPerPage: false
    });

    return (
        <div>
            <div className="subMenu">
                <button type={"button"} className="btn-link item" onClick={() => {
                    setActionNew(true);
                    modalRef.current.openModal();
                }}><FontAwesomeIcon icon={faPlus}/> Přidat ERP
                </button>
            </div>


            <DataTable
                title="ERP"

                data={data}
                columns={columns(handleButtonClick)}
                onRowSelected={handleChange}
                paginationComponentOptions={pagination}
                pagination
                dense
            />

            <Modal ref={modalRef}>
                {actionNew ? <ErpAdd onFinished={onFinished}></ErpAdd> :
                    <ErpEdit id={selectedId} onFinished={onFinished}></ErpEdit>}
            </Modal>
        </div>
    )
}

export default (Erp);
