import React from 'react';
import Loader from "react-loader-spinner";

const OurLoading = () =>{
    
    return(
        <div style={{display: 'flex', alignItems: 'center'}}><Loader
            type="Rings"
            color="#00BFFF"
            height={50}
            width={50}/><div style={{color:'#00BFFF'}}>Loading...</div>
        </div>
    )
    
}

export default OurLoading; 