import React, {useEffect, useState} from "react";
import axios from "axios";
import {Loader} from "react-overlay-loader";
import {Button, Spinner} from "react-bootstrap";
import {EsoApi1002} from "../../eso/esoApiMethods/1002/EsoApi1002";

export const ForecastStocksEsoModal = (props) => {

    const [data, setData] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [err, setErr] = useState(false);
    
    const getDataFromEso = async (region) => {


        let bodyText = {vlTyp:1002, params:[{name:"CIS_ZBOZI",value:props.cisZbozi} ]};
        //let bodyText = {vlTyp:1002, params:[{name:"CIS_ZBOZI",value:"1321900105"}, {name:"CIS_SKLAD",value:"01"}]};
       

        let data = new FormData();
        data.append("bodyText", JSON.stringify(bodyText));


        axios
            .post('api/esoApi', data, {'Content-Type': 'multipart/form-data'})
            .then((res) => {


                if (res.data.state === 0 ) {

                    let data = JSON.parse(res.data.rawResponse);
                    console.log(data);
                    
                    if ('data' in data  ){
                        setData(data.data);
                      
                    }else {
                        setData([]);
                        setErr(false);

                    } 
                    
                    setLoaded(true);

                } else {
                    console.log("error");
                    setErr(true);
                }

            })
            .catch(err => {
                console.log(err);
                setErr(true);

            })


    }
    
    useEffect(() => {
        if(!loaded){
            getDataFromEso();
        }
    }, [loaded])
    
   
    
    return(
        <div>
            {loaded ? <EsoApi1002 data={data}  /> : "Načítám..." }
            
        </div>
    )
}