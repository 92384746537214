import React, {createContext, useReducer, useContext} from 'react';
import ReactDom from 'react-dom';
import Alert from '../components/shared/alert/Alert';

export const AlertContext = createContext();

const initialState = [];

export const ADD = 'ADD';
export const REMOVE = 'REMOVE';
export const REMOVE_ALL = 'REMOVE_ALL';

export const alertReducer = (state, action) => {

    switch (action.type) {
        case ADD:

            return [
                ...state,
                {
                    id: +new Date(),
                    content: action.payload.content,
                    type: action.payload.type
                }
            ];

        case REMOVE:
            return state.filter(t => t.id !== action.payload.id);
        case REMOVE_ALL:
            return initialState;
        default:
            return state;
    }
};

export const AlertProvider = props => {
    const [alert, alertDispatch] = useReducer(alertReducer, initialState);
    const alertData = {alert, alertDispatch};
    
    if(alert.length > 0) {
        
        for (let i = 0; i < alert.length; i++)
        {
            let time = alert[i].timeout;

            if(time === undefined && alert[i].content.state === "success")
            {
                alert[i].timeout = 2000;
                setTimeout(() => {
                    alertDispatch({type: REMOVE, payload: {id: alert[i].id}})
                }, 2000);
            }
        }
    }

    return (
        <AlertContext.Provider value={alertData}>
            {props.children}
            {alert.length === 0 ? null : ReactDom.createPortal(<div><Alert alert={alert}/>
            </div>, document.getElementById('alert-root'))}
        </AlertContext.Provider>
    );
};

export const useAlertContext = () => {
    return useContext(AlertContext);
};