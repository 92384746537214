import {Button, Container, Form} from "react-bootstrap";
import Switch from "react-switch";
import LabelInfo from "../../LabelInfo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faChevronRight, faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState, useCallback} from "react";
import {ADD, useAlertContext} from "../../../contexts/AlertContext";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import axios from "axios";
import EditBasicTags from "./editBasic/EditBasicTags";
import {useUserContext} from "../../../contexts/UserContext";
import {IsReadOnly} from "../../../helpers/RoleProvider";

const EditBasic = (props) => {

    const {alertDispatch} = useAlertContext();
    const user = useUserContext();


    const [globalData, setData] = useState({
        showInPublicCatalogue: false,
        //manufacterId: null
    });

    const {t, i18n} = useTranslation();

    const {register, handleSubmit, setValue} = useForm({
        defaultValues: {
            code: '',
            invoiceUnitOfMeasureId: '',
            sellUnitOfmeasure: '',
            numberOfInvoiceUnitsInSellUnits: '',
            minimalSellUnitQuantity: '',
            descKarton: '',
            id: 0

        }
    });

    const [loaded, setLoaded] = useState(false);
    const [product, setProduct] = useState({});
    const [manufacter, setManufacter] = useState(null);

    const onSubmit = (data, event) => {

        let obj = {...data, ...globalData};


        axios.post('/product/' + props.id + '/edit', obj).then(res => {
            console.log(res);
            if(res.data === true){
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {state: 'success', message: "Produkt byl aktualizován"}
                    }
                });
            }else{
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {state: 'warning', message: "Došlo k chybě"}
                    }
                });
            }
            
        }).catch(err => {
            alertDispatch({
                type: ADD,
                payload: {
                    content: {state: 'warning', message: "Došlo k chybě"}
                }
            });
        });

        event.preventDefault();
    }


    const getProductDetail = useCallback(async () => {

        await axios.get('/product/get/' + props.id).then(res => {

            setValue("id", res.data.id);
            setValue("code", res.data.code);
            setValue("originalRef", res.data.originalRef);
            setValue("invoiceUnitOfMeasureId", res.data.invoiceUnitOfMeasureId);
            setValue("sellUnitOfmeasure", res.data.sellUnitOfmeasure);

        
            if (res.data.manufacterId && res.data.manufacterId > 0) {
                setManufacter(res.data.manufacter);
            }
            setProduct(res.data);

            props.setLanguageLocale(res.data.languageLocale);

            setData(prevState => ({
                ...prevState,
                active: res.data.active,
                showInPublicCatalogue: res.data.showInPublicCatalogue
            }))


        }).catch(err => {
            console.log(err);
        });
    }, [props, setValue]);


    useEffect(() => {


        if (!loaded) {
            getProductDetail().then(() => setLoaded(true));

        }

    }, [loaded, props.id, setValue, i18n.language, getProductDetail])


    return (
        loaded ?
            <Container fluid={true} className={"align-items-start"}>
                <Form className="product-add" onSubmit={handleSubmit(onSubmit)}>
                    <Form.Row>

                        <div className={"p-1"}>
                            <LabelInfo label={t('productEdit.code')}
                                       iconText={t('productEdit.codeDesc')}/>
                            <input type="text" name="code" ref={register} disabled={"disabled"}/>
                        </div>
                        <div className={"p-1"}>
                            <LabelInfo label={t('productEdit.originalRef')}
                                       iconText={t('productEdit.originalRefDesc')}/>
                            <input type="text" name='originalRef' ref={register} disabled={"disabled"}/>
                        </div>
                        <div className={"p-1"}>
                            <div className={"label-info"}>iBatis ID</div>
                            <input type="text" name='id' ref={register} disabled={"disabled"}/>
                        </div>


                    </Form.Row>


                    <Form.Row className={"pt-2"}>
                        <div className={"p-1 w-100"}>
                            <LabelInfo label={t('productEdit.categoryEso')}
                                       iconText={t('productEdit.categoryEsoDesc')}/>
                            {loaded ?
                                <div className={"esoCat"}>
                                    <div
                                        className={"code"}>{product.esoCat1}{product.esoCat2}{product.esoCat3}{product.esoCat4}</div>
                                    <div className={"cont"}>
                                        <div className={"item"}>{product.esoCat1Name}</div>
                                        <div className={"item icon"}><FontAwesomeIcon icon={faChevronRight}/></div>
                                        <div className={"item"}>{product.esoCat2Name}</div>
                                        <div className={"item icon"}><FontAwesomeIcon icon={faChevronRight}/></div>
                                        <div className={"item"}>{product.esoCat3Name}</div>
                                        <div className={"item icon"}><FontAwesomeIcon icon={faChevronRight}/></div>
                                        <div className={"item"}>{product.esoCat4Name}</div>
                                    </div>
                                </div>

                                : null
                            }


                        </div>


                    </Form.Row>
                    <Form.Row className={"pt-2"}>
                        <div className={"p-1"}>
                            <LabelInfo label={t('suppliers.supplier')} iconText={t('suppliers.supplier')}/>
                            <input type="text" disabled  value={product.supplier ?? "" } style={{width: 400}}/>
                        </div>
                        <div className={"p-1"}>
                            <LabelInfo label={t('manufacters.manufacter')} iconText={t('productEdit.manufacterEsoDesc')}/>
                            <input type="text" disabled value= {manufacter ? manufacter.name: ""} style={{width: 400}} /> 
                        </div>
                    </Form.Row>
                    <Form.Row className={"pt-4"}>
                        <Form.Group className={"p-1"}>
                            <Form.Label style={{width: '100%'}}>{t('productEdit.public')}</Form.Label>
                            <Switch disabled={IsReadOnly(user)} checked={globalData.showInPublicCatalogue} ref={register}
                                    name={"showInPublicCatalogue"} onChange={(e) => {
                                setData(prevState => ({
                                    ...prevState,
                                    showInPublicCatalogue: e
                                }));
                            }}/>
                        </Form.Group>

                        

                        {user.data.role !== "Readonly" ?
                            <Form.Group className={"pt-4"}>

                                <Button type={"submit"} variant={"success"}>
                                    <FontAwesomeIcon icon={faSave} className={"i"}/>
                                    {t('productEdit.save')}
                                </Button>
                            </Form.Group> : null
                        }`

                        
                    </Form.Row>
                    <Form.Row >
                        {product.showInPublicCatalogue ?
                            <Form.Group className={"p-1"}>
                                <Form.Label style={{width: '100%'}}></Form.Label>
                                <a href={"https://katalog.batist.com/product?code=" + product.code } target="_blank">
                                    <FontAwesomeIcon icon={faExternalLinkAlt} className={"mr-2"}/>
                                    Podívat se na produkt na veřejném katalogu
                                </a>
                            </Form.Group>
                            : null }
                        
                    </Form.Row>

                    {/*<EditBasicCategorie cat1Selected={cat1Selected} cat2Selected={cat2Selected}
                                        cat3Selected={cat3Selected} cat4Selected={cat4Selected}
                                        cat5Selected={cat5Selected} id={props.id}/>*/}


                    <EditBasicTags id={props.id}/>

                 


                </Form>
            </Container>
            :
            null
    )
}

export default EditBasic;