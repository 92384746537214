
const theme1 = {
    
   
    background: {
        default: '#fff',
    },
    divider: {
        default: '#fff',
    },
    context: {
        background: '#fff',
        text: '#FFFFFF',
    },
   
   
};

export default theme1; 