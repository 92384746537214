import React from 'react';
import ReactDom from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import './ModalOverModal.scss';

const ModalOverModal = (props) => {

    const [display, setDisplay] = React.useState(false);

    const close = () => {
        setDisplay(false);
    }

    if (display || props.isOpen) {

        return ReactDom.createPortal(
            <div className={"custom-modalOverModal-wrapper"}>
                <div onClick={props.onClose || close} className={"custom-modal-backdrop"}/>
                <div className={"modal-box"}>
                    <div className={"close"}><button onClick={props.onClose ||close}><FontAwesomeIcon icon={faTimes} /></button></div>
                    {props.children}
                </div>
            </div>, document.getElementById("modal-root"))
    }

    return null;
};

export default ModalOverModal;