import React, {useState, useEffect} from "react";
import axios from 'axios';
import {ADD, useAlertContext} from "../../contexts/AlertContext";
import {useForm} from "react-hook-form";
import InpurtError from "../InputError";
import {useTranslation} from "react-i18next";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const RegionEdit = ({id, handleRefresh}) => {

    const {t} = useTranslation();
    const {alertDispatch} = useAlertContext();
    const [loaded, setLoaded] = useState(false);

    const {register, handleSubmit, errors, setValue} = useForm({
        defaultValues: {name: "", currency: "", engName: "", abbrev: ""}
    });

    const onSubmit = (data, event) => {

        event.preventDefault();

        axios.post('/region/' + id + '/edit', data).then(res => {
            alertDispatch({
                type: ADD,
                payload: {
                    content: {state: 'success', message: "Region byl aktualizován."}
                }
            });
            handleRefresh();
        }).catch(res => {

        });

    }

    useEffect(() => {

        const loadData = () => {

            axios.get('/region/get/' + id).then(res => {
                setValue('name', res.data.name);
                setValue('currency', res.data.currency);
                setValue('engName', res.data.engName);
                setValue('abbrev', res.data.abbrev);
            }).catch(err => {

            });

        }


        if (!loaded) {
            loadData();
            setLoaded(true);
        }
    }, [loaded, id, setValue]);

    return (
        <div>
            <h1>Upravit region</h1>
            <form onSubmit={handleSubmit(onSubmit)} method={"POST"}>
                <div className={"row"}>
                    <div className="item-row">
                        <label>Název regionu</label>
                        <div className="item-group">
                            <input type="text" name="name" ref={register({required: true})}/>
                            {errors.name && errors.name.type === 'required' &&
                            <InpurtError message={t("validation.required")}></InpurtError>}
                        </div>
                    </div>
                    <div className="item-row">
                        <label>Anglický název</label>
                        <div className="item-group">
                            <input type="text" name="engName" ref={register({required: true})}/>
                            {errors.engName && errors.engName.type === 'required' &&
                            <InpurtError message={t("validation.required")}></InpurtError>}
                        </div>
                    </div>
                    <div className="item-row">
                        <label>Zkratka</label>
                        <div className="item-group">
                            <input type="text" name='abbrev' ref={register({required: true})}/>
                            {errors.abbrev && errors.abbrev.type === 'required' &&
                            <InpurtError message={t("validation.required")}></InpurtError>}
                        </div>
                    </div>
                    <div className="item-row">
                        <label>Měna</label>
                        <div className="item-group">
                            <input type="text" name='currency' ref={register({required: true})}/>
                            {errors.currency && errors.currency.type === 'required' &&
                            <InpurtError message={t("validation.required")}></InpurtError>}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <button type="submit" className="btn-submit-blue"><FontAwesomeIcon icon={faSave}/> Uložit region
                    </button>
                </div>
            </form>
        </div>
    )
}

export default RegionEdit