const theme2Styles = {
    rows: {
        style: {
            "&:hover": {
                backgroundColor: '#e8e8e8'
            }

        }
    },


    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            paddingTop: '12px',
            paddingBottom: '12px',
            fontSize: '12px',



        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            paddingTop: '10px',
            paddingBottom: '10px'
        },
    },
    pagination: {
        style: {
            boxShadow: 'none',
            fontSize: '13px',
            minHeight: '56px',
            borderTopStyle: 'solid',
            borderTopWidth: '1px',
            justifyContent: 'flex-start'
        }
    }
};

export default theme2Styles;