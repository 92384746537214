import React, {useEffect, useState} from "react";
import LabelInfo from "../../../LabelInfo";
import Select from "react-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {Button, Col, Form} from "react-bootstrap";
import axios from "axios";
import {ADD, useAlertContext} from "../../../../contexts/AlertContext";
import Tag from "../../../tagy/Tag";
import {useTranslation} from "react-i18next";
import {useUserContext} from "../../../../contexts/UserContext";
import {IsReadOnly} from "../../../../helpers/RoleProvider";



const EditBasicTags = (props) => {


    const user = useUserContext();
    
    
    const [selectedTag, setSelectedTag] = useState(0);
    const [tags, setTags] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [tagOptions, setTagOptions] = useState([]);
    const {t, i18n} = useTranslation();
    const {alertDispatch} = useAlertContext();


    const handleChangeTag = e => {
        setSelectedTag(e.value);
    }

    const getTagsForSelect = () => {
        axios.get('/tag/' + i18n.language + '/get').then(res => {
            let objectForSelect = [];
            setTagOptions(objectForSelect);
            res.data.map((x, k) => {
                return objectForSelect[k] = {
                    value: x.id,
                    label: x.name
                }
            });

        });
    };

    const getTagsOnProduct = () => {
        axios.get('tag/' + i18n.language + '/getOnProduct/' + props.id).then(res => {
            setTags(res.data);
        }).catch(error => {
            console.log(error);
        })
    }

    const addTagToProduct = () => {
        let data = {
            idProduct: props.id,
            idTag: selectedTag
        }
        axios.put('tag/AddTagToProduct', data).then(res => {
            if (res.data.result > 0) {
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {state: 'success', message: res.data.message}
                    }
                });
                setLoaded(false);
            } else {
                console.log(res.data.error);
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {state: 'warning', message: res.data.message}
                    }
                });
            }

        }).catch(error => {
            console.log(error);
            alertDispatch({
                type: ADD,
                payload: {
                    content: {state: 'warning', message: "Došlo k chybě na serveru."}
                }
            });
        });
    };

    const removeTagFromProd = (e) => {
        let id = e.target.closest('.tag').getAttribute('data-id');
        axios.delete('tag/removeTagFromProd/' + id).then(res => {
            if (res.data.result > 0) {
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {state: 'success', message: res.data.message}
                    }
                });
                setLoaded(false);
            } else {
                console.log(res.data.error);
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {state: 'warning', message: res.data.message}
                    }
                });
            }

        }).catch(error => {
                console.log(error);
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {state: 'warning', message: "Došlo k chybě na serveru."}
                    }
                });
            }
        );
    }

    const loadData = async () => {
        getTagsForSelect();
        getTagsOnProduct();
    }

    useEffect(() => {

        if (!loaded) {
            loadData().then(() => setLoaded(true));
        }

    })

    return (

        loaded ?
            <Form.Row className={"pt-3"}>
                <Form.Group as={Col} className={"col-8"}>
                    <div className={"form-item"}>
                        <LabelInfo label={t('productEdit.tags')}
                                   iconText={t('productEdit.tagsDesc')}/>
                    </div>

                    <div className={"tagContent"} style={{minHeight: 60}}>
                        {tags.map((x, k) => {
                            return (
                                <Tag remove={removeTagFromProd} key={k} usedInEditProd={true} id={x.id}
                                     note={x.note} name={x.name}/>
                            )

                        })}
                    </div>
                </Form.Group>
                {!IsReadOnly(user) ?
                <Form.Group as={Col} className={"col-4"}>
                    <div className={"form-item"} style={{width: "300px"}}>
                        <LabelInfo label={t('productEdit.addTag')}
                                   iconText={t('productEdit.addTagDesc')}/>

                        <Select options={tagOptions} onChange={handleChangeTag}/>
                    </div>
                      
                    <div className={"form-item"}>

                        <Button className={"mt-2"} type={"button"} variant={"primary"} onClick={addTagToProduct}>
                            <FontAwesomeIcon icon={faPlus} className="i"/>
                            {t('productEdit.addTag')}
                        </Button>

                    </div> 
                </Form.Group> : null }
            </Form.Row>
            :
            null


    )
}

export default EditBasicTags;