import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {ADD, useAlertContext} from "../../contexts/AlertContext";
import {useForm} from "react-hook-form";
import InpurtError from "../InputError";
import {useTranslation} from "react-i18next";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {useUserContext} from "../../contexts/UserContext";


const UserUpdate = (props) => {

    const [changePassword, setChangePassword] = useState(false);
    /* const {register, handleSubmit, setValue} = useForm();*/
    const {
        register: registerPass,
        watch: watchPass,
        handleSubmit: handleSubmitPass,
        errors: errorsPass,
        setValue
    } = useForm();
    const watchPassword = watchPass("password");
    const [currentUserId, setCurrentUserId] = useState(0);

    const {t} = useTranslation();
    const {alertDispatch} = useAlertContext();
    const [loaded, setLoaded] = useState(false);
    const [typeOfComponent, setTypeOfComponent] = useState("");

    const user = useUserContext();


    useEffect(() => {


        let apiUserId = 0;
        // click on settings the whole componeent is rendered, can not insert user id into props of component
        if (props.userId == null) {
            setCurrentUserId(user.data.id);
            apiUserId = user.data.id;
            setTypeOfComponent("editUserAsMyOwnSettings");

        }
        //click on new user
        else if (props.userId === 0) {
            apiUserId = 0;
            setTypeOfComponent("newUser");
            setChangePassword(true);

        }
        // when clicking on edit in user table, id of user is put into props component
        else {
            setCurrentUserId(props.userId);
            apiUserId = props.userId;
            setTypeOfComponent("editUser");
        }

        if (apiUserId > 0) {
            const load = () => {

                axios.get('/user/' + apiUserId).then(res => {
                    console.log(res);
                    setValue('email', res.data.email);
                    setValue('username', res.data.username);
                    setValue('roleId', res.data.roleId);
                    setValue('id', res.data.id);
                    setValue('firstName', res.data.firstName);
                    setValue('lastName', res.data.lastName);
                    setValue('active', res.data.active);
                    setValue('note', res.data.note);
                    setValue('phone', res.data.phone);
                }).catch(err => {

                });
            }

            if (!loaded) {
                load();
                setLoaded(true);
            }

        } else {
            setLoaded(true);
        }


    }, [loaded, currentUserId, setValue, props.userId, user.data.id]);

    const onSubmitPassword = (data, event) => {


        event.preventDefault();

        if (typeOfComponent === "newUser") {
            axios.post('/user/create', data).then(res => {

                if (res.data.isOk) {
                    alertDispatch({
                        type: ADD,
                        payload: {
                            content: {state: 'success', message: t("users.addNewUserSuccess")}
                        }
                    });
                    props.refreshParent();
                    props.closeModal();
                } else {
                    alertDispatch({
                        type: ADD,
                        payload: {
                            content: {state: 'warning', message: res.data.message}
                        }
                    });
                }

            }).catch(err => {
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {state: 'warning', message: t("global.saveError")}
                    }
                });
            });

        } else {
            axios.post('/user/' + currentUserId + '/settings', data).then(res => {

                if (res.data.isOk) {
                    alertDispatch({
                        type: ADD,
                        payload: {
                            content: {state: 'success', message: t("users.saveUserDataSuccess")}
                        }
                    });
                    if(typeOfComponent !== 'editUserAsMyOwnSettings'){
                        props.refreshParent();
                        props.closeModal();
                    }
                    
                } else {
                    alertDispatch({
                        type: ADD,
                        payload: {
                            content: {state: 'warning', message: res.data.message}
                        }
                    });
                }

            }).catch(err => {
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {state: 'warning', message: t("global.saveError")}
                    }
                });
            });

        }


    }


    return (
        loaded ?

            <Container>

                <Row className={"align-items-center"}>
                    <Col className={"pl-0"}>
                        {typeOfComponent === "editUser" ?
                            <h1>Nastavení uživatele</h1>
                            :
                            null
                        }
                        {typeOfComponent === "newUser" ?
                            <h1>Vytvoření nového uživatele</h1>
                            :
                           null
                        }
                        {typeOfComponent === "editUserAsMyOwnSettings" ?
                            <h1>Nastavení uživatele</h1>
                            :
                            null
                        }

                       
                    </Col>


                </Row>

                <Row>

                    <Form onSubmit={handleSubmitPass(onSubmitPassword)} style={{width: "100%"}}>
                        <Form.Row>

                            {typeOfComponent === "editUser" ?
                                <Form.Group as={Col}>
                                    <Form.Label>Id</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={"id"}
                                        readOnly
                                        ref={registerPass({required: true})}/>
                                </Form.Group>
                                :
                                null
                            }

                            <Form.Group as={Col}>
                                <Form.Label>Uživatelské jméno</Form.Label>
                                {typeOfComponent === "editUser" || typeOfComponent === "editUserAsMyOwnSettings"  ?
                                    <Form.Control
                                        type="text"
                                        name={"username"}
                                        readOnly
                                        ref={registerPass({required: true})}/>
                                    :
                                    <Form.Control
                                        type="text"
                                        name={"username"}
                                        ref={registerPass({required: true})}/>

                                }

                            </Form.Group>

                            {user.data.role === 'Admin'
                                ?
                                <Form.Group as={Col}>
                                    <Form.Label>Aktivní</Form.Label>
                                    <Form.Control as={"select"} custom name={"active"}
                                                  ref={registerPass({required: true})}>
                                        <option value={1}>Ano</option>
                                        <option value={0}>Ne</option>
                                    </Form.Control>
                                </Form.Group>

                                : null

                            }
                        </Form.Row>


                        {typeOfComponent === "editUserAsMyOwnSettings" ?
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Role</Form.Label>
                                    <Form.Control as="select" disabled name={"roleId"} ref={registerPass({required: true})} custom>
                                        <option value={4}>Readonly user</option>
                                        <option value={2}>Regular user</option>
                                        <option value={1}>Admin</option>
                                        <option value={3}>Api</option>
                                    </Form.Control>

                                </Form.Group>
                            </Form.Row>
                            :
                            null
                        }

                        
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Check type="checkbox" inline label={"Změna hesla"} id={"passChange"}
                                                onClick={() => setChangePassword(!changePassword)}/>
                                </Form.Group>
                                {typeOfComponent === "editUser" ?
                                <Form.Group as={Col}>
                                    <Form.Label>Role</Form.Label>
                                    <Form.Control as="select" name={"roleId"} ref={registerPass({required: true})} custom>
                                        <option value={4}>Readonly user</option>
                                        <option value={2}>Regular user</option>
                                        <option value={1}>Admin</option>
                                        <option value={3}>Api</option>
                                    </Form.Control>
                                </Form.Group>
                                    :
                                    null
                                }

                            </Form.Row>
                            

                        {typeOfComponent === "newUser" ?
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Role</Form.Label>
                                    <Form.Control as="select" name={"roleId"} ref={registerPass({required: true})} custom>
                                        <option value={4}>Readonly user</option>
                                        <option value={2}>Regular user</option>
                                        <option value={1}>Admin</option>
                                        <option value={3}>Api</option>
                                    </Form.Control>
                                </Form.Group>

                            </Form.Row>
                            :
                            null
                        }


                        {changePassword ?
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Heslo</Form.Label>
                                    <Form.Control type="password" placeholder="Password" name="password"
                                                  ref={registerPass({required: true})}/>
                                    <Form.Text>
                                        {errorsPass.password && errorsPass.password.type === 'required' &&
                                        <InpurtError message={t("validation.required")} showMessage={true}/>}
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Heslo potvrzení</Form.Label>
                                    <Form.Control type="password" placeholder="Password" name="passwordConfirm"
                                                  ref={registerPass({
                                                      validate: value =>
                                                          value === watchPassword || t("passwordRecovery.passwordNotMatch")
                                                  })}/>
                                    <Form.Text>
                                        {errorsPass.passwordConfirm &&
                                        <InpurtError message={t("passwordRecovery.passwordNotMatch")}
                                                     showMessage={true}/>}
                                    </Form.Text>


                                </Form.Group>
                            </Form.Row>

                            : null}


                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Jméno</Form.Label>
                                <Form.Control type="text" placeholder="" name="firstName"
                                              maxLength={"50"}
                                              ref={registerPass({required: true})}/>
                                <Form.Text>
                                    {errorsPass.firstName && errorsPass.firstName.type === 'required' &&
                                    <InpurtError message={t("validation.required")}/>}
                                </Form.Text>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Příjmení</Form.Label>
                                <Form.Control type="text" placeholder="" name="lastName"
                                              maxLength={"50"}
                                              ref={registerPass({required: true})}/>
                                <Form.Text>
                                    {errorsPass.lastName && errorsPass.lastName.type === 'required' &&
                                    <InpurtError message={t("validation.required")}/>}
                                </Form.Text>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Telefon</Form.Label>
                                <Form.Control type="text" placeholder="telefon" name="phone" maxLength={"50"}
                                              ref={registerPass()}/>
                               
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>E-mail</Form.Label>
                                <Form.Control type="email" placeholder="email" name="email" maxLength={"100"}
                                              ref={registerPass({required: true})}/>
                                <Form.Text>
                                    {errorsPass.email && errorsPass.email.type === 'required' &&
                                    <InpurtError message={t("validation.required")}/>}
                                </Form.Text>
                            </Form.Group>
                        </Form.Row>
                        {user.data.role === 'Admin' ?
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label name={"note"}>Poznámka</Form.Label>
                                    <Form.Control ref={registerPass()} as="textarea" name={"note"} maxLength={"2000"}
                                                  rows={3}/>
                                </Form.Group>
                            </Form.Row>
                        : null}


                        <Form.Row>
                            <Button variant="success" type="submit">
                                <FontAwesomeIcon className="i"
                                                 icon={faSave}/> Uložit
                            </Button>
                        </Form.Row>

                    </Form>
                </Row>


            </Container>

            : null

    )
}

export default UserUpdate