import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Button, Card } from "react-bootstrap";
import OurLoading from "../../shared/OurLoading";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheckCircle, faCloud, faDownload, faTrash} from '@fortawesome/free-solid-svg-icons'
import ModalOverModal from "../../shared/modal/ModalOverModal";
import {faEdit} from "@fortawesome/free-regular-svg-icons";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import AddPhoto from "../../photos/AddPhoto";
import PhotoStatus from "./PhotoStatus";
import {useTranslation} from "react-i18next";
import {ADD, useAlertContext} from "../../../contexts/AlertContext";
import PhotoStatusMain from "./PhotoStatusMain";
//import {saveAs} from 'file-saver';
import {useUserContext} from "../../../contexts/UserContext";
import {IsReadOnly} from "../../../helpers/RoleProvider";
import './PhotoPrivate.scss';
import JsonStatus from "./JsonStatus";

const PhotoPrivate = ({refId, url}) => {

    const {t} = useTranslation();
    const {alertDispatch} = useAlertContext();
    const user = useUserContext();

    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isModalEditOpen, setIsModalEditOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [allowedContainers] = useState(["200x200", "1000x1000"])
    const [blobDistinct, setBlobDistinct] = useState([]);
    const [jsonObject, setJsonObject] = useState(false);
    

    useEffect(() => {

        if (!loaded) {

            axios.get('/product/' + refId + '/photo-private').then((res) => {
                setData(res.data.items);
                setJsonObject(res.data.jsonObject);
                if(res.data.items){
                    let arr = [];
                    res.data.items.map((x) => {
                        if (!allowedContainers.some(v => x.publicUrl.includes(v))) {
                            return;
                        }
    
                        if (!arr.includes(x.blobName)) {
    
                            arr.push(x.blobName);
                        }
                    })
                    setBlobDistinct(arr);
                }

                setLoaded(true);
               
            });

            
            
        }

    }, [loaded]);

    
    
    /*useEffect(() => {
        data && data.map((x => {

            if (!allowedContainers.some(v => x.publicUrl.includes(v))) {
                return;
            }

            if (!blobDistinct.includes(x.blobName)) {

                let arr = blobDistinct.slice();
                arr.push(x.blobName);
                setBlobDistinct(arr);
            }
            
        }));
    }, [data, blobDistinct]);*/

    const handleEditDocClick = (item) => {
        setSelectedItem(item);
        setIsEdit(true);
        setIsModalEditOpen(true);
    }

    function sleep(delay) {
        var start = new Date().getTime();
        while (new Date().getTime() < start + delay) ;
    }

    const handleDelete = (item) => {
        let blob = item.blobName.split('.')[0];
        let foundItem = data.find(x => x.publicUrl.includes("/originals/") && x.blobName.startsWith(blob));

        if (window.confirm(t('global.deleteConfirm')) === true) {
            axios.delete('/AzureBlob/' + foundItem.blobName).then((res) => {

                sleep(5000);

                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {state: 'success', message: t('global.deleteSuccess')}
                    }
                });


                setLoaded(false);
            });
        }



        /*if (window.confirm(t('global.deleteConfirm')) === true) {
            axios.delete('/AzureBlob/' + item.blobName).then((res) => {

                sleep(2500);

                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {state: 'success', message: t('global.deleteSuccess')}
                    }
                });


                setLoaded(false);
            });*/
    }

    const handleDownloadOriginal = (blobName) => {
        let blob = blobName.split('.')[0];
        let foundItem = data.find(x => x.publicUrl.includes("/originals/") && x.blobName.startsWith(blob));
        window.open(foundItem.publicUrl);
        //saveAs(foundItem.publicUrl, foundItem.blobName, false);
    }

    const setMain = (blobName, container) => {

        let url = '/azureblob/' + blobName + '/main?refId=' + refId;
        
        if(container)
            url += '&container=' + container
        
        axios.post(url).then((res) => {
            sleep(2500);
            setLoaded(false);
        });

    }

    const PhotoItem = (props) => {


        return (


            <React.Fragment>
                <Card style={{width: '17rem'}} className={"ml-1 mr-1"}>
                    <Card.Img variant={"top"} src={props.url}/>
                    <Card.Body>
                        <Card.Text>
                            {props.isMain ?
                                <span className={"main-photo mb-2"}><FontAwesomeIcon icon={faCheckCircle} className={"mr-2"}/>{t('photos.azure.mainPhoto')}</span>
                                :
                                <Button disabled={IsReadOnly(user)} onClick={() => {
                                    setMain(props.blobName)
                                }} className={"btn-set-main mb-2"}>{t('photos.azure.setMainPhoto')}</Button>
                            }
                            {props.isBsMain ?
                                <span className={"main-photo mb-2"}><FontAwesomeIcon icon={faCheckCircle} className={"mr-2"}/>{t('photos.azure.mainBsPhoto')}</span>
                                :
                                <Button disabled={IsReadOnly(user)} onClick={() => {
                                    setMain(props.blobName, "batistsafety")
                                }} className={"btn-set-main mb-2 btn-warning"}>{t('photos.azure.setBsMainPhoto')}</Button>
                            }
                            <div className="photoStatusCont">
                                <PhotoStatus targetContainer={"200x200"} allData={data} blobName={props.blobName}/>
                                <PhotoStatus targetContainer={"1000x1000"} allData={data} blobName={props.blobName}/>
                            </div>
                            
                            <div 
                                className={"link-download"} 
                                onClick={() => {
                                    handleDownloadOriginal(props.blobName)
                                }}
                            >
                                <FontAwesomeIcon icon={faDownload} className={"mr-2"}/>
                                {t('photos.azure.downloadOriginal')}
                            </div>
                        </Card.Text>
                        {!IsReadOnly(user) ?
                            <React.Fragment>
                                <Button
                                    title={"edit"}
                                    size={"sm"}
                                    variant={"primary"}
                                    className={"mr-1"}
                                    onClick={() => {

                                        handleEditDocClick(props.original.blobName);
                                    }}>
                                    <FontAwesomeIcon icon={faEdit} className={"mr-2"}/>
                                    {t('global.edit')}
                                </Button>
                                <Button
                                    title="remove"
                                    size={"sm"}
                                    variant={"danger"}
                                    className={"mr-1"}
                                    onClick={() => {
                                        handleDelete(props);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTrash} className={"mr-2"}/>
                                    {t('global.delete')}
                                </Button>
                            </React.Fragment> : null
                        }
                    </Card.Body>
                </Card>

            </React.Fragment>
        )
    };

    return (
        <div className={"photoPrivateOnProduct"}>
            <h1><FontAwesomeIcon icon={faCloud} className="i"/>Photos on Azure</h1>
            {!IsReadOnly(user) ?
                <Button
                    title={"Add photo"}
                    size={"sm"}
                    variant={"primary"}
                    className={"mr-1"}
                    onClick={() => {
                        setIsEdit(false);
                        setSelectedItem(null);
                        setIsModalEditOpen(true);
                    }}><FontAwesomeIcon icon={faPlus} className={"mr-2"}/>
                    {t('global.add')}
                </Button> : null}

            {!loaded ? <OurLoading/> :


                <React.Fragment>
                    {data && data.length > 0 && loaded &&
                    <div className={"mt-4"}>
                        <PhotoStatusMain targetName={refId + "_sm.webp"} allData={data} size="sm"/>
                        <PhotoStatusMain targetName={refId + "_md.webp"} allData={data} size="md"/>
                        {jsonObject && <JsonStatus refId={refId} isOK={jsonObject.photos.length === blobDistinct.length} actionDone={() => setLoaded(false)} /> }
                        
                    </div>
                    }
                    <div className={"photos"}>
                        {data && data.length === 0 && loaded ?
                            <div className={"mt-2"}>Na produktu nejsou žádné fotky na azure</div>
                            :
                            <div className={"cont"}>
                                <div className={"contItems"}>
                                    {


                                        data && blobDistinct.map((x, i) => {

                                            let items = data.filter(a => a.blobName === x);
                                            
                                            if(items.length === 0)
                                                return null;


                                            let url = null;
                                            let i200 = items.find(c => c.publicUrl.includes("200x200"));
                                            let i1000 = items.find(c => c.publicUrl.includes("1000x1000"));
                                            if (i200) url = i200.publicUrl;
                                            if (!url && i1000) url = i1000.publicUrl;
                                            

                                            let blobName = items[0].blobName.replace('.webp', '');

                                            return (<PhotoItem key={i}
                                                               original={data.find(x => x.publicUrl.includes("originals") && x.blobName.startsWith(blobName))}
                                                               blobName={x}
                                                               url={url}
                                                               isMain={items[0].isMain}
                                                               isBsMain={items[0].isBsMain}/>)


                                        })

                                    }
                                </div>
                            </div>
                        }


                    </div>


                    <ModalOverModal isOpen={isModalEditOpen} onClose={() => {
                        setIsModalEditOpen(false);
                    }}>
                        <AddPhoto selectedItem={selectedItem} isEdit={isEdit} refid={refId} onClose={() => {
                            sleep(2500);
                            setIsEdit(false);
                            setIsModalEditOpen(false);
                            setLoaded(false);
                        }}/>
                    </ModalOverModal>
                </React.Fragment>
            }

        </div>


    );


}

export default PhotoPrivate;