import React from 'react';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "react-bootstrap";


export function Home() {

    const {t} = useTranslation();

    return (
        <div>
            <h1>{t('home.title')}</h1>
            <div>
                <h3>Návod na nahrávání dokumentů na Sharepoint</h3>
                <Link to="/docs/iBatist_Sharepoint_navod.pdf" target="_blank">
                    <Button variant={"light"}>
                        <FontAwesomeIcon className="i" icon={faDownload}/>
                        Stáhnout návod
                    </Button>
                </Link>

            </div>

        </div>
    );

}

export default Home;
