import React, {useState, useEffect, useCallback} from 'react';
import {Button, Col, Container, Form, Row, } from "react-bootstrap";
import {IsReadOnly} from "../../helpers/RoleProvider";
import {useUserContext} from "../../contexts/UserContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {faCheck, faEye, faFilter, faPlusCircle, faShareSquare, faWindowClose} from "@fortawesome/free-solid-svg-icons";
import Modal from "../shared/modal/Modal";
import UpsertManufacter from "./UpsertManufacter";
import DataTable from "react-data-table-component";
import theme1Styles from "../../config/dataTable/theme1Styles";
import {faEdit} from "@fortawesome/free-regular-svg-icons";
import axios from "axios";


const Manufacters = () => {

    const {t} = useTranslation();
    const user = useUserContext();
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage] = useState(50);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [editId, setEditId] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [filter, setFilter] = useState({name: ""});

    const columns = (() => [
        {
            name: 'Id',
            selector: 'id',
            sortable: false
        },
        {
            name: t('manufacters.name'),
            selector: 'name',
            sortable: true,
        },
        /*{
            name: t('manufacters.web'),
            selector: 'web',
            sortable: true,
        },
        {
            name: t('manufacters.note'),
            selector: 'note',
            sortable: true,
        },*/


       /* {
            cell: (row) => {
                return (
                    <React.Fragment>
                        {/!* edit *!/}

                        <Button
                            title={"edit"}
                            size={"sm"}
                            variant={"primary"}
                            className={"mr-1"}
                            onClick={() => {
                                setEditId(row.id);
                                setIsUpsertModalOpen(true);
                            }}
                            id={row.id}><FontAwesomeIcon icon={faEdit}/>
                        </Button>


                    </React.Fragment>
                )
            }
            ,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },*/

    ]);

    const handleFilter = (currentPage) => {

        let params = 'name=' + filter.name;
        
        axios.get('api/manufacters/filter?' + params).then((res) => {
            setData(res.data.items);
        }).catch((err) => {

        }).finally(() => {

        })
    }

    const handleChangeFilter = (e, name, value) => {
        setFilter(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handlePageChange = page => {
        handleFilter(page);
    };

    const [isUpsertModalOpen, setIsUpsertModalOpen] = useState(false);

    return (


        <Container fluid>

            <Row>
                <h1>{t('manufacters.title')}</h1>
            </Row>

            {/*{!IsReadOnly(user) ?
                <Row className={"pt-2 pb-2 pr-3 pl-3"}>
                    <Button variant={"primary"} onClick={() => {setEditId(null); setIsUpsertModalOpen(true)}}>
                        <FontAwesomeIcon icon={faPlusCircle} className={"i"}/>
                        {t('manufacters.add')}
                    </Button>
                </Row> : null}*/}

            <Row className={"p-3"}>
                <Form style={{width: '100%'}} className={"cBG_BS_light p-3 rounded  documentsShrp"}>
                    <Form.Row>
                        <Form.Group as={Col} className={"col-6"}>

                            <Form.Row className={"ps-3"}>
                                <Form.Group as={Col}>
                                    <Form.Label>{t('global.name')}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={"name"}

                                        value={filter.name}

                                        onChange={(e) => {
                                            handleChangeFilter(e, 'name', e.target.value)
                                        }}

                                    />
                                </Form.Group>

                            </Form.Row>
                            <Form.Row className={"p-2"}>

                                <Button
                                    variant={"primary"}
                                    className={"btn-lg"}
                                    onClick={a => handleFilter(1)}

                                >
                                    <FontAwesomeIcon className="i" icon={faFilter}/>
                                    {t('catalog.filter')}
                                </Button>
                            </Form.Row>

                        </Form.Group>

                    </Form.Row>


                </Form>

            </Row>


            <DataTable className="dataTable"

                       data={data}
                       columns={columns()}
                       pagination
                       paginationServer
                       paginationTotalRows={totalRows}
                       dense
                       noHeader
                       paginationPerPage={perPage}
                       paginationRowsPerPageOptions={[50]}
                       theme="theme1"
                       customStyles={theme1Styles}
                       onChangePage={handlePageChange}
                       paginationResetDefaultPage={resetPaginationToggle}
            />

            <Modal isOpen={isUpsertModalOpen} onClose={() => {
                setIsUpsertModalOpen(false)
            }}>
                <UpsertManufacter onCloseRefresh={() => {
                    handleFilter(currentPage)
                }} onClose={() => {
                    setIsUpsertModalOpen(false)
                }} id={editId}/>
            </Modal>

        </Container>


    );
}

export default Manufacters;