import React, {useEffect, useState} from 'react';
import {faCheckCircle, faTimesCircle} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import DownloadIcon from "./DownloadIcon";
import './PhotoStatusMain.scss';

const PhotoStatusMain = ({targetName, allData, size}) => {

    const [item, setItem] = useState(null);

    useEffect(() => {
        
        targetName = 'mains/' + targetName;
        
        console.log(targetName);
        console.log(allData);

        let foundItem = allData.find(x => x.publicUrl.includes(targetName));

        console.log(foundItem)
        
        setItem(foundItem);

    }, [allData]);

    const {t} = useTranslation();

    return (
        <div className={"mb-2 photoStatusMain"}>

            {item ?
                <div className={"found"}>
                    <FontAwesomeIcon icon={faCheckCircle} className={"icon"}/>
                    {size === "sm" ? t('photoStatusMain.foundSm') : t('photoStatusMain.foundMd')}
                    <div className={"ml-2"}>
                        <DownloadIcon url={item.publicUrl}/>
                    </div>
                </div> :
                <div className={"not-found"}>
                    <FontAwesomeIcon icon={faTimesCircle} className={"icon"}/>
                    {size === "sm" ? t('photoStatusMain.notFoundSm') : t('photoStatusMain.notFoundMd')}
                </div>
            }
        </div>
    )
}

export default PhotoStatusMain;