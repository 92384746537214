import React from 'react';
import {faFile} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './PhotoStatus.scss';

const DownloadIcon = ({url}) => {

    return (
        <div className={"photoStatus mb-2"}>

            <div className={"found"}>
                <a href={url}>
                    <FontAwesomeIcon icon={faFile} className={"icon"}/>
                </a>
            </div>
        </div>
    )
}

export default DownloadIcon;