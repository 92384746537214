import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import theme1Styles from "../../config/dataTable/theme1Styles";
import Moment from "react-moment";
import {useTranslation} from "react-i18next";
import {faInfoCircle, faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Overlay, OverlayTrigger, Popover} from "react-bootstrap";
import OrderNote from "./OrderNote";

export default function BsOrders() {

    const {t} = useTranslation();
    const [loaded, setLoaded] = useState(false);
    const [show, setShow] = useState(false);
    const [data, setData] = useState([]);
    const target = useRef(null);
    const [pagination] = useState({
        rangeSeparatorText: 'z',
        noRowsPerPage: false,
    });

    const columns = (clickHandler => [
        {
            name: 'Id',
            selector: 'id',
            sortable: true,
        },
        {
            name: t("batistsafety.name"),
            selector: 'name',
            sortable: true
        },
        {
            name: t("batistsafety.lastName"),
            selector: 'lastName',
            sortable: true,
        },
        {
            name: t("batistsafety.email"),
            selector: 'email',
            sortable: true
        },
        {
            name: t("batistsafety.phone"),
            selector: 'phone',
            sortable: true
        },
        {
            name: t("batistsafety.note"),
            //selector: 'note',
            cell: (row) =>  <OrderNote key={row.id} data={row.note} />,
            sortable: true
        },
        {
            name: t("batistsafety.state"),
            cell: (row) => {
                return (
                    row.state === 1 ? t("batistsafety.crated") : t("batistsafety.finished")
                )
            }
        },
        {
            name: t("batistsafety.registered"),
            cell: (row) => {
                return (
                    row.idUser ? <FontAwesomeIcon icon={faUser}
                                                  className="i"/> : null
                )
            }
        },
        {
            name: t("batistsafety.transport"),
            cell: (row) => {

                if(!row.idTransport)
                {
                    return null;
                }
                
                if(row.idTransport === 2)
                {
                    return (
                        t("batistsafety.transportDhl")
                    )
                }

                if(row.idTransport === 3)
                {
                    return (
                        t("batistsafety.transportOsob")
                    )
                }
            }
        },
        {
            name: t("batistsafety.payment"),
            cell: (row) => {

                if(!row.idPayment)
                {
                    return null;
                }
                
                if(row.idPayment === 3)
                {
                    return (
                        t("batistsafety.paymentDobirka")
                    )    
                }

                if(row.idPayment === 4)
                {
                    return (
                        t("batistsafety.paymentOnline")
                    )
                }
                
                
            }
        },
        
        {
            name: t("batistsafety.cratedDate"),
            sortable: true,
            cell: row => <Moment format="DD.MM.YYYY HH:mm:ss" date={row.createdDate}/>
        },
        {
            name: t("batistsafety.finishedDate"),
            sortable: true,
            cell: row => row.finishedDate ? <Moment format="DD.MM.YYYY HH:mm:ss" date={row.finishedDate}/> : null
        }
    ]);

    const handleButtonClick = () => {

    }
    
    useEffect(() => {

        if (!loaded) {
            axios.get("batistsafety/orders").then((res) => {
                setData(res.data.orders);
                setLoaded(true);
            });
        }
    })

    return (
        <React.Fragment>
            <DataTable
                title={t("batistsafety.lastOrders")}
                data={data}
                columns={columns(handleButtonClick)}
                paginationComponentOptions={pagination}
                pagination
                paginationPerPage={50}
                paginationRowsPerPageOptions={[10, 30, 50, 100]}
                dense
                theme="theme1"
                customStyles={theme1Styles}
            />

        </React.Fragment>
    )

}