import React, {useState} from "react";
import DataTable from "react-data-table-component";
import theme1Styles from "../../../config/dataTable/theme1Styles";
import {OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import Badge from "react-bootstrap/Badge";
import {faShoppingBasket} from "@fortawesome/free-solid-svg-icons/faShoppingBasket";
import Modal from "../../shared/modal/Modal";
import {ForecastStocksCont} from "./ForecastStocksCont";
import axios from "axios";
import {ADD, useAlertContext} from "../../../contexts/AlertContext";
import theme2Styles from "../../../config/dataTable/theme2Styles";

export const ForecastStocksContTable = (props) => {

    const [data, setData] = React.useState(props.data);
    const [isOpen, setOpen] = React.useState(false);
    const [idzbozi, setIdzbozi] = React.useState(0);

    const {t, i18n} = useTranslation();

    const {alertDispatch} = useAlertContext();
    
    const openDetailModal = (idzbozi) => {
        setOpen(true);
        setIdzbozi(idzbozi);
        
    }
    
    const addToBasket = (d) => {
        let data = {
            referenceCode: d.code,
            amountInBasket: d.amount
        }
        axios
            .post('api/reports/forecast/upsertBasketItem', data)
            .then((res) => {
                if (res.data.state === 0) {
                    alertDispatch({
                        type: ADD,
                        payload: {
                            content: {state: 'success', message: 'Ok'}
                        }
                    });
                   
                    props.refreshBasket();
                } else {
                    alertDispatch({
                        type: ADD,
                        payload: {
                            content: {
                                state: 'warning',
                                message: 'Chyba'
                            }
                        }
                    });
                    console.log(res.data);
                }

            })
            .catch(err => {
                console.log(err);
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {
                            state: 'warning',
                            message: 'Chyba'
                        }
                    }
                });

            });
    }


    const renderName = (props) => {

        return (
            <span>
                <span className="type">
                        {props.vlNeAnoVypadek === 1 ? <Badge variant="danger mr-1">K</Badge> : null}
                    {props.vlNeAnoVypadekBud === 1 ? <Badge variant="warning mr-1">V</Badge> : null}
                    {props.vlNeAnoVypadekBud === 0 && props.vlNeAnoVypadek === 0 ?
                        <Badge variant="success mr-1">Ok</Badge> : null}
                    </span>
                
                <span>
                    {props.nazevZbozi}
                </span>
            </span>
        )

    }


    const renderActionButtons = (props) => {
       
        
        const returnLastComm = () => {
            if(props.comments.length === 0){
                return(
                    <div>
                        Položka nemá žádný komentář.
                    </div>
                )
            }
            return(
                <div>
                    Poslední komentář:
                    <br></br>
                    {props.comments[0].comment}
                </div>
            )
            
        }

        return (
            <span>
                <span>
                     <OverlayTrigger
                         placement='top'
                         overlay={
                             <Tooltip id={`tooltip-top`}>
                                 Detail
                             </Tooltip>
                         }
                     >
                        <Badge style={{cursor: 'pointer'}} variant="primary mr-1 p-2" onClick={() => openDetailModal(props.idZbozi)}>
                            <FontAwesomeIcon icon={faInfoCircle}/>
                        </Badge>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement='top'
                        overlay={
                            <Tooltip id={`tooltip-top2`}>
                                Dát do košíku doporučené množství
                            </Tooltip>
                        }
                    >
                        
                      
                        <Badge style={{cursor: 'pointer'}} onClick={() => addToBasket({code: props.cisZbozi, amount: props.mnNavrhNobDoklad})} variant="success mr-1 p-2">
                            <FontAwesomeIcon icon={faShoppingBasket}/>
                        </Badge>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement='right'
                        overlay={
                            <Tooltip id={`tooltip-right`}>
                                {returnLastComm()}
                            </Tooltip>
                        }
                    >
                        
                      
                        <Badge style={{cursor: 'pointer'}}  variant="info mr-1 p-2">
                            {props.comments.length}
                        </Badge>
                    </OverlayTrigger>
                </span>
            </span>
        )

    }

    const columns = ([
        {
            name: '',
            cell: (row) => renderActionButtons(row),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '100px',
            center: true
        },

        {
            name: 'Ref',
            selector: 'cisZbozi',
            sortable: false,
            width: '100px',
            center: true
        },
        {
            name: 'Název',
            cell: (row) => renderName(row),
            width: '450px',
            sortable: false
            
        },
        {
            name: 'Dodavatel',
            selector: 'subjNazev',
            sortable: false,
            width: '250px'
        },
        {
            name: 'Mj',
            selector: 'kodMj',
            sortable: false,
            width: '60px',
            center: true
        },
        {
            name: 'Prio.',
            selector: 'jkpov',
            sortable: false,
            width: '60px',
            center: true
        },
        {
            name: 'Bal.',
            selector: 'text1',
            sortable: false,
            width: '130px'
        },
        {
            name: 'Lead time',
            cell: (row) => ( row.dobaDodani + 'd ' +  row.tydnyDodani + 't' ),
            sortable: false,
            width: '80px',
            right: true
        },
        {
            name: 'Doporučeno',
            selector: 'mnNavrhNobDoklad',
            sortable: false,
            width: '90px',
            right: true
        },
        {
            name: 'MOQ',
            selector: 'moq',
            sortable: false,
            width: '60px',
            right: true
        },
        {
            name: 'Stock QTY',
            selector: 'mnFyzickyStav',
            sortable: false,
            width: '80px',
            right: true
        },
        {
            name: 'Stock QTY w. o.',
            selector: 'mnFyzickyStavObj',
            sortable: false,
            width: '110px',
            right: true
        },
        {
            name: 'Safety stock',
            selector: 'mnMinZasoba',
            sortable: false,
            width: '90px',
            right: true
        },
        {
            name:  <div><div>Blok. celk.</div> <div>Blok. do LT</div></div>,
            cell: (row) => ( <div><div>{row.mnBlokovanoCel}</div> <div>{row.mnBlokovanoLt}</div></div>  ),
            sortable: false,
            width: '100px',
            right: true
        },
        {
            name: 'Objed.',
            selector: 'mnObjednanoCel',
            sortable: false,
            width: '60px',
            right: true
        },
        {
            name: 'Sales 90/3',
            selector: 'mnProdej90Deleno3',
            sortable: false,
            width: '90px',
            right: true
        }
        
        

    ]);

    const [pagination] = useState({
        rowsPerPageText: t('global.itemsOnPage') + ':',
        rangeSeparatorText: t('global.from'),
        noRowsPerPage: false
    });

    return (
        <div className="w-100 pb-5">

                <DataTable

                    data={data}
                    columns={columns}
                    paginationComponentOptions={pagination}
                    pagination
                    paginationPerPage={100}
                    paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                    theme="theme1"
                    customStyles={theme2Styles}
                    responsive={true}
                    dense={true}
                    noHeader={true}
                    fixedHeader={true}
                    fixedHeaderScrollHeight="500px"
                    striped={true}
                    
                  

                />
            <Modal isOpen={isOpen} onClose={() => {
                setOpen(false);
            }}>
                <ForecastStocksCont idzbozi={idzbozi} addToBasket={(d) => addToBasket(d)} />
            </Modal>

        </div>
        
    )

}