import React, {useState, useEffect, useCallback} from 'react';
import {Button, ButtonGroup, Col, Container, Form, Row, ToggleButton,} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle, faFilter, faWindowClose} from "@fortawesome/free-solid-svg-icons";
import {IsReadOnly} from "../../helpers/RoleProvider";
import {useUserContext} from "../../contexts/UserContext";
import Modal from "../shared/modal/Modal";
import AddPhoto from "./AddPhoto";
import Axios from "axios";
import {useTranslation} from "react-i18next";
import * as photosTypes from "../photos/PhotosTypes";
import DataTable from "react-data-table-component";
import theme1Styles from "../../config/dataTable/theme1Styles";
import {faEdit} from "@fortawesome/free-regular-svg-icons";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import axios from "axios";


const Photos = () => {

    const {t} = useTranslation();
    const [types, setTypes] = useState([]);

    const [isModalNewOpen, setModalNewOpen] = useState(false);
    const [isModalEditOpen, setIsModalEditOpen] = useState(false);
    const user = useUserContext();
    const [filter, setFilter] = useState({name: "", selectedType: "all", refId: ""});
    const [currentPage, setCurrentPage] = useState(1);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [perPage] = useState(10);
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [editId, setEditId] = useState(0);
    const [refData, setRefData] = useState([]);
    const [searchType, setSearchType] = useState('title');
    const [loaded, setLoaded] = useState(false);

    const handleChangeFilter = (e, name, value) => {

        setFilter(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    useEffect(() => {
        let data = photosTypes.returnDataForSelectByLang('cz');
        data.unshift({label: "vše", value: ""});
        setTypes(data);
    }, []);

    const handlePageChange = page => {
        handleFilter(page);
    }

    const handleFilter = useCallback((page) => {

        setCurrentPage(page);

        if (page === 1) {
            setResetPaginationToggle(!resetPaginationToggle);
        }

        let la = '';

        for(var i=0; i < refData.length; i++)
        {
            la += '&refs=' + refData[i].value;
        }




        let params = searchType + '=' + filter.name + '&refId=' + filter.refId + '&page=' + page + '&perPage=' + perPage + '&active=' + filter.activeOnly + la;

        Axios.get('sharepointPhotosPrivate/filter?' + params).then(res => {
            setData(res.data.items);
            setTotalRows(res.data.totalItems);
        });
        
        
    }, [resetPaginationToggle, currentPage, filter, searchType, refData]);

    const columns = (() => [
        {
            name: 'idSharepoint',
            selector: 'id',
            sortable: false
        },
        {
            name: t('catalog.table.name'),
            selector: 'title',
            sortable: true,
        },
        {
            name: 'FileName',
            cell: (row) => {
                return (
                    row.displayName + '.' + row.fileExtension
                )
            }
        },
        {
            cell: (row) => {
                return (
                    <React.Fragment>
                        {/* edit */}
                        {!IsReadOnly(user) ?
                            <Button
                                title={"edit"}
                                size={"sm"}
                                variant={"primary"}
                                className={"mr-1"}
                                onClick={() => {
                                    setEditId(row.id);
                                    setIsModalEditOpen(true);
                                }}
                                id={row.id}><FontAwesomeIcon icon={faEdit}/>
                            </Button>
                            : null}
                    </React.Fragment>
                )
            }
            ,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },

    ]);

    const handleRefIdItemsClikc = (key) => {
        let arr = [...refData];
        arr.splice(key, 1);
        setRefData(arr);
    }

    const handleOptionsRefIdClick = (event) => {
        let arr = [];
        if (refData && refData.length > 0) {
            for (let i = 0; i < refData.length; i++) {
                if (refData[i].value === event.value) {
                    console.log('This code already exists');
                    return false;
                }
            }
            arr = [...refData];
        }

        arr.push({value: event.value, label: event.label});
        setRefData(arr);
    }

    //reference na produkt
    const getOptionsForRef = (inputValue) => {
        return axios.get("/sharepoint/1/productsRef/" + inputValue).then(res => {
            return (res.data);
        });
    }

    return (


        <Container fluid>

            <Row>
                <h1>{t('photos.photos')}</h1>
            </Row>
            {!IsReadOnly(user) ?
                <Row className={"pt-2 pb-2 pr-3 pl-3"}>
                    <Button variant={"primary"} onClick={() => setModalNewOpen(true)}>
                        <FontAwesomeIcon icon={faPlusCircle} className={"i"}/>
                        {t('photos.add')}
                    </Button>
                </Row> : null}
            <Row className={"p-3"}>
                <Form style={{width: '100%'}} className={"cBG_BS_light p-3 rounded  documentsShrp"}>
                    <Form.Row>
                        <Form.Group as={Col} className={"col-6"}>

                            <Form.Row className={"p-2"}>
                                <ButtonGroup toggle>
                                    <ToggleButton
                                        key={1}
                                        type="radio"
                                        variant="secondary"
                                        name="filter"
                                        value={'title'}
                                        checked={searchType === 'title'}
                                        onChange={() => {
                                            setSearchType('title')
                                        }}

                                    >
                                        {t('catalog.name')}
                                    </ToggleButton>

                                    <ToggleButton
                                        key={2}
                                        type="radio"
                                        variant="secondary"
                                        name="filter"
                                        value={'fileName'}
                                        checked={searchType === 'fileName'}
                                        onChange={() => {
                                            setSearchType('fileName')
                                        }}

                                    >
                                        {t('catalog.fileName')}
                                    </ToggleButton>
                                </ButtonGroup>

                            </Form.Row>
                            <Form.Row className={"ps-3"}>
                                <Form.Group as={Col}>
                                    <Form.Control
                                        placeholder={"Search value"}
                                        type="text"
                                        name={"name"}
                                        value={filter.name}

                                        onChange={(e) => {
                                            handleChangeFilter(e, 'name', e.target.value)
                                        }}

                                    />
                                </Form.Group>

                            </Form.Row>
                            <Form.Row className={"ps-3"}>
                                <Form.Group as={Col}>
                                    <Form.Check
                                        placeholder={"Search value"}
                                        type="checkbox"
                                        name={"activeOnly"}
                                        checked={filter.activeOnly}
                                        label={"Zobrazit pouze aktivní"}
                                        onChange={(e) => {
                                            setFilter(prevState => ({
                                                ...prevState,
                                                'activeOnly': !filter.activeOnly
                                            }))
                                        }}

                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={"p-2"}>

                                <Button
                                    variant={"primary"}
                                    className={"btn-lg"}
                                    onClick={a => handleFilter(1)}

                                >
                                    <FontAwesomeIcon className="i" icon={faFilter}/>
                                    {t('catalog.filter')}
                                </Button>
                            </Form.Row>

                        </Form.Group>
                        <Form.Group as={Col} className={"col-6"}>
                            <Form.Row className={"ps-3 pe-3 pt-4"}>
                                <Form.Group as={Col} >
                                    <Form.Label>Vazba na REF produktu</Form.Label>
                                    <AsyncSelect
                                        loadOptions={(e) => getOptionsForRef(e)}
                                        valueKey={"value"}
                                        labelKey={"label"}
                                        onChange={(e) => {
                                            handleOptionsRefIdClick(e)
                                        }}

                                    />
                                    {refData && refData.length > 0 ?

                                        <div className={'cont'}>
                                            {
                                                refData.map((x, i) => {
                                                    return (
                                                        <div className={'item'} key={i}>
                                                    <span onClick={() => {
                                                        handleRefIdItemsClikc(i)
                                                    }}><FontAwesomeIcon className="i" icon={faWindowClose}/> </span>
                                                            <span>{x.label}</span>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                        : null
                                    }

                                </Form.Group>

                            </Form.Row>
                        </Form.Group>


                    </Form.Row>
                 
                </Form>
            </Row>

            <DataTable className="dataTable"

                       data={data}
                       columns={columns()}
                       pagination
                       paginationServer
                       paginationTotalRows={totalRows}
                       dense
                       noHeader
                       paginationPerPage={perPage}
                       paginationRowsPerPageOptions={[10]}
                       theme="theme1"
                       customStyles={theme1Styles}
                       onChangePage={handlePageChange}
                       paginationResetDefaultPage={resetPaginationToggle}
            />


            <Modal isOpen={isModalNewOpen} onClose={() => {
                setModalNewOpen(false)
            }}>
                <AddPhoto onClose={()=> {
                    setModalNewOpen(false); 
                    //handleFilter(currentPage)
                }} />
            </Modal>

            <Modal isOpen={isModalEditOpen} onClose={() => {
                setIsModalEditOpen(false)
            }}>
                <AddPhoto id={editId} edit={true} onClose={()=> {
                    setIsModalEditOpen(false); 
                    //handleFilter(currentPage)
                }} />
            </Modal>

        </Container>


    );
}

export default Photos;