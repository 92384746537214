import React, {useState, useEffect} from 'react';
import {useForm} from "react-hook-form";
import Switch from "react-switch";
import axios from 'axios';
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ADD, useAlertContext} from "../../contexts/AlertContext";
import Select from 'react-select';
import {faSync} from "@fortawesome/free-solid-svg-icons";
import InpurtError from "../InputError";
import {useTranslation} from "react-i18next";
import {Button, Col, Form, Row} from "react-bootstrap";
import {useUserContext} from "../../contexts/UserContext";
import {IsReadOnly} from "../../helpers/RoleProvider";

const ProductEshopItem = ({originalItem, index, locales}) => {

    const {register, watch, handleSubmit, errors, setValue} = useForm(
        {
            defaultValues: {
                price: originalItem.price,
                priceVat: originalItem.priceVat,
                vat: originalItem.vat,
                quantityInSellUnit: originalItem.quantityInSellUnit,
                priority: 0
            }
        });

    const watchPrice = watch("priceVat", false);
    
    useEffect(()=>{
        let price = getAmountWithoutVat(watchPrice, originalItem.vat)
        
        setValue('price', price.priceExVat )
    }, [watchPrice])

    function getAmountWithoutVat(amountIncludingVat, vatPercent) {

        var amountExVat = amountIncludingVat / (100 + vatPercent) * 100;
        var sumVat = amountIncludingVat - amountExVat;
        var amounts = {
            'priceExVat' : amountExVat.toFixed(2),
            'vat' : sumVat.toFixed(2)
        };

        return amounts;
    }
    
    const priorityOptions = [
        {value: '0', label: '0'},
        {value: '1', label: '1'},
        {value: '2', label: '2'},
        {value: '3', label: '3'},
    ]

    const {t} = useTranslation();
    const [item, setItem] = useState({isActive: false, showInMainPage: false, notSell: false, showInGoogleFeed: false});
    const user = useUserContext();
    const {alertDispatch} = useAlertContext();
    const [abbrev] = useState([]);

    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedLocale, setSelectedLocale] = useState('cz');
    const [selectedPriority, setSelectedPriority] = useState(1);
    const [loaded, setLoaded] = useState(false);

    const onSubmit = (items) => {

        items['isActive'] = item.isActive;
        items['showInMainPage'] = item.showInMainPage;
        items['notSell'] = item.notSell;
        items['showInGoogleFeed'] = item.showInGoogleFeed;
        
        

        if (selectedCategory) {
            items['selectedCategory'] = selectedCategory.map(item => item.value);
        }

        items['locale'] = selectedLocale;
        items['lang'] = selectedLocale;
        items['priority'] = selectedPriority;

        axios.post('/product/' + originalItem.productId + '/eshops/' + originalItem.eshopId + '/connected', items).then((res) => {
            alertDispatch({
                type: ADD,
                payload: {
                    content: {state: 'success', message: t('productEdit.eshopTab.succesMessage')}
                }
            });
        }).catch((err) => {
            alertDispatch({
                type: ADD,
                payload: {
                    content: {state: 'error', message: t('productEdit.eshopTab.errorMessage')}
                }
            });
        });

    }

    useEffect(() => {

        if (!loaded) {
            setItem(originalItem);

            setSelectedCategory(originalItem.selectedCategory);
            if (originalItem.lang) {
                setSelectedLocale(originalItem.lang);
                setSelectedPriority(originalItem.priority);
            }

            locales.forEach(item => abbrev.push({label: item.abbrev, value: item.abbrev}));

            setLoaded(true);
        }
    }, [originalItem, abbrev, loaded, locales]);

    const getCategories = (item) => {

        axios.get('/synchronize/categories/' + item.eshopCode).then(res => {
            setCategories(res.data);
            let arr = [];
            res.data.forEach(item => {
                if (selectedCategory && selectedCategory.includes(item.value)) {
                    arr.push(item);
                }
            });

            setSelectedCategory(arr);

            alertDispatch({
                type: ADD,
                payload: {
                    content: {state: 'success', message: t('productEdit.eshopTab.categoryLoaded')}
                }
            });

        }).catch(err => {
            alert(t('productEdit.eshopTab.categoryNotLoaded'));
        })
    }

    const handleCategory = (selectedOptions) => {
        setSelectedCategory(selectedOptions);
    }

    const handleAbbrev = (item) => {
        setSelectedLocale(item.value);
    };


    return (


        <Form method={"POST"} onSubmit={handleSubmit(onSubmit)} className={"p-3 rounded cBG_lightGray mb-3"}>
            <Form.Row className={"box"}>
                <Form.Group as={Col}>
                    <h2>{item.name}</h2>
                </Form.Group>
            </Form.Row>


            <Form.Row className={"flex-wrap justify-content-start"}>

                <Col sm={3} md={12} className={"p-2"}>
                    <Row className={"flex-nowrap justify-content-start m-0"}>
                        <div>
                            <Form.Label className={"w-100"}>{t('productEdit.eshopTab.active')}</Form.Label>
                            <Switch checked={item.isActive} name={"active"} onChange={(a) => {

                                setItem(prevState => ({
                                    ...prevState,
                                    isActive: a
                                }));
                                item.isActive = a;

                            }}/>
                        </div>
                        <div>
                            <Form.Label className={"w-100"}>{t('productEdit.eshopTab.sale')}</Form.Label>
                            <Switch checked={item.showInMainPage} name={"active"} onChange={(a) => {

                                setItem(prevState => ({
                                    ...prevState,
                                    showInMainPage: a
                                }));
                                item.showInMainPage = a;
                            }}/>
                        </div>
                        <div>
                            <Form.Label className={"w-100"}>{t('productEdit.eshopTab.notSell')}</Form.Label>
                            <Switch checked={item.notSell} name={"active"} onChange={(a) => {

                                setItem(prevState => ({
                                    ...prevState,
                                    notSell: a
                                }));
                                item.notSell = a;
                            }}/>
                        </div>
                        <div>
                            <Form.Label className={"w-100"}>{t('productEdit.eshopTab.showInGoogleFeed')}</Form.Label>
                            <Switch checked={item.showInGoogleFeed} name={"active"} onChange={(a) => {

                                setItem(prevState => ({
                                    ...prevState,
                                    showInGoogleFeed: a
                                }));
                                console.log(a);
                                item.showInGoogleFeed = a;
                            }}/>
                        </div>
                    </Row>

                </Col>

                <Col sm={3} className={"p-2"}>
                    <label>{t('productEdit.eshopTab.variant')}</label>

                    {loaded && <Select
                        className={"select-abbrev"}
                        style={{width: 60}}
                        onChange={(e) => {
                            handleAbbrev(e)
                        }}
                        options={abbrev}
                        value={abbrev.filter(option => option.value === selectedLocale)}
                    />}
                </Col>
                <Col sm={3} className={"p-2"}>
                    <label>{t('productEdit.eshopTab.priority')}</label>
                    <Select className={""}
                            options={priorityOptions}
                            name={"priority"}
                            value={priorityOptions.find(option => option.value === selectedPriority)}
                            defaultValue={"1"}
                            onChange={(e) => {
                                setSelectedPriority(e.value);
                            }}
                    />
                </Col>
            </Form.Row>
            <Form.Row className={"flex-wrap justify-content-start align-items-center"}>


                <Col sm={2} className={"pt-1"}>
                        <label>Cena bez DPH</label>
                        <div className={"error-row"}>
                            
                            <Form.Control type={"number"} name={"price"}
                                          ref={register}
                                          step="any"
                                            readOnly={true}/>
                        </div>
                    </Col>
                <Col sm={2} className={"pt-1"}>
                        <label>{t('productEdit.eshopTab.vat')}</label>
                        <div className={"error-row"}>
                            <Form.Control type={"number"} name={"vat"}
                                          ref={register}
                                          step="any"
                                          readOnly={true}/>
                            {errors.vat && errors.vat.type === 'required' &&
                            <InpurtError message={t("validation.required")}/>}
                            {errors.vat && errors.vat.type === 'min' &&
                            <InpurtError message={t("validation.biggerThan0")}/>}
                        </div>
                    </Col>
                <Col sm={2} className={"pt-1"}>
                    <Form.Label>{t('productEdit.eshopTab.vatPrice')} * </Form.Label>
                    <div className={"error-row"}>
                        {item.lang === "cz" ?
                            <Form.Control type={"number"} name={"priceVat"}
                                          ref={register({required: true, min: 1, pattern: /^[0-9\b]+$/})}
                                          step="any"/> :

                            <Form.Control type={"number"} name={"priceVat"}
                                          ref={register({required: true, min: 1})}
                                          step="any"/>}
                        <Form.Text>
                            {errors.priceVat && errors.priceVat.type === 'required' &&
                            <InpurtError showMessage={true} message={t("validation.required")}/>}
                            {errors.priceVat && errors.priceVat.type === 'min' &&
                            <InpurtError showMessage={true} message={t("validation.biggerThan1")}/>}
                            {errors.priceVat && errors.priceVat.type === 'pattern' &&
                            <InpurtError showMessage={true} message={t("validation.fullNumber")}/>}
                        </Form.Text>
                    </div>
                </Col>
                <Col sm={3} className={"pt-1"}>
                    <Form.Label>{t('productEdit.eshopTab.itemsInSellUnit')}</Form.Label>
                    <Form.Control className="mx-sm-3" type={"text"} name={"quantityInSellUnit"} ref={register}
                                  disabled={"disabled"}/>
                </Col>


            </Form.Row>
            <Form.Row className={"pb-3"}>
                <Col sm={5}>
                    <Form.Label>{t('productEdit.eshopTab.category')}</Form.Label>

                    <Select className={"select-category"}
                            isDisabled={categories.length > 0 ? "" : "disabled"}
                            options={categories}
                            name={"categories"}
                            isMulti
                            value={categories.length > 0 ? selectedCategory : null}
                            defaultValue={""}
                            onChange={(e) => {
                                handleCategory(e)
                            }}
                    />
                </Col>
                <Col sm={3}>
                    <Button size={"sm"} type={"button"} className={"btn-submit-blue mt-4"} onClick={() => {
                        getCategories(item);
                    }}>
                        <FontAwesomeIcon icon={faSync} className="i"/>
                        {t('productEdit.eshopTab.loaded')}
                    </Button>
                </Col>
            </Form.Row>
            {!IsReadOnly(user) ? 
            <Form.Row>
                <Form.Group as={Col}>
                    <Button type="submit" variant={"success"}>
                        <FontAwesomeIcon icon={faSave} className="i"/>
                        {t('productEdit.eshopTab.save')}
                    </Button>
                </Form.Group>
            </Form.Row> : null }
            <Form.Row className={"row notes"}>
                {item.isActive && item.notSell &&
                <div className={"not-sell"}>{t('productEdit.eshopTab.notSellAlert')}</div>}
                <div
                    dangerouslySetInnerHTML={{__html: t('productEdit.eshopTab.priceAlert', {interpolation: {escapeValue: false}})}}/>

            </Form.Row>
        </Form>


    )

}
export default ProductEshopItem;