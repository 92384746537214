import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, useHistory} from 'react-router-dom'
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import './config/i18n';
import {authenticationService} from './helpers/AuthenticationService';
import axios from 'axios';
import {AlertProvider} from "./contexts/AlertContext";
import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.scss';

const rootElement = document.getElementById('root');

axios.interceptors.request.use(
    config => {
        document.getElementById('loader').classList.remove('lds-spinner-hide');

        if ((config.url === "/user/authenticate" || config.url.startsWith("/user/recovery"))) {
            return config;
        } else {
            const currentUser = authenticationService.currentUserValue;

            if (currentUser) {
                config.headers['Authorization'] = 'Bearer ' + currentUser.data.token;
                return config;
            } else {
                authenticationService.logout();
               // let history = useHistory();
               // history.push('/login')
            }
        }
    },
    error => {
        console.log(error);
        Promise.reject(error)
    });


axios.interceptors.response.use(response => {

    let version = response.headers['x-version'];

    document.getElementById('loader').classList.add('lds-spinner-hide');

    
    let saveVersion = localStorage.getItem('version');
    
    if(saveVersion == null)
    {
        localStorage.setItem('lastcheck', new Date());
        localStorage.setItem('version', version);
    }
    
    if(saveVersion !== version)
    {
        console.log('Jina verze i-Batist.com');
        
        let diff = Math.abs(new Date() - new Date(localStorage.getItem('lastcheck')));
        let minutes = Math.floor((diff/1000)/60);

        console.log('Minut od posledniho potvrzeni: ' + minutes);

        if(minutes > 1)
        {
            if (window.confirm('IBatist has been updated. Please click "OK" and then press CTRL + F5 on WINDOWS and CMD + SHIFT + R on Mac OS on your computer. Thank you.')) {
                localStorage.setItem('version', version);
            }

            localStorage.setItem('lastcheck', new Date());
        }

        
    }
    

    


    if ((response.config.url === "/user/authenticate") && !response.data.token) {
        return Promise.reject('not valid token');
    }

    return response;
}, error => {

    document.getElementById('loader').classList.add('lds-spinner-hide');

    if (error.response !== undefined) {
        if (error.response.status === 401) {
            authenticationService.logout();
            window.location.reload();
        }

        if (error.response.status === 403) {
            authenticationService.logout();
            window.location.reload();
        }
    }

    return Promise.reject(error);
});


ReactDOM.render(
    <BrowserRouter>
        <AlertProvider>
            <App/>
        </AlertProvider>
    </BrowserRouter>
    ,
    rootElement);

registerServiceWorker();

