import React from 'react';
import 'react-overlay-loader/styles.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";





const Mall = () => {



    return (
        <div>
            <h1>Mall.cz</h1>
            <p>Ovládání komunikace s Mall.cz. Oboustranný přenos objednávek, vyúčtování, atd...</p>
            <div className="subMenu">
                <button type={"button"} ><FontAwesomeIcon className="i" icon={faPlus}/> Synchronizovat objednávky
                </button>
            </div>
        </div>
    )
}

export default Mall;
