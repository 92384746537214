export function IsReadOnly(userContext) {
    
    return userContext.data.role === "Readonly";
}


export  function IsAdminOnly(userContext) {

    return userContext.data.role === "Admin";
}
