import React, {useEffect, useState} from "react";
import {Button, Form} from "react-bootstrap";
import {faShoppingBasket} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from "axios";
import {ADD, useAlertContext} from "../../../contexts/AlertContext";
import Badge from "react-bootstrap/Badge";
import NumberFormat from "react-number-format";

export const ForecastStocksItemControl = (props) => {
    const moq = parseInt(props.moq);
    const [amount, setAmount] = useState(props.mnNavrh);
    const [basketDisabled, setBasketDisabled] = useState(false);
    const {alertDispatch} = useAlertContext();
    
    
    

 /*   const addToBasket = () => {
        if (isNaN(moq) || isNaN(amount) ){
            alertDispatch({
                type: ADD,
                payload: {
                    content: {
                        state: 'warning',
                        message: 'Množství MOQ musí být číslo.'
                    }
                }
            });
            return false;
            
        }

        let data = {
            referenceCode: props.code,
            amountInBasket: amount
        }
        
        props.addToBasket(data);
        setBasketDisabled(true);
       
    }*/

    const handleNumberChange = (val) => {
        setAmount(val);

    }

    return (
        <div className="actions">

            {props.amountInBasket ?
                <div className="addToBasket">
                    <h5 style={{margin: 0}}>
                        <Badge variant="warning" style={{fontWeight: 500, padding: 7}}>
                            <FontAwesomeIcon className={"i"} icon={faShoppingBasket}/>
                            <NumberFormat value={props.amountInBasket}  displayType={'text'} thousandSeparator={" "} />
                            <span style={{marginLeft: 5}}>{props.mj}</span>
                            
                        </Badge>
                    </h5>
                </div>


                :

                <div className="addToBasket">
                    
                    <Form.Control
                        aria-label="Default"
                        aria-describedby="inputGroup-sizing-default"
                        type="number"
                        value={amount}
                        className="number"
                        onChange={(e) => handleNumberChange(parseInt(e.target.value))}
                        placeholder=""
                        min={0}


                    />
                    <div className="amount">
                        
                        <span>{props.mj}</span>

                    </div>
                    <Button size="sm" className="m-1 ml-2" variant="warning" onClick={() => props.addToBasket({code: props.code, amount: amount})} disabled={basketDisabled}><FontAwesomeIcon
                        className={"i"} icon={faShoppingBasket}/>Do košíku</Button>
                </div>
            }

            <Button size="sm" className="m-1" variant="outline-secondary"  onClick={() => props.toggleComments()}>Komentáře ({props.commLength})</Button>
           {/* <Button size="sm" className="m-1" variant="secondary" disabled={true} onClick={() => props.openModal(true)}>Načti obj z
                ESO9</Button>*/}
            

        </div>
    )
}