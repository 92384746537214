import React, {useState, useEffect, useCallback} from 'react';
import axios from "axios";
import {Col, Form} from "react-bootstrap";
import Select from "react-select";
import {ADD, useAlertContext} from "../../contexts/AlertContext";


const CatalogFilterCategoriesEso = (props) => {
    //const
    const {alertDispatch} = useAlertContext();
    
    
    //data
    const noCat = {label: '', value: 0};
    const [cat1, setCat1] = useState([]);
    const [cat2, setCat2] = useState([]);
    const [cat3, setCat3] = useState([]);
    const [cat4, setCat4] = useState([]);

    const [cat1Selected, setCat1Selected] = useState(noCat);
    const [cat2Selected, setCat2Selected] = useState(noCat);
    const [cat3Selected, setCat3Selected] = useState(noCat);
    const [cat4Selected, setCat4Selected] = useState(noCat);

    //useEffect - pass data to parent
    useEffect(() => {
        let cats = {}
        cats.cat1 = cat1Selected.value;
        cats.cat2 = cat2Selected.value;
        cats.cat3 = cat3Selected.value;
        cats.cat4 = cat4Selected.value;
        
        props.setCategories(cats)
        
       

    }, [cat1Selected,cat2Selected,cat3Selected,cat4Selected]);
    
    //handle
    const loadOptions = (level, code) => {

        let c1 = {...noCat};
        let c2 = {...noCat};
        let c3 = {...noCat};
        let c4 = {...noCat};

        switch (level) {
            case 1:
                break;
            case 2:
                c1.value = code.toString();
                break;
            case 3:
                c1 = cat1Selected;
                c2.value = code.toString();
                break;
            case 4:
                c1 = cat1Selected;
                c2 = cat2Selected;
                c3.value = code.toString();
                break;
            default:
                break;
        }


        axios.get('api/categoryEso/2/select?cat1=' + c1.value + '&cat2=' + c2.value + '&cat3=' + c3.value + '&cat4=' + c4.value).then((res) => {
            //console.log(res.data);
            if (res.data.state !== 0) {
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {state: 'warning', message: "Error in loading categories"}
                    }
                });
            }
            switch (level) {
                case 1:
                    setCat1(res.data.items);
                    break;
                case 2:
                    setCat2(res.data.items);
                    break;
                case 3:
                    setCat3(res.data.items);
                    break;
                case 4:
                    setCat4(res.data.items);
                    break;
                default:

            }


        });
    };

    const onChangeHandle = (e, level) => {

        if (level === 1) {
            if (e) {
                setCat1Selected({value: e.value, label: e.label});
                setCat2Selected(noCat);
                setCat3Selected(noCat);
                setCat4Selected(noCat);
                loadOptions(2, e.value);
            } else {
                setCat1Selected(noCat);
                setCat2Selected(noCat);
                setCat3Selected(noCat);
                setCat4Selected(noCat);
                setCat2([]);
                setCat3([]);
                setCat4([]);
            }
        } else if (level === 2) {
            if (e) {
                setCat2Selected({value: e.value, label: e.label});
                setCat3Selected(noCat);
                setCat4Selected(noCat);
                loadOptions(3, e.value);
            } else {
                setCat2Selected(noCat);
                setCat3Selected(noCat);
                setCat4Selected(noCat);
                setCat3([]);
                setCat4([]);
            }
        } else if (level === 3) {
            if (e) {
                setCat3Selected({value: e.value, label: e.label});
                setCat4Selected(noCat);
                loadOptions(4, e.value);

            } else {
                setCat3Selected(noCat);
                setCat4Selected(noCat);
                setCat4([]);
            }
        } else if (level === 4) {
            if (e) {
                setCat4Selected({value: e.value, label: e.label});
            } else {
                setCat4Selected(noCat);
            }
        }

    }
    
    

    //render
    return (
        <Form.Row className={"ps-2 pt-0 pb-2"}>
            <Form.Group as={Col}>

                <div style={{minWidth: 280}} className={"p-2"}>

                    <label>Category 1 </label>
                    <button className={'btn btn-secondary btn-sm m-1'} type={"button"} onClick={(e) => {
                        loadOptions(1, 0)
                    }}>Load</button>

                    <Select placeholder={"Select category1"} options={cat1} value={cat1Selected} isClearable
                            isSearchable onChange={(e) => onChangeHandle(e, 1)}/>

                </div>
                <div style={{minWidth: 280}} className={"p-2"}>
                    <label>Category 2</label>
                    {cat1Selected ?
                        <button type={"button"} className={'btn btn-secondary btn-sm m-1'} onClick={(e) => {
                            loadOptions(2, cat1Selected.value)
                        }}>Load</button>
                        : null}
                    <Select placeholder={"Select category2"} options={cat2} isClearable isSearchable
                            value={cat2Selected} onChange={(e) => onChangeHandle(e, 2)}/>


                </div>
                <div style={{minWidth: 280}} className={"p-2"}>
                    <label>Category 3</label>
                    {cat2Selected ?
                        <button className={'btn btn-secondary btn-sm m-1'} type={"button"} onClick={(e) => {
                            loadOptions(3, cat2Selected.value)
                        }}>Load</button>
                        : null}

                    <Select placeholder={"Select category3"} options={cat3} isClearable isSearchable
                            value={cat3Selected} onChange={(e) => onChangeHandle(e, 3)}/>


                </div>
                <div style={{minWidth: 280}} className={"p-2"}>

                    <label>Category 4 </label>
                    {cat3Selected ?
                        <button className={'btn btn-secondary btn-sm m-1'} type={"button"} onClick={(e) => {
                            loadOptions(4, cat3Selected.value)
                        }}>Load</button>
                        : null}


                    <Select placeholder={"Select category4"} options={cat4} isClearable isSearchable
                            value={cat4Selected} onChange={(e) => onChangeHandle(e, 4)}/>


                </div>

            </Form.Group>
        </Form.Row>
    )
}

export default CatalogFilterCategoriesEso