



export const data = [
    {
        code: "certificate",
        id: 2,
        langs: [
            {
                code: "en",
                name: "certificate"
            },
            {
                code: "cz",
                name: "certifikát"
            },
            {
                code: "pl",
                name: "certifikát"
            },

        ]
    },
    {
        code: "manual",
        id: 3,
        langs: [
            {
                code: "en",
                name: "manual"
            },
            {
                code: "cz",
                name: "manuál"
            },
            {
                code: "pl",
                name: "manuál"
            },

        ]
    }
    ,
    {
        code: "other",
        id: 1,
        langs: [
            {
                code: "en",
                name: "other"
            },
            {
                code: "cz",
                name: "ostatní"
            },
            {
                code: "pl",
                name: "ostatní"
            },

        ]
    }
];

export const returnDataForSelectByLang = (lang) =>{
    let returnData = [];
    for (let i = 0; i < data.length; i++) {
        let langData = data[i].langs.filter(f => f.code === lang);
        let label = langData[0].name;
        let value = data[i].code;
        let obj = {label: label, value: value};
        returnData.push(obj);

    }

    return returnData;

}