import {OverlayTrigger, Popover} from "react-bootstrap";
import React from "react";


const CatalogImagePreview = (props) => {

    const popover = (props) => {

        return(


            <Popover id={"popover-basic"}  style={{maxWidth: 300, width: 300}}>
                
                <Popover.Content style={{maxWidth: '100%'}}>
                    <img src={'https://ibatists4m.blob.core.windows.net/mains/' + props.data + '_md.webp'}  onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src="";
                    }} width={"250"}  alt={""}/>
                </Popover.Content>
            </Popover>

        )
    }

    return(
        props.code ?
            <OverlayTrigger focus trigger={["hover", "hover"]} placement="right" key={props.key} overlay={popover({data: props.code})}>
                <img src={'https://ibatists4m.blob.core.windows.net/mains/' + props.code + '_sm.webp'}  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src="";
                }}  width={"50"} alt={""}/>
            </OverlayTrigger>
            : null
    )
}

export default CatalogImagePreview;