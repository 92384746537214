import React from 'react';
import {Route} from 'react-router-dom';
import {PrivateRoute} from './components/PrivateRoute';
import {Layout} from './components/Layout';
import Home from './components/Home';
import Login from './components/Login';
import Erp from './components/erp/Erp';
import Region from './components/region/Region';
import User from './components/user/User';
import Category from './components/category/Category';
import Tagy from './components/tagy/Tagy';
import Mall from "./components/mall/Mall";
import GP from "./components/GP/GP";
import PasswordRecovery from "./components/user/PasswordRecoery";
import UserUpdate from "./components/user/UserUpdate";
import Documents from './components/documents/Documents'
import CategoryEso from "./components/categoryEso/CategoryEso";
import Photos from "./components/photos/Photos";
import BatistSafety from "./components/batistsafety/BatistSafety";
import Manufacters from "./components/manufacters/Manufacters";
import ReportsPage from "./pages/ReportsPage";
import ReportStocks from "./components/reports/reportStocks/ReportStocks";
import Catalog from "./components/catalog/Catalog";
import {ReportForecastStocks} from "./components/reports/forecastStocks/ReportForecastStocks";
import Shoptet from "./components/shoptet/Shoptet";


export default function App() {

    return (

        <Layout>
            <Route path='/login' component={Login}/>
            <Route exact path={'/password-recovery/:token?'} component={PasswordRecovery}/>

            <PrivateRoute exact path='/' component={Home}/>
            <PrivateRoute path='/catalog' component={Catalog}/>
            <PrivateRoute exact path='/erp' component={Erp}/>
            <PrivateRoute exact path='/region' component={Region}/>
            <PrivateRoute exact path='/user' component={User}/>
            <PrivateRoute path='/category' component={Category}/>
            <PrivateRoute path='/documents' component={Documents}/>
            <PrivateRoute path='/photos' component={Photos}/>
            <PrivateRoute path='/categoryEso' component={CategoryEso}/>
            <PrivateRoute path='/tags' component={Tagy}/>
            <PrivateRoute path='/mall' component={Mall}/>
            <PrivateRoute path='/gp' component={GP}/>
            <PrivateRoute path='/settings' component={UserUpdate}/>
            <PrivateRoute path={"/batistsafety"} component={BatistSafety}/>
                <PrivateRoute path={"/shoptet"} component={Shoptet}/>
            <PrivateRoute path={"/manufacters"} component={Manufacters}/>

            {/*  REPORTS  */}
            <PrivateRoute exact path={"/reports"} component={ReportsPage}  />
            <PrivateRoute exact path={"/reports/stocks"} component={ReportStocks}/>
                <PrivateRoute exact path={"/reports/forecastStocks"} component={ReportForecastStocks}/>

        </Layout>
    );

}


