
export const data = [
    {
        code: "cs",
        langs: [
            {
                code: "en",
                name: "Czech"
            },
            {
                code: "cz",
                name: "Čeština"
            },
            {
                code: "pl",
                name: "Czech"
            },

        ]
    },
    {
        code: "en",
        langs: [
            {
                code: "en",
                name: "English"
            },
            {
                code: "cz",
                name: "Angličtina"
            },
            {
                code: "pl",
                name: "Angielski"
            },

        ]
    }
    ,
    {
        code: "pl",
        langs: [
            {
                code: "en",
                name: "Polish"
            },
            {
                code: "cz",
                name: "Polština"
            },
            {
                code: "pl",
                name: "Polskie"
            },

        ]
    },
    {
        code: "nl",
        langs: [
            {
                code: "en",
                name: "Dutch"
            },
            {
                code: "cz",
                name: "Holandština"
            },
            {
                code: "pl",
                name: "Holenderski "
            },

        ]
    },
    {
        code: "fi",
        langs: [
            {
                code: "en",
                name: "Finnish"
            },
            {
                code: "cz",
                name: "Finština"
            },
            {
                code: "pl",
                name: "Fiński"
            },

        ]
    },
    {
        code: "fr",
        langs: [
            {
                code: "en",
                name: "French"
            },
            {
                code: "cz",
                name: "Francouzština"
            },
            {
                code: "pl",
                name: "Francuski"
            },

        ]
    },
    {
        code: "de",
        langs: [
            {
                code: "en",
                name: "German"
            },
            {
                code: "cz",
                name: "Němčina"
            },
            {
                code: "pl",
                name: "Niemiecki"
            },

        ]
    },
    {
        code: "he",
        langs: [
            {
                code: "en",
                name: "Hebrew"
            },
            {
                code: "cz",
                name: "Hebrejština"
            },
            {
                code: "pl",
                name: "Hebrajski"
            },

        ]
    },
    {
        code: "hu",
        langs: [
            {
                code: "en",
                name: "Hungarian"
            },
            {
                code: "cz",
                name: "Maďarština"
            },
            {
                code: "pl",
                name: "Węgierski"
            },

        ]
    },
    {
        code: "it",
        langs: [
            {
                code: "en",
                name: "Italian"
            },
            {
                code: "cz",
                name: "Italština"
            },
            {
                code: "pl",
                name: "Italski"
            },

        ]
    },
    {
        code: "ja",
        langs: [
            {
                code: "en",
                name: "Japanese"
            },
            {
                code: "cz",
                name: "Japonština"
            },
            {
                code: "pl",
                name: "Japoński"
            },

        ]
    },
    {
        code: "ko",
        langs: [
            {
                code: "en",
                name: "Korean"
            },
            {
                code: "cz",
                name: "Korejština"
            },
            {
                code: "pl",
                name: "Koreański"
            },

        ]
    },
    {
        code: "no",
        langs: [
            {
                code: "en",
                name: "Norwegian"
            },
            {
                code: "cz",
                name: "Norština"
            },
            {
                code: "pl",
                name: "Norweski "
            },

        ]
    },
    {
        code: "pt",
        langs: [
            {
                code: "en",
                name: "Portugese"
            },
            {
                code: "cz",
                name: "Portugalština"
            },
            {
                code: "pl",
                name: "Portugalski "
            },

        ]
    },
    {
        code: "ro",
        langs: [
            {
                code: "en",
                name: "Romanian"
            },
            {
                code: "cz",
                name: "Rumunština"
            },
            {
                code: "pl",
                name: "Rumuński"
            },

        ]
    },
    {
        code: "ru",
        langs: [
            {
                code: "en",
                name: "Russian"
            },
            {
                code: "cz",
                name: "Ruština"
            },
            {
                code: "pl",
                name: "Ruski"
            },

        ]
    },
    {
        code: "sk",
        langs: [
            {
                code: "en",
                name: "Slovak"
            },
            {
                code: "cz",
                name: "Slovenština"
            },
            {
                code: "pl",
                name: "Słowacki "
            },

        ]
    },
    {
        code: "sl",
        langs: [
            {
                code: "en",
                name: "Slovenian"
            },
            {
                code: "cz",
                name: "Slovinština"
            },
            {
                code: "pl",
                name: "Słoweński  "
            },

        ]
    },
    {
        code: "es",
        langs: [
            {
                code: "en",
                name: "Spanish"
            },
            {
                code: "cz",
                name: "Španělština"
            },
            {
                code: "pl",
                name: "Hiszpański  "
            },

        ]
    },
    {
        code: "sv",
        langs: [
            {
                code: "en",
                name: "Swedish"
            },
            {
                code: "cz",
                name: "Švédština"
            },
            {
                code: "pl",
                name: "Szwedzki  "
            },

        ]
    },
    {
        code: "tr",
        langs: [
            {
                code: "en",
                name: "Turkish"
            },
            {
                code: "cz",
                name: "Turečtina"
            },
            {
                code: "pl",
                name: "Szwedzki"
            },

        ]
    },
    {
        code: "zh",
        langs: [
            {
                code: "en",
                name: "Chinese"
            },
            {
                code: "cz",
                name: "Čínština"
            },
            {
                code: "pl",
                name: "Chiński"
            },

        ]
    }
];

export const returnDataForSelectByLang = (lang) =>{
    let returnData = [];
    for (let i = 0; i < data.length; i++) {
        let langData = data[i].langs.filter(f => f.code === lang);
        let label = langData[0].name;
        let value = data[i].code;
        let obj = {label: label, value: value};
        returnData.push(obj);

    }

    return returnData;

}