import React from "react";
import "./EsoApi1002.scss";
import Moment from "react-moment";

export const EsoApi1002 = (props) => {
    const data = props.data;
    
    if(data.length === 0  ){
        return(
            <div>
                Žádná data
            </div>
        )
    } 
    
  
    
    return(
        <div className="EsoAPi1002">
            <div className="cont">
                {data.map((x,i) => {
                    return(
                        <div className="item bg-light rounded border" key={i}>
                            <div className="roww"><div className="value">{x.SUBJ_NAZEV}</div></div>
                            <div className="roww"><div className="key">Č. skladu:</div><div className="value">{x.CIS_SKLAD}</div></div>
                            <div className="roww"><div className="key">Č. zboží:</div><div className="value">{x.CIS_ZBOZI}</div></div>
                            <div className="roww"><div className="key">Počet:</div><div className="value">{x.MNPOCET}</div></div>
                            <div className="roww"><div className="key">Dodáno:</div><div className="value">{x.MNDODANO}</div></div>
                            <div className="roww"><div className="key">Zbývá:</div><div className="value">{x.MNZBYVA}</div></div>
                            <div className="roww"><div className="key">Jednotka:</div><div className="value">{x.KOD_MJ}</div></div>
                            <div className="roww"><div className="key">DTT termín:</div><div className="value"> <Moment format="DD.MM.YYYY" date={x.DTTERMIN}/></div></div>
                            
                        </div>
                    )
            })}
            </div>
        </div>
    )
    
}