import {Col, OverlayTrigger, Popover, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import Badge from "react-bootstrap/Badge";
import axios from "axios";


const PopOverPt = (props) => {
    const [consumption, setConsumption] = useState([]);
    const [consumptionLoaded, setConsumptionLoaded] = useState(false)
    const [show, setShow] = useState(false);

    const region = props.region;
    const productRegion = props.productRegion && props.productRegion.length > 0 ? props.productRegion[0] : null;

    const getDataFromPt = async () => {
        let params = "PTReference=" + props.ptRef;
        

        axios
            .get('api/reports/consumption/pt?' + params)
            .then((res) => {
                if(res.data.state === 0){
                    setConsumption(res.data.items);
                } 

            })
            .catch(err => {
                console.log(err);

            });

    }

    const getDataFromEs = async () => {
        let params = "PTReference=" + props.ptRef;


        axios
            .get('api/reports/consumption/es?' + params)
            .then((res) => {
                if(res.data.state === 0){
                    setConsumption(res.data.items);
                }

            })
            .catch(err => {
                console.log(err);

            });

    }

    useEffect(() => {
        if (!consumptionLoaded && show && props.ptRef) {
            if(props.region === "PT"){
                getDataFromPt().then(() => setConsumptionLoaded(true));
            } else if (props.region === "ES"){
                getDataFromEs().then(() => setConsumptionLoaded(true));
            }
            
        }


    }, [show, props.ptRef, consumptionLoaded]);

    const popover = (props) => {

        return(


            <Popover id={"popover-basic-" + props.data.ptReference ?? null}  style={{maxWidth: 390, width: 390}}>
                <Popover.Title as="h3">{region} detail info </Popover.Title> 
                
                <Popover.Content style={{maxWidth: '100%'}}>
                    {productRegion ?
                        <Row>
                            <Col xs={12} >
                                <div >
                                    <Badge variant="info">ERP name</Badge>
                                    <div>{productRegion.erpData.regionErpName}</div>
                                </div>


                            </Col>
                        </Row>
                        : null}
                    <Row>
                        <Col xs={5} >
                            
                            <div >
                                <Badge variant="info">Actual stock (Pcs/Bx)</Badge>
                                <div>{props.data.actualStock}/{props.data.actualStockBx}</div>
                            </div>
                            <div >
                                <Badge variant="info">Free (Pcs/Bx)</Badge>
                                <div>{props.data.freeStockPcs}/{props.data.freeStockBx}</div>
                            </div>
                            <div >
                                <Badge variant="info">Ordered (Pcs/Bx)</Badge>
                                <div>{props.data.orderedStockPcs}/{props.data.orderedStockBx}</div>
                            </div>
                            <div >
                                <Badge variant="info">Blocked</Badge>
                                <div>N/A</div>
                            </div>

                        </Col>
                        <Col xs={7} >
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <div style={{marginRight: 5}}>
                                    <Badge variant="info">Sell Unit</Badge>
                                    <div>{props.data.units} {productRegion && productRegion.unitMaster  ? "(" + productRegion.unitMaster.name  + ")" : null}</div>
                                </div>
                                <div  style={{marginRight: 5}}>
                                    <Badge variant="info">Alt. Unit</Badge>
                                    <div>{props.data.alternativeUnits}</div>
                                </div>
                                
                                
                            </div>
                            <div>
                                <Badge variant="info">Avg. Cost Price</Badge>
                                <div>{parseFloat(props.data.avgCostPrice).toFixed(2) ?? "N/A"}</div>
                            </div>
                            <div >
                                <Badge variant="info">Pcs in Sell Unit</Badge>
                                <div>{productRegion ? productRegion.erpData.piecesInSellUnit  : null}</div>

                                <Badge variant="info">Minimal Sell Unit Qty.</Badge>
                                <div>{productRegion ? productRegion.erpData.minimalSellUnitQuantity  : null}</div>
                            </div>
                          
                            {/*<div style={{display: 'flex', flexDirection: 'row'}}>
                                <div style={{marginRight: 5}}>
                                    <Badge variant="info">Box Qty</Badge>
                                    <div>{props.data.boxQty ?? "N/A"}</div>
                                </div>
                                <div>
                                    <Badge variant="info">Min Order Qty</Badge>
                                    <div>{props.data.minimiumOrderQty ?? "N/A"}</div>
                                </div>
                               
                                
                            </div>*/}
                            
                            {/*<div>
                                <Badge variant="info">Supplier</Badge>
                                <div>{props.data.suplier}</div>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <div style={{marginRight: 5}}>
                                    <Badge variant="info">State</Badge>
                                    <div>{props.data.statename}</div>
                                </div>
                                {props.region === "PT" ?
                                    <div>
                                        <Badge variant="info">Sell Out</Badge>
                                        <div>{props.data.sellOut ? "YES" : "NO"}</div>
                                    </div>
                                : null}
                                
                            </div>*/}
                            {consumption.length > 0 ?
                                <React.Fragment>
                                    <div >
                                        <Badge variant="info">Consumption (Bx)</Badge>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        {consumption.map((x,i) => {
                                            return(
                                                <React.Fragment>
                                                    <div style={{marginRight: 5}}>
                                                        <Badge variant="info">3 m</Badge>
                                                        <div>{ parseFloat(x.avgConsume3).toFixed(0) }</div>
                                                    </div>
                                                    <div style={{marginRight: 5}}>
                                                        <Badge variant="info">6 m</Badge>
                                                        <div>{ parseFloat(x.avgConsume6).toFixed(0) }</div>
                                                    </div>
                                                    <div>
                                                        <Badge variant="info">12 m</Badge>
                                                        <div>{ parseFloat(x.avgConsume12).toFixed(0) }</div>
                                                    </div>
                                                </React.Fragment>
                                            )
                                            
                                        })}
                                            
                                        
                                    </div>


                                </React.Fragment>
                                 
                            
                            : null
                            }
                          

                        </Col>
                    </Row>
                </Popover.Content>
            </Popover>

        )
    }

    return(
        props.data ?
            <OverlayTrigger onEnter={()=>{setShow(true)}} onExit={()=>{setShow(false)}}  trigger="hover" placement="left" key={props.key} overlay={popover({data: props.data})}>
                <FontAwesomeIcon icon={faInfoCircle} style={{fontSize: 20, cursor: 'pointer', color: '#66a4ff'}} />
            </OverlayTrigger>
            : null
    )
}

export default PopOverPt;