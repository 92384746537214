import React, {useEffect} from "react";
import {ForecastStocksItems} from "./ForecastStocksItems";
import {Paginating} from "../../shared/Paginating";
import axios from "axios";

export const ForecastStocksCont = (props) => {
    
    const [loaded, setLoaded] = React.useState(false);
    const [data, setData] = React.useState([]);
    
   
    
    useEffect(() => {
        if (!loaded){
            axios.get('api/reports/forecast/stocks/'+ props.idzbozi + '/detail' ).then(res => {
                setData(res.data.length > 0 ? res.data[0] : [] );
                setLoaded(true);

            });
        }

        
            
    }, [props.idzbozi]);
    
    
    
    return(
        /*<div className="w-100 mt-5 pb-5">
           <div><Paginating page={props.page} take={props.take} totalRows={props.data[0].totalRows} filter={(page) => props.filter(page)} /> </div>
            <div className="ReportForecastStocksCont " >
                {data.length > 0 ? 
                    data.map((x,i) => {
                        return(<ForecastStocksItems refreshBasket={() => props.refreshBasket()} item={x} key={"cont" + x.cisZbozi} />)
                    })
                : null
                }
            </div>
           <div><Paginating page={props.page} take={props.take} totalRows={props.data[0].totalRows} filter={(page) => props.filter(page)} /> </div>
        </div>*/
        <div>
            <ForecastStocksItems refreshBasket={() => props.refreshBasket()} item={data} key={1} addToBasket={(d) => props.addToBasket(d)} />
        </div>
    )
    
}