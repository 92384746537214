const theme1Styles = {
    rows: {
        style: {
            minHeight: '72px', // override the row height
            background: '#f8f9fa',
            "&:hover": {
                backgroundColor: '#e8e8e8'
            }
           
        }
    },
   
   
    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            paddingTop: '12px',
            paddingBottom: '12px',
            background: '#6c757d',
            color: '#fff',
            fontSize: '12px',
       
            
            
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            paddingTop: '10px',
            paddingBottom: '10px'
        },
    },
    pagination: {
        style: {
            boxShadow: 'none',
            fontSize: '13px',
            minHeight: '56px',
            borderTopStyle: 'solid',
            borderTopWidth: '1px',
            justifyContent: 'flex-start'
        }
    }
};

export default theme1Styles;