import React, {useState, useEffect} from 'react';
import axios from 'axios';
//import './ProductEshop.scss';
import ProductEshopItem from "./ProductEshopItem";
import {Col} from "react-bootstrap";
import Shoptet from "./Shoptet";

const ProductEshop = ({id, locales}) => {

    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState([]);
    const [, setGlobalData] = useState({active: false});


    useEffect(() => {

        const load = () => {
            axios.get('/product/' + id + '/eshops').then((res) => {
               //console.log(res.data);
                setData(res.data);

                setGlobalData(prevState => ({
                    ...prevState,
                    active: res.data.active
                }));
            });
        }

        if (!loaded) {
            load();
            setLoaded(true);
        }

    }, [loaded, id]);


    return (
        <Col >
            
          
            {data && loaded && data.map((e, index) => {
                
                    if(e.type === "shoptet")
                        return (<Shoptet originalItem={e} index={index} locales={locales} allItems={data} key={e.name}/>)


                    return (<ProductEshopItem originalItem={e} index={index} locales={locales} allItems={data} key={e.name}/>)
                }
                
           
           
               

            )}
        </Col>)
}

export default ProductEshop