import React, {useState, useEffect, useCallback} from 'react'
import {Button, ButtonGroup, Col, Form, Row, ToggleButton} from "react-bootstrap";
import Select from "react-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/free-solid-svg-icons";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import CatalogFilterCategoriesEso from "./CatalogFilterCategoriesEso";
import {useTranslation} from "react-i18next";
import axios from "axios";

const CatalogFilter = (props) => {
    
    const {t, i18n} = useTranslation()
    
   

    //data
    const radios = [
        {name: t('catalog.filterByRegion'), value: '0'},
        {name: 'CZ', value: 'cz'},
        {name: 'PL', value: 'pl'},
    ];
    const [filterLoaded, setFilterLoaded] = useState(false);
    const [lang, setLang] = useState('0');
    const [searchType, setSearchType] = useState('name')
    const [searchQuery, setSearchQuery] = useState('')
    const [filterValues, setFilterValues] = useState({
        states: [],
        regions: [],
        eshops: []
    });
    const [tagOptions, setTagOptions] = useState([])
    const [searchByTag, setSearchByTag] = useState(false)
    const [selectedTags, setSelectedTags] = useState([])
    const [searchByCat, setSearchByCat] = useState(false)
    const [filterManufacters, setFilterManufacters] = useState([]);
    const [filterSuppliers, setFilterSuppliers] = useState([]);
    const [stateFilter, setStateFilter] = useState([]);
    const [categories, setCategories] = useState({cat1:0, cat2:0, cat3:0, cat4:0})

    //useEffect
    useEffect(() => {

        const init = () => {
            axios.get('catalog/init').then(res => {
                //res.data.regions.unshift({label: t('global.all'), value: '-999'});
                setFilterValues(prevState => ({
                    ...prevState,
                    states: res.data.states,
                    regions: res.data.regions,
                    eshops: res.data.eshops
                }));


            });

            //get tags
            axios.get('/tag/' + i18n.language + '/get').then(res => {
                let objectForSelect = [];
                setTagOptions(objectForSelect);
                res.data.map((x, k) => {
                    return objectForSelect[k] = {
                        value: x.id,
                        label: x.name
                    }
                });

            });
        }

        if (!filterLoaded) {

            init();
            setFilterLoaded(true);
        }

    }, [filterLoaded, props.isLoading]);
    
    //handle

    const [filterSelectedValues, setFilterSelectedValues] = useState({
        region: '1',
        eshop: '0'
    });
    
    const handleFilter = (e, type) => {

        switch (type) {
            case 'eshop':
                filterSelectedValues.eshop = e.value;
                setFilterSelectedValues(prevState => ({
                    ...prevState,
                    eshop: e.value
                }));
                break;
            case 'region':
                filterSelectedValues.region = e.value;
                setFilterSelectedValues(prevState => ({
                    ...prevState,
                    region: e.value
                }));
                break;
            default:
                filterSelectedValues.state = e.value;
                setFilterSelectedValues(prevState => ({
                    ...prevState,
                    state: e.value
                }));
                break;
        }
    }

    const getManufacters = (inputValue) => {
        return axios.get("api/manufacters/filter?name=" + inputValue).then(res => {
            //return(res.data.items);
            return res.data.items.map(function (item) {
                return {
                    label: item.name,
                    value: item.id
                }
            });
        });
    }

    const getSuppliers = (inputValue) => {
        return axios.get("api/suppliers/filter?name=" + inputValue + "&regionId=" + filterSelectedValues.region).then(res => {
            //return(res.data.items);
            return res.data.items.map(function (item) {
                return {
                    label: item.regionErpName,
                    value: item.regionErpId
                }
            });
        });
    }
    
    const handleManufacturer = (e) => {
        setFilterManufacters(e === null ? [] : e);
    }

    const handleSupplier = (e) => {
        setFilterSuppliers(e === null ? [] : e);
    }
    

    const search = (async (disLang) => {
        
        
        if (props.isLoading) return;

        props.setIsLoading(true);

        let tags = "";
        if (selectedTags !== null && selectedTags.length > 0 && searchByTag) {
            let i;

            for (i = 0; i < selectedTags.length; i++) {
                if (i === selectedTags.length || i === 0) {
                    tags = tags + selectedTags[i].value;
                } else {
                    tags = tags + ',' + selectedTags[i].value;
                }

            }
        }

        let data = {}
        data.manufacters = filterManufacters
        data.suppliers = filterSuppliers
        data.tags = tags
        data.states = stateFilter
        data.searchType = searchType
        data.searchQuery = searchQuery
        data.region = filterSelectedValues.region
        data.eshop = filterSelectedValues.eshop
        data.lang = disLang !== undefined ? disLang : lang
        data.categories = categories
        data.searchByCat = searchByCat
        props.search(data)       
        
        
    });
    
    
    
    //render
    
    return (
        <React.Fragment>
            <Row className={"pt-2 pb-2 pr-3 pl-3"}>
                {/*<Link className="item link" to="product/add"><FontAwesomeIcon className="i" icon={faPlus}/> {t('catalog.addProduct')}</Link>*/}
                {/*{user.data.role === 'Admin' ?
                    <Button variant={"primary"} onClick={updateCatalog}> {t('catalog.updateFromEso')}</Button>
                    : null}*/}
                {/*<button className="item btn1" type="button" onClick={updateCatalog}>{t('catalog.updateFromEso')}</button>*/}
            </Row>

            <Row className={"p-3"}>
                <Form style={{width: '100%'}} className={"cBG_BS_light p-3 rounded"}>
                    <Form.Row>
                        <Form.Group as={Col} className={"col-7"}>
                            <Form.Row className={"p-2"}>

                                <ButtonGroup toggle>
                                    <ToggleButton
                                        key={1}
                                        type="radio"
                                        variant="secondary"
                                        name="filter"
                                        value={'name'}
                                        checked={searchType === 'name'}
                                        onChange={() => {
                                            setSearchType('name')
                                        }}

                                    >
                                        {t('catalog.name')}
                                    </ToggleButton>

                                    <ToggleButton
                                        key={2}
                                        type="radio"
                                        variant="secondary"
                                        name="filter"
                                        value={'code'}
                                        checked={searchType === 'code'}
                                        onChange={() => {
                                            setSearchType('code')
                                        }}

                                    >
                                        {t('catalog.code')}
                                    </ToggleButton>
                                </ButtonGroup>


                                {/*<Form.Check
                                        inline
                                        className={"input-M"}
                                        type={'radio'}
                                        label={t('catalog.name')}
                                        id="filtrName"
                                        name="filter"
                                        checked={searchType === 'name'}
                                        onChange={() => {
                                            setSearchType('name')
                                        }}
                                    />

                                    <Form.Check
                                        inline
                                        className={"input-M"}
                                        type={'radio'}
                                        label={t('catalog.code')}
                                        id="filtrCode"
                                        name="filter"
                                        checked={searchType === 'code'}
                                        onChange={() => {
                                            setSearchType('code')
                                        }}
                                    />*/}
                            </Form.Row>
                            <Form.Row className={"p-2"}>
                                <Form.Group as={Col}>
                                    <Form.Control
                                        placeholder={"Search value"}
                                        type="text"
                                        name={"search"}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value)
                                        }}
                                        value={searchQuery}
                                    />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row className={"p-2"}>
                                <Form.Group as={Col}>
                                    <Form.Check
                                        style={{zIndex: 0}}
                                        className={"pb-2"}
                                        type="switch"
                                        id="searchByTag"
                                        label={t('catalog.searchByTag')}
                                        value={searchByTag}
                                        name={"searchByTag"}
                                        onChange={() => {
                                            setSearchByTag(!searchByTag)
                                        }}
                                    />

                                    {searchByTag ? <Select options={tagOptions} isMulti
                                                           onChange={(value) => setSelectedTags(value)}/> : null}
                                </Form.Group>

                            </Form.Row>

                            <Form.Row className={"p-2"}>
                                <Form.Group as={Col}>
                                    <Form.Check
                                        style={{zIndex: 0}}
                                        className={"pb-0"}
                                        type="switch"
                                        id="searchByCat"
                                        label={t('catalog.searchByCategory')}
                                        value={searchByCat}
                                        name={"searchByCat"}
                                        onChange={() => {
                                            setSearchByCat(!searchByCat)
                                        }}
                                    />


                                </Form.Group>

                            </Form.Row>

                            {searchByCat ? <CatalogFilterCategoriesEso setCategories={(val) => setCategories(val)} /> : null}

                            <Form.Row className={"pb-2"}>

                                <Button
                                    variant={"primary"}
                                    className={"btn-lg"}
                                    onClick={() => {
                                        search();
                                    }}
                                >
                                    <FontAwesomeIcon className="i" icon={faFilter}/>
                                    {t('catalog.filter')}
                                </Button>
                            </Form.Row>

                        </Form.Group>
                        <Form.Group as={Col} className={"col-5 p-2"}>
                            <Form.Label>{t('catalog.region')}</Form.Label>
                            <Select options={filterValues.regions}
                                    value={filterValues.regions.filter(option => option.value === filterSelectedValues.region)}
                                    onChange={(e) => {
                                        handleFilter(e, 'region')

                                    }}
                            />

                            <Form.Label>{t('catalog.state')}</Form.Label>
                            <Select
                                isMulti
                                options={filterValues.states} onChange={(e) => {
                                    e == null ? setStateFilter([]) : setStateFilter(e)                                    
                                }}
                            />

                            <Form.Label>{t('catalog.eshop')}</Form.Label>
                            <Select
                                value={filterValues.eshops.filter(option => option.value === filterSelectedValues.eshop)}
                                options={filterValues.eshops} onChange={(e) => {
                                handleFilter(e, 'eshop')
                            }}/>

                            <Form.Label>{t('manufacters.manufacter')}</Form.Label>
                            <AsyncSelect
                                loadOptions={getManufacters}
                                isMulti
                                valueKey={"value"}
                                labelKey={"label"}
                                onChange={(e) => {
                                    handleManufacturer(e)
                                }}
                            />

                            <Form.Label>{t('suppliers.supplier')}</Form.Label>
                            <AsyncSelect
                                loadOptions={getSuppliers}
                                isMulti
                                valueKey={"value"}
                                labelKey={"label"}
                                onChange={(e) => {
                                    handleSupplier(e)
                                }}
                            />

                        </Form.Group>
                    </Form.Row>

                </Form>

            </Row>
            {props.dataLength > 0 ?
                <ButtonGroup className="mb-2">
                    {radios.map((radio, idx) => (
                        <ToggleButton
                            key={idx}
                            id={`radio-${idx}`}
                            type="radio"
                            variant="secondary"
                            name="radio"
                            value={radio.value}
                            checked={lang === radio.value}
                            onChange={(e) => {
                                setLang(e.currentTarget.value);
                                search(e.currentTarget.value);
                            }}
                        >
                            {radio.name}
                        </ToggleButton>
                    ))}
                </ButtonGroup> : null}
        </React.Fragment>
    )
}


export default CatalogFilter