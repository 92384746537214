import React, {useState} from 'react';
import {authenticationService} from '../helpers/AuthenticationService';
import {useTranslation} from "react-i18next";
import {useHistory} from 'react-router-dom';
import {ADD, useAlertContext} from "../contexts/AlertContext";
import {useForm} from "react-hook-form";
import InpurtError from "./InputError";
import './Login.scss';
import {faChevronLeft, faDownload, faSignInAlt, faLock} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "react-bootstrap";
import PasswordRecovery from "./user/PasswordRecoery";


export function Login() {


    const {register, handleSubmit, errors} = useForm({
        defaultValues: {
            username: '',
            password: ''
        }
    });

    const [isLoading, setIsLoading] = useState(false);
    const [showPassRec, setShowPassRec] = useState(false);

    const {t, i18n} = useTranslation();
    const {alertDispatch} = useAlertContext();

    let history = useHistory();

    const onSubmit = (async (data) => {

        if (isLoading) return;

        setIsLoading(true);

        authenticationService.login(data.username, data.password)
            .then(
                res => {
                    if(res.status === 200){
                        localStorage.removeItem('lang');
                        localStorage.setItem('lang', i18n.language);
                        history.push('/')
                    } else{
                        alertDispatch({
                            type: ADD,
                            payload: {
                                content: {state: 'warning', message: t("login.canNotLogIn")}
                            }
                        })
                        return false;
                    }

                },
                error => {
                    alertDispatch({
                        type: ADD,
                        payload: {
                            content: {state: 'warning', message: t("login.canNotLogIn")}
                        }
                    })
                    return false;
                }
            );

        setIsLoading(false);

    });


    return (

        <div className="loginCont" 
            
        >
            {showPassRec ?

                <div className="login loginForm">
                    <Button size={"sm"} variant={"outline-primary"} onClick={() => {setShowPassRec(!showPassRec)}}><FontAwesomeIcon className="i" icon={faChevronLeft}/>{t('global.back')}</Button>
                    <h1>{t('login.recovery')}</h1>
                    <PasswordRecovery back={() => {setShowPassRec(!showPassRec)}} />
                </div>
                
            
                :

                <form className="login loginForm" onSubmit={handleSubmit(onSubmit)}>
                    <div>
                    <img className={"logo"} src={"/img/batist-logo-1.png"} alt={"logo"} />
                    </div>
                    <img className={"iBlogo"} src={"/img/icons/blockchain2.png"} alt={"block"} />
                    <h1>iBatist</h1>
                    <div className={"item-row"}>
                        <label>{t("login.username")}</label>
                        <div className={"item-group"}>
                            <input type="text" name={"username"} ref={register({required: true})}/>
                            {errors.username && errors.username.type === 'required' &&
                            <InpurtError message={t("validation.required")}></InpurtError>}
                        </div>
                    </div>
                    <div className={"item-row"}>
                        <label>{t("login.password")}</label>
                        <div className={"item-group"}>
                            <input type="password" name={"password"} ref={register({required: true})}/>
                            {errors.password && errors.password.type === 'required' &&
                            <InpurtError message={t("validation.required")}></InpurtError>}
                        </div>
                    </div>
                    
                    <div className={"item-row"}>
                    <Button disabled={isLoading} type="submit" style={{marginTop: "20px"}} className="btn btn-lg btn-primary"><FontAwesomeIcon className="i" icon={faSignInAlt}/>{t("login.buttonLogin")}</Button>
                    </div>
                    <div className={"item-row mt-4"}>
                        <div className={'passRecovery'}><FontAwesomeIcon className="i" icon={faLock}/><a  onClick={() => {setShowPassRec(!showPassRec)}}>{t('login.recovery')}</a></div>
                    </div>
                </form>
               
            }
        
           
        </div>
            
    );
}

export default Login;