import React, {useState, useEffect, useCallback} from 'react';
import Modal from "../shared/modal/Modal";
import DataTable, {createTheme} from "react-data-table-component";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEdit} from '@fortawesome/free-regular-svg-icons'
import { faImage, faFile} from "@fortawesome/free-solid-svg-icons";
import {Button, ButtonGroup, Container, ToggleButton} from "react-bootstrap";
import './Catalog.scss';
import CatalogFilter from "./CatalogFilter";
import CatalogImagePreview from "./CatalogImagePreview";
import PhotoPrivate from "../product/PhotoPrivate/PhotoPrivate";
import theme1 from "../../config/dataTable/theme1";
import theme1Styles from "../../config/dataTable/theme1Styles";
import {ADD, useAlertContext} from "../../contexts/AlertContext";
import ProdcutEdit from "../product/Edit";
import Documents from "../product/Documents/Documents";

const Catalog = () => {

    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [modalDocumentsOpen, setModalDocumentsOpen] = useState(false);
    const [modalPrivatePhotoOpen, setModalPrivatePhotoOpen] = useState(false);
    const [id, setId] = useState();
    const [nameEdit, setNameEdit] = useState("");
    const [selectedRefId, setSelectedRefId] = useState([]);
    const {t} = useTranslation();
    const {alertDispatch} = useAlertContext();
    const [loaded, setLoaded] = useState(true); //defaultne nastaveno na true, aby se po nacteni stranky nespoustelo nacitani
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    


    

    const [pagination] = useState({
        rowsPerPageText: t('global.itemsOnPage') + ':',
        rangeSeparatorText: t('global.from'),
        noRowsPerPage: false
    });

    
    const search = (filter) =>{
        

        let url = new URL(window.document.location)
        let params = new URLSearchParams(url.search.slice(1))
        params.append(filter.searchType, filter.searchQuery)
        params.append('eshop', filter.eshop)
        //params.append('state', filter.state)
        params.append('region', filter.region)
        if(filter.tags) params.append('tags', filter.tags )
        params.append('esoCat1', filter.categories.cat1 && filter.searchByCat ? filter.categories.cat1 : "");
        params.append('esoCat2', filter.categories.cat2 && filter.searchByCat ? filter.categories.cat2 : "");
        params.append('esoCat3', filter.categories.cat3 && filter.searchByCat ? filter.categories.cat3 : "");
        params.append('esoCat14', filter.categories.cat4 && filter.searchByCat ? filter.categories.cat4 : "");
        

        if(filter.manufacters.length > 0)
        {
            for (let i = 0; i < filter.manufacters.length; i++) {
                params.append('Manufacters',filter.manufacters[i].value)
            }
            
        }

        if(filter.suppliers.length > 0)
        {
            for (let i = 0; i < filter.suppliers.length; i++) {
                params.append('Suppliers',filter.suppliers[i].value)
            }

        }

        if(filter.states.length > 0)
        {
            for (let i = 0; i < filter.states.length; i++) {
                params.append('States',filter.states[i].value)
            }

        }

        if (filter.lang !== 0) {
            params.append('displayLang', filter.lang);
        }
        

        axios.get('/product/search?' + params  ).then(res => {
            setData(res.data);
            setIsLoading(false);
            setLoaded(true);
        });
    }

    /*const search = (async (disLang) => {

        if (isLoading) return;

        setIsLoading(true);

        let tags = "";
        if (selectedTags !== null && selectedTags.length > 0 && searchByTag) {
            let i;

            for (i = 0; i < selectedTags.length; i++) {
                if (i === selectedTags.length || i === 0) {
                    tags = tags + selectedTags[i].value;
                } else {
                    tags = tags + ',' + selectedTags[i].value;
                }

            }
        }
        
        let manufacters = '';
        
        if(filterManufacters && filterManufacters.length > 0)
        {
            manufacters = Object.keys(filterManufacters).map(function(key) {
                return 'Manufacters=' + filterManufacters[key].value;
            }).join('&');
        }
        

        let displayLang = disLang !== undefined ? disLang : lang

        if (filterSelectedValues.region === "-999") {

            if (displayLang === "0" && data.length === 0) {
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {state: 'warning', message: t('catalog.regionAllError')}
                    }
                })

                setIsLoading(false);
                return;
            }

            if (displayLang === "0") {
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {state: 'warning', message: t('catalog.selectLang')}
                    }
                })

                setIsLoading(false);
                return;
            }


        }


        let url = new URL(window.document.location);
        let params = new URLSearchParams(url.search.slice(1));
        params.append(searchType, searchQuery);
        params.append('eshop', filterSelectedValues.eshop);
        params.append('state', filterSelectedValues.state);
        params.append('region', filterSelectedValues.region);
        params.append('tags', tags);
        params.append('esoCat1', cat1Selected && searchByCat ? cat1Selected.value : "");
        params.append('esoCat2', cat2Selected && searchByCat ? cat2Selected.value : "");
        params.append('esoCat3', cat3Selected && searchByCat ? cat3Selected.value : "");
        params.append('esoCat14', cat4Selected && searchByCat ? cat4Selected.value : "");

        if (lang !== 0) {
            params.append('displayLang', displayLang);
        }

        axios.get('/product/search?' + params + "&" + manufacters).then(res => {
            setData(res.data);
            setIsLoading(false);
            setLoaded(true);
        });
    });*/



    const updateCatalog = () => {
        axios.post('/catalog/update').then(res => {
            alertDispatch({
                type: ADD,
                payload: {
                    content: {state: 'success', message: t('catalog.catalogUpdated')}
                }
            })
        });
    }


    const columns = (  [
        {
            width: "80px",
            cell: (row)=>{
                
                return ( <CatalogImagePreview code={row.code} />  )
            }
        },
        {
            cell: (row) => {
                return (
                    <React.Fragment>
                        <Button title={"documents"} size={"sm"} variant={"secondary"} className={"mr-1"}
                                onClick={() => {
                                    setSelectedRefId(row.code);
                                    setModalDocumentsOpen(true);
                                }} id={row.id}><FontAwesomeIcon icon={faFile}/>
                        </Button>

                        <Button title={"photos"} size={"sm"} variant={"secondary"} className={"mr-1"} onClick={() => {
                            setSelectedRefId(row.code);
                            setModalPrivatePhotoOpen(true);
                        }} id={row.id}><FontAwesomeIcon icon={faImage}/>
                        </Button>

                        {/* edit */}
                        <Button title={"edit"} size={"sm"} variant={"primary"} className={"mr-1"} onClick={() => {
                            setId(row.id);
                            setSelectedRefId(row.code);
                            setNameEdit(row.code + " - " + row.name);
                            setModalEditOpen(true);
                        }} id={row.id} code={row.code}><FontAwesomeIcon icon={faEdit}/>
                        </Button>
                    </React.Fragment>
                )
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: t('catalog.table.code'),
            selector: 'code',
            sortable: true
        },
        {
            name: t('suppliers.supplier'),
            selector: 'supplier',
            sortable: true
        },
        {
            name: t('catalog.table.nameErp'),
            selector: 'nameEso',
            sortable: true,
        },
        {
            name: t('catalog.table.name') + " iBatist",
            selector: 'name',
            sortable: true,
        }

        

    ]);

 

    //data table
    createTheme('theme1', theme1);

    const Categories = () => {
       
    }


    return (
        <div>

            <Container fluid>

                <CatalogFilter dataLength={data.length} search={(val) => {search(val)}}   setIsLoading={ (val) => setIsLoading(val)} isLoading={isLoading} />

                <DataTable className="dataTable"

                           data={data}
                           columns={columns}
                           paginationComponentOptions={pagination}
                           pagination
                           dense
                           noHeader
                           paginationPerPage={50}
                           paginationRowsPerPageOptions={[10, 30, 50, 100]}
                           theme="theme1"
                           customStyles={theme1Styles}
                />


                {/* MODALS  */}

                <Modal isOpen={modalEditOpen} onClose={() => {
                    setModalEditOpen(false)
                }}>
                    <ProdcutEdit id={id} refId={selectedRefId} name={nameEdit} onClose={() => {
                        setModalEditOpen(false)
                    }}/>
                </Modal>

                <Modal isOpen={modalDocumentsOpen} onClose={() => {
                    setModalDocumentsOpen(false)
                }}>
                    
                    <Documents refId={selectedRefId} onClose={() => {
                        setModalDocumentsOpen(false)
                    }}/>
                </Modal>

                <Modal isOpen={modalPrivatePhotoOpen} onClose={() => {
                    setModalPrivatePhotoOpen(false)
                }}>
                    <PhotoPrivate refId={selectedRefId} onClose={() => {
                        setModalPrivatePhotoOpen(false)
                    }}/>
                </Modal>

            </Container>
        </div>

    );
}
export default Catalog;