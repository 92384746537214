import React, {useState, useEffect} from 'react';
import axios from "axios";
import InpurtError from "../InputError";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import './AddEditTag.scss';
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LabelInfo from "../LabelInfo";
import {ADD, useAlertContext} from "../../contexts/AlertContext";

const AddEditTag = (props) => {

    const {t} = useTranslation();
    const [langs, setLangs] = useState([]);

    const {alertDispatch} = useAlertContext();

    const [data] = useState({
        note: "",
        langs: {}
    })


    const {register, handleSubmit, errors, setValue} = useForm();

    const getLangs = () => {
        axios.get('/lang').then(res => {
            setLangs(res.data);
        });

    }



    const deleteTag = () => {

        axios.delete('/tag/deleteTag/' + props.id).then(res => {
                if (res.data.result > 0) {
                    alertDispatch({
                        type: ADD,
                        payload: {
                            content: {state: 'success', message: res.data.message}
                        }
                    });

                    props.onClose();
                } else if (res.data.result < 0) {
                    alertDispatch({
                        type: ADD,
                        payload: {
                            content: {state: 'warning', message: res.data.message}
                        }
                    });

                } else if (res.data.result === 0) {
                    console.log(res.data.error);
                    alertDispatch({
                        type: ADD,
                        payload: {
                            content: {state: 'warning', message: res.data.message}
                        }
                    });

                }
            }
        ).catch(error => {
            alert('There has benn an error on server.');
            console.log(error);

        });
    }

    const onSubmit = (fdata) => {
        data.note = fdata.note;
        data.langs = fdata;
        data.id = props.id;
        delete data.langs.note;


        if (props.edit) {
            axios.post('/tag/editTag', data).then(res => {
                if (res.data.result > 0) {
                    alertDispatch({
                        type: ADD,
                        payload: {
                            content: {state: 'success', message: res.data.message}
                        }
                    });
                    props.onClose();
                } else {
                    console.log(res.data.error);
                    alertDispatch({
                        type: ADD,
                        payload: {
                            content: {state: 'warning', message: "Došlo k chybě v DB."}
                        }
                    });
                }
            }).catch(error => {
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {state: 'warning', message: "Došlo k chybě na serveru."}
                    }
                });
                console.log(error);

            });

        } else {
            axios.post('/tag/addTag', data).then(res => {
                if (res.data.result > 0) {
                    alertDispatch({
                        type: ADD,
                        payload: {
                            content: {state: 'success', message: res.data.message}
                        }
                    });
                    props.onClose();
                } else {
                    console.log(res.data.error);
                    alertDispatch({
                        type: ADD,
                        payload: {
                            content: {state: 'warning', message: "Došlo k chybě v DB."}
                        }
                    });
                }
            }).catch(error => {
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {state: 'warning', message: "Došlo k chybě na serveru."}
                    }
                });
                console.log(error);

            });
        }

    }

    useEffect(() => {

        const getEditData = () => {
            axios.get('/tag/detail/' + props.id).then(res => {
                for (let key in res.data.langs) {
                    setValue(key, res.data.langs[key])

                }
                setValue('note', res.data.note);


            }).catch(error => {
                alert('Došlo k chybě na serveru.');
                console.log(error);

            });
        }

        getLangs();
        if (props.edit) {
            getEditData();
        }
    }, [props.edit, props.id, setValue]);

    return (
        <div className="detailTag">
            <h1>{props.edit ? t('tags.edit') : t('tags.new')}</h1>
            <h2>{t('global.mutations')}</h2>
            <div className="langsTag">
                {langs.map((x, k) => {
                    return (
                        <div className="item" key={k}>
                            <span className={"key"}>[{x.abbrev}]</span>
                            <input maxLength={200} name={x.abbrev} ref={register({required: true})}/>

                            {errors[x.abbrev] && errors[x.abbrev].type === 'required' &&
                            <InpurtError message={t("validation.required")}></InpurtError>}

                        </div>
                    )
                })
                }
            </div>
            <div>

                <LabelInfo label={t('tags.note')} iconText={t('tags.noteDesc')}></LabelInfo>
                <textarea name={'note'} maxLength={1000} ref={register()}></textarea>
            </div>
            <div className={"ctrl"}>

                <button onClick={handleSubmit(onSubmit)} className={"btn-save"}><FontAwesomeIcon className="i"
                                                                                                 icon={faSave}/>{t('global.save')}
                </button>
                {props.edit ? <button type={"button"} onClick={deleteTag}
                                      className={"btn-delete"}>{t('global.delete')}</button> : null}
            </div>
        </div>
    );
}

export default AddEditTag;