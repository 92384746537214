import React, {useEffect, useState} from "react";
import './ProductRegion.scss';
import ProductRegionItem from "./ProductRegionItem";
import axios from "axios";
import {Button, Form} from "react-bootstrap";
import {faHashtag, faBoxOpen, faBox, faSync, faBoxes, faTag, faCity, faBan, faTruckLoading} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LabelInfo from "../LabelInfo";
import NumberFormat from 'react-number-format';
import {useTranslation} from "react-i18next";
import GetProductDataFromEso from "../eso/GetProductDataFromEso";

const ProductRegion = (props) => {

    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState([]);
    const [, setGlobalData] = useState({active: false});
    const {t} = useTranslation();
    const showOnlyRegions = ["cz", "pt", "pl"];



    useEffect(() => {
        

        const load = () => {
            axios.get('/product/' + props.id + '/regions').then((res) => {
                setData(res.data);

                setGlobalData(prevState => ({
                    ...prevState,
                    active: res.data.active
                }));
            });
        }

        if (!loaded) {
            load();
            setLoaded(true);
        }

    }, [props.id]);

    return (
        <div className="product-region" >
            
            
                {loaded && data.length > 0 ?
                    data.map((x,i) => {
                        return(
                        showOnlyRegions.includes(x.abbrev) ?
                        
                            <div key={i} className='box' >
                                <div className="header">{x.name} [{x.abbrev}] [{x.currency}]</div>
                           
                                <Form  >
                                    
                                    <Form.Row>
                                        <div className={"p-1"}>
                                            <LabelInfo label="Purchase price"
                                                       iconText="Purchase price"/>
                                            <input type="text"  value={x.erpData.purchasePrice} readOnly="readOnly"/>
                                        </div>
                                        <div className={"p-1"}>
                                            <LabelInfo label="Purchase price vat"
                                                       iconText="Purchase price vat"/>
                                            <input type="text"  value={x.erpData.purchaseVat} readOnly="readOnly"/>
                                        </div>
                                        <div className={"p-1"}>
                                            <LabelInfo label="Purchase price with vat"
                                                       iconText="Purchase price with vat"/>
                                            <input type="text"  value={x.erpData.purchasePriceWithVat} readOnly="readOnly"/>
                                        </div>
                                    </Form.Row>
                                    <Form.Row>
                                        <div className={"p-1"}>
                                            <LabelInfo label="Sales price"
                                                       iconText="Sales price"/>
                                            <input type="text"  value={x.erpData.salesPrice} readOnly="readOnly"/>
                                        </div>
                                        <div className={"p-1"}>
                                            <LabelInfo label="Sales price vat"
                                                       iconText="Sales price vat"/>
                                            <input type="text"  value={x.erpData.salesVat} readOnly="readOnly"/>
                                        </div>
                                        <div className={"p-1"}>
                                            <LabelInfo label="Sales price with vat"
                                                       iconText="Sales price with vat"/>
                                            <input type="text"  value={x.erpData.salesPriceWithVat} readOnly="readOnly"/>
                                        </div>
                                    </Form.Row>
                                <Form.Row>
                                    <div className={"p-1"}>
                                        <LabelInfo label="Sell unit"
                                                   iconText="Sell unit of measure"/>
                                        <input type="text"  value={x.unit ? x.unit.code : "" } readOnly="readOnly"/>
                                    </div>
                                    <div className={"p-1"}>
                                        <LabelInfo label="Sell unit translate"
                                                   iconText="Sell unit translate"/>
                                        <input type="text"  value={x.unitMaster ? x.unitMaster.name : "" } readOnly="readOnly"/>
                                    </div>
                                    <div className={"p-1"}>
                                        <LabelInfo label="Pieces in sell unit"
                                                   iconText="Pieces in sell unit"/>
                                        <input type="text"  value={x.erpData.piecesInSellUnit} readOnly="readOnly"/>
                                    </div>
                                    
                                    {x.abbrev === "pt" ?
                                        
                                            <React.Fragment>
                                                <div className={"p-1"}>
                                                    <LabelInfo label="Minimal sell unit qty" iconText="Minimal sell unit quantity"/>
                                                    <input type="text"  value={parseFloat(x.erpData.minimalSellUnitQuantity)/parseFloat(x.erpData.piecesInSellUnit)} readOnly="readOnly"/>
                                                </div>
                                                <div className={"p-1"}>
                                                    <LabelInfo label="Minimal sell unit qty (in pieces)"
                                                iconText="Minimal sell unit quantity"/>
                                                    <input type="text"  value={x.erpData.minimalSellUnitQuantity} readOnly="readOnly"/>
                                                </div>
                                            </React.Fragment>
                                        

                                        :
                                        <React.Fragment>
                                            <div className={"p-1"}>
                                                <LabelInfo label="Minimal sell unit qty"
                                                           iconText="Minimal sell unit quantity"/>
                                                <input type="text"  value={x.erpData.minimalSellUnitQuantity} readOnly="readOnly"/>
                                            </div>
                                            <div className={"p-1"}>
                                                <LabelInfo label="Minimal sell unit qty (in pieces)"
                                                           iconText="Minimal sell unit quantity"/>
                                                <input type="text"  value={parseFloat(x.erpData.minimalSellUnitQuantity)*parseFloat(x.erpData.piecesInSellUnit)} readOnly="readOnly"/>
                                            </div>
                                            
                                        </React.Fragment>
                                        
                                    }
                                </Form.Row>
                                {/*<Form.Row>
                                    <div className={"p-1"}>
                                        <LabelInfo label="Supplier code"
                                                   iconText={t('productEdit.codeDesc')}/>
                                        <input type="text"  value={x.supplier ? x.supplier.regionErpCode : "" } readOnly="readOnly"/>
                                    </div>
                                    <div className={"p-1"}>
                                        <LabelInfo label="Supplier name"
                                                   iconText={t('fff')}/>
                                        <input type="text" style={{width: 450}}  value={x.supplier ? x.supplier.regionErpName : "" } readOnly="readOnly"/>
                                    </div>
                                    
                                </Form.Row>*/}
                                </Form>





                            </div>
                         : null)
                    })
                    
                : null}
            <div style={{marginTop: 30}}>
                {/*<GetProductDataFromEso refId={props.refId}/>*/}
            </div>
           
        
        </div>
    )
}

export default ProductRegion;