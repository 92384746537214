import React, {useState} from "react";
import {Button, Form} from "react-bootstrap";
import axios from "axios";
import {ADD, useAlertContext} from "../../../contexts/AlertContext";
import Moment from "react-moment";
import {useUserContext} from "../../../contexts/UserContext";

export const ForecastStocksItemComments = (props) => {
    
    const [comment, setComment] = useState("");
    const {alertDispatch} = useAlertContext();
    const [makeComment, setMakeComment] = useState(false);
    const user = useUserContext();
    
    
    
    const onMakeCommentHandler = (e) => {
        
        setMakeComment(true);
    }
    
    const onDeleteComment = (id) => {
        if(!window.confirm('Opravdu smazat komentář?'))
            return false;
        
        axios.delete(`/product/${id}/comment`).then(res => {
            if (res.data.isOk === true) {

                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {state: 'success', message: 'Komentář byl smazán.'}
                    }
                });
                props.onSaveComment();
            } else {

                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {state: 'warning', message: 'Došlo k chybě.'}
                    }
                });
            }
        }).catch(err => {
            alertDispatch({
                type: ADD,
                payload: {
                    content: {state: 'warning', message: "Došlo k chybě."}
                }
            });
        });
            
        
        
        
    }
    
   
    
    
    
    const onSaveComment = () => {
        
        let data = {
            comment: comment,
            productCode: props.code,
            where: 1
            
            
        }
        
        axios.post(`/product/${props.code}/comment`, data).then(res => {
            if (res.data.isOk === true) {

                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {state: 'success', message: 'Komentář byl přidán.'}
                    }
                });
                setComment("");
                setMakeComment(false);
                props.onSaveComment();
            } else {

                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {state: 'warning', message: 'Došlo k chybě.'}
                    }
                });
            }
        }).catch(err => {
            alertDispatch({
                type: ADD,
                payload: {
                    content: {state: 'warning', message: "Během ukládání došlo k chybě"}
                }
            });
        });
    }
    
    return(
        <div className="commentsSection">
            <h4>Komentáře na položce</h4>
            <div>
                {
                    makeComment ?
                        <Form>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Přidat komentář</Form.Label>
                                <Form.Control as="textarea" rows={1} onChange={(e) => setComment(e.target.value)} />
                            </Form.Group>
                            <Button size="sm" onClick={() => {onSaveComment()}}>Přidat</Button>
                        </Form>
                        :
                        <Button size="sm" variant="secondary" onClick={(e) => {onMakeCommentHandler(e)}}>Komentovat</Button>
                }
               
                <div className="commCont" >
                    {props.comments.length > 0 ?
                        props.comments.map((x,i) => {
                            return(
                                <div key={i} className="item border rounded">
                                    <div className="head">
                                        <div>
                                            <Moment format="DD.MM.YYYY HH:mm" date={x.createdAt} /> 
                                        </div>
                                        <div>
                                            |
                                        </div>
                                        <div>
                                            {x.userName}
                                        </div>
                                        {x.createdBy === user.data.id ?
                                            <React.Fragment>
                                                <div>
                                                    |
                                                </div>
                                                <div>
                                                    <span onClick={() => onDeleteComment(x.id)} style={{color: 'red', textDecoration: 'underline', opacity: 0.6, cursor: 'pointer'}}>smazat</span>
                                                </div>
                                            </React.Fragment>
                                        : null}
                                       
                                    </div>
                                    <div className="body">
                                        {x.comment}
                                    </div>
                                    
                                </div>
                            )
                        })
                        : <div>Položka nemá žádný komentář</div>}
                </div>
            </div>
            <div>
                
            </div>
            
        </div>
    )
    
}