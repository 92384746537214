import React, {useState, useEffect, useCallback} from 'react';
import {Button, ButtonGroup, Col, Container, Form, Row, ToggleButton,} from "react-bootstrap";
import Select from "react-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {
    faPlusCircle,
    faFilter,
    faEye,
    faShareSquare,
    faCheck,
    faWindowClose
} from "@fortawesome/free-solid-svg-icons";
import * as documentTypes from './DocumentsTypes';
import DataTable, {createTheme} from "react-data-table-component";
import theme1Styles from "../../config/dataTable/theme1Styles";
import theme1 from "../../config/dataTable/theme1";
import {faEdit} from "@fortawesome/free-regular-svg-icons";
import Modal from "../shared/modal/Modal";
import AddDocument from "./AddDocument";
import {useUserContext} from "../../contexts/UserContext";
import {IsReadOnly} from "../../helpers/RoleProvider";
import Axios from "axios";
import ShareModal from "../shared/sharepoint/ShareModal";
import axios from "axios";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import * as langsSharepoint from "../shared/langs/LangsSharepoint";
import './Documents.scss';

const Documents = () => {
    //MARK: Consts
    const [types, setTypes] = useState([]);
    const [filter, setFilter] = useState({name: "", selectedType: "", refId: "", activeOnly: true});
    const {t} = useTranslation();
    const [isModalNewOpen, setModalNewOpen] = useState(false);
    const [isModalEditOpen, setIsModalEditOpen] = useState(false);
    const [isModalShareOpen, setIsModalShareOpen] = useState(false);
    const user = useUserContext();
    const [data, setData] = useState([]);
    const [perPage] = useState(50);
    const [currentPage, setCurrentPage] = useState(1);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [editId, setEditId] = useState(0);
    const [searchType, setSearchType] = useState('title');
    const [refData, setRefData] = useState([]);
    const [langData, setLangData] = useState([]);
    const [langs, setLangs] = useState([]);

    //MARK: actions


    //MARK: effect

    useEffect(() => {
        let data = documentTypes.returnDataForSelectByLang('cz');
        data.unshift({label: "vše", value: ""});
        setTypes(data);

        let langs = langsSharepoint.returnDataForSelectByLang('cz');
        setLangs(langs);

    }, []);

    //MARK: actions


    const handleChangeFilter = (e, name, value) => {
        setFilter(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handlePageChange = page => {
        handleFilter(page);
    };

    const handleFilter = useCallback((page) => {
                
        setCurrentPage(page);

        if (page === 1) {
            setResetPaginationToggle(!resetPaginationToggle);
        }
        
        let la = '';
        let re = '';
        
        for(var i=0; i < langData.length; i++)
        {
            la += '&langs=' + langData[i];
        }

        for(var i=0; i < refData.length; i++)
        {
            la += '&refs=' + refData[i].value;
        }
        
        
        
        
        let params = searchType + '=' + filter.name + '&refId=' + filter.refId + '&typeOfDocument=' + filter.selectedType + '&page=' + page + '&perPage=' + perPage + '&active=' + filter.activeOnly + la;
        //console.log(params);

        Axios.get('sharepointdocumentsPrivate/filter?' + params).then(res => {
            //console.log(res.data.items);
            setData(res.data.items);
            setTotalRows(res.data.totalItems);
        });
    }, [resetPaginationToggle, currentPage, filter, searchType, refData, langData]);


    const handleShareModalClick = (id) => {
        setEditId(id);
        setIsModalShareOpen(true);
    }

    const handleDownload = (id) => {

        var url = "/SharepointDocumentsPrivate/" + id + "/share";


        axios.get(url).then((res) => {
            window.open(res.data.shareLink, '_blank');

        });
    }

    //langs
    const handleOptionsLangsClick = (event) => {
        let arr = [...langData];
        if (langData && langData.length > 0) {
            for (let i = 0; i < langData.length; i++) {
                if (langData[i] === event.value) {
                    console.log('This lang already exists in array');
                    return false;
                }
            }
            arr = [...langData];
            
        }

        arr.push(event.value);
        setLangData(arr);
    }

    const handleLangsItemsClikc = (key) => {
        let arr = [...langData];
        arr.splice(key, 1);
        setLangData(arr);
    }


    //reference na produkt
    const getOptionsForRef = (inputValue) => {
        let params = new URLSearchParams();
        params.append('States', '-2');
        params.append('States', '-1');
        params.append('States', '0');
        params.append('States', '3');
        params.append('Code', inputValue);
        params.append('Take', '200');
        params.append('OrderByProductCode', 'true');
        params.append('DisplayLang', '0');
        params.append('Region', '1');
        
        
        return axios.get("/sharepoint/productsRef?" + params).then(res => {
            return (res.data);
        });
    }

    const handleOptionsRefIdClick = (event) => {
        let arr = [];
        if (refData && refData.length > 0) {
            for (let i = 0; i < refData.length; i++) {
                if (refData[i].value === event.value) {
                    console.log('This code already exists');
                    return false;
                }
            }
            arr = [...refData];
        }

        arr.push({value: event.value, label: event.label});
        setRefData(arr);
    }

    const handleRefIdItemsClikc = (key) => {
        let arr = [...refData];
        arr.splice(key, 1);
        setRefData(arr);
    }


    //data table
    createTheme('theme1', theme1);

    const [pagination] = useState({
        rowsPerPageText: t('global.itemsOnPage') + ':',
        rangeSeparatorText: t('global.from'),
        noRowsPerPage: false
    });

    const columns = (() => [
        {
            cell: (row) => {
                return (
                    <React.Fragment>
                        {/* edit */}

                        <Button
                            title={"edit"}
                            size={"sm"}
                            variant={"primary"}
                            className={"mr-1"}
                            onClick={() => {
                                setEditId(row.id);
                                setIsModalEditOpen(true);
                            }}
                            id={row.id}><FontAwesomeIcon icon={faEdit}/>
                        </Button>
                        <Button
                            title={"show"}
                            size={"sm"}
                            variant={"secondary"}
                            className={"mr-1"}
                            onClick={() => {
                                handleDownload(row.id);
                            }}
                        ><FontAwesomeIcon icon={faEye}/>
                        </Button>

                        <Button
                            title={"share"}
                            size={"sm"}
                            variant={"warning"}
                            className={"mr-1"}
                            onClick={() => {
                                handleShareModalClick(row.id)
                            }}
                        ><FontAwesomeIcon icon={faShareSquare}/>
                        </Button>
                    </React.Fragment>
                )
            }
            ,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '140px'
        },
        {
            name: 'idSharepoint',
            selector: 'id',
            sortable: false,
            width: '200px'
        },
        {
            name: t('catalog.table.name'),
            selector: 'title',
            sortable: true,
        },
        {
            name: t('catalog.fileName'),
            cell: (row) => {
                return (
                    row.displayName + '.' + row.fileExtension
                )
            }
        },
        {
            name: t('documents.typeOfDocument'),
            selector: 'typeOfDocument',
            sortable: false,
            width: '110px'
        },
        {
            name: t('documents.active'),
            cell: (row) => {
                return (
                    row.active ? <FontAwesomeIcon icon={faCheck}/> : ''
                )
            },
            width: '65px'
        }

        

    ]);


    return (


        <Container fluid>

            <Row>
                <h1>{t('documents.documents')}</h1>
            </Row>

            {!IsReadOnly(user) ?
                <Row className={"pt-2 pb-2 pr-3 pl-3"}>
                    <Button variant={"primary"} onClick={() => setModalNewOpen(true)}>
                        <FontAwesomeIcon icon={faPlusCircle} className={"i"}/>
                        {t('documents.addDocument')}
                    </Button>
                </Row> : null}

            <Row className={"p-3"}>
                <Form style={{width: '100%'}} className={"cBG_BS_light p-3 rounded  documentsShrp"}>
                    <Form.Row>
                        <Form.Group as={Col} className={"col-6"}>

                            <Form.Row className={"p-2"}>
                                <ButtonGroup toggle>
                                    <ToggleButton
                                        key={1}
                                        type="radio"
                                        variant="secondary"
                                        name="filter"
                                        value={'title'}
                                        checked={searchType === 'title'}
                                        onChange={() => {
                                            setSearchType('title')
                                        }}

                                    >
                                        {t('catalog.name')}
                                    </ToggleButton>

                                    <ToggleButton
                                        key={2}
                                        type="radio"
                                        variant="secondary"
                                        name="filter"
                                        value={'fileName'}
                                        checked={searchType === 'fileName'}
                                        onChange={() => {
                                            setSearchType('fileName')
                                        }}

                                    >
                                        {t('catalog.fileName')}
                                    </ToggleButton>
                                </ButtonGroup>

                            </Form.Row>
                            <Form.Row className={"ps-3"}>
                                <Form.Group as={Col}>
                                    <Form.Control
                                        placeholder={"Search value"}
                                        type="text"
                                        name={"name"}
                                        value={filter.name}

                                        onChange={(e) => {
                                            handleChangeFilter(e, 'name', e.target.value)
                                        }}

                                    />
                                </Form.Group>

                            </Form.Row>
                            

                            <Form.Row className={"ps-3"}>
                                {/*<Form.Group as={Col}>
                                    <Form.Label>Vazba na REF produktu</Form.Label>

                                    <Form.Control
                                        placeholder={"Search value"}
                                        type="text"
                                        name={"refId"}
                                        value={filter.refId}

                                        onChange={(e) => {
                                            handleChangeFilter(e, 'refId', e.target.value)
                                        }}

                                    />
                                </Form.Group>*/}
                                <Form.Group as={Col} >
                                    <Form.Label>{t('documents.refs')}</Form.Label>
                                    <AsyncSelect
                                        loadOptions={(e) => getOptionsForRef(e)}
                                        valueKey={"value"}
                                        labelKey={"label"}
                                        onChange={(e) => {
                                            handleOptionsRefIdClick(e)
                                        }}

                                    />
                                    {refData && refData.length > 0 ?

                                            <div className={'cont'}>
                                                {
                                                    refData.map((x, i) => {
                                                        return (
                                                            <div className={'item'} key={i}>
                                                    <span onClick={() => {
                                                        handleRefIdItemsClikc(i)
                                                    }}><FontAwesomeIcon className="i" icon={faWindowClose}/> </span>
                                                                <span>{x.label}</span>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        : null
                                    }

                                </Form.Group>
                                

                            </Form.Row>
                            <Form.Row className={"ps-3"}>
                                <Form.Group as={Col}>
                                    <Form.Check
                                        placeholder={"Search value"}
                                        type="checkbox"
                                        name={"activeOnly"}
                                        checked={filter.activeOnly}
                                        label={t('documents.showOnlyActive')}
                                        onChange={(e) => {
                                            setFilter(prevState => ({
                                                ...prevState,
                                                'activeOnly': !filter.activeOnly
                                            }))
                                        }}

                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className={"p-2"}>

                                <Button
                                    variant={"primary"}
                                    className={"btn-lg"}
                                    onClick={a => handleFilter(1)}

                                >
                                    <FontAwesomeIcon className="i" icon={faFilter}/>
                                    {t('catalog.filter')}
                                </Button>
                            </Form.Row>

                        </Form.Group>
                        <Form.Group as={Col} className={"col-6"}>
                            <Form.Row className={"ps-3 pe-3 pt-4"}>
                                <Form.Group as={Col}>
                                    <Form.Label>{t('documents.typeOfDocument')}</Form.Label>
                                    <Select options={types}
                                            value={types.filter(option => option.value === filter.selectedType)}
                                            onChange={(e) => {
                                                handleChangeFilter(e, 'selectedType', e.value)
                                            }}/>
                                </Form.Group>
                                
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} >
                                    <Form.Label>{t('documents.langsInDocument')}</Form.Label>
                                    <Select
                                        options={langs}
                                        onChange={(e) => {
                                            handleOptionsLangsClick(e)
                                        }}
                                    />
                                    {langData.length > 0 ?

                                        <div className={'cont'}>
                                            {
                                                langData.map((x, i) => {
                                                    return (
                                                        <div className={'item'} key={i}>
                                                    <span onClick={() => {
                                                        handleLangsItemsClikc(i)
                                                    }}><FontAwesomeIcon className="i" icon={faWindowClose}/> </span>
                                                            <span>{x} - {langs.filter(f => f.value === x)[0].label}</span>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                        : null
                                    }
                                </Form.Group>
                            </Form.Row>
                            
                        </Form.Group>
                        

                    </Form.Row>
                    

                </Form>

            </Row>

            <DataTable className="dataTable"

                       data={data}
                       columns={columns()}
                       pagination
                       paginationServer
                       paginationTotalRows={totalRows}
                       dense
                       noHeader
                       paginationPerPage={perPage}
                       paginationRowsPerPageOptions={[50]}
                       theme="theme1"
                       customStyles={theme1Styles}
                       onChangePage={handlePageChange}
                       paginationResetDefaultPage={resetPaginationToggle}
            />

            <Modal isOpen={isModalNewOpen} onClose={() => {
                setModalNewOpen(false)
            }}>
                <AddDocument onCloseRefresh={() => {
                    handleFilter(currentPage)
                }} onClose={() => {
                    setModalNewOpen(false)
                }}/>
            </Modal>


            <Modal isOpen={isModalEditOpen} onClose={() => {
                setIsModalEditOpen(false)
            }}>
                <AddDocument id={editId} edit={true} onCloseRefresh={() => {
                    handleFilter(currentPage)
                }} onClose={() => {
                    setIsModalEditOpen(false)
                }}/>
            </Modal>

            <Modal isOpen={isModalShareOpen} onClose={() => {
                setIsModalShareOpen(false)
            }}>
                <ShareModal id={editId} url={"SharepointDocumentsPrivate"}/>
            </Modal>
        </Container>


    );
}

export default Documents;