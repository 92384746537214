import React, {useEffect, useState} from 'react';
import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import * as documentTypes from './DocumentsTypes';
import * as langsSharepoint from '../shared/langs/LangsSharepoint';
import Select from "react-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faTrash, faWindowClose} from "@fortawesome/free-solid-svg-icons";
import InpurtError from "../InputError";
import {useTranslation} from "react-i18next";
import axios from "axios";
import './AddDocument.scss';
import OurLoading from "../shared/OurLoading";
import FileIconBasedOnFIleExtension from "../shared/sharepoint/FileIconBasedOnFileExtension";
import FileSize from "../shared/sharepoint/FileSize";
import {ADD, useAlertContext} from "../../contexts/AlertContext";
import {IsReadOnly} from "../../helpers/RoleProvider";
import {IsAdminOnly} from "../../helpers/RoleProvider";
import {useUserContext} from "../../contexts/UserContext";
import ManufacturerSingleSelect from "../shared/manufacturer/ManufacturerSingleSelect";
import RefsOnDocument from "./RefsOnDocument";


const AddDocument = (props) => {

    //MARK - Consts
    const {register, handleSubmit, setValue, errors} = useForm();
    const [types, setTypes] = useState([]);
    const [langs, setLangs] = useState([]);
    const [docType, setDocType] = useState('Other');
    const [docTypeDefaultLable, setDocTypeDefaultLable] = useState(null);
    const [active, setActive] = useState(true);
    const [isPublic, setIsPublic] = useState(false);
    const [editLoaded, setEditLoaded] = useState(false);
    const [isSendingToShrp, setIsSendingToShrp] = useState(false);
    const [shrpObject, setShrpObject] = useState({})
    const [mongoId, setMongoId] = useState(null);
    const [fileSize, setFileSize] = useState(0);
    const [manufacturer, setManufacturer] = useState({label: "", value: ""});

    const {t} = useTranslation();

    const [refData, setRefData] = useState([]);
    const [langData, setLangData] = useState([]);


    const {alertDispatch} = useAlertContext();
    const user = useUserContext();


    //MARK - Effects
    useEffect(() => {
        let data = documentTypes.returnDataForSelectByLang('cz');
        setTypes(data);

        let langs = langsSharepoint.returnDataForSelectByLang('cz');
        setLangs(langs);
        
        

        if (props.edit) {
            axios.get("/sharepointdocumentsPrivate/" + props.id).then(res => {


                if (res.data.state === 0) {

                    setValue('title', res.data.item.title);
                    setValue('expiration', new Date(res.data.item.expiration).toISOString().split('T')[0]);
                    setValue('note', res.data.item.note);
                    setValue('descriptionPublic', res.data.item.descriptionPublic);
                    setValue('id', res.data.item.id);
                    setValue('mongoDbId', res.data.item.mongoDbId)
                    setMongoId(res.data.item.mongoDbId);
                    setDocType(res.data.item.typeOfDocument);
                    setActive(res.data.item.active);
                    setIsPublic(res.data.item.isPublic);
                    setShrpObject(res.data.item);
                    setRefData(res.data.item.productsRefIdSelect);
                    setLangData(res.data.item.langsCode);
                    setFileSize(res.data.item.fileSize);
                    
                    if(res.data.item.manufacturerId){
                        setManufacturer({label:"", value:res.data.item.manufacturerId})
                    }
                    
                    let label = documentTypes.returnLabel(res.data.item.typeOfDocument, 'cz');
                    setDocTypeDefaultLable(label);
                    
                    setEditLoaded(true);
                }
            });
        } else {
            let label = documentTypes.returnLabel(docType, 'cz');
            setDocTypeDefaultLable(label);
            setEditLoaded(true);
        }

        

        

    }, []);


    //MARK - Actions

    const onDeleteItem = () => {

        if (window.confirm('Opravdu chcete smazat tento dokument?')) {
            setIsSendingToShrp(true);

            axios.delete("/sharepointdocumentsPrivate/" + props.id + "/" + mongoId).then(res => {
                if (res.data.state === 0) {
                    alertDispatch({
                        type: ADD,
                        payload: {
                            content: {state: 'success', message: 'Dokument byl na Sharepointu úspěšně smazán'}
                        }
                    });
                    setIsSendingToShrp(false);
                    props.onClose();
                    props.onCloseRefresh();
                } else {
                    alertDispatch({
                        type: ADD,
                        payload: {
                            content: {
                                state: 'warning',
                                message: 'Dokument se nepodařilo smazat'
                            }
                        }
                    });
                    console.log(res.data);
                }

            });
        }
    }

    /*const onSubmitFormUpdate = (data) => 
    {
        const formData = new FormData();
        
        console.log('here');
        console.log(data);

        if(refData.length > 0){
            refData.forEach((x, i) => {
                formData.append('ProductsRefId[' + i + ']', x.value);
                i++;
            })
        } 
        
        if(langData.length > 0){
            langData.forEach((x, m) => {
                formData.append('LangsCode[' + m + ']', x);
                m++;
            })
        }
        

        if (!props.edit) {
            formData.append("formFile", data.file[0]);
        }

        formData.append("title", data.title);
        formData.append("type", docType);
        formData.append("note", data.note);
        formData.append("descriptionPublic", data.descriptionPublic);
        formData.append("expiration", data.expiration);
        formData.append("active", active);
        formData.append("isPublic", isPublic);
        
        if (props.edit) {
            formData.append("id", data.id);
        }


        axios.put("/sharepointDocumentsPrivate/" + data.id, formData, {'Content-Type': 'multipart/form-data'}).then(res => {
            
            if(res.data.state === 0)
            {
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {state: 'success', message: 'Položka byla aktualizována'}
                    }
                })
            }
            
        });
    }*/


    const onSubmitForm = (data) => {

        setIsSendingToShrp(true);


        const formData = new FormData();


        if (refData && refData.length > 0) {
            refData.forEach((x, i) => {
                formData.append('ProductsRefId[' + i + ']', x.value);
                i++;
            })
        }

        if (langData && langData.length > 0) {
            langData.forEach((x, m) => {
                formData.append('LangsCode[' + m + ']', x);
                m++;
            })
        }
        
        
        //uprava expirace pokud se da 00:00 casovy posun to hazi do minuleho dne
        let expiration = data.expiration;
        if(expiration  ){
            expiration += "T12:00:00";
        }
        
        


        if (props.edit) {
            formData.append("fileSize", fileSize);
        }
        
        if(data.file != null && data.file.length > 0 ) {
            formData.append("formFile", data.file[0]);
            formData.append("fileSize", data.file[0].size);
        }
        
        formData.append("title", data.title);
        formData.append("typeOfDocument", docType);
        formData.append("note", data.note);
        formData.append("descriptionPublic", data.descriptionPublic);
        formData.append("expiration", expiration);
        formData.append("active", active);
        formData.append("isPublic", isPublic);
        formData.append("manufacturerId", manufacturer ? manufacturer.value : "");

        if (props.edit) {
            formData.append("id", data.id);
            formData.append("mongoDbId", data.mongoDbId);
        }


        if (props.edit) {
            axios.put("/sharepointDocumentsPrivate/" + data.id, formData, {'Content-Type': 'multipart/form-data'}).then(res => {

                if (res.data.state === 0) {
                    props.onCloseRefresh();
                    alertDispatch({
                        type: ADD,
                        payload: {
                            content: {state: 'success', message: 'Položka byla aktualizována'}
                        }
                    });
                    
                    props.onClose();
                    
                } else {
                    alertDispatch({
                        type: ADD,
                        payload: {
                            content: {
                                state: 'warning',
                                message: 'Aktualizace na Sharepoint se nezdařila'
                            }
                        }
                    });
                    console.log(res.data);
                }

            }).catch(() => {
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {
                            state: 'warning',
                            message: 'Aktualizace na Sharepoint se nezdařila'
                        }
                    }
                });

            }).finally(() => {
                setIsSendingToShrp(false);
            });
        } else {
            axios.post("/sharepointDocumentsPrivate", formData, {'Content-Type': 'multipart/form-data'}).then(res => {
                if (res.data.state === 0) {
                    alertDispatch({
                        type: ADD,
                        payload: {
                            content: {state: 'success', message: 'Dokument byl úspěšně nahrán na sharepoint'}
                        }
                    });
                    props.onClose();
                    props.onCloseRefresh();
                } else if (res.data.state === 1) {
                    alertDispatch({
                        type: ADD,
                        payload: {
                            content: {
                                state: 'info',
                                message: 'Dokument na Sharepoint nelze nahrát. Tento dokument na Sharepointu již existuje.'
                            }
                        }
                    });
                    console.log(res.data);
                } else {
                    alertDispatch({
                        type: ADD,
                        payload: {
                            content: {
                                state: 'warning',
                                message: 'Nahrání se nezdařilo.'
                            }
                        }
                    });
                    console.log(res.data);
                }

            }).catch(() => {
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {
                            state: 'warning',
                            message: 'Nahrání se nezdařilo.'
                        }
                    }
                });
            }).finally(() => {
                setIsSendingToShrp(false);
            });
        }


    }

    

    const handleLangsItemsClikc = (key) => {
        let arr = [...langData];
        arr.splice(key, 1);
        setLangData(arr);
    }


   

    const handleOptionsLangsClick = (event) => {
        let arr = [...langData];
        if(langData && langData.length > 0){
            for (let i = 0; i < langData.length; i++) {
                if (langData[i] === event.value) {
                    console.log('This lang already exists in array');
                    return false;
                }
            }
            arr = [...langData];
        }
        
        arr.push(event.value);
        setLangData(arr);
    }

    

    const printDebug = () => {
        console.log("data:");
        console.log(shrpObject);
    }

    return (
        <Container className={"addDocument"}>
            <Row>

                {props.edit ?
                    <Col as={Row}><h1>{t('documents.editDocument')}</h1>{!editLoaded ? <OurLoading/> : null}</Col>

                    : <Col as={Row}><h1>{t('documents.addDocument')}</h1></Col>

                }


            </Row>
            {editLoaded ?

                <Row>
                    <Form style={{width: "100%"}}>
                        {props.edit ?
                            <React.Fragment>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>
                                            Id Sharepoint
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="id"
                                            ref={register()}
                                            maxLength={200}
                                            disabled={true}

                                        />

                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>
                                            Id MongoDb
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="mongoDbId"
                                            ref={register()}
                                            disabled={true}

                                        />

                                    </Form.Group>
                                </Form.Row>
                            </React.Fragment>

                            : null


                        }

                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>
                                    Název dokumentu*
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="title"
                                    ref={register({required: true})}
                                    maxLength={200}

                                />
                                <Form.Text>
                                    {errors.title && errors.title.type === 'required' &&
                                    <InpurtError message={t("validation.required")} showMessage={true}/>}
                                </Form.Text>

                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Typ dokumentu*</Form.Label>
                                <Select
                                    options={types}
                                    //defaultValue={{label: 'ostatní', value: 'Other'}}
                                    defaultValue={{label: docTypeDefaultLable, value: docType}}
                                    onChange={(e) => {
                                        setDocType(e.value)
                                    }}
                                />
                                {docType === 'CE' || docType === 'ISO' ?
                                <Alert style={{marginTop: 10}}  variant={'warning'}>
                                    V případě CE či ISO dokumentu musí být vyplněna expirace.
                                </Alert>
                                    : null }
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Dokument má být aktivní*</Form.Label>
                                <Select
                                    name='active'
                                    options={[{value: false, label: 'Ne'}, {value: true, label: 'Ano'}]}
                                    defaultValue={active ? {value: true, label: 'Ano'} : {
                                        value: false,
                                        label: 'Ne'
                                    }}
                                    onChange={(e) => {
                                        setActive(e.value)
                                    }}

                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Dokument má být veřejný*</Form.Label>
                                <Select
                                    name='isPublic'
                                    options={[{value: false, label: 'Ne'}, {value: true, label: 'Ano'}]}
                                    defaultValue={isPublic ? {value: true, label: 'Ano'} : {value: false, label: 'Ne'}}
                                    onChange={(e) => {
                                        setIsPublic(e.value)
                                    }}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>
                                    Expirace
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    name="expiration"
                                    ref={docType === 'CE' || docType === 'ISO' ?
                                        register({required: true})
                                     :  register()
                                    }
                                   

                                />
                                {docType === 'CE' || docType === 'ISO' ?
                                <Form.Text>
                                    {errors.expiration && errors.expiration.type === 'required' &&
                                    <InpurtError message={t("validation.required")} showMessage={true}/>}
                                </Form.Text>
                                    : null }
                            </Form.Group>
                        </Form.Row>
                        
                        {/* Manufacturer  */}
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Výrobce</Form.Label>
                                <ManufacturerSingleSelect defaultManufacturer={manufacturer} setParentData={(e) => setManufacturer(e)}  />
                                
                            </Form.Group>
                        </Form.Row>
                        
                        {/* Refs on document */}
                        
                        <RefsOnDocument setParentData={(data) => setRefData(data)} manufacturer={manufacturer} parentData={refData} />
                        
                        {/* languages */}
                        
                        <Form.Row className={'mt-2'}>
                            <Form.Group as={Col}>
                                <Form.Label>Jazyky obsažené v dokumentu</Form.Label>
                                <Select
                                    options={langs}
                                    onChange={(e) => {
                                        handleOptionsLangsClick(e)
                                    }}
                                />
                            </Form.Group>
                        </Form.Row>
                        {langData.length > 0 ?

                            <Form.Row>
                                <div className={'cont'}>
                                    {
                                        langData.map((x, i) => {
                                            return (
                                                <div className={'item'} key={i}>
                                                    <span className="count">{i + 1}.</span>
                                                    <div className="item2">
                                                        <span onClick={() => {
                                                            handleLangsItemsClikc(i)
                                                        }}>
                                                            <FontAwesomeIcon className="i" icon={faWindowClose}/> 
                                                        </span>
                                                        <span>
                                                            {x} - {langs.filter(f => f.value === x)[0].label}
                                                        </span>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </Form.Row>
                            : null
                        }
                        <Form.Row className={'mt-2'}>
                            <Form.Group as={Col}>
                                <Form.Label>Poznámka (pro interní použití)</Form.Label>
                                <Form.Control name={"note"} ref={register()} maxLength={5000} as="textarea" rows={5}/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className={'mt-2'}>
                            <Form.Group as={Col}>
                                <Form.Label>Veřejný popis</Form.Label>
                                <Form.Control name={"descriptionPublic"} ref={register()} maxLength={5000} as="textarea"
                                              rows={5}/>
                            </Form.Group>
                        </Form.Row>
                        {
                            props.edit && editLoaded ?
                                
                                <Form.Row className={'mt-2'}>
                                    <Form.Group as={Col}>
                                        <div className={"file"}>
                                            <div className={"icon"}>
                                                <FileIconBasedOnFIleExtension
                                                    extension={shrpObject.fileExtension}
                                                />
                                            </div>
                                            <div
                                                className={"fileName"}>{shrpObject.displayName}.{shrpObject.fileExtension}</div>
                                            <div className={"size"}>
                                                <FileSize fileSize={fileSize}/>
                                            </div>

                                        </div>
                                    </Form.Group>

                                </Form.Row>: null
                        }

                        {!props.edit ?
                                !IsReadOnly(user) ?
                                    <Form.Row className={'mt-2'}>
                                        <Form.Group as={Col} controlId="formFile">
                                            <Form.Label>Vyberte soubor z počítače*</Form.Label>
                                            <Alert variant={"warning"} className={"mb-3 mt-2"}>
                                                Vkládejte pouze 1 soubor.
                                            </Alert>
                                            <Form.Control type="file" name={"file"} ref={register({required: true})}/>
                                            <Form.Text>
                                                {errors.file && errors.file.type === 'required' &&
                                                    <InpurtError message={t("validation.required")} showMessage={true}/>}
                                            </Form.Text>
                                        </Form.Group>
                                    </Form.Row>
                                :null

                           
                            :
                                !IsReadOnly(user) ?
                                    <Form.Row className={'mt-2'}>
                                        <Form.Group as={Col} controlId="formFile">
                                            <Form.Label>Vyberte soubor z počítače*</Form.Label>
                                            <Alert variant={"warning"} className={"mb-3 mt-2"}>
                                                <div>Vkládejte pouze 1 soubor. </div>
                                                <div style={{color: "red"}}>Při změně souboru bude původní soubor nenávratně smazán!</div>
                                            </Alert>
                                            <Form.Control type="file" name={"file"} ref={register} />
                                        </Form.Group>
                                    </Form.Row>
                                :null
                           
                        }

                        <Form.Row className={"mt-3"}>
                            {isSendingToShrp ? <OurLoading/>

                                :

                                props.edit ?

                                    !IsReadOnly(user) ?
                                        <React.Fragment>
                                            <Button variant="success" type="button"
                                                    onClick={handleSubmit(onSubmitForm)}>
                                                <FontAwesomeIcon className="i" icon={faSave}/>
                                                Aktualizovat
                                            </Button>

                                            <Button variant={"danger"} type={"button"} onClick={onDeleteItem}
                                                    style={{marginLeft: 10}}>
                                                <FontAwesomeIcon className="i" icon={faTrash}/>
                                                Smazat
                                            </Button>
                                        </React.Fragment>
                                        : null
                                    :
                                    <Button variant="success" type="button" onClick={handleSubmit(onSubmitForm)}>
                                        <FontAwesomeIcon className="i" icon={faSave}/>
                                        Nahrát na Sharepoint
                                    </Button>
                            }


                        </Form.Row>


                    </Form>


                </Row>


                : null}

            {
                props.edit && editLoaded ?
                    IsAdminOnly(user) ?
                        <Row style={{marginTop: 50}}><Button variant="outline-secondary" size="sm" onClick={() => {
                            printDebug()
                        }}>log</Button></Row>
                        : null
                    : null
            }


        </Container>
    );

}

export default AddDocument;