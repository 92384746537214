import React, {useState, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import axios from "axios";
import {useForm} from "react-hook-form";
import {faSave, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import './AddCategory.scss';
import InpurtError from "../InputError";
import {Button, Container, Form, Row} from "react-bootstrap";


const AddCategory = (props) => {


    const {t} = useTranslation();


    const [data] = useState({
        level: props.forModal['level'],
        cat1id: props.forModal['cat1id'],
        cat2id: props.forModal['cat2id'],
        cat3id: props.forModal['cat3id'],
        cat4id: props.forModal['cat4id'],
        cat5id: props.forModal['cat5id'],
        id: props.forModal['catid'],
        langs: {}
    })

    const {register, handleSubmit, errors, setValue} = useForm();

    const onSubmit = (fData) => {
        data.langs = fData;
        props.setEditcatId(data.id);
        if (props.edit) {
            axios.post('/category/editCat', data).then(res => {
                if (res.data) {
                    if (res.data.result === 1) {
                        alert(res.data.message);
                        props.saveModal();

                    } else {
                        alert(res.data.message);
                        console.log(res.data.error);
                    }

                } else {
                    alert('Došlo k chybš v DB.');
                }
            }).catch(error => {
                alert('Došlo k chybě na serveru.');
                console.log(error);
            });


        } else {
            axios.post('/category/addCat', data).then(res => {
                console.log(res);
                if (res.data) {
                    alert('Kategorie byla přidána.');
                    props.saveModal();
                } else {
                    alert('Došlo k chybš v DB.');
                }
            }).catch(error => {
                alert('Došlo k chybě na serveru.');
                console.log(error);
            });

        }
    };

    const deleteHandler = () => {
        console.log(data);
        axios.post('/category/deleteCat', data).then(res => {

            if (res.data.result === 1) {
                alert(res.data.message);
            } else {
                alert(res.data.message);
                console.log(res.data.error);
            }

            props.saveModal();

        }).catch(error => {
            alert('Došlo k chybě na serveru.');
            console.log(error);
        });

    }

    /*const getLangs =  () => {
        axios.get('/lang').then(res => {
            setLangs(res.data);
        });

    }*/

    


    useEffect(() => {


        if (props.edit) {
            axios.get('/category/catDetail/' + data.id).then(res => {
                res.data.forEach((x) => {
                    setValue(x.devLang, x.nazevLang);
                });

            }).catch(error => {
                alert('Došlo k chybě na serveru. Nepovedlo se načíst data ke kategorii.');
                console.log(error);
            });
        }


    }, [props.edit, data.id, setValue]);


    return (


        <Container className="addCategory">
            <Row>
                {props.edit ? <h1>Editovat kategorii úrovně {props.forModal.level}</h1> :
                    <h1>Přidat kategorii úrovně {props.forModal.level}</h1>}
            </Row>

            <Row className="catString">
                <h2>Nadřazené kategorie</h2>
                {data.level > 1 && props.catString['1'] > '' ?
                    <div className="catStringItem"><span className="num">1</span><span
                        className="value">{props.catString['1']}</span></div> :
                    <div className="catStringItem"><span className="value">Žádné, jedná se o kategorii úroveň 1.</span>
                    </div>}
                {data.level > 2 && props.catString['2'] > '' ?
                    <div className="catStringItem"><span className="num">2</span><span
                        className="value"> {props.catString['2']}</span></div> : null}
                {data.level > 3 && props.catString['3'] > '' ?
                    <div className="catStringItem"><span className="num">3</span><span
                        className="value"> {props.catString['3']}</span></div> : null}
                {data.level > 4 && props.catString['4'] > '' ?
                    <div className="catStringItem"><span className="num">4</span><span
                        className="value"> {props.catString['4']}</span></div> : null}
                {data.level > 5 && props.catString['5'] > '' ?
                    <div className="catStringItem"><span className="num">5</span><span
                        className="value"> {props.catString['5']}</span></div> : null}
            </Row>


            <Row>

                <Form className={"p-3 w-100"}>
                    {props.langs.map((x, k) => {
                        return (
                            <Form.Row className="w-100 pt-2" key={k}>
                                <Form.Label>[{x.abbrev}] Název</Form.Label>
                                <Form.Control type={"text"} name={x.abbrev} ref={register({required: true})}/>

                                {errors[x.abbrev] && errors[x.abbrev].type === 'required' &&
                                <Form.Text><InpurtError showMessage={true}
                                                        message={t("validation.required")}/></Form.Text>}

                            </Form.Row>
                        )
                    })
                        }
                        </Form>


                        </Row>
                        <Row className="p-2">
                        <Button variant={"success"} className="m-1" onClick={handleSubmit(onSubmit)}>
                        <FontAwesomeIcon className="i" icon={faSave}/>Uložit
                        </Button>
                    {props.edit ?
                        <Button variant={"danger"} className="m-1" onClick={deleteHandler}><FontAwesomeIcon className="i" icon={faTrash}/>Smazat
                        </Button> : null}
                        </Row>


                        </Container>
                        );

                        }

                        export default AddCategory;