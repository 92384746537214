



export const data = [
    
    {
        code: "Other",
        id: 1,
        order: 0,
        langs: [
            {
                code: "en",
                name: "Other - Other"
            },
            {
                code: "cz",
                name: "Other - Ostatní"
            },
            {
                code: "pl",
                name: "Other - Ostatní"
            },

        ]
    },
    {
        code: "DoC",
        id: 2,
        order: 1,
        langs: [
            {
                code: "en",
                name: "Prohlášení o shodě"
            },
            {
                code: "cz",
                name: "DoC - Prohlášení o shodě"
            },
            {
                code: "pl",
                name: "Prohlášení o shodě"
            },

        ]
    },
    {
        code: "CE",
        id: 3,
        order: 2,
        langs: [
            {
                code: "en",
                name: "CE - EC certificate"
            },
            {
                code: "cz",
                name: "CE - EC certificate"
            },
            {
                code: "pl",
                name: "EC certificate"
            },

        ]
    },
    {
        code: "TDS",
        id: 4,
        order: 3,
        langs: [
            {
                code: "en",
                name: "TDS - Technical data sheet"
            },
            {
                code: "cz",
                name: "TDS - Technical data sheet"
            },
            {
                code: "pl",
                name: "Technical data sheet"
            },

        ]
    },
    {
        code: "AW",
        id: 5,
        order: 4,
        langs: [
            {
                code: "en",
                name: "AW - Art Work"
            },
            {
                code: "cz",
                name: "AW - Art Work"
            },
            {
                code: "pl",
                name: "AW - Art Work"
            },

        ]
    },
    {
        code: "MSDS",
        id: 6,
        order: 5,
        langs: [
            {
                code: "en",
                name: "MSDS - Bezpečnostní list"
            },
            {
                code: "cz",
                name: "MSDS - Bezpečnostní list"
            },
            {
                code: "pl",
                name: "MSDS - Bezpečnostní list"
            },

        ]
    },
    {
        code: "IFU",
        id: 7,
        order: 6,
        langs: [
            {
                code: "en",
                name: "IFU - Návod k použití"
            },
            {
                code: "cz",
                name: "IFU - Návod k použití"
            },
            {
                code: "pl",
                name: "Návod k použití"
            },

        ]
    },
    {
        code: "TEST",
        id: 8,
        order: 7,
        langs: [
            {
                code: "en",
                name: "TEST - Výsledky testů dle aplikovatelných norem"
            },
            {
                code: "cz",
                name: "TEST - Výsledky testů dle aplikovatelných norem"
            },
            {
                code: "pl",
                name: "Výsledky testů dle aplikovatelných norem"
            },

        ]
    },
    {
        code: "ISO",
        id: 9,
        order: 8,
        comment: "má odkaz na dodavatele",
        langs: [
            {
                code: "en",
                name: "ISO - ISO certifikát"
            },
            {
                code: "cz",
                name: "ISO - ISO certifikát"
            },
            {
                code: "pl",
                name: "ISO certifikát"
            },

        ]
    },
    {
        code: "QAA",
        id: 10,
        order: 9,
        comment: "má odkaz na dodavatele, má být pouze interní (neveřejný)",
        langs: [
            {
                code: "en",
                name: "QAA - Quality Agreement"
            },
            {
                code: "cz",
                name: "QAA - Quality Agreement"
            },
            {
                code: "pl",
                name: "Quality Agreement"
            },

        ]
    },
    {
        code: "LF",
        id: 11,
        order: 10,
        comment: "",
        langs: [
            {
                code: "en",
                name: "LF - Leaflet"
            },
            {
                code: "cz",
                name: "LF - Reklamní leták"
            },
            {
                code: "pl",
                name: "LF - Reklamní leták"
            },

        ]
    },
    {
        code: "Test_IT",
        id: 12,
        order: 11,
        comment: "",
        langs: [
            {
                code: "en",
                name: "Test_IT - Testing document for IT purpose"
            },
            {
                code: "cz",
                name: "Test_IT - Testovací dokument IT oddělení"
            },
            {
                code: "pl",
                name: "LF - Testing document for IT purpose"
            },

        ]
    }
    
];

export const returnDataForSelectByLang = (lang) =>{
    let returnData = [];
    for (let i = 0; i < data.length; i++) {
        let langData = data[i].langs.filter(f => f.code === lang);
        let label = langData[0].name;
        let value = data[i].code;
        let obj = {label: label, value: value};
        returnData.push(obj);
        
    }
    
    return returnData;
    
}

export const returnDataForDocumentsOnProduct = (lang) =>{
    let returnData = [];
    for (let i = 0; i < data.length; i++) {
        let langData = data[i].langs.filter(f => f.code === lang);
        let name = langData[0].name;
        let order = data[i].order;
        let id = data[i].order;
        let code = data[i].code;
        returnData.push({name: name, order: order, id: id, code: code});

    }

    return returnData;
}

export const returnLabel = (docType, lang) => {
        let label = "";
        for (let i = 0; i < data.length; i++) {
            if(data[i].code === docType){
                let typeDataLang = data[i].langs.filter(f => f.code === lang);
                label = typeDataLang[0].name;
                
            }
        }
        


    return label;
    
    
}