import React from 'react';
import './Alert.scss';
import {faCheckCircle, faExclamationCircle, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {useAlertContext, REMOVE} from '../../../contexts/AlertContext';

export default function Alert({alert}) {
    const {alertDispatch} = useAlertContext();

    function renderItem(content) {
        if (typeof content === 'function') {
            return content();
        } else {
            return <div className="text">
                {content.state === "success" ? <FontAwesomeIcon className="i" icon={faCheckCircle}/> : null}
                {content.state === "warning" ? <FontAwesomeIcon className="i" icon={faExclamationCircle}/> : null}
                {content.state === "info" ? <FontAwesomeIcon className="i" icon={faInfoCircle}/> : null}
                {content.message}
            </div>;
        }
    }

    return (
        <div className="alert-box">
            
                {alert.map(t => {
                    return (
                        <div 
                            className={"_alert " + t.content.state} 
                            key={t.id}
                            onClick={() => alertDispatch({type: REMOVE, payload: {id: t.id}})}
                        >
                                {renderItem(t.content)}
                        </div>
                    );
                })}
            </div>
    );
}