import React from 'react';
import ReactDom from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import './Modal.scss';

const Modal = (props) => {

   

    if (props.isOpen) {

        return ReactDom.createPortal(
            <div className={"custom-modal-wrapper"}>
                <div onClick={props.onClose } className={"custom-modal-backdrop"}/>
                <div className={"modal-box"}>
                    <div className={"close"}><button onClick={props.onClose }><FontAwesomeIcon icon={faTimes} /></button></div>
                    {props.children}
                </div>
            </div>, document.getElementById("modal-root"))
    }

    return null;
};

export default Modal