import React, {useEffect, useState} from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import theme1Styles from "../../config/dataTable/theme1Styles";
import Moment from "react-moment";
import {useTranslation} from "react-i18next";

export default function BsStock() {

    const {t} = useTranslation();
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState([]);
    const [pagination] = useState({
        rangeSeparatorText: 'z',
        noRowsPerPage: false,
    });

    const columns = (clickHandler => [
        {
            name: 'Id',
            selector: 'id',
            sortable: true,
        },
        {
            name: 'RefId',
            selector: 'refId',
            sortable: true
        },
        {
            name: t("batistsafety.journalDate"),
            sortable: true,
            cell: row => <Moment format="DD.MM.YYYY" date={row.journalDate}/>
        },
        {
            name: t("batistsafety.lastChange"),
            sortable: true,
            cell: row => <Moment format="DD.MM.YYYY" date={row.lastChange}/>
        },
        {
            name: t("batistsafety.stockCount"),
            selector: 'stockCount',
            sortable: true
        },
        {
            name: t("batistsafety.turnOfAtt"),
            selector: 'turnOffAt',
            sortable: true
        }
    ]);

    const handleButtonClick = () => {

    }

    useEffect(() => {

        if (!loaded) {
            axios.get("batistsafety/stock").then((res) => {
                setData(res.data.stock);
                setLoaded(true);
            });
        }
    })

    return (
        <React.Fragment>
            <DataTable
                title={t("batistsafety.stock")}
                data={data}
                columns={columns(handleButtonClick)}
                paginationComponentOptions={pagination}
                pagination
                paginationPerPage={50}
                paginationRowsPerPageOptions={[10, 30, 50, 100]}
                dense
                theme="theme1"
                customStyles={theme1Styles}
            />

        </React.Fragment>
    )

}