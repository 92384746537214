import React from 'react';

const UserContext = React.createContext(false);

export function UserContextProvider(props) {

    
    

    return (
        <UserContext.Provider value={{ data: props.data }}>
            {props.children}
        </UserContext.Provider>
    );
}

export const useUserContext = () => React.useContext(UserContext);