import React, {useEffect, useState, useRef} from 'react';
import {useForm} from "react-hook-form";
import axios from 'axios';
import 'react-overlay-loader/styles.css';
import { faChevronLeft, faSignInAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {ADD, useAlertContext} from "../../contexts/AlertContext";
import {Link, useHistory, useParams} from "react-router-dom";
import InpurtError from "../InputError";
import {Button, Row} from "react-bootstrap";
import './PasswordRecovery.scss';

const PasswordRecovery = (props) => {

    const {t, i18n} = useTranslation();
    const [email, setEmail] = useState("");
    const {alertDispatch} = useAlertContext();
    const {token} = useParams();

    const {register, errors, handleSubmit, watch} = useForm();
    const password = useRef({});
    password.current = watch("password", "");

    let history = useHistory();

    useEffect(() => {


    });

    const handleSubmitReset = (data) => {

        axios.post("/user/recovery/" + token, data).then(res => {
            if (res.data.isOk) {
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {state: 'success', message: t("passwordRecovery.passwordChanged")}
                    }
                });
            }
            else{

                switch (res.data.result) {
                    case 1:
                    case 2:
                        alertDispatch({
                            type: ADD,
                            payload: {
                                content: {state: 'warning', message: t("passwordRecovery.tokenExpired")}
                            }
                        });
                        break;
                    default:
                        break;
                }
            }
        }).catch(err => {
            alertDispatch({
                type: ADD,
                payload: {
                    content: {state: 'warning', message: t("global.saveError")}
                }
            })
        });

    }

    const handleSubmitSendToken = () => {

        let data = {
            email: email,
            lang: i18n.language
        }

        axios.post('/user/recovery', data).then(res => {
            if (res.data.isOk) {
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {state: 'success', message: t("passwordRecovery.successMessage")}
                    }
                });
            }
            else{
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {state: 'warning', message: t("passwordRecovery.userNotExist")}
                    }
                });
            }

        }).catch(err => {
            alertDispatch({
                type: ADD,
                payload: {
                    content: {state: 'warning', message: t("global.saveError")}
                }
            })
        });
    }


    return (
        <div className={"password-recovery"}>
            

            {token != null ?
                <form className={"passwordRecoveryForm"}>
                    <h1>{t('passwordRecovery.title')}</h1>
                    <Button size={"sm"} variant={"outline-primary"} onClick={() => {history.push('/login')}}><FontAwesomeIcon className="i" icon={faChevronLeft}/>{t('global.back')}</Button>
                    <div className={"recovery-form"}>
                        <div>
                            {t("passwordRecovery.descriptionRecovery")}
                            <div className={"item-row"}>
                                <label>{t('passwordRecovery.password')}</label>
                                <div className="item-group">
                                    <input
                                        name="password"
                                        type="password"
                                        ref={register({required: true})}
                                    />
                                    {errors.password && <InpurtError message={t("validation.required")}></InpurtError>}
                                </div>
                            </div>
                            <div className={"item-row"}>
                                <label>{t('passwordRecovery.passwordRepeat')}</label>
                                <div className="item-group">
                                    <input
                                        name="passwordConfirm"
                                        type="password"
                                        ref={register({
                                            validate: value =>
                                                value === password.current || t("passwordRecovery.passwordNotMatch")
                                        })}
                                    />
                                    {errors.passwordConfirm &&
                                    <InpurtError message={t("passwordRecovery.passwordNotMatch")}></InpurtError>}
                                </div>
                            </div>
                            <div>
                                <button type={"submit"} className={"btn-submit-blue"}
                                        onClick={handleSubmit(handleSubmitReset)}>
                                    <FontAwesomeIcon className="i" icon={faSignInAlt}/>
                                    {t("global.submit")}</button>
                            </div>
                        </div>
                    </div>
                </form>
                :
                <div>
                    
                    <Row className={"p-3"} >
                        {t("passwordRecovery.description")}
                    </Row>
                    <Row className={"p-3"}>
                        <input type={"email"} style={{width: "100%"}} onChange={(e) => {
                            setEmail(e.target.value)
                        }}/>
                    </Row>
                    <Row className={"p-3"}>
                        <Button onClick={handleSubmitSendToken}>
                            <FontAwesomeIcon className="i" icon={faSignInAlt}/>
                            {t("global.submit")}
                        </Button>
                    </Row>
                </div>
            }
        </div>
    )
}

export default PasswordRecovery;
