import React from 'react';
import 'react-overlay-loader/styles.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {ADD, useAlertContext} from "../../contexts/AlertContext";





const Shoptet = () => {

    const {alertDispatch} = useAlertContext();
    
    const generate = () => {
        
        axios.post('/shoptet/generate').then(() => {
            alertDispatch({
                type: ADD,
                payload: {
                    content: {state: 'success', message: 'Feed byl vygenerován'}
                }
            });
        }).catch(() => {
            alertDispatch({
                type: ADD,
                payload: {
                    content: {
                        state: 'warning',
                        message: 'Došlo k chybě při generování feedu'
                    }
                }
            });
        });
        
    }


    return (
        <div>
            <h1>Shoptet</h1>
            <div className="subMenu">
                <button type={"button"} onClick={generate} ><FontAwesomeIcon className="i" icon={faPlus}/> Vynutit generování feed</button>
            </div>
        </div>
    )
}

export default Shoptet;
