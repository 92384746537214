import React, {useState, useEffect, useCallback} from 'react';
import UploadPhoto from '../../Media/UploadPhoto';
import LocalePhotos from '../../Media/LocalePhotos';
import axios from 'axios';
import {ADD, useAlertContext} from "../../../contexts/AlertContext";
import LabelInfo from "../../LabelInfo";
import {useTranslation} from "react-i18next";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faImages} from "@fortawesome/free-solid-svg-icons";
import {EditorState, ContentState, convertToRaw} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {useUserContext} from "../../../contexts/UserContext";
import {IsReadOnly} from "../../../helpers/RoleProvider";


const EditLocale = ({locale, productId, handleFinished, handleShowError, allLocales}) => {

    const {t} = useTranslation();

    const {alertDispatch} = useAlertContext();
    const user = useUserContext();

    const [data, setData] = useState({
        id: 0,
        nameIbatist: '',
        nameEso: '',
        noSale: 0,
        descriptionShort: '',
        descriptionMax: '',
        descriptionLong: EditorState.createEmpty(),
        descriptionMedium: '',
        codeRegion: '',
        form: '',
        loaded: false,
        keywords: ''
    });

    const [localePhotoUnique, setLocalePhotoUnique] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [uploadButtonEnabled, setUploadButtonEnabled] = useState(true);

    useEffect(() => {

        if (!loaded) {

            if (locale.descriptionLong) {
                const contentBlock = htmlToDraft(locale.descriptionLong);
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                if (contentBlock) {

                    setData(prevState => ({
                        ...prevState,
                        descriptionLong: EditorState.createWithContent(contentState)
                    }));
                }
            }

            setData(prevState => ({
                ...prevState,
                nameEso: locale.nameEso,
                nameIbatist: locale.nameIbatist,
                id: locale.id,

                descriptionMax: locale.descriptionMax,
                descriptionMedium: locale.descriptionMedium,
                codeRegion: locale.codeRegion,
                descriptionShort: locale.descriptionShort,
                form: locale.form,
                noSale: locale.noSale,
                keywords: locale.keywords
            }));

            setLoaded(true);
        }

    }, [loaded, locale]);

    const onChange = (e) => {
        const {name, value} = e.target;
        setData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSubmit = (event) => {

        event.preventDefault();

        //console.log(data);

        if (data.nameIbatist === ''){
            handleShowError('Name of product in iBatist must not be empty.');
            return false;

        }

        let dataE = data;

        if(data.descriptionLong !== ''){
            dataE.descriptionLong = draftToHtml(convertToRaw(data.descriptionLong.getCurrentContent()));
        }

       

        axios.post('/product/locale/' + locale.abbrev + '/' + productId, dataE).then(rest => {
            handleFinished(productId);
        }).catch(res => {
            alertDispatch({
                type: ADD,
                payload: {
                    content: {state: 'warning', message: t('global.saveError')}
                }
            });
        });
    }

    const handleUploadedPhoto = useCallback(() => {
        let prevValue = localePhotoUnique + 1;
        setLocalePhotoUnique(prevValue);
    }, [localePhotoUnique]);

    const handleImageDeleted = useCallback(() => {
        let prevValue = localePhotoUnique + 1;
        setLocalePhotoUnique(prevValue);
    }, [localePhotoUnique]);

    const isUploadButtonEnabled = (value) => {
        setUploadButtonEnabled(value);
    };

    const handleWyswyg = (a) => {
        setData(prevState => ({
            ...prevState,
            descriptionLong: a
        }));
    }

    return (
        <Container className={"editLocale"}>
            {/*<h2><FontAwesomeIcon icon={faPen} className="i"/>Úprava jazykové mutace</h2>*/}
            <Row>
                <Form onSubmit={handleSubmit} method={"POST"}>
                    <Form.Row className={"p-1"}>
                        <Form.Group as={Col}>
                            <LabelInfo label={t('productEdit.languageTab.nameIBatist')}
                                       iconText={t('productEdit.languageTab.nameIBatist')}/>
                            <Form.Control type="text" name="nameIbatist" onChange={onChange}
                                          value={data.nameIbatist || ''}/>
                        </Form.Group>


                    </Form.Row>
                    <Form.Row className={"p-1"}>
                        <Form.Group as={Col}>
                            <LabelInfo label={t('productEdit.languageTab.nameEso')}
                                       iconText={t('productEdit.languageTab.nameEsoDesc')}/>
                            <Form.Control type="text" name="nameEso" disabled="disabled" onChange={onChange}
                                          value={data.nameEso || ''}/>
                        </Form.Group>
                    </Form.Row>


                    <Form.Row className={"p-1"}>
                        <Form.Group as={Col}>
                            <LabelInfo label={t('productEdit.languageTab.shortDesc')}
                                       iconText={t('productEdit.languageTab.shortDescDesc')}/>
                            <Form.Control as={'textarea'} maxLength="150" rows="2" cols="60" name='descriptionShort'
                                          onChange={onChange} value={data.descriptionShort || ''}/>
                        </Form.Group>
                    </Form.Row>

                    {/*<Form.Row className={"p-1"}>
                        <Form.Group as={Col}>
                            <LabelInfo label={t('productEdit.languageTab.middleDesc')}
                                       iconText={t('productEdit.languageTab.middleDescDesc')}/>
                            <Form.Control as={'textarea'} maxLength="400" rows="5" cols="60" name='descriptionMedium'
                                          onChange={onChange} value={data.descriptionMedium || ''}/>
                        </Form.Group>
                    </Form.Row>*/}

                    <Form.Row>
                        <Form.Group as={Col}>
                            <LabelInfo label={t('productEdit.languageTab.longDesc')}
                                       iconText={t('productEdit.languageTab.longDescDesc')}/>

                            <Editor
                                stripPastedStyles
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                editorState={data.descriptionLong}
                                onEditorStateChange={handleWyswyg}
                                toolbar={{
                                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'image', 'remove', 'history'],
                                    inline: {
                                        inDropdown: false,
                                        className: undefined,
                                        component: undefined,
                                        dropdownClassName: undefined,
                                        options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace']
                                    }, blockType: {
                                        inDropdown: true,
                                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                                        className: undefined,
                                        component: undefined,
                                        dropdownClassName: undefined,
                                    },
                                    fontFamily: {
                                        options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                                        className: undefined,
                                        component: undefined,
                                        dropdownClassName: undefined,
                                    }
                                    
                                    
                                  
                                  
                                 
                                }}/>
                        </Form.Group>
                        {/*
                    <div className="form-item col-2">
                        <LabelInfo label={"Max popis"} iconText={t("Podrobný popis obsahující všechny informace")}></LabelInfo>
                        <textarea type="text" rows="6" cols="60" name='descriptionMax' onChange={onChange}
                                  value={data.descriptionMax}></textarea>
                    </div>*/}

                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <LabelInfo label={t('productEdit.languageTab.keywords')}
                                       iconText={t("productEdit.languageTab.keywordsDesc")}/>
                            <Form.Control type="text" name="keywords" style={{width: "100%"}} onChange={onChange}
                                          defaultValue={data.keywords} maxLength={"1000"}/>
                        </Form.Group>

                    </Form.Row>
                    {!IsReadOnly(user) ?
                        <Form.Row className={"pt-3"}>
                            <Button variant={"success"} type="submit" className="btn-lg"><FontAwesomeIcon
                                icon={faSave}/> {t('productEdit.languageTab.saveLocale')}</Button>
                        </Form.Row> : null}
                </Form>
            </Row>

            {/*<Row className="locale-photos p-2">
                <h2>
                    <FontAwesomeIcon icon={faImages} className="i"/>
                    {t('productEdit.languageTab.photos')}
                </h2>
            </Row>

            {!IsReadOnly(user) && uploadButtonEnabled &&
            <Row className={"p-2"}>
                <UploadPhoto
                    handleFinished={handleUploadedPhoto}
                    type='product'
                    locale={locale}/>
            </Row>

            }
            <Row className={"p-2"}>
                <LocalePhotos
                    key={localePhotoUnique}
                    handleImageDeleted={handleImageDeleted}
                    isUploadButtonEnabled={isUploadButtonEnabled}
                    itemId={productId}
                    locale={locale}
                    allLocales={allLocales}
                    langId={locale}/>
            </Row>*/}
            
        </Container>
    )
};

export default EditLocale;