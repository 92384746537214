import React, {useEffect, useState} from 'react';
import AsyncSelect from "react-select/async";
import axios from "axios";
import {Button} from "react-bootstrap";


const ManufacturerSingleSelect = (props) => {
    const [defaultManufacter, setDefaultManufacter] = useState(props.defaultManufacturer ?? {label: '', value: ''});

    const getOptionsForRef = (inputValue) => {
        return axios.get("api/manufacters/filter?name=" + inputValue).then(res => {
            //return(res.data.items);
            return res.data.items.map(function (item) {
                return {
                    label: item.name,
                    value: item.id
                }
            });
        });
    }


    const handleManufacterSelect = (event) => {

        setDefaultManufacter( {
           
            value: event.value,
            label: event.label
        });
        props.setParentData({label: event.label, value: event.value});
    }

    const removeManufacter = () =>{
        setDefaultManufacter({label: '', value: ''})
        props.setParentData({label: '', value: ''});
    }

    useEffect(() => {
        //load manufacturer name if only id provided
        if(props.defaultManufacturer && (props.defaultManufacturer.value && !props.defaultManufacturer.label)){
            axios.get("api/manufacters/filter?id=" + props.defaultManufacturer.value).then(res => {
                setDefaultManufacter({label:res.data.items[0].name, value: res.data.items[0].id})
            });
        }
    }, [props.defaultManufacturer]);
    
    return(
        <div style={{ display: 'flex', width: 340}}>
            <span style={{width: 300}}>
                
                 <AsyncSelect
                     loadOptions={getOptionsForRef}
                     value={defaultManufacter}
                     valueKey={"value"}
                     labelKey={"label"}
                     onChange={(e) => {
                         handleManufacterSelect(e)
                     }}


                 />
            </span>
             <Button title={"Remove manufacturer"} size={"sm"} variant={"secondary"} className={"mr-1"} onClick={removeManufacter} style={{marginLeft: 5}} >X</Button>
            
       
   
        </div>
    )
}

export default ManufacturerSingleSelect;