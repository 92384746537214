import React from 'react';
import {Button, Card, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartPie} from "@fortawesome/free-solid-svg-icons";


const ReportsPage = () => {



    return (
        <Container className="reports">
            <Row className="p-3 flex-row report_cont">
                <Card className="m-1" style={{width: 300}}>
                    <Card.Header as="h5">Actual stocks</Card.Header>
                    <Card.Body>
                        <Card.Text>
                           Show the size of goods stocked for various regions
                        </Card.Text>
                        <Link className="item" to='/reports/stocks'>
                            <Button variant="primary"><FontAwesomeIcon className="i" icon={faChartPie}/>Show report</Button></Link>
                    </Card.Body>
                </Card>
                <Card className="m-1" style={{width: 300}}>
                    <Card.Header as="h5">Forecast of stocks</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            Show the forecast of goods on stock
                        </Card.Text>
                        <Link className="item" to='/reports/forecastStocks'>
                            <Button variant="primary"><FontAwesomeIcon className="i" icon={faChartPie}/>Show report</Button></Link>
                    </Card.Body>
                </Card>
                    

            </Row>
            



            

        </Container>
    );

}

export default ReportsPage;