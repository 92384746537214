import React, { useState } from 'react';
import './Langs.scss';




const Langs = ({langs, setLang, actualLang}) => {

    const [data, setData] = useState(actualLang);

    const onChangeHandle = (e) => {
        let lang = e.target.value;
        setData(lang);
        setLang(lang);
    }

    return(
        <div className="langs">
            {langs && langs.map((x, k) => {
                return (
                    <div className="item" key={k}>
                        <label className={data === x.abbrev ? "checked" : null}  htmlFor={"lang" + x.abbrev}>{x.abbrev} </label>
                        <input type="radio" className="toggle" id={ "lang" + x.abbrev} name="langsradio" value={x.abbrev} onChange={onChangeHandle}    />


                    </div>
                )
            })
            }


        </div>
    )
}

export default Langs;