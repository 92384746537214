import React from 'react';
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './LabelInfo.scss';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

const LabelInfo = ({label, iconText}) => {

    return (
        <div className={"label-info"}>
            
            <Tippy content={iconText}>
                <span><FontAwesomeIcon className={"icon"} icon={faInfoCircle}/></span>
            </Tippy>
            {label}


        </div>);

}

export default LabelInfo;