import React from 'react';
import axios from "axios";
import {faCheckCircle, faTimesCircle} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import './JsonStatus.scss';

export default function JsonStatus({isOK, refId, actionDone}) {

    const {t} = useTranslation();

    const handleRegenerate = () => {

        axios.post("/AzureBlob/" + refId + "/generate").then(() => {
            actionDone();

        }).catch(() => {
            alert('Generování JSON se nezdařilo');
        });

    }

    return (<div className={"jsonStatus"}>


        {isOK ? <div className={"found"}>
                <FontAwesomeIcon icon={faCheckCircle} className={"icon"}/>
                {t('jsonStatus.ok')}
            
            </div> :
            <div className={"not-found"}>
                <FontAwesomeIcon icon={faTimesCircle} className={"icon"}/>
                {t('jsonStatus.notOk')}
                <div className={"ml-2"}>
                    <button className={"btn btn-sm btn-outline-primary"} onClick={handleRegenerate}>Přegenerovat json</button>
                </div>
            </div>
        }
    </div>)
}