import React from 'react';
import axios from 'axios';
import {useTranslation} from "react-i18next";
import {ADD, useAlertContext} from "../../contexts/AlertContext";
import {useForm} from "react-hook-form";
import InpurtError from "../InputError";

const ErpAdd = ({onFinished}) => {


    const {t} = useTranslation();
    const {alertDispatch} = useAlertContext();


    const {register, handleSubmit, errors} = useForm({
        defaultValues: {
            name: ''
        }
    });

    const onSubmit = (data, event) => {

        event.preventDefault();

        axios.post('/erp/create', data).then(res => {
            alertDispatch({
                type: ADD,
                payload: {
                    content: {state: 'success', message: 'Položka byla přidána'}
                }
            });

            onFinished();

        }).catch(err => {
            alertDispatch({
                type: ADD,
                payload: {
                    content: {state: 'warning', message: 'ERP se nepovedlo uložit'}
                }
            });
        });
    }

    return (
        <div>
            <h1>{t("erpAdd.title")}</h1>
            <form method={"POST"} onSubmit={handleSubmit(onSubmit)}>
                <div className="item-row">

                    <label>Název ERP</label>
                    <div className="item-group">
                        <input type="text" name="name" ref={register({required: true})} />
                        {errors.name && errors.name.type === 'required' &&
                        <InpurtError message={t("validation.required")}></InpurtError>}
                    </div>

                </div>
                <div className="row">
                    <button type="submit" className="btn-submit-blue">Vytvořit ERP</button>
                </div>
            </form>
        </div>
    )
}
export default ErpAdd