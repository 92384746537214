import React, { useState} from "react";
import {Button} from "react-bootstrap";
import BsOrders from "./BsOrders";
import BsStock from "./BsStock";
import {useTranslation} from "react-i18next";

export  default function BatistSafety() {

    const {t} = useTranslation();
    const [visibleTable, setVisibleTable] = useState("orders");
    
    
    const handleButtonClick = () => {
        
    }
    
    const handleLoadOrders = () => {
        setVisibleTable("orders");
    }
    
    const handleLoadStock = () => {
        setVisibleTable("stock");        
    }
    
    return(
        <React.Fragment>
            <div className="subMenu">
                <Button onClick={() => handleLoadOrders() } style={{marginRight: 10}}  variant="primary">{t("batistsafety.loadOrders")}</Button>
                <Button onClick={() => handleLoadStock() } variant="primary">{t("batistsafety.loadStock")}</Button>
            </div>
            
            {
                visibleTable === "orders" ? <BsOrders /> : <BsStock />
            }
        </React.Fragment>
    )
    
}