import React, {useState, useEffect} from 'react';
import {useForm} from "react-hook-form";
import Switch from "react-switch";
import axios from 'axios';
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ADD, useAlertContext} from "../../contexts/AlertContext";
import {useTranslation} from "react-i18next";
import {Button, Col, Form, Row} from "react-bootstrap";
import {useUserContext} from "../../contexts/UserContext";
import {IsReadOnly} from "../../helpers/RoleProvider";
import InpurtError from "../InputError";
import LabelInfo from "../LabelInfo";
import './Shoptet.scss';

const Shoptet = ({originalItem, index, locales}) => {
    
    const [showVatAlert, setShowVatAlert] = useState(false);

    const {register, watch, handleSubmit, errors, setValue} = useForm(
        {
            defaultValues: {
                price: originalItem.price,
                priceVat: originalItem.priceVat,
                vat: originalItem.vatProductEshop ?? originalItem.vat,
                quantityInSellUnit: originalItem.quantityInSellUnit,
                priority: 0
            }
        });
    

    const {t} = useTranslation();
    const [item, setItem] = useState({isActive: false, showInMainPage: false, notSell: false, showInGoogleFeed: false});
    const user = useUserContext();
    const {alertDispatch} = useAlertContext();
    const [abbrev] = useState([]);
    
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedLocale, setSelectedLocale] = useState('cz');
    const [selectedPriority, setSelectedPriority] = useState(1);
    const [loaded, setLoaded] = useState(false);
    const [info, setInfo] = React.useState(null);
    const watchPriceVat = watch("priceVat", false);
    const watchVat = watch("vat", false);
    const watchPrice = watch("price", false);
    
    useEffect(() => {

        if(!watchVat) return;
        
        if(parseFloat(watchVat) !== parseFloat(originalItem.vat))
            setShowVatAlert(true);
        else
            setShowVatAlert(false);
    }, [watchVat])

    useEffect(()=>{


        if(!watchPrice || !watchVat) return;
        
        let priceExVat = getAmountWithoutVat(watchPriceVat,watchVat)

        setValue('price', priceExVat.priceExVat )
    }, [watchPriceVat, watchVat])

    function getAmountWithoutVat(amountIncludingVat, vatPercent) {

        //var amountExVat = amountIncludingVat / (100 + vatPercent) * 100;
        var amountExVat = (amountIncludingVat / ((1 + vatPercent) / 100));
        var sumVat = amountIncludingVat - amountExVat;
        var priceVat = amountExVat + sumVat;
        var amounts = {
            'priceExVat' : amountExVat.toFixed(2),
            'vat' : sumVat.toFixed(2),
            'priceVat': priceVat.toFixed(2)
        };

        return amounts;
    }
    

    const onSubmit = (items) => {

        items['isActive'] = item.isActive;
        items['showInMainPage'] = item.showInMainPage;
        items['notSell'] = item.notSell;
        items['showInGoogleFeed'] = item.showInGoogleFeed;



        if (selectedCategory) {
            items['selectedCategory'] = selectedCategory.map(item => item.value);
        }

        items['locale'] = selectedLocale;
        items['lang'] = selectedLocale;
        items['priority'] = selectedPriority;

        axios.post('/product/' + originalItem.productId + '/eshops/' + originalItem.eshopId + '/connected', items).then((res) => {
            alertDispatch({
                type: ADD,
                payload: {
                    content: {state: 'success', message: t('productEdit.eshopTab.succesMessage')}
                }
            });
        }).catch((err) => {
            alertDispatch({
                type: ADD,
                payload: {
                    content: {state: 'error', message: t('productEdit.eshopTab.errorMessage')}
                }
            });
        });

    }
    
    const getDetail = () => {
        
        axios.get('shoptet/feed/' + originalItem.productId + "/eshop/"+ originalItem.eshopCode).then((res) => {
            console.log(res.data);
            setInfo(res.data.product);
        }).catch((e) => {alert('Při načítání položky pro Shoptet došlo k chybě'); console.log(e)});
    }

    useEffect(() => {

        if (!loaded) {
            
            setItem(originalItem);

            setSelectedCategory(originalItem.selectedCategory);
            if (originalItem.lang) {
                setSelectedLocale(originalItem.lang);
                setSelectedPriority(originalItem.priority);
            }

            locales.forEach(item => abbrev.push({label: item.abbrev, value: item.abbrev}));

            getDetail();
            
            setLoaded(true);
        }
    }, [originalItem, abbrev, loaded, locales]);
    

    const handleAbbrev = (item) => {
        setSelectedLocale(item.value);
    };


    return (


        <Form method={"POST"} onSubmit={handleSubmit(onSubmit)} className={"p-3 rounded cBG_lightGray mb-3"}>
            <Form.Row className={"box"}>
                <Form.Group as={Col}>
                    <h2>{item.name}</h2>
                </Form.Group>
            </Form.Row>


            <Form.Row className={"flex-wrap justify-content-start"}>

                <Col sm={3} md={12} className={"p-2"}>
                    <Row className={"flex-nowrap justify-content-start m-0"}>
                        <div>
                            <Form.Label className={"w-100"}>{t('productEdit.eshopTab.active')}</Form.Label>
                            <Switch checked={item.isActive} name={"active"} onChange={(a) => {

                                setItem(prevState => ({
                                    ...prevState,
                                    isActive: a
                                }));
                                item.isActive = a;

                            }}/>
                        </div>
                    </Row>

                </Col>
            </Form.Row>
            <Form.Row className={"flex-wrap justify-content-start align-items-center"}>


                <Col sm={2} className={"pt-1"}>
                    <label>Cena bez DPH</label>
                    <div className={"error-row"}>

                        <Form.Control type={"number"} name={"price"}
                                      ref={register}
                                      step="any"
                                      readOnly={true}/>
                        
                    </div>
                </Col>
                <Col sm={2} className={"pt-1"}>
                    
                    <LabelInfo label={t('productEdit.eshopTab.vat')}
                               iconText={"Výchozí cena pro tento region je nastavena na: " + originalItem.vat }/>
                    <div className={"error-row"}>
                        <Form.Control type={"number"} name={"vat"}
                                      ref={register}
                                      step="any"/>
                       
                        {errors.vat && errors.vat.type === 'required' &&
                            <InpurtError message={t("validation.required")}/>}
                        {errors.vat && errors.vat.type === 'min' &&
                            <InpurtError message={t("validation.biggerThan0")}/>}
                    </div>
                </Col>
                <Col sm={2} className={"pt-1"}>
                    <Form.Label>{t('productEdit.eshopTab.vatPrice')} * </Form.Label>
                    <div className={"error-row"}>
                        {item.lang === "cz" ?
                            <Form.Control type={"number"} name={"priceVat"}
                                          ref={register({required: true, pattern: /^[0-9\b]+$/})}
                                          step="any"/> :

                            <Form.Control type={"number"} name={"priceVat"}
                                          ref={register({required: true})}
                                          step="any"/>}
                        <Form.Text>
                            {errors.priceVat && errors.priceVat.type === 'required' &&
                                <InpurtError showMessage={true} message={t("validation.required")}/>}
                            {errors.priceVat && errors.priceVat.type === 'min' &&
                                <InpurtError showMessage={true} message={t("validation.biggerThan1")}/>}
                            {errors.priceVat && errors.priceVat.type === 'pattern' &&
                                <InpurtError showMessage={true} message={t("validation.fullNumber")}/>}
                        </Form.Text>
                    </div>
                </Col>
                <Col sm={3} className={"pt-1"}>
                    <Form.Label>{t('productEdit.eshopTab.itemsInSellUnit')}</Form.Label>
                    <Form.Control className="mx-sm-3" type={"text"} name={"quantityInSellUnit"} ref={register}
                                  disabled={"disabled"}/>
                </Col>


            </Form.Row>
            <Form.Row>
                {info ?
                    <>
                <div style={{display: "flex", flexDirection: "column"}}>
                <div><b>Synchronizovaná data:</b></div>
                <div><label>Název: </label> {info.name}</div>
                <div><label>Keywords: </label> {info.keywords}</div>
                    <div><label>Katalog URL: </label> {info.catalogUrl}</div>
                    <div><label>Stav: </label> {info.state ? info.state.name : null}</div>
                    <div><label>Jednotka: </label> {info.unit ? info.unit.name : null}</div>
                    <div><label>Výrobce: </label> {info.manufacter ? info.manufacter.name : null}</div>
                    <div><label>Dodavatel: </label> {info.supplier ? info.supplier.name : null}</div>
                </div>
                </> : null }
                
            </Form.Row>
            <Form.Row>
                {showVatAlert ?
                <div className={"war"}>Upozornění: Je nastaveno jiné DPH, než je určeno pro daný region.</div> : null }
            </Form.Row>
            {!IsReadOnly(user) ?
                <Form.Row>
                    <Form.Group as={Col}>
                        <Button type="submit" variant={"success"}>
                            <FontAwesomeIcon icon={faSave} className="i"/>
                            {t('productEdit.eshopTab.save')}
                        </Button>
                    </Form.Group>
                </Form.Row> : null }
        </Form>


    )

}
export default Shoptet;