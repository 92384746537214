import {Col, OverlayTrigger, Popover, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import Badge from "react-bootstrap/Badge";


const OrderNote = (props) => {

    const popover = (props) => {

        return(


            <Popover id={"popover-basic-" + props.data.CIS_ZBOZI ?? null}  style={{maxWidth: 300, width: 300}}>

                <Popover.Content style={{maxWidth: '100%'}}>
                    {props.data}
              
                </Popover.Content>
            </Popover>

        )
    }

    return(
        props.data ?
            <OverlayTrigger rootClose trigger="click" placement="left" key={props.key} overlay={popover({data: props.data})}>
                <FontAwesomeIcon icon={faInfoCircle} style={{fontSize: 20, cursor: 'pointer', color: '#66a4ff'}} />
            </OverlayTrigger>
            : null
    )
}

export default OrderNote;