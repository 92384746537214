import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faSave} from "@fortawesome/free-solid-svg-icons";
import LabelInfo from "../../LabelInfo";
import {convertToRaw} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import {useTranslation} from "react-i18next";
import {Button, Col, Container, Form, Row} from "react-bootstrap";

const AddLocale = ({handleFinished, handleShowError, productId}) => {

    const {t} = useTranslation();
    const [langs, setLangs] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [data, setData] = useState({
        id: '',
        nameIbatist: '',
        noSale: 0,
        descriptionShort: '',
        descriptionMax: '',
        descriptionLong: '',
        descriptionMedium: '',
        codeRegion: '',
        form: '',
        productId: 0,
        langId: 0,
        keywords: ''
    });

    const onChange = (e) => {
        const {name, value} = e.target;
        setData(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleSubmit = (event) => {

        event.preventDefault();

        let dataE = data;
        
        if (data.nameIbatist === ''){
            handleShowError('Name of product in iBatist must not be empty.');
            return false;
            
        } 

        if(data.descriptionLong !== ''){
            dataE.descriptionLong = draftToHtml(convertToRaw(data.descriptionLong.getCurrentContent()));
        }
        

        axios.post('/product/' + productId + '/locale', dataE).then(res => {
            handleFinished(productId);
        });
    }

    const handleWyswyg = (a) => {
        setData(prevState => ({
            ...prevState,
            descriptionLong: a
        }));
    }

    useEffect(() => {


        axios.get('/product/' + productId + '/no-locales').then(res => {

            if (res.data.length !== 0) {
                setShowForm(true);
            }

            setLangs(res.data);

            if (res.data.length > 0) {
                setData(prevState => ({
                    ...prevState,
                    langId: res.data[0].id
                }));
            }

            setData(prevState => ({
                ...prevState,
                productId: productId
            }));

        }).catch(res => {
            console.log(res);
        }).finally(res => {
        });

    }, [showForm, productId]);


    const langsMap = langs && langs.map((object, i) =>
        <option key={object.id} value={object.id}>{object.name}</option>
    )

    return (
        <Container className={"addLocale"}>
            <h2><FontAwesomeIcon icon={faPen} className="i"/>{t('productEdit.languageTab.newLocale')}</h2>
            {showForm ?
                <Row>
                <Form onSubmit={handleSubmit} method={"POST"}>
                    <Form.Row >
                        <Form.Group as={Col} className="form-item">
                            <Form.Label>Jazyk</Form.Label>
                            <Form.Control as={"select"} onChange={onChange} name="langId" value={data.langId}>
                                {langsMap}
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row >
                        <Form.Group as={Col} >
                            <LabelInfo label={t('productEdit.languageTab.nameIBatist')} iconText={t('productEdit.languageTab.nameIBatist')}/>
                            <Form.Control type="text" name="nameIbatist" onChange={onChange} value={data.nameIbatist}/>
                        </Form.Group>
                        {/*<div className="form-item col-2">
                            <label>Název produktu ESO</label>
                            <input type="text" name="nameEso" onChange={onChange} value={data.nameEso || ''}/>
                        </div>*/}
                    </Form.Row>
                    {/*<div className={"row"}>
                        <div className="form-item col-2">
                            <label>Aplik. sleva</label>
                            <input type="number" name='noSale' onChange={onChange}
                                   value={data.noSale}/>
                        </div>
                        <div className="form-item col-2">
                            <label>Kód regionu</label>
                            <input type="text" name='codeRegion' onChange={onChange}
                                   value={data.codeRegion}/>
                        </div>
                    </div>*/}
                    <Form.Row >
                        <Form.Group as={Col} >
                            <LabelInfo label={t('productEdit.languageTab.shortDesc')} iconText={t('productEdit.languageTab.shortDescDesc')}/>
                            <Form.Control as={"textarea"} maxLength="150" rows="2" cols="60" name='descriptionShort' onChange={onChange} value={data.descriptionShort}/>
                        </Form.Group>
                    </Form.Row>
                    {/*<Form.Row>
                        <Form.Group>
                            <LabelInfo label={t('productEdit.languageTab.middleDesc')} iconText={t('productEdit.languageTab.middleDescDesc')}/>
                            <Form.Control as={"textarea"} maxLength="400" rows="5" cols="60" name='descriptionMedium' onChange={onChange} value={data.descriptionMedium}/>
                        </Form.Group>
                        
                    </Form.Row>*/}
                    <Form.Row >
                        <Form.Group as={Col} >
                            <LabelInfo label={t('productEdit.languageTab.longDesc')}
                                       iconText={t('productEdit.languageTab.longDescDesc')}/>
                            {/*<textarea type="text" rows="6" cols="60" name='descriptionLong'
                                      onChange={onChange} value={data.descriptionLong}></textarea>*/}
                            <Editor
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                editorState={data.descriptionLong}
                                onEditorStateChange={handleWyswyg}
                                toolbar={{
                                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'image', 'remove', 'history'],
                                    inline: {
                                        inDropdown: false,
                                        className: undefined,
                                        component: undefined,
                                        dropdownClassName: undefined,
                                        options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace']
                                    }, blockType: {
                                        inDropdown: true,
                                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                                        className: undefined,
                                        component: undefined,
                                        dropdownClassName: undefined,
                                    },
                                    fontFamily: {
                                        options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                                        className: undefined,
                                        component: undefined,
                                        dropdownClassName: undefined,
                                    }
                                }}/>
                        </Form.Group>
                        {/*<div className="form-item col-2">
                            <label>Max popis</label>
                            <textarea type="text" rows="6" cols="60" name='descriptionMax'
                                      onChange={onChange} value={data.descriptionMax}></textarea>
                        </div>*/}
                    </Form.Row>
                    <Form.Row >
                        
                        <Form.Group className="form-item" style={{width: "100%"}}>
                            <LabelInfo label={t('productEdit.languageTab.keywords')}
                                       iconText={t("productEdit.languageTab.keywordsDesc")}/>
                            <Form.Control type="text" name="keywords" style={{width: "100%"}} onChange={onChange} value={data.keywords} maxLength={"1000"}/>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row >
                        <Button variant={"success"} type="submit">
                            <FontAwesomeIcon icon={faSave}/>
                            {t('productEdit.languageTab.newLocaleAdd')}
                        </Button>
                    </Form.Row>
                </Form>
                </Row>
                :
                <div>{t('productEdit.languageTab.noLocaleToAdd')}</div>
            }
        </Container>
    )
}

export default AddLocale