import {Button, Col, Container, FormControl, InputGroup,  Row} from "react-bootstrap";

import React, {useEffect, useState} from "react";
import axios from "axios";
import DataTable, {createTheme} from "react-data-table-component";
import {useTranslation} from "react-i18next";
import theme1Styles from "../../../config/dataTable/theme1Styles";
import theme1 from "../../../config/dataTable/theme1";
import Badge from "react-bootstrap/Badge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-regular-svg-icons";
import {faSpinner, faMagic, faFileExcel} from "@fortawesome/free-solid-svg-icons";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import ReactExport from "react-export-excel";
import PopOverCz from "./PopOverCz";
import PopOverPt from "./PopOverPt";


//react-export-excel

const ReportStocksModal = (props) => {
    const {t, i18n} = useTranslation();
    
    const categories = props.categories;
    const cat1 = categories.cat1 > 0 ? categories.cat1.toString() : "";
    const cat2 = categories.cat2 > 0 ? categories.cat2.toString() : "";
    const cat3 = categories.cat3 > 0 ? categories.cat3.toString() : "";
    const cat4 = categories.cat4 > 0 ? categories.cat4.toString() : "";
    const [filterText, setFilterText] = React.useState('');

    const [dataIsCombined, setDataIsCombined] = useState(false);


    
    const [dataEso, setDataEso] = useState([]);
    const [loadingEso, setLoadingEso] = useState(false);
    const [esoLoaded, setEsoLoaded] = useState(false);
    const [errEso, setErrEso] = useState(false);

    const [dataEsoPl, setDataEsoPl] = useState([]);
    const [loadingEsoPl, setLoadingEsoPl] = useState(false);
    const [esoLoadedPl, setEsoLoadedPl] = useState(false);
    const [errEsoPl, setErrEsoPl] = useState(false);


    const [dataIbatist, setDataIbatist] = useState([]);
    const [loadingIbatist, setLoadingIbatist] = useState(false);
    const [ibatistLoaded, setIbatistLoaded] = useState(false);
    const [errIbatist, setErrIbatist] = useState(false);

    const [dataPt, setDataPt] = useState([]);
    const [loadingPt, setLoadingPt] = useState(false);
    const [ptLoaded, setPtLoaded] = useState(false);
    const [errPt, setErrPt] = useState(false);

    const [dataEs, setDataEs] = useState([]);
    const [loadingEs, setLoadingEs] = useState(false);
    const [esLoaded, setEsLoaded] = useState(false);
    const [errEs, setErrEs] = useState(false);

    const [data, setData] = useState([]);
    const [defaultData, setDefaultData] = useState([]);
    
    const [loadingCombineData, setLoadingCombineData] = useState(false);

    //table
    createTheme('theme1', theme1);


    const [pagination] = useState({
        rowsPerPageText: t('global.itemsOnPage') + ':',
        rangeSeparatorText: t('global.from'),
        noRowsPerPage: false
    });

    

    const columns = ([

        {
            name: t('catalog.table.code'),
            selector: 'ref',
            sortable: true,
            width: '100px'
        },

        {
            name: t('catalog.table.name'),
            selector: 'name',
            sortable: false,
            grow: 1
        },
        {
            name: 'CZ',
            cell: (row) =>  <PopOverCz key={row.ref + "_cz"} productRegion={row.cz_productRegion ?? null} region="CZ" data={row.cz.length > 0 ? row.cz[0] : null } />,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '40px'
        },
        {
            name: 'PL',
            cell: (row) =>  <PopOverCz key={row.ref + "_pl"} productRegion={row.pl_productRegion ?? null} region="PL" data={row.pl.length > 0 ? row.pl[0] : null } />,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '40px'
        },
        {
            name: 'PT',
            cell: (row) =>  <PopOverPt region="PT" productRegion={row.pt_productRegion ?? null} ptRef={row.ptReference} key={row.ref + "_pt"} data={row.pt.length > 0 ? row.pt[0] : null } />,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '40px'
        },
        {
            name: 'ES',
            cell: (row) =>  <PopOverPt region="ES" productRegion={null} ptRef={row.ptReference} key={row.ref + "_es"} data={row.es.length > 0 ? row.es[0] : null } />,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '40px'
        },
        {
            name: "supplier",
            selector: 'supplier',
            sortable: false,
            right: true
        },
       
        {
            name: "cz free stock",
            selector: 'cz_free',
            sortable: true,
            width: '100px',
            right: true
        },
        {
            name: "cz actual stock",
            selector: 'cz_actualStock',
            sortable: true,
            width: '100px',
            right: true
        },
        {
            name: "cz avg. consumption 3months",
            selector: 'cz_avgConsume3',
            sortable: true,
            width: '100px',
            right: true
        },
        {
            name: "cz unit",
            selector: 'cz_unit',
            sortable: false,
            width: '60px'
        },
        /*{
            name: "pl supplier",
            selector: 'pl_supplier',
            sortable: false,
            right: true
        },*/

        {
            name: "pl free stock",
            selector: 'pl_free',
            sortable: true,
            width: '100px',
            right: true
        },
        {
            name: "pl actual stock",
            selector: 'pl_actualStock',
            sortable: true,
            width: '100px',
            right: true
        },
        {
            name: "pl avg. consumption 3months",
            selector: 'pl_avgConsume3',
            sortable: true,
            width: '100px',
            right: true
        },
       /* {
            name: "pl unit",
            selector: 'pl_unit',
            sortable: false,
            width: '60px'
        },*/
        /*{
            name: "pt free stock Pcs",
            selector: 'pt_freePcs',
            sortable: true,
            width: '100px',
            right: true
        },*/
        {
            name: "pt free stock",
            selector: 'pt_freeBx',
            sortable: true,
            width: '100px',
            right: true
        },
        /*{
            name: "pt actual stock",
            selector: 'pt_actualStock Pcs',
            sortable: true,
            width: '100px',
            right: true
        },*/
        /*{
            name: "pt unit",
            selector: 'pt_unit',
            sortable: false,
            width: '60px'
        },*/
        {
            name: "pt actual stock",
            selector: 'pt_actualStockBx',
            sortable: true,
            width: '100px',
            right: true
        },
        {
            name: "pt alt. units",
            selector: 'pt_altUnit',
            sortable: false,
            width: '60px'
        },
        {
            name: "pt avg. consumption 3months",
            selector: 'pt_avgConsume3',
            sortable: true,
            width: '100px',
            right: true
        },
        {
            name: "pt avg. cost price",
            selector: 'pt_avgCostPrice',
            sortable: true,
            width: '100px',
            right: true
        },
        /*{
            name: "es free stock Pcs",
            selector: 'es_freePcs',
            sortable: true,
            width: '100px',
            right: true
        },*/
        {
            name: "es free stock",
            selector: 'es_freeBx',
            sortable: true,
            width: '100px',
            right: true
        },
        /*{
            name: "es actual stock",
            selector: 'es_actualStock Pcs',
            sortable: true,
            width: '100px',
            right: true
        },*/
        /*{
            name: "es unit",
            selector: 'es_unit',
            sortable: false,
            width: '60px'
        },*/
        {
            name: "es actual stock",
            selector: 'es_actualStockBx',
            sortable: true,
            width: '100px',
            right: true
        },
        {
            name: "es alt. units",
            selector: 'es_altUnit',
            sortable: false,
            width: '60px'
        },
        {
            name: "es avg. consumption 3months (Bx)",
            selector: 'es_avgConsume3',
            sortable: true,
            width: '100px',
            right: true
        },
        {
            name: "es avg. cost price",
            selector: 'es_avgCostPrice',
            sortable: true,
            width: '100px',
            right: true
        }


    ]);

    //excel
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


    useEffect(() => {
        if (!ibatistLoaded) {
            getDataFromIbatist().then(() => setIbatistLoaded(true));
        }


    }, []);

    useEffect(() => {
        if (!esoLoaded) {
            getDataFromEso("CZ").then(() => {
                setEsoLoaded(true);
            });
        }
    }, []);


    useEffect(() => {
        if (!ptLoaded) {
            getDataFromPt().then(() => setPtLoaded(true));
        }
    }, []);

    useEffect(() => {
        if (!esLoaded) {
            getDataFromEs().then(() => setEsLoaded(true));
        }


    }, []);

    useEffect(() => {
            if(!esoLoadedPl) {
                getDataFromEso("PL").then(() => setEsoLoadedPl(true));
            }

    }, []);

  

    /*const popover = (props) => {
        
            return(
                
                    
                <Popover id={"popover-basic-" + props.data.CIS_ZBOZI ?? null}  style={{maxWidth: 300, width: 300}}>
                    <Popover.Title as="h3">Czech detail info </Popover.Title>
                    <Popover.Content style={{maxWidth: '100%'}}>
                        <Row>
                            <Col xs={4} >
                                <div >
                                    <Badge variant="info">Actual stock</Badge>
                                    <div>{props.data.MNFYZICKYSTAV_SUM}</div>
                                </div>
                                <div >
                                    <Badge variant="info">Free</Badge>
                                    <div>{props.data.MNFYZVOLNYSTAV_SUM}</div>
                                </div>
                                <div >
                                    <Badge variant="info">Ordered</Badge>
                                    <div>{props.data.MNOBJEDNANO_SUM}</div>
                                </div>
                                <div >
                                    <Badge variant="info">Blocked</Badge>
                                    <div>{props.data.MNBLOKOVANO_SUM}</div>
                                </div>

                            </Col>
                            <Col xs={8} >
                                <div>
                                    <Badge variant="info">Unit</Badge>
                                    <div>{props.data.KOD_MJ}</div>
                                </div>
                                <div>
                                    <Badge variant="info">Box</Badge>
                                    <div>{props.data.TEXT_1}</div>
                                </div>
                                <div>
                                    <Badge variant="info">Supplier</Badge>
                                    <div>{props.data.SUBJ_NAZEV}</div>
                                </div>

                            </Col>
                        </Row>
                    </Popover.Content>
                </Popover>
                       
            )
        } */
        
        /*const PopoverContainer = (props) => {
            return(
                props.data ?
                <OverlayTrigger trigger="hover" placement="left" key={props.key} overlay={popover({data: props.data})}>
                    <FontAwesomeIcon icon={faInfoCircle} style={{fontSize: 20, cursor: 'pointer', color: '#66a4ff'}} />
                </OverlayTrigger>
                    : null
            )
        }*/


    const getDataFromEso = async (region) => {
        
        
        let kodSkupZbozi = cat1 + cat2 + cat3 + cat4;
        let bodyText = {};
        let setData;
        let setLoading;
        let setErr;
        if(region === "CZ"){
            bodyText = {vlTyp:1004, params:[{name:"SKUPZBOZI",value:[{KOD_SKUP_ZBOZI:kodSkupZbozi}]}]};
            setData = (data) => {
                setDataEso(data);
            }
            setLoading = (val) => {
                setLoadingEso(val);
            }
            setErr = (val) => {
                setErrEso(val);
            }

        } else if(region === "PL")  {
            bodyText = {vlTyp:1004, params:[{"name":"FIRMA","value":[{"FIRMA":region}]},{name:"SKUPZBOZI",value:[{KOD_SKUP_ZBOZI:kodSkupZbozi}]}]};
            setLoadingEsoPl(true);
            setData = (data) => {
                setDataEsoPl(data);
            }
            setLoading = (val) => {
                setLoadingEsoPl(val);
            }
            setErr = (val) => {
                setErrEsoPl(val);
            }
        }
        
        setLoading(true);
        
        
        
       

        let data = new FormData();
        data.append("bodyText", JSON.stringify(bodyText));

            
        axios
            .post('api/reports/stocks/eso', data, {'Content-Type': 'multipart/form-data'})
            .then((res) => {
                
                
                if (res.data.state === 0 ) {
                    
                    let data = JSON.parse(res.data.rawResponse);
                    if (data.data.length > 0) {
                            setData(data.data);
                            setErr(false);
                        
                    } else {
                            setErr(true);
                    }

                } else {
                    setErr(true);
                }

            })
            .catch(err => {
                console.log(err);
                setErr(true);

            }).finally(() => {
            setLoading(false);
        })


    }

    const getDataFromIbatist = async () => {
        setLoadingIbatist(true);
        let catParams = "esoCat1=" + cat1;
        catParams +=  "&esoCat2=" + cat2;
        catParams +=  "&esoCat3=" + cat3;
        catParams +=  "&esoCat4=" + cat4;
        axios
            .get('api/reports/stocks/ibatist?' + catParams)
            .then((res) => {
                setDataIbatist(res.data);
                setErrIbatist(false);
            })
            .catch(err => {
                console.log(err);
                setErrIbatist(true);

            }).finally(() => {
            setLoadingIbatist(false)
        })


    }

    const getDataFromPt = async () => {
        setLoadingPt(true);

        let catParams = "esoCat1=" + cat1;
        catParams +=  "&esoCat2=" + cat2;
        catParams +=  "&esoCat3=" + cat3;
        catParams +=  "&esoCat4=" + cat4;
        
        axios
            .get('api/reports/stocks/pt?' + catParams)
            .then((res) => {
                if(res.data.state === 0){
                    setDataPt(res.data.items);
                    setErrPt(false);
                } else{
                    setErrPt(true);
                }
                
            })
            .catch(err => {
                console.log(err);
                setErrPt(true);

            }).finally(() => {
            setLoadingPt(false)
        })


    }

    const getDataFromEs = async () => {
        setLoadingEs(true);

        let catParams = "esoCat1=" + cat1;
        catParams +=  "&esoCat2=" + cat2;
        catParams +=  "&esoCat3=" + cat3;
        catParams +=  "&esoCat4=" + cat4;

        axios
            .get('api/reports/stocks/es?' + catParams)
            .then((res) => {
                if(res.data.state === 0){
                    setDataEs(res.data.items);
                    setErrEs(false);
                } else{
                    setErrEs(true);
                }

            })
            .catch(err => {
                console.log(err);
                setErrEs(true);

            }).finally(() => {
            setLoadingEs(false)
        })


    }

    const combineData = async () => {
        setLoadingCombineData(true);
        
        let arr = [];
        let obj = {};
        let eso = {};
        let pl = {};
        let pt = {};
        let es = {};
        let x = {};


        for (let i = 0; i < dataIbatist.length; i++) {
            x = dataIbatist[i];
            obj = {};
            eso = [];
            pt = [];
            pl = [];
            es = [];
            
            obj.key = i;
            obj.ref = x.code;
            obj.id = x.id;
            obj.name = x.name;
            obj.cz = {};
            obj.pt = {};
            obj.pl = {};
            obj.es = {};
            
            obj.supplier = x.supplier;
            
            eso = dataEso.filter((f) => f.CIS_ZBOZI === x.code);
            obj.cz_free = eso && eso.length > 0 ? parseInt(eso[0].MNFYZVOLNYSTAV_SUM) : 0;
            obj.cz_actualStock = eso && eso.length > 0 ? parseInt(eso[0].MNFYZICKYSTAV_SUM) : 0;
            obj.cz_unit = eso && eso.length > 0 ? eso[0].KOD_MJ : "";
            obj.cz_supplier = eso && eso.length > 0 ? eso[0].SUBJ_NAZEV : "";
            obj.cz_avgConsume3 = eso && eso.length > 0 ? ((parseInt(eso[0].MNPOCET_PRODEJ_30)) * 3) : 0;
            obj.cz_productRegion = x.productRegion.filter(f => f.abbrev === "cz");
            obj.eso = eso;

            pl = dataEsoPl.filter((f) => f.CIS_ZBOZI === x.code);
            obj.pl_free = pl && pl.length > 0 ? parseInt(pl[0].MNFYZVOLNYSTAV_SUM) : 0;
            obj.pl_actualStock = pl && pl.length > 0 ? parseInt(pl[0].MNFYZICKYSTAV_SUM) : 0;
            obj.pl_unit = pl && pl.length > 0 ? pl[0].KOD_MJ : "";
            obj.pl_supplier = pl && pl.length > 0 ? pl[0].SUBJ_NAZEV : "";
            obj.pl_avgConsume3 = pl && pl.length > 0 ? ((parseInt(pl[0].MNPOCET_PRODEJ_30)) * 3) : 0;
            obj.pl_productRegion = x.productRegion.filter(f => f.abbrev === "pl");
            obj.pl = pl;
            
            pt = dataPt.filter((f) => f.czReference === x.code);
            obj.pt = pt;
            obj.pt_actualStock = pt && pt.length > 0 ? parseInt(pt[0].actualStock) : 0;
            obj.pt_unit = pt && pt.length > 0 ? pt[0].units : "";
            obj.pt_actualStockBx = pt && pt.length > 0 ? parseInt(pt[0].actualStockBx) : 0;
            obj.pt_altUnit = pt && pt.length > 0 ? pt[0].alternativeUnits : "";
            obj.pt_freeBx = pt && pt.length > 0 ? parseInt(pt[0].freeStockBx) : 0;
            obj.pt_freePcs = pt && pt.length > 0 ? parseInt(pt[0].freeStockPcs) : 0;
            obj.ptReference = pt && pt.length > 0 ? pt[0].ptReference.trimEnd() : null;
            obj.pt_supplier = pt && pt.length > 0 ? pt[0].suplier : "";
            obj.pt_active = pt && pt.length > 0 ? pt[0].state ? "NO" : "YES" : "";
            obj.pt_sellOut = pt && pt.length > 0 ? pt[0].sellOut ? "YES" : "NO" : "";
            obj.pt_avgConsume3 = pt && pt.length > 0 ? pt[0].avgConsume3 ? parseInt(pt[0].avgConsume3) : 0 : 0;
            obj.pt_avgCostPrice = pt && pt.length > 0 ? pt[0].avgCostPrice ? parseFloat(pt[0].avgCostPrice).toFixed(2) : 0 : 0;
            obj.pt_productRegion = x.productRegion.filter(f => f.abbrev === "pt");

            es = dataEs.filter((f) => f.czReference === x.code);
            obj.es = es;
            obj.es_actualStock = es && es.length > 0 ? parseInt(es[0].actualStock) : 0;
            obj.es_unit = es && es.length > 0 ? es[0].units : "";
            obj.es_actualStockBx = es && es.length > 0 ? parseInt(es[0].actualStockBx) : 0;
            obj.es_altUnit = es && es.length > 0 ? es[0].alternativeUnits : "";
            obj.es_freeBx = es && es.length > 0 ? parseInt(es[0].freeStockBx) : 0;
            obj.es_freePcs = es && es.length > 0 ? parseInt(es[0].freeStockPcs) : 0;
            obj.ptReference = es && es.length > 0 ? es[0].ptReference.trimEnd() : null;
            obj.es_supplier = es && es.length > 0 ? es[0].suplier : "";
            obj.es_active = es && es.length > 0 ? es[0].state ? "NO" : "YES" : "";
            obj.es_sellOut = "N/A";
            obj.es_avgConsume3 = es && es.length > 0 ? es[0].avgConsume3 ? parseInt(es[0].avgConsume3) : 0 : 0;
            obj.es_avgCostPrice = es && es.length > 0 ? es[0].avgCostPrice ? parseFloat(es[0].avgCostPrice).toFixed(2) : 0 : 0;
            
            
            arr[i] = obj;
        }
        setDefaultData(arr);
        setData(arr);
        setLoadingCombineData(false);
        setDataIsCombined(true);
    }
    
    const addRefToRefArr = (data, lang, refArr) => {
        // set rigt ref param
        let refParam="";
        
        switch(lang) {
            case "CZ":
                refParam = "CIS_ZBOZI";
                break;
            case "PL":
                refParam = "CIS_ZBOZI";
                break;
            case "PT":
                refParam = "ptReference";
                break;
            case "ES":
                refParam = "ptReference";
                break;
            case "iBatist":
                refParam = "code";
                break;
            default:
            // code block
        }
        let find = [];
        data.map((x) =>{
            find = refArr.filter((f) => f === x[refParam].trimEnd());
            if(find.length === 0) refArr.push(x[refParam].trimEnd());
        })
    } 
    
    const applyRegionData = (refArr) => {
        let arr = [];
        let cz = {};
        let pl = {};
        let pt = {};
        let es = {};
        let iBatist = {};
        let obj = {};
        refArr.map((x,i) => {
            obj ={};
            obj.key = i;
            obj.ref = x;
            obj.id = i;

            obj.cz = {};
            obj.pt = {};
            obj.pl = {};
            obj.es = {};
            obj.iBatist = {};
            
            iBatist = dataIbatist.filter(f => f.code === x);
            obj.iBatist = iBatist;
            obj.supplier = iBatist.length > 0 ? iBatist[0].supplier : "";
            obj.name = iBatist.length > 0 ? iBatist[0].name : "";
            obj.iBatistId = iBatist.length > 0 ? iBatist[0].id : 0;
            
            cz = dataEso.filter((f) => f.CIS_ZBOZI === x);
            obj.cz = cz;
            obj.cz_free = cz.length > 0 ? parseInt(cz[0].MNFYZVOLNYSTAV_SUM) : 0;
            obj.cz_actualStock = cz.length > 0 ? parseInt(cz[0].MNFYZICKYSTAV_SUM) : 0;
            obj.cz_unit = cz.length > 0 ? cz[0].KOD_MJ : "";
            obj.cz_supplier = cz.length > 0 ? cz[0].SUBJ_NAZEV : "";
            obj.cz_avgConsume3 = cz.length > 0 ? ((parseInt(cz[0].MNPOCET_PRODEJ_30)) * 3) : 0;
            obj.cz_productRegion = iBatist.length > 0 ? iBatist[0].productRegion.filter(f => f.abbrev === "cz") : [];

            pl = dataEsoPl.filter((f) => f.CIS_ZBOZI === x);
            obj.pl = pl;
            obj.pl_free = pl.length > 0 ? parseInt(pl[0].MNFYZVOLNYSTAV_SUM) : 0;
            obj.pl_actualStock = pl.length > 0 ? parseInt(pl[0].MNFYZICKYSTAV_SUM) : 0;
            obj.pl_unit = pl.length > 0 ? pl[0].KOD_MJ : "";
            obj.pl_supplier = pl.length > 0 ? pl[0].SUBJ_NAZEV : "";
            obj.pl_avgConsume3 = pl.length > 0 ? ((parseInt(pl[0].MNPOCET_PRODEJ_30)) * 3) : 0;
            obj.pl_productRegion = iBatist.length > 0 ? iBatist[0].productRegion.filter(f => f.abbrev === "pl") : [];
            
 
            pt = dataPt.filter((f) => f.czReference.trimEnd() === x || f.ptReference.trimEnd() === x);
            obj.pt = pt;
            obj.pt_actualStock = pt.length > 0 ? parseInt(pt[0].actualStock) : 0;
            obj.pt_unit = pt.length > 0 ? pt[0].units : "";
            obj.pt_actualStockBx = pt.length > 0 ? parseInt(pt[0].actualStockBx) : 0;
            obj.pt_altUnit = pt.length > 0 ? pt[0].alternativeUnits : "";
            obj.pt_freeBx = pt.length > 0 ? parseInt(pt[0].freeStockBx) : 0;
            obj.pt_freePcs = pt.length > 0 ? parseInt(pt[0].freeStockPcs) : 0;
            obj.ptReference = pt.length > 0 && pt[0].ptReference ? pt[0].ptReference.trimEnd() : "";
            obj.pt_supplier = pt.length > 0 ? pt[0].suplier : "";
            obj.pt_active = pt.length > 0 ? pt[0].state ? "NO" : "YES" : "";
            obj.pt_sellOut = pt.length > 0 ? pt[0].sellOut ? "YES" : "NO" : "";
            obj.pt_avgConsume3 = pt.length > 0 ? pt[0].avgConsume3 ? parseInt(pt[0].avgConsume3) : 0 : 0;
            obj.pt_avgCostPrice = pt.length > 0 ? pt[0].avgCostPrice ? parseFloat(pt[0].avgCostPrice).toFixed(2) : 0 : 0;
            obj.pt_productRegion = iBatist.length > 0 ? iBatist[0].productRegion.filter(f => f.abbrev === "pt") : [];
            if(obj.name === "") obj.name = pt.length > 0 ? pt[0].description : "";
            if(obj.supplier === "") obj.supplier = pt.length > 0 ? pt[0].suplier : "";

            es = dataEs.filter((f) => f.czReference.trimEnd() === x || f.ptReference.trimEnd() === x);
            obj.es = es;
            obj.es_actualStock = es.length > 0 ? parseInt(es[0].actualStock) : 0;
            obj.es_unit = es.length > 0 ? es[0].units : "";
            obj.es_actualStockBx = es.length > 0 ? parseInt(es[0].actualStockBx) : 0;
            obj.es_altUnit = es.length > 0 ? es[0].alternativeUnits : "";
            obj.es_freeBx = es.length > 0 ? parseInt(es[0].freeStockBx) : 0;
            obj.es_freePcs = es.length > 0 ? parseInt(es[0].freeStockPcs) : 0;
            obj.ptReference = es.length > 0 && es[0].ptReference ? es[0].ptReference.trimEnd() : "";
            obj.es_supplier = es.length > 0 ? es[0].suplier : "";
            obj.es_active = es.length > 0 ? es[0].state ? "NO" : "YES" : "";
            obj.es_sellOut = "N/A";
            obj.es_avgConsume3 = es.length > 0 ? es[0].avgConsume3 ? parseInt(es[0].avgConsume3) : 0 : 0;
            obj.es_avgCostPrice = es.length > 0 ? es[0].avgCostPrice ? parseFloat(es[0].avgCostPrice).toFixed(2) : 0 : 0;
            if(obj.name === "") obj.name = es.length > 0 ? es[0].description : "";
            if(obj.supplier === "") obj.supplier = es.length > 0 ? es[0].suplier : "";
            
            arr.push(obj);
            
            
        })

        return arr;

    }
    
    const combineData2 = () =>{
        setLoadingCombineData(true);
        //combine array of refs
        let refArr = [];
        //misto cz a pl dam ibatist tim si osefuju ze budou jen aktivni polozky
        //addRefToRefArr(dataEso, "CZ", refArr);
        //addRefToRefArr(dataEsoPl, "PL", refArr);
        addRefToRefArr(dataIbatist, "iBatist", refArr);
        addRefToRefArr(dataPt, "PT", refArr);
        addRefToRefArr(dataEs, "ES", refArr);
        let arr = applyRegionData(refArr);   
        setDefaultData(arr);
        setData(arr);
        setLoadingCombineData(false);
        setDataIsCombined(true);
        
    }
    
    const filter = (value) => {
        setFilterText(value);
        if(value !== '' || value !== null || value !== undefined){
            let arr = [...defaultData];
            arr = arr.filter(item => item.ref .includes(value));
            setData(arr);
        }
        
    }
    
    const clearFilter = () => {
        setFilterText("");
        setData(defaultData);
    }

    return (
        <React.Fragment>
        <Container fluid={true}>

            <Row className="p-1 ">
                <div>
                    <h5 className="mb-0">

                        {/* ibatist */}

                        {loadingIbatist ?
                            <Badge className="m-1" variant="info">
                                <FontAwesomeIcon icon={faSpinner} className="i fa-pulse"/>
                                iBatits
                            </Badge> : null}
                        {!loadingIbatist && errIbatist && ibatistLoaded ?
                            <Badge className="m-1" variant="danger">
                                <FontAwesomeIcon icon={faExclamationCircle} className="i "/>
                                iBatist
                            </Badge> : null}
                        {!loadingIbatist && !errIbatist && ibatistLoaded ?
                            <Badge className="m-1" variant="success">
                                <FontAwesomeIcon icon={faCheckCircle} className="i "/>
                                iBatist ({dataIbatist.length})
                            </Badge> : null}
                        
                        {/* ESO */}
                        {loadingEso ? 
                            <Badge className="m-1" variant="info">
                                <FontAwesomeIcon icon={faSpinner} className="i fa-pulse"/>
                                CZ
                            </Badge> : null}
                        {!loadingEso && errEso && esoLoaded ?
                            <Badge className="m-1" variant="danger">
                                <FontAwesomeIcon icon={faExclamationCircle} className="i "/>
                                CZ
                            </Badge> : null}
                        {!loadingEso && !errEso && esoLoaded ?
                            <Badge className="m-1" variant="success"> 
                                <FontAwesomeIcon icon={faCheckCircle} className="i "/>
                                CZ ({dataEso.length})
                            </Badge> : null}

                        {/* PL */}
                        {loadingEsoPl ?
                            <Badge className="m-1" variant="info">
                                <FontAwesomeIcon icon={faSpinner} className="i fa-pulse"/>
                                PL
                            </Badge> : null}
                        {!loadingEsoPl && errEsoPl && esoLoadedPl ?
                            <Badge className="m-1" variant="danger">
                                <FontAwesomeIcon icon={faExclamationCircle} className="i "/>
                                PL
                            </Badge> : null}
                        {!loadingEsoPl && !errEsoPl && esoLoadedPl ?
                            <Badge className="m-1" variant="success">
                                <FontAwesomeIcon icon={faCheckCircle} className="i "/>
                                PL ({dataEsoPl.length})
                            </Badge> : null}

                        

                        {/* PT */}

                        {loadingPt ? <Badge className="m-1" variant="info">
                            <FontAwesomeIcon icon={faSpinner} className="i fa-pulse"/>
                            PT
                        </Badge> : null}
                        {!loadingPt && errPt && ptLoaded ?
                            <Badge className="m-1" variant="danger">
                                <FontAwesomeIcon icon={faExclamationCircle} className="i "/>
                                PT 
                            </Badge> : null}
                        {!loadingPt && !errPt && ptLoaded ?
                            <Badge className="m-1" variant="success"> 
                                <FontAwesomeIcon icon={faCheckCircle} className="i "/>
                                PT ({dataPt.length})
                            </Badge> : null}

                        {/* ES */}

                        {loadingEs ? <Badge className="m-1" variant="info">
                            <FontAwesomeIcon icon={faSpinner} className="i fa-pulse"/>
                            ES
                        </Badge> : null}
                        {!loadingEs && errEs && esLoaded ?
                            <Badge className="m-1" variant="danger">
                                <FontAwesomeIcon icon={faExclamationCircle} className="i "/>
                                ES
                            </Badge> : null}
                        {!loadingEs && !errEs && esLoaded ?
                            <Badge className="m-1" variant="success">
                                <FontAwesomeIcon icon={faCheckCircle} className="i "/>
                                ES ({dataEs.length})
                            </Badge> : null}
                        
                        
                    </h5>
                </div>
            </Row>
            <Row className="pl-1">
                {!loadingEso && !loadingIbatist && !loadingPt ?
                    <Button size="sm" onClick={() => {combineData2()}}>
                        <FontAwesomeIcon icon={faMagic} className="i "/>
                        Show combined data
                    </Button>
                    
                    
                    
                    :

                    <Button size="sm" disabled={true}>
                        <FontAwesomeIcon icon={faSpinner}
                                         className="i fa-pulse"/>
                        Processing...
                    </Button>
                  
                }
                
            </Row>
            {dataIsCombined ?
                <React.Fragment>
                    <Row style={{marginTop: 40}} className="pl-1">
                        <Col className="pl-0">
                            <InputGroup>
                                <FormControl
                                    placeholder="Filter ref"
                                    onChange={(e) => filter(e.target.value)}
                                    value={filterText}

                                />

                                <Button type="button" onClick={() => clearFilter()}>
                                    x
                                </Button>
                            </InputGroup>
                        </Col>

                        <Col>
                            <ExcelFile
                                element={<Button><FontAwesomeIcon icon={faFileExcel} className="i "/>Export to EXCEL</Button>}
                            >
                            
                                <ExcelSheet 
                                    data={data} 
                                    name="iBatistStocks" 
                                >
                                    <ExcelColumn label="Ref" value="ref"/>
                                    <ExcelColumn label="Name" value="name"/>
                                    <ExcelColumn label="supplier" value="supplier"/>
                                    {/*<ExcelColumn label="CZ supplier" value="cz_supplier"/>*/}
                                    <ExcelColumn label="CZ free" value="cz_free"/>
                                    <ExcelColumn label="CZ actual stock" value="cz_actualStock" />
                                    <ExcelColumn label="CZ unit" value="cz_unit"/>
                                    <ExcelColumn label="cz avg consumption 3 months" value="cz_avgConsume3" />

                                    {/*<ExcelColumn label="PL supplier" value="pl_supplier"/>*/}
                                    <ExcelColumn label="PL free" value="pl_free"/>
                                    <ExcelColumn label="PL actual stock" value="pl_actualStock" />
                                    <ExcelColumn label="PL unit" value="pl_unit"/>
                                    <ExcelColumn label="PL avg consumption 3 months" value="pl_avgConsume3" />
                                    
                                    {/*<ExcelColumn label="PT supplier" value="pt_supplier"/>*/}
                                    {/*<ExcelColumn label="PT active" value="pt_active"/>
                                    <ExcelColumn label="PT sell out" value="pt_sellOut"/>*/}
                                    {/*<ExcelColumn label="PT free Pcs" value="pt_freePcs"/>*/}
                                    <ExcelColumn label="PT free" value="pt_freeBx"/>
                                    {/*<ExcelColumn label="PT actual stock" value="pt_actualStock"/>*/}
                                    {/*<ExcelColumn label="PT unit" value="pt_unit"/>*/}
                                    <ExcelColumn label="pt actual stock" value="pt_actualStockBx"/>
                                    <ExcelColumn label="pt alt. units" value="pt_altUnit" />
                                    <ExcelColumn label="pt avg consumption 3 months (Bx)" value="pt_avgConsume3" />
                                    <ExcelColumn label="pt avg cost price" value="pt_avgCostPrice" />

                                    {/*<ExcelColumn label="ES supplier" value="es_supplier"/>
                                    <ExcelColumn label="ES active" value="es_active"/>
                                    <ExcelColumn label="ES sell out" value="es_sellOut"/>*/}
                                    {/*<ExcelColumn label="ES free Pcs" value="es_freePcs"/>*/}
                                    <ExcelColumn label="ES free" value="es_freeBx"/>
                                    {/*<ExcelColumn label="ES actual stock" value="es_actualStock"/>*/}
                                    {/*<ExcelColumn label="ES unit" value="es_unit"/>*/}
                                    <ExcelColumn label="ES actual stock" value="es_actualStockBx"/>
                                    <ExcelColumn label="ES alt. units" value="es_altUnit" />
                                    <ExcelColumn label="ES avg consumption 3 months" value="es_avgConsume3" />
                                    <ExcelColumn label="ES avg cost price" value="es_avgCostPrice" />


                                    
                                    
                                </ExcelSheet>
                            </ExcelFile>
                        </Col>
                        <Col></Col>
                    </Row>

                    <Row className="mt-3">
                            <DataTable
                               
                                data={data}
                                columns={columns}
                                paginationComponentOptions={pagination}
                                pagination
                                dense
                                noHeader
                                paginationPerPage={20}
                                paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                                theme="theme1"
                                customStyles={theme1Styles}
                                responsive

                            />
                    </Row>
                   
                </React.Fragment>


                : null}


        </Container>
            
        </React.Fragment>
    );
}

export default ReportStocksModal;