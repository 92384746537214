import React, {useState} from 'react';
import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartPie,faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../shared/modal/Modal";
import ReportStocksModal from "./ReportStocksModal";
import {ADD, useAlertContext} from "../../../contexts/AlertContext";
import CatalogFilterCategoriesEso from "../../catalog/CatalogFilterCategoriesEso";

//use https://www.npmjs.com/package/react-data-grid ?
//https://ag-grid.com/react-data-grid/excel-export/

const ReportStocks = () => {
    //data
    const [categories, setCategories] = useState({cat1:0, cat2:0, cat3:0, cat4:0})
    
    const [open, setOpen] = useState(false);

    const {alertDispatch} = useAlertContext();
    
    
    
   
    
    //handlers
    const search = async () => {
        if(categories.cat1 === 0 ){
            
            alertDispatch({
                type: ADD,
                payload: {
                    content: {state: 'info', message: 'Choose at least one category level 1'}
                }
            });
            return false;
        }
        setOpen(true);
        
    }

    



    return (
        <React.Fragment>
        <Container className="reports">
            <Row className="p-1">
                <Link  to="/reports"><Button variant="outline-primary"><FontAwesomeIcon className="i" icon={faChevronLeft}/>Back</Button></Link>
                
            </Row>
            <Row className="p-1">
                <h1><FontAwesomeIcon className="i" icon={faChartPie}/>Report stocks</h1>


            </Row>
            <Row >
                <Form style={{width: '100%'}} className={"cBG_BS_light p-4 rounded border"}>
                    <Form.Row>
                        {categories.cat1 === 0 ?
                            <Alert variant="warning" style={{width: '100%'}}>
                                Choose atleast one category level 1
                            </Alert>
                             : null}
                        
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} className={"col-7"}>
                            <CatalogFilterCategoriesEso setCategories={(val) => setCategories(val)} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Button variant={"primary"} disabled={categories.cat1 === 0} className={"btn-lg"} onClick={() => {search();}}>
                            Filter
                        </Button>
                    </Form.Row>
                </Form>
                
            </Row>
            
            

        </Container>
        <Modal isOpen={open} onClose={() => {setOpen(false)}}>
            <ReportStocksModal categories={categories} />
        </Modal>
            
              
            
           

        </React.Fragment>
    );

}

export default ReportStocks;