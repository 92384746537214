import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Form, FormCheck, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import Select from "react-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faWindowClose} from "@fortawesome/free-solid-svg-icons";
import InpurtError from "../InputError";
import {useTranslation} from "react-i18next";
import axios from "axios";
import './AddPhoto.scss'
import OurLoading from "../shared/OurLoading";
import {ADD, useAlertContext} from "../../contexts/AlertContext";
import {IsAdminOnly, IsReadOnly} from "../../helpers/RoleProvider";
import {useUserContext} from "../../contexts/UserContext";
import * as langsSharepoint from "../shared/langs/LangsSharepoint";


const AddPhoto = (props) => {

    //MARK - Consts
    const [langs] = useState(langsSharepoint.returnDataForSelectByLang('cz'));
    const {register, handleSubmit, setValue, errors} = useForm();
    const [isPublic, setIsPublic] = useState(true);
    const [eshop, setEshop] = useState(true);
    const [editLoaded, setEditLoaded] = useState(false);
    const [shrpObject, setShrpObject] = useState({})
    const user = useUserContext();
    const {t} = useTranslation();
    const {alertDispatch} = useAlertContext();
    const [files, setFiles] = useState(null);
    const [langData, setLangData] = useState([]);
    const [showLangSelect, setShowLangSelect] = useState(false);

    //MARK - Effects
    useEffect(() => {
        
        if (props.isEdit) {

            axios.get("/azureblob/" + props.selectedItem).then(res => {

                if (res.data.state === 0) {

                    setValue('mongo', res.data.item.mongoDbId);
                    setValue('notePublic', res.data.item.notePublic);
                    setValue('noteInternal', res.data.item.noteInternal);
                    setValue('originalFileName', res.data.item.originalFileName);
                    setLangData(res.data.item.langs);
                    setValue('id', res.data.item.id);
                    setIsPublic(res.data.item.isPublic);
                    setEshop(res.data.item.isEshop);
                    setShrpObject(res.data.item);
                    setEditLoaded(true);
                    setShowLangSelect(res.data.item.langs && res.data.item.langs.length > 0);
                }
            });
        } else {
            setEditLoaded(true);
        }

    }, [props.id, setValue]);

    const handleUploadFile = (event) => {
        const files = event.target.files;
        setFiles(files);
    }

    const handleLangsItemsClikc = (key) => {
        let arr = [...langData];
        arr.splice(key, 1);
        setLangData(arr);
    }


    const handleOptionsLangsClick = (event) => {
        let arr = [...langData];
        if(langData && langData.length > 0){
            for (let i = 0; i < langData.length; i++) {
                if (langData[i] === event.value) {
                    console.log('This lang already exists in array');
                    return false;
                }
            }
            arr = [...langData];
        }

        arr.push(event.value);
        setLangData(arr);
    }

    const onSubmitForm = (data) => {

        const formData = new FormData();

        if (!props.isEdit) {
            for (let i = 0; i < files.length; i++) {
                formData.append(`formFiles`, files[i])
            }
        }

        if (showLangSelect && langData && langData.length > 0) {
            langData.forEach((x, m) => {
                formData.append('LangsCode[' + m + ']', x);
                m++;
            })
        }

        formData.append("noteInternal", data.noteInternal);
        formData.append("notePublic", data.notePublic);
        formData.append("originalFileName", data.originalFileName);
        formData.append("isPublic", isPublic);
        formData.append("eshop", eshop);
        formData.append("refId", props.refid);

        if (props.isEdit) {
            formData.append("id", data.id);
            formData.append("mongoDbId", data.mongoDbId);
        }


        if (props.isEdit) {
            axios.put("/azureblob/" + props.selectedItem, formData, {'Content-Type': 'multipart/form-data'}).then(res => {

                if (res.data.state === 0) {
                    alertDispatch({
                        type: ADD,
                        payload: {
                            content: {state: 'success', message: 'Fotka byla aktualizována.'}
                        }
                    });
                    props.onClose();
                }  else {
                    alertDispatch({
                        type: ADD,
                        payload: {
                            content: {
                                state: 'warning',
                                message: 'Aktualizace fotky se nezdařila.'
                            }
                        }
                    });
                }

            }).catch(() => {
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {
                            state: 'warning',
                            message: 'Aktualizace se nezdařila'
                        }
                    }
                });

            }).finally(() => {

            });
        } else {
            axios.post("/azureblob", formData, {'Content-Type': 'multipart/form-data'}).then(res => {
                if (res.data.state === 0) {
                    alertDispatch({
                        type: ADD,
                        payload: {
                            content: {
                                state: 'success',
                                message: 'Fotka byl úspěšně nahrán na sharepoint případně Microsoft BLOB pokud byla označena jako veřejná.'
                            }
                        }
                    });
                    props.onClose();
                }  else {
                    alertDispatch({
                        type: ADD,
                        payload: {
                            content: {
                                state: 'warning',
                                message: 'Nahrání se nezdařilo.'
                            }
                        }
                    });
                }

            }).catch(() => {
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {
                            state: 'warning',
                            message: 'Nahrání se nezdařilo.'
                        }
                    }
                });
            }).finally(() => {

            });
        }


    }

    const printDebug = () => {
        console.log("data:");
        console.log(shrpObject);
    }

    return (
        <Container className={"addDocument"}>
            <Row>

                {props.isEdit ?
                    <Col as={Row}><h1>{(t('photos.edit'))}</h1>{!editLoaded ? <OurLoading/> : null}</Col>

                    : <Col><h1>{t('photos.add')}</h1></Col>

                }


            </Row>
            {editLoaded ?

                <Row>
                    <Form style={{width: "100%"}}>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>{t('photos.azure.public')}</Form.Label>
                                <Select
                                    isDisabled={true}
                                    name='isPublic'
                                    options={[{value: false, label: t('photos.azure.no')}, {value: true, label: t('photos.azure.yes')}]}
                                    defaultValue={isPublic ? {value: true, label: t('photos.azure.yes')} : {value: false, label: t('photos.azure.no')}}
                                    onChange={(e) => {
                                        setIsPublic(e.value)
                                    }}
                                />
                            </Form.Group>                            
                            <Form.Group as={Col}>
                                <Form.Label>{t('photos.azure.eshop')}</Form.Label>
                                <Select
                                    name='eshop'
                                    options={[{value: false, label: t('photos.azure.no')}, {value: true, label: t('photos.azure.yes')}]}
                                    defaultValue={eshop ? {value: true, label: t('photos.azure.yes')} : {value: false, label: t('photos.azure.no')}}
                                    onChange={(e) => {
                                        setEshop(e.value)
                                    }}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className={'mt-2'}>
                            <Form.Group as={Col}>

                                <FormCheck label={t('photos.azure.selectLangs')} onChange={() => setShowLangSelect(!showLangSelect)} />
                            </Form.Group>
                        </Form.Row>

                        {showLangSelect &&
                            <Form.Row className={'mt-2'}>
                                <Form.Group as={Col}>
                                    <Select
                                        options={langs}
                                        onChange={(e) => {
                                            handleOptionsLangsClick(e)
                                        }}
                                    />
                                </Form.Group>
                            </Form.Row>
                        }
                        {langData && langData.length > 0 ?

                            <Form.Row>
                                <div className={'cont'}>
                                    {
                                        langData.map((x, i) => {
                                            return (
                                                <div className={'item'} key={i}>
                                                    <span className="count">{i + 1}.</span>
                                                    <div className="item2">
                                                        <span onClick={() => {
                                                            handleLangsItemsClikc(i)
                                                        }}>
                                                            <FontAwesomeIcon className="i" icon={faWindowClose}/> 
                                                        </span>
                                                        <span>{x} - {langs.filter(f => f.value === x)[0].label}</span>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </Form.Row>
                            : null
                        }
                        <Form.Row className={'mt-2'}>
                            <Form.Group as={Col}>
                                <Form.Label>{t('photos.azure.internalNote')}</Form.Label>
                                <Form.Control name={"noteInternal"} ref={register()} maxLength={5000} as="textarea"
                                              rows={5}/>
                            </Form.Group>
                        </Form.Row>
                        {
                            props.isEdit ?
                                null
                                :

                                <Form.Row className={'mt-2'}>
                                    <Form.Group as={Col} controlId="formFile">
                                        <Form.Label>{t('photos.azure.selectPhotos')}</Form.Label>
                                        <Form.Control type="file" name={"file"} ref={register({required: true})} multiple="multiple" onChange={handleUploadFile}/>
                                        <Form.Text>
                                            {errors.file && errors.file.type === 'required' &&
                                            <InpurtError message={t("validation.required")} showMessage={true}/>}
                                        </Form.Text>
                                    </Form.Group>
                                </Form.Row>
                        }

                        {!IsReadOnly(user) ?

                        <Form.Row className={"mt-3"}>

                            {props.isEdit ?
                                <React.Fragment>
                                    <Button variant="success" type="button" onClick={handleSubmit(onSubmitForm)}>
                                        <FontAwesomeIcon className="i" icon={faSave}/>
                                        {t('photos.azure.update')}
                                    </Button>
                                </React.Fragment>
                                :
                                <Button variant="success" type="button" onClick={handleSubmit(onSubmitForm)}>
                                    <FontAwesomeIcon className="i" icon={faSave}/>
                                    {t('photos.azure.upload')}
                                </Button>
                            }
                        </Form.Row> : null }


                    </Form>


                </Row>


                : null}

            {
                props.isEdit && editLoaded ?
                    IsAdminOnly(user) ?
                        <Row style={{marginTop: 50}}><Button variant="outline-secondary" size="sm" onClick={() => {
                            printDebug()
                        }}>log</Button></Row>
                        : null
                    : null
            }


        </Container>
    );

}

export default AddPhoto;