import React from 'react';
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import InpurtError from "../InputError";
import axios from "axios";
import {ADD, useAlertContext} from "../../contexts/AlertContext";

const Report = (props) => {

    const {t} = useTranslation();
    const {register, handleSubmit, errors} = useForm();
    const {alertDispatch} = useAlertContext();

    const onSubmitForm = (data) => {
        axios.post('/report/send', data).then((res) => {
            if (res.data.state === 0) {
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {state: 'success', message: 'Formulář byl úspěšně odeslán'}
                    }
                });
                props.onClose();
            }  else {
                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {
                            state: 'warning',
                            message: 'Došlo k chybě při odesílání formuláře'
                        }
                    }
                });
                console.log(res);
            }
        }).catch(err => {
            alertDispatch({
                type: ADD,
                payload: {
                    content: {
                        state: 'warning',
                        message: 'Došlo k chybě při odesílání formuláře'
                    }
                }
            });
            console.log(err);
            }
        );
    }
    
    return(
        <React.Fragment>
            <Container>
                <Row className={"p-3"}>
                    <h1>{t('report.title')}</h1>
                </Row>
                <Row className={"p-3"}>
                    <p>Odešlete nám formulář s chybou či dotazem a nebo nám napište přímo na  <b>podpora@system4m.cz</b></p>
                </Row>
                <Row className={"p-3"}>
                    <Form style={{width: "100%"}} >
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>{t('report.reportName')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    ref={register({required: true})}
                                    maxLength={200}
                                />
                                <Form.Text>
                                    {errors.name && errors.name.type === 'required' &&
                                    <InpurtError message={t("validation.required")} showMessage={true}/>}
                                </Form.Text>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>{t('report.description')}</Form.Label>
                                <Form.Control name={"desc"}  ref={register()} maxLength={5000} as="textarea" rows={5} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className={"mt-3"}>
                            <Button variant="success" type="button" onClick={handleSubmit(onSubmitForm)}>
                                <FontAwesomeIcon className="i" icon={faSave}/>
                                {t('report.submit')}
                            </Button>
                        </Form.Row>
                    </Form>
                </Row>
            </Container>
        </React.Fragment>
    )
    
}

export default Report;