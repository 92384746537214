import React, {useState, useEffect} from "react";
import axios from 'axios';
import {useTranslation} from "react-i18next";
import {ADD, useAlertContext} from "../../contexts/AlertContext";
import {useForm} from "react-hook-form";
import InpurtError from "../InputError";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ErpEdit = ({id, onFinished}) => {

    const {t} = useTranslation();
    const {alertDispatch} = useAlertContext();

    const [loaded, setLoaded] = useState(false);

    const {register, handleSubmit, errors, setValue} = useForm({
        defaultValues: {
            name: ''
        }
    });

    const onSubmit = (data, event) => {

        event.preventDefault();

        axios.post('/erp/' + id + '/edit', data).then(res => {
            alertDispatch({
                type: ADD,
                payload: {
                    content: {state: 'success', message: 'Položka byla upravena'}
                }
            });

            onFinished();

        }).catch(res => {
        });


    }

    useEffect(() => {

        const load = () => {
            axios.get('/erp/get/' + id).then(res => {
                setValue('name', res.data.name);
            }).catch(err => {
            });
        }

        if (!loaded) {
            load();
            setLoaded(true);
        }

    }, [loaded, id, setValue]);


    return (
        <div>
            <h1>{t("erpEdit.title")}</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="item-row">
                    <label>Název ERP</label>
                    <div className="item-group">
                        <input type="text" name="name" ref={register({required: true})}/>
                        {errors.name && errors.name.type === 'required' &&
                        <InpurtError message={t("validation.required")}></InpurtError>}
                    </div>

                </div>
                <div className="row">
                    <button type="submit" className="btn-submit-blue"><FontAwesomeIcon icon={faSave}/> Uložit ERP</button>
                </div>
            </form>
        </div>
    )

}

export default ErpEdit