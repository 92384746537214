import {BehaviorSubject} from 'rxjs';
import axios from 'axios';
import {useHistory} from "react-router-dom";

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
//const history = useHistory();

export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() {
        return currentUserSubject.value
    }
};


function login(username, password) {

    return axios.post('/user/authenticate', {username, password })
        .then(user => {
            if(user.status === 200){
                let json = JSON.stringify(user);
                localStorage.setItem('currentUser', json);
                currentUserSubject.next(user);

                return user;
            } else{

                return false;
            }

        }).catch(err=>{
            let history = useHistory();
            console.log(err);
            history.push('/login')
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}

