import React, {useEffect, useState} from 'react';
import NavMenu from './shared/menu/NavMenu';
import {UserContextProvider} from "../contexts/UserContext";
import {authenticationService} from "../helpers/AuthenticationService";
import Login from "./Login";
import {Container, Row} from "react-bootstrap";
import axios from "axios";

export const Layout = (props) => {

    const [currentUser, setCurrentUser] = useState(false);

    useEffect(() => {
        
        
        axios.get('/user/check');
        
        
        authenticationService.currentUser.subscribe(x => setCurrentUser(x));


    }, [setCurrentUser]);

    return (
        <div>
          
            {currentUser &&

            <UserContextProvider data={currentUser.data}>
                

                <NavMenu/>
                <div className="content">
                    {props.children}
                </div>
            </UserContextProvider>
            }
            
            

            {window.location.href.includes('password-recovery') &&
            <React.Fragment>
                <div className="content">
                    {props.children}
                </div>
            </React.Fragment>
            }
            
            {(!currentUser && !window.location.href.includes('password-recovery')) &&
                 <Container 
                     fluid={true}
                     style={{
                         backgroundImage: "url(" + "/img/loginBG2.svg" + ")",
                         backgroundPosition: 'center',
                         backgroundSize: 'cover',
                         backgroundRepeat: 'no-repeat',
                         height: '100%'
                     }}
                 >
                     <Row className={'align-items-center'}>
                         <Login/>
                     </Row>
                 </Container>
            }
        </div>
    );
}
