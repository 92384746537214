import React, {useEffect, useState} from 'react';
import axios from 'axios';
import DataTable, {createTheme} from 'react-data-table-component';
import 'react-overlay-loader/styles.css';
import Modal from "../shared/modal/Modal";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-regular-svg-icons";
import UserUpdate from "./UserUpdate";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import theme1 from "../../config/dataTable/theme1";
import theme1Styles from "../../config/dataTable/theme1Styles";

const User = () => {

    const {t} = useTranslation();

    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState([]);
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(0);
    const columns = (clickHandler => [
        {
            name: 'Id',
            selector: 'id',
            sortable: true,
        },
        {
            name: 'Active',
            selector: 'active',
            sortable: true
        },
        {
            name: 'Username',
            selector: 'username',
            sortable: true,
        },
        {
            name: 'Role',
            selector: 'role',
            sortable: true
        },
        {
            cell: (row) => <button className={"btn-edit"} onClick={clickHandler} id={row.id}><FontAwesomeIcon onClick={clickHandler} id={row.id}
                icon={faEdit}/> Upravit</button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ]);

    const [pagination] = useState({
        //rowsPerPageText: 'Záznamů na stránku:',
        rangeSeparatorText: 'z',
        noRowsPerPage: false,
       
    });

    const handleButtonClick = (state) => {
        setSelectedId(state.target.id);
        setModalEditOpen(true);
    };

    const handleAddUserClick = () => {
        setSelectedId(0);
        setModalEditOpen(true);
    };

    useEffect(() => {
        
        
        if (!loaded) {
            axios.get('/user/all').then(res => {
                setData(res.data);
                setLoaded(true);
            });
        }
    });

    createTheme('theme1', theme1);
    
   

    return (
        <div>
            <div className="subMenu">
                
                <Button onClick={() => handleAddUserClick() } variant="primary"><FontAwesomeIcon icon={faPlus}/> {t("users.addUser")}</Button>
            </div>


            <DataTable
                title="Seznam uživatelů"

                data={data}
                columns={columns(handleButtonClick)}
                paginationComponentOptions={pagination}
                pagination
                paginationPerPage={50} 
                paginationRowsPerPageOptions={[10, 30, 50, 100]} 
                dense
                theme="theme1"
                customStyles={theme1Styles}
            />

           
            <Modal  isOpen={modalEditOpen} onClose={() => {setModalEditOpen(false)}}>
                <UserUpdate userId={selectedId} isAdmin={true} refreshParent={() => {setLoaded(false)}} closeModal={() => {setModalEditOpen(false)}} edit={true} />
            </Modal>
        </div>
    )
}

export default User;
