import {Alert, Button, ButtonGroup, Col, Container, Form, Row, Spinner, ToggleButton} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartPie, faChevronLeft, faShoppingBasket} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import Select from "react-select";
import axios from "axios";
import "./ReportForecastStocks.scss";
import LabelInfo from "../../LabelInfo";
import {ForecastStocksCont} from "./ForecastStocksCont";
import CatalogFilterCategoriesEso from "../../catalog/CatalogFilterCategoriesEso";
import Modal from "../../shared/modal/Modal";
import {ForecastBasket} from "./ForecastBasket";
import {faFilter} from "@fortawesome/free-solid-svg-icons/faFilter";
import {faSortAmountDown} from "@fortawesome/free-solid-svg-icons/faSortAmountDown";
import {ForecastStocksContTable} from "./ForecastStockContTable";

export const ReportForecastStocks = () => {
    const [filterBasket, setFilterBasket] = useState({
        supplyPlanner: "All",
        supplier: "All",
        supplierName: "All",
        supplierId: 0
    });
    const [supplyPlannersBasket, setSupplyPlannersBasket] = useState([]);
    const [priorita, setPriorita] = useState({
        "label": "All",
        "value": "0"
    });
    const [suppliersBasket, setSuppliersBasket] = useState([]);
    const [blokaceNula, setBlokaceNula] = useState(false);
    const [eBezBlokace, setEbezBlokace] = useState(false);
    const [nobVysNula, setNobVysNula] = useState(true);
    const [searchByCat, setSearchByCat] = useState(false);
    const [isBasketOpen, setIsBasketOpen] = useState(false);
    const [basket, setBasket] = useState([]);
    const [basketLoaded, setBasketLoaded] = useState(false);
    const [categories, setCategories] = useState({cat1:0, cat2:0, cat3:0, cat4:0});
    const [loaded, setLoaded] = useState(false);
    const [typeValue, setTypeValue] = useState(1);
    const [supplier, setSupplier] = useState({regionErpCode: "0", regionErpName: "All" });
    const [supplyPlanner, setSupplyPlanner] = useState({value: "0", label: "All" });
    const [allItems, setAllItems] = useState(true);
    const [criticalItems, setCriticalItems] = useState(true);
    const [outageItems, setOutageItems] = useState(false);
    const [supplierOptions, setSupplierOptions] = useState([{regionErpCode: "0", regionErpName: "All" }]);
    const [ref, setRef] = useState("");
    const [stockQ, setStockQ] = useState("");
    const [stockQorders, setStockQorders] = useState("");
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [take, setTake] = useState(0);
    const [basketSummary, setBasketSummary] = useState({pocetPolozek: 0, celkovyObjem: 0, celkovaCena: 0});

    const [rand, setRand] = useState(0);
    
    const [priorities, setPriorities] = useState([
        
        {
            "label": "All",
            "value": "0"
        },
        {
            "label": "TOP",
            "value": "TOP"
        },
        {
            "label": "A",
            "value": "A"
        },
        {
            "label": "AE",
            "value": "AE"
        },
        {
            "label": "B",
            "value": "B"
        },
        {
            "label": "BE",
            "value": "BE"
        },
        {
            "label": "C",
            "value": "C"
        },
        {
            "label": "D",
            "value": "D"
        },
        {
            "label": "E",
            "value": "E"
        },
        {
            "label": "OUT",
            "value": "OUT"
        }

       
    ])
    const [states, setStates] = useState([
        {
            "label": "rozpracovaný",
            "value": "-2"
        },
        {
            "label": "přípravný",
            "value": "-1"
        },
        {
            "label": "aktivní",
            "value": "0"
        },
        {
            "label": "neaktivní",
            "value": "1"
        },
        {
            "label": "výprodej",
            "value": "2"
        },
        {
            "label": "k vyřazení",
            "value": "3"
        }
    ]);
    const [stateFilter, setStateFilter] = useState([ {
        "label": "přípravný",
        "value": "-1"
    },
        {
            "label": "aktivní",
            "value": "0"
        },]);
    const [numberInBasket, setNumberInBasket] = useState(0);
    const [addWholeFilterResponse, setAddWholeFilterResponse] = useState(null);
    
    
    
    useEffect(() => {
        if(!loaded){
            axios
                .get('api/suppliers/filter?regionId=1&name='   )
                .then((res) => {
                    setSupplierOptions(res.data.items);
                    setLoaded(true);
                })
                .catch(err => {
                    console.log(err);

                });
        }
        
    }, [loaded])
    
    const resetFilter = () => {
        setFilterBasket({
            supplyPlanner: "All",
            supplier: "",
            supplierName: "All",
            supplierId: 0
        })
    }


    useEffect(() => {
        if(!basketLoaded){
            

            let params = "?";
            params += "&supplierCode=" + (filterBasket.supplierName === "All" ? "" : filterBasket.supplier);
            params += "&supplyPlanner=" + (filterBasket.supplyPlanner === "All" ? "" : filterBasket.supplyPlanner);
            
            axios
                .get('api/reports/forecast/getBasket' + params   )
                .then((res) => {
                    setBasket(res.data.items);
                    res.data.supplyPlanners.unshift({ kodUzivPlanoNemedAdd: "All"});
                    setSupplyPlannersBasket(res.data.supplyPlanners);
                    //res.data.suppliers.unshift({ subjNazev: "All", kodSubjektu: "All", idSubjekt:0});
                    setSuppliersBasket(res.data.suppliers);
                    setNumberInBasket(res.data.items.length);
                    setBasketLoaded(true);
                    setBasketSummary({pocetPolozek: res.data.pocetPolozek, celkovaCena: res.data.celkovaCena, celkovyObjem: res.data.celkovyObjem})
                    
                })
                .catch(err => {
                    console.log(err);

                });
        }

    }, [basketLoaded])

    const filterHandlerBasket = (filter) => {
      
        setFilterBasket(filter);

        
    }
    
    useEffect(() => {
        refreshBasket();
    }, [filterBasket])
    
    const refreshBasket = () => {
        if(basketLoaded === true){
            setBasketLoaded(false);
        }
    }

    /*const processSupplyPBasket = (source) => {
        let arr = [];
        arr.push("All");
        for (const item of source) {
            let isThere = arr.filter(f => f === item.kodUzivPlanoNemedAdd);
            if(isThere.length === 0){
                arr.push(item.kodUzivPlanoNemedAdd ?? "");
            }
          


        }
        setSupplyPlannersBasket(arr);


    }*/

    /*const processSuppliersBasket = (source) => {
        let arr = [];
        let supplierCode;
        let supplierName;
        let supplierId;
        arr.push({ label: "All", value: "", id:0});
        for (const item of source) {
            
             supplierCode = item.kodSubjektu ?? "";
             supplierName = item.subjNazev ?? "";
             supplierId = item.idSubjekt ?? 0;
            let isThere = arr.filter(f => f.value === item.kodSubjektu);
            if(isThere.length === 0){
                arr.push({label: supplierName, value: supplierCode, id: supplierId});
            }
          


        }
        setSuppliersBasket(arr);


    }*/
    
    
    
   

    /*const types = [
        { name: 'Only confirmed orders', value: 1 },
        { name: 'All orders', value: 0 }
        
    ];
    
    
    const checkAllItemsHandler = (val) => {
        
        if(val){
            setCriticalItems(false);
            setOutageItems(false);
            
            
        } else {
            setOutageItems(true);

        }
        setAllItems(val);

    }*/

    const supplyPlanners = [
        {label: "All", value: "0"},
        {label: "konl", value: "konl"},
        {label: "mertlin", value: "mertlin"},
        {label: "dolm", value: "dolm"},
        {label: "medj", value: "medj"},
        {label: "pohs", value: "pohs"},
        {label: "pinm", value: "pinm"},
        {label: "help", value: "help"},
        {label: "barp", value: "barp"},
        {label: "haji", value: "haji"},
        {label: "lanl", value: "lanl"}
        

    ];
    
    const createFilterParams = () => {
        let params = "?";
        params += "confirmedOrders=" + typeValue.toString();
        params += "&supplierCode=" + (supplier.regionErpCode === "0" ? "" : supplier.regionErpCode.toString());
        params += "&supplyPlanner=" + (supplyPlanner.value === "0" ? "" : supplyPlanner.value.toString());
        params += "&referenceCode=" + (ref ? ref : "");
        params += "&outageItems=" + (outageItems ? "1" : "0");
        params += "&criticalItems=" + (criticalItems ? "1" : "0");
        params += "&cat1=" + (searchByCat ? categories.cat1 : 0);
        params += "&cat2=" + (searchByCat ? categories.cat2 : 0);
        params += "&cat3=" + (searchByCat ? categories.cat3 : 0);
        params += "&cat4=" + (searchByCat ? categories.cat4 : 0);
        params += "&stockQ=" + stockQ ;
        params += "&stockQorders=" + stockQorders ;
        params += "&priorita=" + (priorita.value === "0" ? "" : priorita.value);
        params += "&eBezBlokace=" + (eBezBlokace ? "1" : "0");
        params += "&blokaceNula=" + (blokaceNula ? "1" : "0");
        params += "&nobVysNula=" + (nobVysNula ? "1" : "0");

        if(stateFilter.length > 0)
        {
            for (let i = 0; i < stateFilter.length; i++) {
                params += "&states=" + stateFilter[i].value;
            }

        }

        params += "&page=" + page ;
        params += "&take=" + take ;
        
        return params;
    }
    
   
    const filterHandler = (page) => {
        
        setAddWholeFilterResponse(null);
        

        axios.get('api/reports/forecast/stocks' +  createFilterParams()).then((res) => {
            setData(res.data);
            setRand(Math.random());
        });

    }
    
    const filterToBasketHandler = () => {
        if(window.confirm('Opravdu přidat ' + data.length + ' položek do košíku?')){
            axios.get('api/reports/forecast/addWholeFilterToBasket' +  createFilterParams()).then((res) => {
                setAddWholeFilterResponse(res.data);
                refreshBasket();

            });
        }
       
    }
    
  
    
    return(
        <React.Fragment>
        <Container className="reports reportForecastStocks">
            <Row className="p-1">
                <Link  to="/reports"><Button variant="outline-primary"><FontAwesomeIcon className="i" icon={faChevronLeft}/>Back</Button></Link>

            </Row>
            <Row className="p-1">
                <h1><FontAwesomeIcon className="i" icon={faChartPie}/>Report forecast stocks</h1>


            </Row>
            <Row >
                <Form style={{width: '100%'}} className={"cBG_BS_light p-4 rounded border"}>
                    <Form.Row>
                  
                    
                        <Col>
                            <Form.Row>
                                <div style={{width: 320}} className={"p-2"}>
                                    <label>Supplier</label>
                                    <div className="flex-row align-items-center d-flex" >
                                        <div style={{minWidth: 280}} className="mr-2">

                                            <Select
                                                isLoading={!loaded}
                                                options={supplierOptions}
                                                getOptionLabel={(option)=>option.regionErpName}
                                                getOptionValue ={(option)=>option.regionErpCode}
                                                value={supplier}
                                                onChange={(e) => setSupplier({regionErpCode: e.regionErpCode, regionErpName: e.regionErpName })} />


                                        </div>
                                        <Button variant="outline-primary"  onClick={() => setSupplier({regionErpCode: "0", regionErpName: "All" })} >All</Button>
                                    </div>
                                </div>
                            </Form.Row>

                            <Form.Row >
                                <div style={{width: 320}} className={"p-2"}>
                                    <label>Supply planner</label>
                                    <div className="flex-row align-items-center d-flex" >
                                        <div style={{minWidth: 280}} className="mr-2">
                                            <Select  options={supplyPlanners} value={supplyPlanner} onChange={(e) => setSupplyPlanner({value: e.value, label: e.label })} />


                                        </div>
                                        <Button variant="outline-primary" onClick={() => setSupplyPlanner({value: "0", label: "All" })} >All</Button>
                                    </div>
                                </div>
                            </Form.Row>

                            <Form.Row >
                                <div style={{width: 320}} className={"p-2"}>
                                    <label>Priorita</label>
                                    <div className="flex-row align-items-center d-flex" >
                                        <div style={{minWidth: 280}} className="mr-2">
                                            <Select  options={priorities} value={priorita} onChange={(e) => setPriorita({value: e.value, label: e.label })}  />


                                        </div>
                                        <Button variant="outline-primary" onClick={() => setPriorita({value: "0", label: "All" })}  >All</Button>
                                    </div>
                                </div>
                                
                            </Form.Row>

                           

                            <Form.Row>
                                <div style={{width: 320}} className={"p-2"}>
                                    <label>Reference code</label>
                                    <div className="flex-row align-items-center d-flex" >
                                        <div style={{minWidth: 280}} className="mr-2">
                                            <Form.Control
                                                aria-label="Default"
                                                aria-describedby="inputGroup-sizing-default"
                                                type="text"
                                                value={ref}
                                                onChange={(e) => setRef(e.target.value)}
                                                placeholder="All"
                                            />
                                        </div>
                                        <Button variant="outline-primary" onClick={() => setRef("")} >All</Button>
                                    </div>
                                </div>
                            </Form.Row>
                            <Form.Row>
                                <div style={{width: 330}} className={"p-2"}>
                                    <Form.Label>State</Form.Label>
                                    <div className="flex-row align-items-center d-flex" >
                                        <div style={{minWidth: 330}} className="mr-2">
                                            <Select
                                                defaultValue={stateFilter}
                                                isMulti
                                                options={states}
                                                placeholder="All"
                                                onChange={(e) => {
                                                    e == null ? setStateFilter([]) : setStateFilter(e); 
                                                }}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </Form.Row>
                            <Form.Row>
                                <div style={{width: 330}} className={"p-2"}>
                                    <Form.Label>Stock q. in months  </Form.Label>
                                    <div className="flex-row align-items-center d-flex" >
                                        <div style={{minWidth: 280}} className="mr-2">
                                        <Form.Control
                                            aria-label="Default"
                                            aria-describedby="inputGroup-sizing-default"
                                            type="text"
                                            value={stockQ}
                                            onChange={(e) => setStockQ(e.target.value)}
                                            placeholder="=<"
                                        />
                                        </div>
                                        <Button variant="outline-primary" onClick={() => setStockQ("")} >All</Button>
                                    </div>
                                    
                                </div>
                            </Form.Row>
                            <Form.Row>
                                <div style={{width: 330}} className={"p-2"}>
                                    <Form.Label>Stock q. in months with orders  </Form.Label>
                                    <div className="flex-row align-items-center d-flex" >
                                        <div style={{minWidth: 280}} className="mr-2">
                                            <Form.Control
                                                aria-label="Default"
                                                aria-describedby="inputGroup-sizing-default"
                                                type="text"
                                                value={stockQorders}
                                                onChange={(e) => setStockQorders(e.target.value)}
                                                placeholder="=<"
                                            />
                                        </div>
                                        <Button variant="outline-primary" onClick={() => setStockQ("")} >All</Button>
                                    </div>

                                </div>
                            </Form.Row>




                            {/*<Form.Row className="p-1">
                                <Form.Check checked={allItems} onChange={(e) => checkAllItemsHandler(e.target.checked)} className="big-checkbox" type="checkbox" label="All items"  />
                                <LabelInfo iconText="All items regardless wether stock is critical or there will be an outage in the near future"  />
                            </Form.Row>

                            {!allItems &&

                                <React.Fragment>
                                    <Form.Row className="p-1">
                                        <Form.Check checked={outageItems} onChange={() => setOutageItems(!outageItems)} className="big-checkbox" type="checkbox" label="Outage items"  />
                                        <LabelInfo iconText="Only items where there will be an outage in the near future"  />

                                    </Form.Row>
                                    <Form.Row className="p-1">
                                        <Form.Check checked={criticalItems} onChange={() => setCriticalItems(!criticalItems)} className="big-checkbox" type="checkbox" label="Critical items"  />
                                        <LabelInfo iconText="Only items where there will be a shortage in the future"  />
                                    </Form.Row>
                                </React.Fragment>
                            }*/}
                            
                        </Col>
                        <Col>
                            <Form.Row className={"p-2"}>
                                <Form.Group as={Col}>
                                    <Form.Check
                                        style={{zIndex: 0}}
                                        className={"pb-0"}
                                        type="switch"
                                        id="searchByCat"
                                        label="Search by category"
                                        value={searchByCat}
                                        name={"searchByCat"}
                                        onChange={() => {
                                            setSearchByCat(!searchByCat)
                                        }}
                                    />


                                </Form.Group>

                            </Form.Row>

                            {searchByCat ? <CatalogFilterCategoriesEso setCategories={(val) => setCategories(val)} /> : null}

                            <Form.Row className="p-2">
                                <Form.Check checked={eBezBlokace} onClick={() => setEbezBlokace(!eBezBlokace)}  className="big-checkbox" type="checkbox" label="Nezobrazovat priority E bez blokaci
"  />
                            </Form.Row>

                            <Form.Row className="p-2">
                                <Form.Check checked={blokaceNula} onClick={() => setBlokaceNula(!blokaceNula)}  className="big-checkbox" type="checkbox" label="Pouze blokace vyšší než 0
"  />
                            </Form.Row>

                            <Form.Row className="p-2">
                                <Form.Check disabled={true} checked={false}  className="big-checkbox" type="checkbox" label="Pouze kde je zadán ruční požadavek
"  />

                            </Form.Row>

                            <Form.Row className="p-2">
                                <Form.Check  checked={nobVysNula} onClick={() => setNobVysNula(!nobVysNula)}  className="big-checkbox" type="checkbox" label="Pouze NOB vyšší než nula
"  />

                            </Form.Row>

                            <Form.Row className={"p-2 mt-4"}>
                                <Col>
                                    <Form.Check
                                        checked={!criticalItems && !outageItems }
                                        onClick={() => {
                                            setCriticalItems(false);
                                            setOutageItems(false);
                                        }}

                                        className="big-checkbox" type="checkbox" label="Vše"
                                    />

                                    <Form.Check
                                        onClick={() => {
                                            setOutageItems(!outageItems);

                                        }}
                                        checked={outageItems }

                                        className="big-checkbox" type="checkbox" label="Výpadek"
                                    />

                                    <Form.Check
                                        onClick={() => {
                                            setCriticalItems(!criticalItems);
                                        }}
                                        checked={criticalItems}

                                        className="big-checkbox" type="checkbox" label="Kritické"
                                    />
                                </Col>
                                
                            </Form.Row>

                            {/*<Form.Row className={"p-2 mt-4"}>
                                <ButtonGroup>
                                    <ToggleButton
                                        key={1}
                                        id={`radio-1`}
                                        type="checkbox"
                                        variant={1 % 2 ? 'outline-primary' : 'outline-primary'}
                                        name="radio"
                                        value={1}
                                        onClick={() => {
                                            setCriticalItems(false);
                                            setOutageItems(false);
                                        }}
                                        checked={!criticalItems && !outageItems }

                                    >
                                        Vše
                                    </ToggleButton>
                                    <ToggleButton
                                        key={2}
                                        id={`radio-2`}
                                        type="checkbox"
                                        variant={2 % 2 ? 'outline-primary' : 'outline-primary'}
                                        name="radio"
                                        value={2}
                                        onClick={() => {
                                            setOutageItems(true);
                                            setCriticalItems(false);
                                        }}
                                        checked={outageItems }

                                    >
                                        Výpadek
                                    </ToggleButton>
                                    <ToggleButton
                                        key={3}
                                        id={`radio-3`}
                                        type="checkbox"
                                        variant={3 % 2 ? 'outline-primary' : 'outline-primary'}
                                        name="radio"
                                        value={3}
                                        onClick={() => {
                                            setOutageItems(false);
                                            setCriticalItems(true);
                                        }}
                                        checked={criticalItems}

                                    >
                                        Kritické
                                    </ToggleButton>
                                </ButtonGroup>
                            </Form.Row>*/}
                            
                        </Col>
                    </Form.Row>

                       
                 
                    
                    

                    <Form.Row className="mt-4">
                        <Button variant={"primary"}  className={"btn-lg"} onClick={() => filterHandler(1)}>
                            <FontAwesomeIcon icon={faFilter} className="i" />
                            Filter
                        </Button>
                    </Form.Row>
                </Form>

            </Row>
            <Row>
                <Alert onClick={() => setIsBasketOpen(true)} variant="warning" className="w-100 mt-2" style={{cursor: "pointer", minHeight: 60}}>
                    {basketLoaded ?
                        <div className="d-flex">
                            <div className="d-flex" style={{fontSize: 22, marginRight: 20, alignItems: 'center'}}> 
                                <div>
                                <FontAwesomeIcon className={"i"} icon={faShoppingBasket}/>
                                    Košík
                                </div>
                            </div>
                                <div className="d-flex" style={{alignItems: 'center'}}>
                                    <div className="m-1"><span className="mr-1 d-inline-block">Položek:</span><span className="text-primary">{numberInBasket}</span></div> 
                                    <div className="m-1"><span className="mr-1 d-inline-block">Supply planner:</span><span className="text-primary">{filterBasket.supplyPlanner}</span></div>
                                    <div className="m-1"><span className="mr-1 d-inline-block">Supplier:</span><span className="text-primary">{filterBasket.supplierName}</span></div>
                                </div>
                            
                        </div>

                        :
                        <Spinner animation="border" role="status">
                            
                        </Spinner>}
                    
                    
                </Alert>
            </Row>
            {addWholeFilterResponse !== null ?
                <Row>
                <Alert variant="success"  className="w-100">
                        <div className="d-flex">
                            <div className="m-1">
                                <span className="mr-1 d-inline-block">Do košíku bylo úspešně přidáno</span>
                                <span className="text-primary mr-1">{addWholeFilterResponse.success.length}</span>
                                <span className="mr-1 d-inline-block">z celkem</span>
                                <span className="text-primary mr-1">{addWholeFilterResponse.total}</span>
                                <span className="mr-1 d-inline-block">položek. ({addWholeFilterResponse.fail.length} chyb)</span>
                            </div>

                        </div>

                </Alert>
                </Row>
                : null}
        </Container>
        <Container  >
            {data.length > 0 && (supplier.regionErpCode !== '0' || supplyPlanner.value !== '0') && addWholeFilterResponse === null  ?
                <Row  >
                    <Button variant="outline-primary" onClick={() => filterToBasketHandler()}><FontAwesomeIcon icon={faSortAmountDown} className="i" /> Vše vyfiltrované ({data.length} položek) přidat do košíku</Button>
                </Row>
            
            : null}
            
        </Container>
        <Container fluid>
            <Row className='pt-2 pb-2 ps-3 pe-3'>
            {data.length > 0 ? <ForecastStocksContTable key={rand} page={page}  setPage={(n) => setPage(n)} take={take} filter={(skip) => filterHandler(skip)} data={data} refreshBasket={() => refreshBasket()} /> : null }
            </Row>
        </Container>
        <Modal isOpen={isBasketOpen} onClose={() => {setIsBasketOpen(false)}}>
            <ForecastBasket resetFilter={() => resetFilter()} summary={basketSummary}  basket={basket} suppliers={suppliersBasket} supplyPlanners={supplyPlannersBasket} filter={filterBasket} filterHandler={(filter) => filterHandlerBasket(filter)} refreshBasket={() => refreshBasket()} basketLoaded={basketLoaded}/>
        </Modal>

           
        </React.Fragment>
    )
}

