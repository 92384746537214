import {Col, OverlayTrigger, Popover, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import Badge from "react-bootstrap/Badge";


const PopOverCz = (props) => {
    const region = props.region;
    const productRegion = props.productRegion ? props.productRegion[0] : null;

    const popover = (props) => {

        return(


            <Popover id={"popover-basic-" + props.data.CIS_ZBOZI ?? null}  style={{maxWidth: 300, width: 300}}>
                <Popover.Title as="h3">{region} detail info </Popover.Title> 
                
                
                <Popover.Content style={{maxWidth: '100%'}}>
                    {productRegion ?
                        <Row>
                            <Col xs={12} >
                                <div >
                                    <Badge variant="info">ERP name</Badge>
                                    <div>{productRegion.erpData.regionErpName}</div>
                                </div>
                                

                            </Col>
                        </Row>
                    : null}
                    
                    <Row>
                        <Col xs={4} >
                            <div >
                                <Badge variant="info">Actual stock</Badge>
                                <div>{props.data.MNFYZICKYSTAV_SUM}</div>
                            </div>
                            <div >
                                <Badge variant="info">Free</Badge>
                                <div>{props.data.MNFYZVOLNYSTAV_SUM}</div>
                            </div>
                            <div >
                                <Badge variant="info">Ordered</Badge>
                                <div>{props.data.MNOBJEDNANO_SUM}</div>
                            </div>
                            <div >
                                <Badge variant="info">Blocked</Badge>
                                <div>{props.data.MNBLOKOVANO_SUM}</div>
                            </div>

                        </Col>
                        <Col xs={8} >
                            <div>
                                <Badge variant="info">Sell Unit</Badge>
                                <div>{props.data.KOD_MJ} {productRegion ? "(" + productRegion.unitMaster.name + ")" : null}</div>
                            </div>
                            <div>
                                <Badge variant="info">Pcs in Sell Unit</Badge>
                                <div>{productRegion ? productRegion.erpData.piecesInSellUnit  : null}</div>

                                <Badge variant="info">Minimal Sell Unit Qty.</Badge>
                                <div>{productRegion ? productRegion.erpData.minimalSellUnitQuantity  : null}</div>
                            </div>
                           {/* <div>
                                <Badge variant="info">Box</Badge>
                                <div>{props.data.TEXT_1}</div>
                            </div>*/}
                            {/*<div>
                                <Badge variant="info">Supplier</Badge>
                                <div>{props.data.SUBJ_NAZEV}</div>
                            </div>*/}
                            <div>
                                <Badge variant="info">Consumption</Badge>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <div style={{marginRight: 5}}>
                                    <Badge variant="info">3 m</Badge>
                                    <div>{ (parseFloat(props.data.MNPOCET_PRODEJ_30).toFixed(0)) * 3 }</div>
                                </div>
                                <div style={{marginRight: 5}}>
                                    <Badge variant="info">6 m</Badge>
                                    <div>{ (parseFloat(props.data.MNPOCET_PRODEJ_Rok).toFixed(0)) / 2 }</div>
                                </div>
                                <div style={{marginRight: 5}}>
                                    <Badge variant="info">12 m</Badge>
                                    <div>{ parseFloat(props.data.MNPOCET_PRODEJ_Rok).toFixed(0) }</div>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Popover.Content>
            </Popover>

        )
    }

    return(
        props.data ?
            <OverlayTrigger trigger="hover" placement="left" key={props.key} overlay={popover({data: props.data})}>
                <FontAwesomeIcon icon={faInfoCircle} style={{fontSize: 20, cursor: 'pointer', color: '#66a4ff'}} />
            </OverlayTrigger>
            : null
    )
}

export default PopOverCz;