import React, {useEffect, useState} from "react";
import './ForecastStocksItems.scss';
import Badge from "react-bootstrap/Badge";
import {ForecastStocksItemWeeks} from "./ForecastStocksItemWeeks";
import moment from "moment";
import Moment from "react-moment";
import {ForecastStocksItemControl} from "./ForecastStocksItemControl";
import Modal from "../../shared/modal/Modal";
import {ForecastStocksEsoModal} from "./ForecastStocksEsoModal";
import {Alert} from "react-bootstrap";
import {ForecastStocksItemComments} from "./ForecastStocksItemComments";
import axios from "axios";

export const ForecastStocksItems = (props) => {
    const item = props.item;
    const [isOpenEso, setOpenEso] = useState(false);
    const outagDate = moment().add(item.tydenVypadekBud, 'w');
    const orderDate = moment(outagDate).subtract(item.tydnyDodani, 'w');
    const kodMeny = props.item.kodMeny ? true : false;
    const [showComments, setShowComments] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [commentsLoaded, setCommnetsLoaded] = useState(false);
    const [comments, setComments] = useState([]);


    const resolveVlStav = () => {
        if (item.vlStavDns === 0) {
            return "yellow"
        } else if ((item.vlStavDns === 1)) {
            return "green";

        } else {
            return "none";
        }
    }

    const vlStavDnsColor = resolveVlStav();

    useEffect(() => {
        if (!commentsLoaded && item.cisZbozi) {
            axios.get('product/' + item.cisZbozi + '/comments').then(res => {
                setComments(res.data);
                setCommnetsLoaded(true);
            });

        }
    }, [commentsLoaded, item.cisZbozi])

    const onSaveComment = () => {
        setCommnetsLoaded(false);
    }


    return (
        <div className="ForecastStockItem cBG_BS_light p-2 rounded border mt-3 mb-2 flex-column d-flex flex-wrap"
             key={"fcItem" + item.cisZbozi}>

            <div className="header">
                <div className="header1 mb-1" onClick={() => setShowMore(!showMore)}>
                    <div className="code">{item.cisZbozi}</div>
                    <div className="name">{item.nazevZbozi}</div>

                </div>
                <div className="d-flex flex-row">
                    <div className="type">
                        {item.vlNeAnoVypadek === 1 ? <Badge variant="danger mr-1 ">Kritické</Badge> : null}
                        {item.vlNeAnoVypadekBud === 1 ? <Badge variant="warning mr-1">Výpadek</Badge> : null}
                        {item.vlNeAnoVypadekBud === 0 && item.vlNeAnoVypadek === 0 ?
                            <Badge variant="success mr-1">Ok</Badge> : null}
                    </div>
                    <div className="text1">
                        <span className="">Dodavatel: </span><span className="emph">{item.subjNazev}</span>
                    </div>
                </div>
                <div className="d-flex flex-row">

                    <div className="d-flex flex-column">
                        
                        <div className="text1">
                            <span className="">Stav:</span> <span className="emph">{item.txtStavZbozi}</span>
                            <span className="keyemph">Supply planner:</span> <span
                            className="emph">{item.kodUzivPlanoNemedAdd}</span>
                            <span className="keyemph">Mj</span>: <span className="emph">{item.kodMj}</span>
                            <span className="keyemph">Priority:</span> <span className="emph">{item.jkpov}</span>
                            <span className="keyemph">Balení:</span><span className="emph">{item.text1}</span>
                            <span className="keyemph">Lead time:</span> <span
                            className="emph">{item.dobaDodani} (dnů) {item.tydnyDodani} (týdnů)</span>

                        </div>
                        <div className="text1">
                            <span className="">MOQ:</span> <span className="emph">{item.moq}</span>
                            <span className="keyemph">Stock QTY:</span> <span
                            className="emph">{item.mnFyzickyStav}</span>
                            <span className="keyemph">Stock QTY w. o.:</span> <span
                            className="emph">{item.mnFyzickyStavObj}</span>
                            <span className="keyemph">Safety stock:</span> <span
                            className="emph">{item.mnMinZasoba}</span>
                            <span className="keyemph">Blokace:</span> <span
                            className="emph">{item.mnBlokovanoCel}</span>
                            <span className="keyemph">Objednano:</span> <span
                            className="emph">{item.mnObjednanoCel}</span>
                            <span className="keyemph">Sales 90/3:</span> <span
                            className="emph">{item.mnProdej90Deleno3}</span>

                        </div>

                        <div className="text1"><span className="">Kategorie:</span> <span
                            className="emph">{item.skupZbozi1}</span> > <span
                            className="emph">{item.skupZbozi2}</span> > <span
                            className="emph">{item.skupZbozi3}</span> > <span className="emph">{item.skupZbozi4}</span>
                        </div>
                    </div>


                </div>
                

            </div>

           

                    <div className="cont1 d-flex flex-row">

                      
                        <div className="params params1">
                            <div>
                                <span className="key">Typ nákupu:</span>
                                <span className="val">{item.vlTypNak === 2 ? "Cizí" : "Vlastní"}</span>
                            </div>

                            <div><span className="key">Pocet v kartonu:</span> <span
                                className="val">{item.mnPocetVKartonu}</span></div>

                            <div><span className="key">Min. odběr:</span> <span className="val">{item.mnMinOdber}</span>
                            </div>
                            <div><span className="key">Dávka odběru:</span> <span
                                className="val">{item.mnDavkaOdberu}</span></div>

                            <div><span className="key">Stock qty in m.:</span> <span
                                className="val">{item.mnZasobaMesicu}</span></div>
                            <div><span className="key">Stock qty in m. w. o.:</span> <span
                                className="val">{item.mnZasobaMesicuObj}</span></div>
                            <div><span className="key">Objednáno:</span> <span
                                className="val">{item.mnObjednanoAll}</span></div>
                            <div><span className="key">Doporučeno do košíku:</span> <span
                                className="val">{item.mnNavrhNobDoklad}</span></div>
                            <div><span className="key">Upozornění NOB:</span> <span
                                className="val">{item.upozorneniNob}</span></div>
                            <div><span className="key">Blokace celkové:</span> <span
                                className="val">{item.mnBlokovanoCel}</span></div>
                            <div><span className="key">Blokace do LT:</span> <span
                                className="val">{item.mnBlokovanoLt}</span></div>


                        </div>
                        <div className="params params1">
                            <div><span className="key">Sales yr -2:</span> <span
                                className="val">{item.mnProdejRokuMinus2}</span></div>
                            <div><span className="key">Sales yr -1:</span> <span
                                className="val">{item.mnProdejRokuMinus1}</span></div>

                            <div><span className="key">Sales 30:</span> <span
                                className="val">{item.mnProdejMinus30}</span></div>
                            <div><span className="key">Sales by week:</span> <span
                                className="val">{item.mnProdejFcTyden}</span></div>
                            <div><span className="key">Weight Netto:</span> <span
                                className="val">{item.mnHmotnostNetto}</span></div>
                            <div><span className="key">Weight Brutto:</span> <span
                                className="val">{item.mnHmotnostBrutto}</span></div>
                            <div><span className="key">Objem:</span> <span className="val">{item.mnObjem}</span></div>
                            <div><span className="key">Ruční m. požadavek:</span> <span
                                className="val">{item.rvProdejMesic === 1 ? "ANO" : "NE"}</span></div>
                            <div><span className="key">Korekce:</span> <span className="val">{item.MnProdejMesic}</span>
                            </div>
                            <div>
                                <div>Důvod</div>
                                <div>{item.duvodProdejMesic}</div>
                            </div>


                        </div>
                        <div className="params params2">

                            <div>Výpadek v týdnu: <span
                                className="val">{item.tydenVypadekBud} {item.tydenVypadekBud === 0 ? null : (
                                <Moment format="DD.MM.YYYY" date={outagDate}/>)} </span></div>
                            {item.tydenVypadekBud === 0 ? null :
                                <div>Objednávka do: <span className="val"><Moment format="DD.MM.YYYY" date={orderDate}/></span>
                                </div>}
                            <div>Kod statu: <span className="val">{item.kodStatu} </span></div>
                            <div>Kod statu DOD: <span className="val">{item.kodStatuDod} </span></div>
                            <div>Incoterm: <span className="val">{item.predpisCena} {item.kodMeny} {item.textAdd}</span>
                            </div>
                            <div>COGs price: <span className="val">{item.hdFyzSkladCenaZbozSkladd} CZK</span></div>
                            <div>KN price: <span className="val">{item.hdJCenaKnAdd} CZK</span></div>
                            <div>
                                DNS price:<span style={{
                                backgroundColor: vlStavDnsColor,
                                color: 'black',
                                display: "inline-block",
                                paddingLeft: 3,
                                paddingRight: 3,
                                marginLeft: 3
                            }} className="val">{item.hdJCenaAdNs} CZK</span>
                            </div>


                        </div>
                        <div className="params params3">
                            {item.cisZbozi ?
                                <div className="picture border rounded">
                                    <img
                                        src={'https://ibatists4m.blob.core.windows.net/mains/' + item.cisZbozi + '_sm.webp'}
                                        onError={({currentTarget}) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = "";
                                        }} alt={"No image"}/>
                                </div>
                                : null}
                        </div>
                    </div>
                    <div className="cont2 d-flex flex-row">
                        {/* <div style={{width: 30}}></div>*/}
                        <div className="weeks">
                            <ForecastStocksItemWeeks item={item}/>
                        </div>
                    </div>


              


            {kodMeny ? null
                :
                <div className="mt-1">
                    <Alert variant="danger" style={{marginBottom: 5, fontSize: 14, padding: 5}}>
                        Pozor! Na položce není nastaven kód měny, nemá tedy přiřazený ceník.

                    </Alert>
                </div>
            }

            {item.vlstavZbozi === 2 ?

                <div className="mt-1">
                    <Alert variant="danger" style={{marginBottom: 5, fontSize: 14, padding: 5}}>
                        Pozor! Položka je ve stavu VÝPRODEJ.

                    </Alert>
                </div>

                : null
            }


            
            <div className="cont2 pt-2">
                <ForecastStocksItemControl commLength={comments.length}
                                           toggleComments={() => setShowComments(!showComments)} 
                                           refreshBasket={() => props.refreshBasket()}
                                           openModal={(val) => setOpenEso(val)} 
                                           code={item.cisZbozi}
                                           amountInBasket={item.amountInBasket ?? null} 
                                           moq={item.moq} mj={item.kodMj}
                                           objem={item.mnObjem}
                                           mnNavrh={item.mnNavrhNobDoklad}
                                           addToBasket={(d) => props.addToBasket(d)}
                />

            </div>
            <div className="cont2 pt-2">

                {showComments ? <ForecastStocksItemComments onSaveComment={() => onSaveComment()} comments={comments}
                                                            code={item.cisZbozi}/> : null}
            </div>

            <Modal isOpen={isOpenEso} onClose={() => {
                setOpenEso(false)
            }}>
                <ForecastStocksEsoModal cisZbozi={item.cisZbozi}/>
            </Modal>


        </div>
    )

}