import React, {useEffect, useState} from 'react';
import {faCheckCircle, faTimesCircle} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import './PhotoStatus.scss';
import DownloadIcon from "./DownloadIcon";

const PhotoStatus = ({targetContainer, allData, blobName}) => {
    
    const [item, setItem] = useState(null);
    
    useEffect(() => {
        
        let foundItem = allData.find(x=>x.publicUrl.includes(targetContainer) && x.blobName === blobName);
        
        setItem(foundItem);
        
    }, [allData, blobName]);
    
    const {t} = useTranslation();

    return (
        <div className={"photoStatus mb-2"}>
            
            {item ?
                <div className={"found"}>
                    <FontAwesomeIcon icon={faCheckCircle} className={"icon"}/>
                    {t('photoStatus.containerFound')} {targetContainer}

                    <DownloadIcon url={item.publicUrl} />
                </div> :
                <div className={"not-found"}>
                    <FontAwesomeIcon icon={faTimesCircle} className={"icon"}/>
                    {t('photoStatus.containerNotFound')} {targetContainer}
                </div>
            }
        </div>
    )
}

export default PhotoStatus;