import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Nav, Navbar, NavDropdown} from "react-bootstrap";
import {authenticationService} from '../../../helpers/AuthenticationService';
import {useTranslation, withTranslation} from 'react-i18next';
import {faSignOutAlt, faCog, faQuestion} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {useUserContext} from "../../../contexts/UserContext";

import './NavMenu.scss';
import Modal from "../modal/Modal";
import Report from "../Report";
import Badge from "react-bootstrap/Badge";

const NavMenu = () => {


    const user = useUserContext();
    const [isModalOpen, setIsModalOpen]  = useState(false);


    const logout = (e) => {
        authenticationService.logout();
    }


    const {t, i18n} = useTranslation();

    return (

        <React.Fragment>

            <Navbar bg="primary" variant="dark" expand="lg" className={"mb-3 pb-1 pt-1"}>
                <Navbar.Brand href="#home">iBatist</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <NavDropdown title={t('menu.catalog')} id="cat-basic-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/catalog">
                                {t('menu.products')}
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/manufacters">
                                {t('menu.manufacters')}
                            </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="Sharepoint" id="shrp-basic-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/documents">
                                {t('documents.documents')}
                            </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title={t('menu.category')} id="cat-basic-nav-dropdown">
                            {user.data.role === 'Admin' &&
                                <NavDropdown.Item as={Link} to="/category">
                                    {t('menu.category')}
                                </NavDropdown.Item>
                            }
                            <NavDropdown.Item as={Link} to="/categoryEso">
                                {t('menu.categoryEso')}
                            </NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link as={Link} to="/reports">
                            Reports
                        </Nav.Link>
                        <Nav.Link as={Link} to="/tags">
                            {t('menu.tags')}
                        </Nav.Link>
                        {/*<Nav.Link as={Link} to="/category">
                            {t('menu.category')}
                        </Nav.Link>*/}
                        {/*<Nav.Link as={Link} to="/documents">
                            documents
                        </Nav.Link>
                        <Nav.Link as={Link} to="/photos">
                            photos
                        </Nav.Link>*/}
                        {/*<Nav.Link as={Link} to="/categoryEso">
                            {t('menu.categoryEso')}
                        </Nav.Link>*/}
                        {user.data.role === 'Admin' &&
                        <NavDropdown title="Admin" id="basic-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/user">
                                {t('menu.users')}</NavDropdown.Item>
                            <NavDropdown.Divider/>
                            <NavDropdown.Item as={Link} to="/region">
                                {t('menu.regions')}
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/erp">
                                {t('menu.erp')}
                            </NavDropdown.Item>
                            <NavDropdown.Divider/>
                            <NavDropdown.Item as={Link} to="/mall">
                                MALL_CZ
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/batistsafety">
                                BatistSafety
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/shoptet">
                                Shoptet
                            </NavDropdown.Item>
                        </NavDropdown>
                        }
                    </Nav>
                    <Nav className="ml-auto">
                        
                        <select className={"form-select-sm"} style={{
                            fontSize: 11,
                            backgroundColor: 'transparent',
                            border: 0,
                            color: 'black',
                            cursor: 'pointer'
                        }} value={localStorage.getItem('lang') ? localStorage.getItem('lang') : 'cz'}
                                onChange={(o) => {
                                    i18n.changeLanguage(o.target.value);
                                    localStorage.removeItem('lang');
                                    localStorage.setItem('lang', o.target.value);
                                }}>
                            <option value="cz">CZ</option>
                            <option value="en">EN</option>
                            <option value="pl">PL</option>
                            <option value="pt">PT</option>
                        </select>


                        <Nav.Link as={Link} to="/settings">
                            <FontAwesomeIcon className="" icon={faCog}/>

                        </Nav.Link>
                        <Nav.Link onClick={() => {
                            setIsModalOpen(true);
                        }}>
                            <FontAwesomeIcon className="" icon={faQuestion}/>
                        </Nav.Link>
                        <Nav.Link onClick={e => logout(e)} as={Link} to="/">
                            <FontAwesomeIcon className="" icon={faSignOutAlt}/>

                        </Nav.Link>
                        <Nav.Item style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}> <Badge variant="warning">{user.data.username}</Badge></Nav.Item>


                    </Nav>

                </Navbar.Collapse>
            </Navbar>

            <Modal isOpen={isModalOpen} onClose={() => {
                setIsModalOpen(false)
            }}>
                <Report onClose={() => {
                    setIsModalOpen(false)
                }} />
            </Modal>
        </React.Fragment>

        /*<header>

            {user ?
                <div className="menu">
                    <div className="container">
                        <div>
                           
                            
                        </div>
                        <Link to="/"><FontAwesomeIcon className="i" icon={faHome}/>{t('menu.mainPage')}</Link>
                        <Link to="/catalog"><FontAwesomeIcon className="i" icon={faFileAlt}/>{t('menu.catalog')}</Link>
                        <Link to="/tags"><FontAwesomeIcon className="i" icon={faTags}/>{t('menu.tags')}</Link>
                        <Link to="/category"><FontAwesomeIcon className="i" icon={faTags}/>{t('menu.category')}</Link>
                        <Link to="/mall">mall.cz</Link>
                        {user.data.role === 'Admin' &&
                        <div className={"secure"}>

                            <Link to="/region">{t('menu.regions')}</Link>
                            <Link to="/erp">{t('menu.erp')}</Link>
                            <Link to="/user"><FontAwesomeIcon className="i" icon={faUsers}/>{t('menu.users')}</Link>
                        </div>
                        }
                        <Link to="/settings"><FontAwesomeIcon className="i" icon={faCog}/>{t('menu.settings')}</Link>
                        <Link onClick={e => logout(e)} to='/'><FontAwesomeIcon className="i"
                                                                               icon={faSignOutAlt}/>{t('menu.logout')}
                        </Link>
                        <select value={localStorage.getItem('lang') ? localStorage.getItem('lang') : 'cz'}
                                onChange={(o) => {
                                    i18n.changeLanguage(o.target.value);
                                    localStorage.removeItem('lang');
                                    localStorage.setItem('lang', o.target.value);
                                }}>
                            <option value="cz">CZ</option>
                            <option value="en">EN</option>
                            <option value="pl">PL</option>
                            <option value="pt">PT</option>
                        </select>
                        <div className={"item"}>{t('menu.role')}: {user.data.role}</div>
                    </div>
                </div>
                :
                <div className="menu">
                    <div className="container">
                        <Link to="/login">{t('menu.login')}</Link>
                        <select value={localStorage.getItem('lang') ? localStorage.getItem('lang') : 'cz'}
                                onChange={(o) => {
                                    i18n.changeLanguage(o.target.value);
                                    localStorage.removeItem('lang');
                                    localStorage.setItem('lang', o.target.value);
                                }}>
                            <option value="cz">CZ</option>
                            <option value="en">EN</option>
                            <option value="pl">PL</option>
                            <option value="pt">PT</option>
                        </select>
                    </div>
                </div>
            }

        </header>*/
    );
}

export default withTranslation()(NavMenu)
