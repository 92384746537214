import React, {useState, useEffect} from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import 'react-overlay-loader/styles.css';
import Modal from "../shared/modal/Modal";
import RegionAdd from "./RegionAdd";
import RegionEdit from "./RegionEdit";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {faEdit} from "@fortawesome/free-regular-svg-icons";

const columns = (clickHandler => [
    {
        name: 'ID',
        selector: 'id',
        sortable: true,
    },
    {
        name: 'Name',
        selector: 'name',
        sortable: true,
    },
    {
        name: 'Měna',
        selector: 'currency',
        sortable: true,
    },
    {
        name: 'Zkratka',
        selector: 'abbrev',
        sortable: true,
    },
    {
        cell: (row) => <button className={"btn-edit"} onClick={clickHandler} id={row.id}><FontAwesomeIcon icon={faEdit}/> Upravit</button>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
    },

]);

const Region = () => {

    const modalRef = React.useRef();
    const [regions, setRegions] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [actionNew, setActionNew] = useState(false);
    const [selectedId, setSelectedId] = useState(0);

    const [pagination] = useState({
        rowsPerPageText: 'Záznamů na stránku:',
        rangeSeparatorText: 'z',
        noRowsPerPage: false
    });

    useEffect(() => {

        if (!loaded) {
            populateData();
        }
    });


    const handleButtonClick = (state) => {
        setActionNew(false);
        setSelectedId(state.target.id);
        modalRef.current.openModal();
    };

    const handleChange = state => {

    };

    const populateData = () => {
        axios.get('/region/all').then(res => {
            setRegions(res.data);
            setLoaded(true);
        });
    };

    const handleRefresh = () => {

        modalRef.current.closeModal();
        populateData();
    };

    return (
        <div>
            <div className="subMenu">
                <button type={"button"} className={"btn-link"} onClick={() => {
                    setActionNew(true);
                    modalRef.current.openModal();
                }}><FontAwesomeIcon className="i" icon={faPlus}/> Přidat region
                </button>
            </div>


            <DataTable
                title="Regiony"

                data={regions}
                columns={columns(handleButtonClick)}
                onRowSelected={handleChange}
                paginationComponentOptions={pagination}
                pagination
                dense
            />


            <Modal ref={modalRef}>
                {actionNew ? <RegionAdd handleRefresh={handleRefresh}></RegionAdd> :
                    <RegionEdit id={selectedId} handleRefresh={handleRefresh}></RegionEdit>}
            </Modal>
        </div>
    )
}

export default Region;
