import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from '../locale/en.json'
import cz from '../locale/cz.json'
import pl from '../locale/pl.json'
import pt from '../locale/pt.json'

i18n.use(initReactI18next).init({
  fallbackLng: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'cz',
  lng: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'cz',
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: en,
    },
    cz: {
      translation: cz,
    },
    pl: {
      translation: pl,
    },
    pt: {
      translation: pt,
    },
  },
  react: {
    wait: true,
  }
})

export default i18n