import React, {useState, useEffect, useCallback} from 'react';
import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
import OurLoading from "../shared/OurLoading";
import InpurtError from "../InputError";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {IsReadOnly} from "../../helpers/RoleProvider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useUserContext} from "../../contexts/UserContext";
import axios from "axios";
import {ADD, useAlertContext} from "../../contexts/AlertContext";

const UpsertManufacter  = ({id, onClose, onCloseRefresh}) => {

    const {t} = useTranslation();
    const user = useUserContext();
    const {alertDispatch} = useAlertContext();
    
    const [editLoaded, setEditLoaded] = useState(false);
    const {register, handleSubmit, setValue, errors} = useForm();
    const [isLoading, setIsloading] = useState(false);

    useEffect(() => {
       
        if(id)
        {
            axios.get('api/manufacters/' + id).then((res)=>{
                setValue('web', res.data.item.web);
                setValue('name', res.data.item.name);
                setValue('note', res.data.item.note);
                
            }).catch((err)=>{
                
            }).finally(()=>{
                setEditLoaded(true);
            });
        }
        
    });

    const onSubmitForm = (data) => {
    
        setIsloading(true);
        
        if(!id)
        {
            axios.post('/api/manufacters', data).then((res) => {
                onClose();
                onCloseRefresh();

                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {state: 'success', message: t('manufacters.successCreate')}
                    }
                });
                
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                setIsloading(false);
            })
        }
        else
        {
            axios.put('/api/manufacters/' + id, data).then((res)=>{
                onClose();
                onCloseRefresh();

                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {state: 'success', message: t('manufacters.successUpdate')}
                    }
                });
            }).catch((err)=>{
                console.log(err);
            }).finally(() => {
                setIsloading(false);
            })
        }
        
    }
    
    return (
        <Container className={"upsertManufacter"}>

            <Row>

                {id ?
                    <Col as={Row}><h1>{t('manufacters.edit')}</h1>{!editLoaded ? <OurLoading/> : null}</Col>

                    : <Col as={Row}><h1>{t('manufacters.add')}</h1></Col>

                }
            </Row>
            <Row>
                <Form style={{width: "100%"}}>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>
                                {t("manufacters.name")}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                ref={register({required: true})}
                                maxLength={200}

                            />
                            <Form.Text>
                                {errors.name && errors.name.type === 'required' &&
                                <InpurtError message={t("validation.required")} showMessage={true}/>}
                            </Form.Text>

                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>
                                {t('manufacters.web')}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="web"
                                ref={register()}
                                maxLength={200}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row className={'mt-2'}>
                        <Form.Group as={Col}>
                            <Form.Label>{t('manufacters.note')}</Form.Label>
                            <Form.Control name={"note"} ref={register()} maxLength={5000} as="textarea" rows={5}/>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row className={"mt-3"}>
                        {isLoading ? <OurLoading/>

                            :

                            id ?

                                !IsReadOnly(user) ?
                                    <React.Fragment>
                                        <Button variant="success" type="button"
                                                onClick={handleSubmit(onSubmitForm)}>
                                            <FontAwesomeIcon className="i" icon={faSave}/>
                                            {t('manufacters.update')}
                                        </Button>
                                    </React.Fragment>
                                    : null
                                :
                                <Button variant="success" type="button" onClick={handleSubmit(onSubmitForm)}>
                                    <FontAwesomeIcon className="i" icon={faSave}/>
                                    {t('manufacters.create')}
                                </Button>
                        }


                    </Form.Row>
                </Form>
            </Row>
            
        </Container>
    )
    
    
}

export default UpsertManufacter;