import React from 'react';
import axios from 'axios';

const getFTPdata = () =>{
    axios.get('synchronize/GPgetFTPdata').then(res => {
        alert(res.data);

    }).catch(err=>{
        console.log(err);
    });
}

const processFiles = () =>{
    axios.get('synchronize/GPProcessFiles').then(res => {
        alert(res.data.message);
        if(res.data.result === 0){
            console.log(res.data.error);
        }
    }).catch(err=>{
        console.log(err);
    });
}

const testMall = () =>{
    axios.get('test/testMall').then(res => {

    }).catch(err=>{
        console.log(err);
    });
}


const GP = () => {



    return (
        <div className="GP" >
            <div>
                <h1>GP</h1>
                <button onClick={getFTPdata}>DownloadFilesFromFTP</button>
            </div>
            <div>
                <h1>GP</h1>
                <button onClick={processFiles}>ProcessFiles</button>
            </div>
            <div>
                <h1>Test MALL</h1>
                <button onClick={testMall}>Test MALL</button>
            </div>

        </div>
    );

}

export default GP;