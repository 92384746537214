import React, {useEffect, useState} from 'react';
import axios from "axios";
import {faSync} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ADD, useAlertContext} from "../../../contexts/AlertContext";
import {useTranslation} from "react-i18next";

const ProductSynchronize = ({id, refId}) => {

    const {t} = useTranslation();
    const [data, setData] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const {alertDispatch} = useAlertContext();

    useEffect(() => {


        const load = () => {
            axios.get('/product/' + id + '/eshops').then((res) => {
                console.log(res.data);
                setData(res.data);
            });
        }

        if (!loaded) {
            load();
            setLoaded(true);
        }

    }, [loaded, id]);

    const Synchronize = (eshop) => {


        let code = 0; //eso

        if (eshop !== 0) {
            code = eshop.eshopCode;
        }


        axios.post('synchronize/' + id + '/eshop/' + code).then((res) => {

            let state = 'success'
            let message = t('productEdit.syncTab.successMessage');

            switch (res.data) {

                case 1:
                    state = 'warning'
                    message = t('productEdit.syncTab.notGeneratedXML');
                    break;
                default:
                    state = 'success'
                    message = t('productEdit.syncTab.successMessage');
                    break;

            }

            alertDispatch({
                type: ADD,
                payload: {
                    content: {state: state, message: message}
                }
            });

        }).catch(function (res) {
            alertDispatch({
                type: ADD,
                payload: {
                    content: {state: 'warning', message: t('productEdit.syncTab.errorMessage')}
                }
            });
        });
    }

    return (
        <div className={"product-sync"}>

            {/*<div className={"box"}>
                <div className={"header"}>ESO</div>

                <div className={"row"}>
                    <div className={"row-item"}>
                        <button type={"button"} className={"btn1"} onClick={() => {
                            Synchronize(0)
                        }}><FontAwesomeIcon icon={faSync} className="i"/>{t('productEdit.syncTab.sync')}
                        </button>
                    </div>
                </div>
            </div>*/}

            {data.map((e) =>
                <div key={e.name} className={"box"}>
                    <div className={"header"}>
                        {e.name}
                    </div>

                    <div className={"row"}>
                        <div className={"row-item"}>
                            
                    <img src={'https://ibatists4m.blob.core.windows.net/'  + e.name.slice(0, -3) + '/' + refId + '_sm.webp'}  onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src="https://ibatists4m.blob.core.windows.net/originals/1024px-No_image_available.svg.png";
                    }}  width={"200"} alt={""}/>
                            <button  type={"button"} className={"btn1"} onClick={() => {
                                Synchronize(e)
                            }}><FontAwesomeIcon icon={faSync} className="i"/>{t('productEdit.syncTab.sync')}
                            </button>
                        </div>
                    </div>

                </div>)}
        </div>)

}

export default ProductSynchronize;