import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Button} from "react-bootstrap";
import Badge from 'react-bootstrap/Badge'
import {useTranslation} from "react-i18next";
import * as types from "../../documents/DocumentsTypes";
import OurLoading from "../../shared/OurLoading";
import "./Documents.scss";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEye, faShareSquare, faCloud} from '@fortawesome/free-solid-svg-icons'
import ModalOverModal from "../../shared/modal/ModalOverModal";
import {faEdit} from "@fortawesome/free-regular-svg-icons";
import FileIconBasedOnFIleExtension from "../../shared/sharepoint/FileIconBasedOnFileExtension";
import AddDocument from "../../documents/AddDocument";
import FileSize from "../../shared/sharepoint/FileSize";
import ShareModal from "../../shared/sharepoint/ShareModal";


/*

sharepoint documents on product

*/


const Documents = ({refId}) => {

    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState([]);
    const [documentTypes, setDocumentTypes] = useState([]);
    const [docShrpId, setDocShrpId] = useState(0);
    const {t} = useTranslation();
    const [isModalEditOpen, setIsModalEditOpen] = useState(false);
    const [isModalShareOpen, setIsModalShareOpen] = useState(false);

    /* filtr zobrazenych typu */
    const [showType, setShowType] = useState('all');

    useEffect(() => {

        if (!loaded) {
            axios.get('/product/' + refId + '/documents').then((res) => {

                setData(res.data.items);
                setDocumentTypes(types.returnDataForDocumentsOnProduct('cz'));
                setLoaded(true);
            });


        }

    }, [loaded, refId]);

    const handleEditDocClick = (id) => {
        setDocShrpId(id);
        setIsModalEditOpen(true);
    }

    const handleShareDocClick = (id) => {
        setDocShrpId(id);
        setIsModalShareOpen(true);
    }

    const handleDownload = (id) => {

        var url = "/SharepointDocumentsPrivate/" + id + "/share";

        /*if (validTo !== undefined) {
            url += '/' + validTo;
        }*/

        axios.get(url).then((res) => {
            window.open(res.data.shareLink, '_blank');

        });
    }


    const DocumentItem = (props) => {
        return (
            <div className={"item"}>
                <div className={ "item2 ctrl" }>
                    <div className={"icon"}>
                        <FileIconBasedOnFIleExtension extension={props.data.fileExtension}/>
                    </div>
                    <div className={"btns"}>

                        <Button
                            title={"edit"}
                            size={"sm"}
                            variant={"primary"}
                            className={"mr-1"}
                            onClick={() => {
                                handleEditDocClick(props.data.id);
                            }}><FontAwesomeIcon icon={faEdit}/>
                        </Button>

                        <Button
                            title={"show"}
                            size={"sm"}
                            variant={"secondary"}
                            className={"mr-1"}
                            onClick={() => {
                                handleDownload(props.data.id);
                            }}><FontAwesomeIcon icon={faEye}/>
                        </Button>

                        <Button
                            title={"share"}
                            size={"sm"}
                            variant={"warning"}
                            className={"mr-1"}
                            onClick={() => {
                                handleShareDocClick(props.data.id);
                            }}><FontAwesomeIcon icon={faShareSquare}/>
                        </Button>
                        
                    </div>
                    
                    


                </div>
                <div className={"item2 infoBadges"}>
                    {props.data.active ? null : <Badge variant="danger">Neaktivní</Badge>}
                    {props.data.isPublic ? <Badge variant="warning">Veřejný</Badge> : null}
                </div>
                <div className={"item2 title"}>{props.data.title}</div>
                <div className={"item2 fileSize"}><FileSize fileSize={props.data.fileSize}/></div>
                <div className={"item2 fileName"}>{props.data.displayName}.{props.data.fileExtension}</div>


            </div>
        )

    };

    const DocumentHeader = (props) => {
        return (
            <div className={"cont"}>
                <h2>{props.name}</h2>
                <div className={"contItems"}>
                    {props.data.length > 0 ? props.data.map((x, i) => {
                        return (<DocumentItem data={x} key={i} name={props.name}/>)
                    }) : <div>Žádný {props.name}</div>}
                </div>
            </div>)
    };


    /* main component */

    return (
        <div className={"documentsOnProduct"}>
            <h1>
                <FontAwesomeIcon icon={faCloud} className="i"/>
                {t('documents.documentsOnShrp')}
            </h1>
            <div className="mb-4">
                <Button key={0} className="m-1" size="sm" variant="primary" onClick={() => {
                    setShowType('all')
                }}>Vše</Button>
                {documentTypes.map((x, i) => {
                    let data = documentTypes.filter(f => f.order === i);
                    return (<Button key={i + 1} className="m-1" size="sm" variant="primary" onClick={() => {
                        setShowType(data[0].code)
                    }}>{data[0].name}</Button>)
                })}
            </div>

            {!loaded ? <OurLoading/> :


                <React.Fragment>

                    <div className={"documents"}>
                        {(data === undefined || data.length === 0) && !loaded ?
                            <div>Na produktu nejsou žádné dokumenty na sharepoitnu</div>
                            :
                            documentTypes.map((x, i) => {
                                let dataToComponent = data.filter(f => f.typeOfDocument === x.code);
                                return (
                                    showType === 'all' || showType === documentTypes[i].code ?
                                        <DocumentHeader data={dataToComponent} name={documentTypes[i].name} key={i}
                                                        code={documentTypes[i].code}/>
                                        :

                                        null

                                )

                            })


                        }

                    </div>


                    <ModalOverModal isOpen={isModalEditOpen} onClose={() => {
                        setIsModalEditOpen(false)
                    }}>
                        <AddDocument id={docShrpId} edit={true} onCloseRefresh={() => setLoaded(false)} onClose={() => {
                            setIsModalEditOpen(false);
                            setLoaded(false)
                        }}/>
                    </ModalOverModal>
                    <ModalOverModal isOpen={isModalShareOpen} onClose={() => {
                        setIsModalShareOpen(false)
                    }}>
                        <ShareModal id={docShrpId} url={"SharepointDocumentsPrivate"}/>
                    </ModalOverModal>
                </React.Fragment>
            }

        </div>


    );


}

export default Documents;