import React, {useEffect, useState} from 'react';
import {Button, Col, Form} from "react-bootstrap";
import AsyncSelect from "react-select/async";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWindowClose} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {IsReadOnly} from "../../helpers/RoleProvider";
import {useUserContext} from "../../contexts/UserContext";


const RefsOnDocument = (props) => {

    //data
    const [refData, setRefData] = useState(props.parentData && props.parentData.length > 0 ? props.parentData : []);
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState();
    const [inputValue, setInputValue] = useState();
    const user = useUserContext();


    //handlers
    const handleRefIdItemsClick = (key) => {
        let arr = [...refData];
        arr.splice(key, 1);
        setRefData(arr);
        props.setParentData(arr);
    }

    const checkAndPushToRefData = (arrToBePushed) => {
        let arrComplete = [...refData];

        for (let y = 0; y < arrToBePushed.length; y++) {
            let push = true;
            for (let i = 0; i < arrComplete.length; i++) {
                if (arrComplete[i].value === arrToBePushed[y].value) {
                    console.log('This code already exists');
                    push = false;
                }

            }
            if (push) arrComplete.push({value: arrToBePushed[y].value, label: arrToBePushed[y].label})


        }

        arrComplete.sort((a, b) => {
            if (a.value < b.value)
                return -1;
            if (a.value > b.value)
                return 1;
            return 0;
        });

        setRefData(arrComplete);
        props.setParentData(arrComplete);
    }

    const handleOptionsRefIdClick = (event) => {

        setValue(event);
        let arrToBePushed = [];
        if (event !== null) {
            arrToBePushed.push(event);
            checkAndPushToRefData(arrToBePushed);
            setValue(event.value);
        }


    }

    const onInputChange = (text, {action}) => (action === 'input-change') && setInputValue(text);
    const onFocus = () => value && setInputValue(value.label);


    const getOptionsForRef = (inputValue) => {
        let params = new URLSearchParams();
        params.append('States', '-2');
        params.append('States', '-1');
        params.append('States', '0');
        params.append('States', '3');
        params.append('Code', inputValue);
        params.append('Take', '200');
        params.append('OrderByProductCode', 'true');
        params.append('DisplayLang', '0');
        params.append('Region', '1');


        return axios.get("/sharepoint/productsRef?" + params).then(res => {
            setOptions(res.data);
            return (res.data);
        });
    }

    const getRefsFromManufacturers = () => {
        let params = new URLSearchParams();

        params.append('States', '-2');
        params.append('States', '-1');
        params.append('States', '0');
        params.append('States', '3');
        params.append('Manufacters', props.manufacturer.value);
        params.append('DisplayLang', '0');
        params.append('Region', '1');

        return axios.get("/sharepoint/productsRef?" + params).then(res => {

            checkAndPushToRefData(res.data);

        });
    }

    return (
        <React.Fragment>
            <Form.Row>
                <Form.Label className="mb-1">Vazba na REF produktu</Form.Label>
            </Form.Row>
            <Form.Row>

                {!IsReadOnly(user) ?
                    <Button disabled={props.manufacturer && props.manufacturer.value ? false : true } onClick={getRefsFromManufacturers} className="m-3">
                        Načíst ref výrobce
                    </Button>
                : null}
                
                
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col}>
                    <AsyncSelect
                        loadOptions={(e) => getOptionsForRef(e)}
                        valueKey={"value"}
                        labelKey={"label"}
                        onChange={(e) => {
                            handleOptionsRefIdClick(e)
                        }}
                        cacheOptions
                        defaultOptions={options}
                        //isLoading={optionsLoading}
                        //isMulti={true}
                        closeMenuOnSelect={false}
                        onBlurResetsInput={false}
                        //value={value}
                        onCloseResetsInput={false}
                        onFocus={onFocus}
                        value={value}
                        inputValue={inputValue}
                        onInputChange={onInputChange}
                        

                    />
                </Form.Group>
            </Form.Row>
            {refData && refData.length > 0 ?

                <Form.Row>
                    <div className={'cont'}>
                        {
                            refData.map((x, i) => {
                                return (
                                    <div className={'item'} key={i}>
                                        <span className="count">{i + 1}.</span>
                                        <div className="item2">
                                             <span onClick={() => {
                                                 handleRefIdItemsClick(i)
                                             }}>
                                                <FontAwesomeIcon className="i" icon={faWindowClose}/> 
                                             </span>
                                            <span className="name">{x.label}</span>
                                        </div>

                                    </div>
                                );
                            })
                        }
                    </div>
                </Form.Row>
                : null}
        </React.Fragment>

    );

}

export default RefsOnDocument;