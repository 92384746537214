import React, {useState} from "react";
import axios from 'axios';
import EditLocale from './editTabs/EditLocale';
import AddLocale from './editTabs/AddLocale';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs'
import 'react-tabs/style/react-tabs.css';
import {Link} from "react-router-dom";
import ProductEshop from "../eshop/ProductEshop";
import {useTranslation} from 'react-i18next';
import {ADD, useAlertContext} from "../../contexts/AlertContext";
import './Edit.scss';
import ProductSynchronize from "./editTabs/ProductSynchronize";
import {

    faInfoCircle,
    faLanguage,
    faStore,
    faGlobeAmericas,
    faSync, faPencilAlt,

} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ProductRegion from "../region/ProductRegion";

import EditBasic from "./editTabs/EditBasic";
import {useUserContext} from "../../contexts/UserContext";
import {IsReadOnly} from "../../helpers/RoleProvider";

const ProdcutEdit = (props) => {
    const {alertDispatch} = useAlertContext();
    const user = useUserContext();
    const {t} = useTranslation();


    const [languageLocale, setLanguageLocale] = useState([]);
    const [showAddLocale, setShowAddLocale] = useState(false);
    const [showEditable, setShowEditable] = useState(false);
    const [selectedLocaleId, setSelectedLocaleId] = useState(0);

    const handleLocaleEdit = (e) => {
        setShowEditable(true);
        setShowAddLocale(false);
        setSelectedLocaleId(e);
    }

    const handleFinished = (productId) => {

        axios.get('/product/' + productId + '/locales').then(res => {

            setShowAddLocale(false);
            setShowEditable(false);
            setLanguageLocale(res.data);

            alertDispatch({
                type: ADD,
                payload: {
                    content: {state: 'success', message: 'Položka byla uložena.'}
                }
            });

        }).catch(err => {
            console.log(err);
        });
    };
    
    const handleShowError = (message) => {
        alertDispatch({
            type: ADD,
            payload: {
                content: {state: 'warning', message: message}
            }
        });
    }


    const locales = languageLocale && languageLocale.map((object) =>
        <div className={"locale " + (object.langId === selectedLocaleId ? " selected" : '')} key={object.id}>
            <Link onClick={() => handleLocaleEdit(object.langId)}
                  to="#"> {'[' + object.abbrev + '] ' + object.nameIbatist}</Link>
        </div>
    )

    const handleImageDeleted = () => {
    }

    return (
        <div className={"edit"}>
            <h1><FontAwesomeIcon icon={faPencilAlt} className="i"/>{props.name}</h1>

            <Tabs forceRenderTabPanel={false} defaultIndex={0}>
                <TabList>
                    <Tab><FontAwesomeIcon icon={faInfoCircle} className="i"/> {t('productEdit.baseInformation')}</Tab>
                    <Tab><FontAwesomeIcon icon={faLanguage} className="i"/>{t('productEdit.languages')}</Tab>
                    <Tab><FontAwesomeIcon icon={faGlobeAmericas} className="i"/>{t('productEdit.regions')}</Tab>
                    {!IsReadOnly(user) ?
                        <React.Fragment>
                            <Tab><FontAwesomeIcon icon={faStore} className="i"/>{t('productEdit.eshops')}</Tab>
                            
                            <Tab><FontAwesomeIcon icon={faSync} className="i"/>{t('productEdit.sync')}</Tab>
                        </React.Fragment>
                        : null}
                </TabList>

                <TabPanel>
                    <EditBasic id={props.id} refId={props.refId} setLanguageLocale={setLanguageLocale} lang={languageLocale}/>
                </TabPanel>
                <TabPanel>
                    <div className="locale-box">
                        <div className="locales">
                            <div className={"locale " + (selectedLocaleId === -1 ? "selected" : "")}>
                                {!IsReadOnly(user) ?
                                    <Link onClick={() => {
                                        setSelectedLocaleId(-1);
                                        setShowAddLocale(true);
                                        setShowEditable(false)
                                    }} to="#"> + {t('productEdit.languageTab.addLocale')}</Link> : null}
                            </div>
                            {locales}
                        </div>
                        {showEditable ? (
                                <EditLocale handleFinished={handleFinished}
                                            handleShowError={handleShowError}
                                            handleImageDeleted={handleImageDeleted}
                                            productId={props.id}
                                            key={selectedLocaleId}
                                            allLocales={languageLocale}
                                            locale={languageLocale.find(x => x.langId === selectedLocaleId)}/>) :
                            showAddLocale ?

                                <AddLocale handleFinished={handleFinished}
                                           handleShowError={handleShowError}
                                           productId={props.id}/> : t('productEdit.languageTab.choose')}
                    </div>
                </TabPanel>
               <TabPanel>
                    <ProductRegion id={props.id} refId={props.refId}/>
                </TabPanel>
                
                
                {!IsReadOnly(user) ?
                    <React.Fragment>
                        <TabPanel>
                            <ProductEshop key={languageLocale.length} id={props.id} locales={languageLocale}/>
                        </TabPanel>
                        <TabPanel>
                            <ProductSynchronize id={props.id} refId={props.refId}/>
                        </TabPanel>
                    </React.Fragment>
                    : null}
            </Tabs>
        </div>

    )


}

export default ProdcutEdit