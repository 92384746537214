import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFilePdf, faFile, faFileWord} from '@fortawesome/free-solid-svg-icons'

const FileIconBasedOnFIleExtension = (props) => {
    let faIcon = faFile;
    switch(props.extension) {
        case 'pdf':
            faIcon = faFilePdf
            break;
        case 'docx':
            faIcon = faFileWord
            break;
        default:
        // code block
    }
    
    return(
        <FontAwesomeIcon icon={faIcon} className={"i"} />
    )
    
}

export default FileIconBasedOnFIleExtension;