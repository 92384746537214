import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import axios from "axios";
import Langs from "../shared/langs/Langs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import Modal from "../shared/modal/Modal";
import AddEditTag from "./AddEditTag";
import Tag from "./Tag";
import "./Tagy.scss";
import {Button, Col, Container, Row} from "react-bootstrap";
import {useUserContext} from "../../contexts/UserContext";
import {IsReadOnly} from "../../helpers/RoleProvider";


const Tagy = () => {

    const {t, i18n} = useTranslation();
    const user = useUserContext();
    const [tags, setTags] = useState([]);
    const [selectedTag, setSelectedTag] = useState(0);
    const [tagModalOpen, setTagModalOpen] = useState(false);
    const [tagModalAddOpen, setTagModalAddOpen] = useState(false);

    const [langAbbrv, setLangAbbrv] = useState(i18n.language);
    const [langs, setLangs] = useState([]);
    const [seacrhData, setSearchData] = useState([]);
    const [seacrhString, setSearchString] = useState("");
    const [loaded, setLoaded] = useState(false);


    const setLang = (lang) => {
        setLangAbbrv(lang);

    }

    const search = (e) => {
        let text = e.target.value.toLowerCase();
        setSearchString(text);
        let searchArr = [];
        searchArr = tags.filter(x => x.name.toLowerCase().indexOf(text) > -1);
        setSearchData(searchArr);

    }

    const openModalAddTag = () => {
        setTagModalAddOpen(true);
    }

    const openModalEditTag = (e) => {
        let id = e.target.closest(".tag").getAttribute("data-id");
        setSelectedTag(parseInt(id));
        setTagModalOpen(true);
        //modalRefEdit.current.openModal();
    }


    const getLangs =  () => {
        axios.get('/lang').then(res => {
            setLangs(res.data);
        });

    }

    useEffect(() => {

            getLangs();
    }, []);

    useEffect(() => {

        const getTags = () => {
            axios.get('/tag/' + langAbbrv + '/get').then(res => {
                setTags(res.data);
            });

        }

        if(!loaded) {
            getTags();
            setLoaded(true);
        }
    }, [langAbbrv, loaded]);



    return (
        <Container className="tagy">
            <Row className="p-3">
                {!IsReadOnly(user) ?
                <Button disabled type={"button"} variant={"primary"} onClick={() => openModalAddTag()} ><FontAwesomeIcon className="i" icon={faPlus}/>{t('tags.add')}</Button>
                   : null} 

            </Row>
            <Row className="p-3">
                <Langs langs={langs} setLang={setLang} actualLang={langAbbrv} />
            </Row>
            <Row>
                <Col className={"tagBody"}>
                    <div className={"search"}>
                        <input className={"form-control"} type={"search"} placeholder={"search"} onChange={search} />
                    </div>
                    <div className={"tagContent"}>

                        {
                            seacrhData.length > 0 || seacrhString > ""
                                ? seacrhData.map((x,k) => {
                                    return(
                                        <Tag key={k} openEdit={openModalEditTag} id={x.id} note={x.note} name={x.name} />
                                    )

                                })

                                : tags.map((x,k) => {
                                    return(
                                        <Tag key={k} openEdit={openModalEditTag} id={x.id} note={x.note} name={x.name} />
                                    )

                                })
                        }
                    </div>
                </Col>
            </Row>            

            

            <Modal  isOpen={tagModalAddOpen} onClose={()=>{setTagModalAddOpen(false); }} >
                <AddEditTag id={selectedTag} edit={false} onClose={()=>{setTagModalAddOpen(false); setLoaded(false);}}></AddEditTag>
            </Modal>

            <Modal  isOpen={tagModalOpen} onClose={()=>{setTagModalOpen(false); }}>
                <AddEditTag id={selectedTag} edit={true} onClose={()=>{setTagModalOpen(false); setLoaded(false);}}></AddEditTag>
            </Modal>

        </Container>
    );

}

export default Tagy;