import React from 'react';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './InputError.scss';

const InputError = (props) => {

    return (

       <div className={"error-icon"}>
          
           {props.showMessage ? <p>{props.message}</p> :    <FontAwesomeIcon icon={faInfoCircle} title={props.message}/>}
          
       </div>
    );
}

export default InputError;