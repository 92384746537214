import React from 'react';
import "./Tag.scss";
import LabelInfo from "../LabelInfo";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useUserContext} from "../../contexts/UserContext";
import {IsReadOnly} from "../../helpers/RoleProvider";


const Tag = (props) => {

    const user = useUserContext();


    return (
        <React.Fragment>
            {!IsReadOnly(user) ?
                <div className="tag" data-id={props.id} onClick={props.openEdit}>
                    {props.usedInEditProd ?
                        <div onClick={props.remove} title={"Remove the tag from product"} className={"remove"}>
                            <FontAwesomeIcon icon={faTimesCircle} className="i"/></div> : null}
                    <div className={"name"}>{props.name}</div>
                    {props.note ?
                        <div className={"icon"}><LabelInfo label={""} iconText={props.note}></LabelInfo></div> : null}


                </div> :

                <div className="tag">
                    {props.usedInEditProd ?
                        <div onClick={props.remove} title={"Remove the tag from product"} className={"remove"}>
                            <FontAwesomeIcon icon={faTimesCircle} className="i"/></div> : null}
                    <div className={"name"}>{props.name}</div>
                    {props.note ?
                        <div className={"icon"}><LabelInfo label={""} iconText={props.note}></LabelInfo></div> : null}


                </div>}
        </React.Fragment>
    );

}

export default Tag;