import React, {useState} from "react";
import Badge from "react-bootstrap/Badge";
import {Col, OverlayTrigger, Popover, Row} from "react-bootstrap";


export const ForecastStocksItemWeeks = ({item}) => {

    const [show, setShow] = useState(false);

    let weeks = []
    
    for (let i = 0; i < 31; i++) {
        
        let mnTyden = "mnTyden";
        let variant = "success";
        let vlStav = "vlStav";
      
       
        if(i < 10){
            mnTyden += "0";
            vlStav += "0";
        }
        mnTyden +=  i.toString();
        vlStav +=  i.toString();
        
        if(item[vlStav] === 0){
            if(i < item.tydenVypadekBud){
                variant="light";
            } else {
                variant="warning";
            }
            
        }else if(item[vlStav] === -1){
            variant = "danger";
        }

        const popover = (props) => {

            return(
                <Popover id={"popover-forecast-" + i }  >
                    <Popover.Title as="h3"> Týden  {i}  </Popover.Title>

                    <Popover.Content style={{maxWidth: '100%'}}>
                        <Row>
                            <Col xs={12} >
                                <div >
                                    <Badge variant={variant}>MNTÝDEN</Badge>
                                    <div>{item[mnTyden]}</div>
                                </div>
                            </Col>
                        </Row>
                    </Popover.Content>
                </Popover>
            )
        }
        
        weeks.push(
           
                <div className="weekItem" key={"wi-" + i}>
                    <div className="wNo">{i}</div>
                    <Badge variant={variant} className="wBadge" >{item[mnTyden]} </Badge>
                </div>
        )
       
    }
    
    return weeks;
        
}