import React, {useState} from 'react';
import {Col, Container, Row, Form, Button} from "react-bootstrap";
import axios from "axios";
import {ADD, useAlertContext} from "../../../contexts/AlertContext";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShareSquare} from "@fortawesome/free-solid-svg-icons";


const ShareModal = (props) => {

    const [validTo, setValidTo] = useState();
    const [showValidTo, setShowValidTo] = useState(false);
    const [generatedLink, setGeneratedLink] = useState("");
    const [loadingLink, setLoadingLink] = useState(false);
    const {alertDispatch} = useAlertContext();
    const {t} = useTranslation();


    const handleShareButton = () => {
        setLoadingLink(true);

        var url = "/" + props.url + "/" + props.id + "/share";

        if (validTo !== undefined) {
            url += '/' + validTo;
        }

        axios.get(url).then((res) => {
                navigator.clipboard.writeText(res.data.shareLink);
                setGeneratedLink(res.data.shareLink);

                alertDispatch({
                    type: ADD,
                    payload: {
                        content: {state: 'success', message: t('documents.copied')}
                    }
                })
            
            setLoadingLink(false)


        });
    }
   
    
    
  return(
      <Container className={"shareModal"}>
          <Row className={"p-2"}>
              <Col><h1>Share document</h1></Col>
          </Row>
          
          <Row className={"p-2"}>
              <Col>
                  {loadingLink ?
                      <Button
                          title={"show"}
                          size={"sm"}
                          variant={"warning"}
                          className={"mr-1"}
                          disabled={true}
                          >Generating link...
                      </Button>
                  
                  :
                      <Button
                          title={"show"}
                          size={"sm"}
                          variant={"warning"}
                          className={"mr-1"}
                          onClick={() => {
                              handleShareButton();
                          }}><FontAwesomeIcon icon={faShareSquare}/> Vygenerovat link pro sdilenni
                      </Button>
                  }
              
              </Col>
              <Col>
                  <Row>
                  <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Casove omezit sdileni"
                      value={showValidTo}
                      onChange={() => setShowValidTo(!showValidTo)}
                  />
                  </Row>
                  <Row>
                      {showValidTo ?
                          <React.Fragment>
                          
                                    <Form.Label className={"mt-3"}>Platnost do</Form.Label>
                          <Form.Control type={"date"} value={validTo} name="validTo" onChange={(e) => setValidTo(e.target.value)}/>
                          </React.Fragment>
                                  
                                  
                              
                          : null}
                  </Row>
              </Col>
          </Row>
          
          
          <Row className={"p-2"}>
              <Col>
                  <Form.Label>Vygenerovany link pro sdileni</Form.Label>
                  <Form.Control readOnly={true} as="textarea" rows={3} value={generatedLink} />
              </Col>
          </Row>
          
          
      </Container>
  );
    
}

export default ShareModal;