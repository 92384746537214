import React from 'react';


const FileSize = (props) => {

    /*let size = props.fileSize ? parseFloat(props.fileSize) : 0;
    let unit = '';
    if (size < 100){
        size = size.toFixed(2);
        unit = 'B';
    } 
    else if(size >= 100 && size < 100000)
    {
        size = size.toFixed(2);
        unit = 'kb';
    }
    else{
        //size = (size * 9.537 * 0.000001).toFixed(2);
        var i = parseInt(Math.floor(Math.log(size) / Math.log(1024)));
        size = (size / Math.pow(1024, i)).toFixed(2);
        unit = 'MB';
    }*/

    let bytes = props.fileSize ? parseFloat(props.fileSize) : 0;

    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

    let size = (bytes / Math.pow(1024, i)).toFixed(2);
    let unit = sizes[i];
    return (
        <div><span>{size}</span><span>{unit}</span></div>
    )

}

export default FileSize;